<template>
  <Head title="Add a Gift" />
  <form @submit.prevent>
    <div class="flex flex-row justify-between items-end border-b border-gray-300 dark:border-gray-600 ">
      <h1 class="text-3xl font-semibold pb-4 flex-grow">
        Select your delivery method
      </h1>
      <StoreLogo :store="store" class="w-[150px] flex-grow-0" />
    </div>

    <p class="pt-6 pb-2 text-sm md:text-base md:tracking-tight">
      This gift will be fulfilled by our partner store {{ store.title }}. This means they will ship the gift directly to you when it is paid for.
    </p>

    <div class="pt-6 text-gray-900 dark:text-gray-50">
      <h2 class="text-lg font-medium text-gray-900 dark:text-gray-50 block">Current shipping details</h2>
      <div class="pb-6">
        <p class="text-gray-600 dark:text-gray-200 text-sm pt-2">
          {{ address.address_1 }}{{ address.address_2 ? ', ' + address.address_2 : '' }}, {{ address.suburb }}, {{ address.state }}, {{ address.country }}
          <Link :href="route('gift.shopify.create', gift.id)" class="font-semibold text-youpay-navy dark:text-youpay-pink hover:text-youpay-teal focus:outline-none focus:underline">
            (edit)
          </Link>
        </p>

      </div>
      <h2 class="text-lg font-medium text-gray-900 dark:text-gray-50 block">Select a delivery method</h2>

      <RadioGroup v-if="shipping_methods?.length" v-model="form.method" class="pt-4">
        <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          <RadioGroupOption as="template" v-for="deliveryMethod in shipping_methods" :key="deliveryMethod.handle" :value="deliveryMethod.handle" v-slot="{ checked, active }">
            <div :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'ring-2 ring-youpay-pink' : '', 'relative flex cursor-pointer rounded-lg border bg-white dark:bg-white/[0.05] p-4 shadow-sm focus:outline-none']">
              <span class="flex flex-1">
                <span class="flex flex-col">
                  <RadioGroupLabel as="span" class="block text-sm font-medium dark:text-white text-gray-900">{{ deliveryMethod.title }}</RadioGroupLabel>
                  <RadioGroupDescription as="span" class="mt-2 text-sm text-gray-900 dark:text-white font-semibold">{{ deliveryMethod.price_formatted }}</RadioGroupDescription>
                </span>
              </span>
              <CheckCircleIcon v-if="checked" class="h-5 w-5 text-youpay-navy dark:text-youpay-pink" aria-hidden="true" />
              <span :class="[active ? 'border' : 'border-2', checked ? 'border-youpay-pink' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
            </div>
          </RadioGroupOption>
        </div>
        <InputError class="mt-2" :message="form.errors.method"/>
      </RadioGroup>
      <div v-else class="pt-2 text-sm text-red-600 dark:text-red-400">
        It looks like there are no delivery methods available for this gift. Please check your address and try again.
      </div>

      <InputError class="mt-2" :message="form.errors.method"/>
    </div>

    <div v-if="submitted && form.hasErrors" class="mt-6 text-sm text-red-600 dark:text-red-400">
      Looks like there are some errors. Once you've fixed them, press the "Add Gift to Wishlist" button.
    </div>

    <button
      type="button"
      :disabled="form.processing"
      @click.prevent="submit"
      class="mt-8 w-full rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-youpay-navy-light hover:bg-youpay-navy-alt focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      :class="form.processing ? 'opacity-50 cursor-not-allowed' : ''"
    >
      <span v-if="!form.processing">
        Add Gift to Wishlist
      </span>
      <span v-else>
        Please wait...
      </span>
    </button>
  </form>
</template>

<script setup lang="ts">
import {Link, Head} from "@inertiajs/vue3";
import {computed, onMounted, ref} from 'vue'
import {Cart} from "@/web/youpay-me-v3/types/checkout";
import {Profile, User} from "@/web/youpay-me-v3/types";
import { useForm } from 'laravel-precognition-vue-inertia';
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import StoreLogo from "@/web/youpay-me-v3/Components/Blocks/StoreLogo.vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import ShopifyLayout from "@/web/youpay-me-v3/Layouts/ShopifyLayout.vue";

// Basic Variables
const submitted = ref(false);

// Get Props
const props = defineProps<{
  auth: User,
  profile: Profile,
  cart: Cart,
  gift: any,
  store: any,
  shipping_methods?: Array<any>,
  product: any,
}>();

const address = computed(() => {
  return props.cart?.shopper?.address;
});

const form = useForm('post', route('gift.shopify.shipping', props.gift.id), {
  method: props.cart?.shipping?.handle ? props.shipping_methods.find(method => method.handle === props.cart.shipping.handle )?.handle : null,
});

// watch and upate function
const submit = () => {
  form.clearErrors();
  form.submit({
    preserveScroll: true,
    onSuccess: (page) => {
      trackEvent('gift_create_end', {
        product: props.product.id,
        type: props.product.type,
        store: props.store.title,
        method: form.method,
      });
    }
  });
};


/**
 * Load Stripe
 */
onMounted(async () => {
  trackEvent('gift_create_shipping_method', {
    product: props.product.id,
    type: props.product.type,
    store: props.store.title,
  });
});

defineOptions({layout: ShopifyLayout})
</script>