<template>
  <Head title="" />

  <div class="mt-8 mx-auto max-w-4xl rounded-md bg-white p-8 text-gray-900 gift-page dark:bg-white/5 dark:text-white">
    <header class="flex flex-col-reverse items-center justify-between pb-4 gap-x-6 gap-y-2 sm:flex-row relative">
      <div class="">
        <h1 class="text-2xl font-semibold">
          Redeem YouCoin from a Store
        </h1>
        <p class="pt-4">
          Use your rewards to redeem gift cards at your favorite stores
        </p>
      </div>
      <div class="-mt-4 flex-shrink-0 ml-4 md:ml-2 rounded-md overflow-hidden flex items-center justify-center flex-col">
        <StoreLogo :store="store" class="w-[250px] sm:w-[150px] md:w-[250px] p-2" />
      </div>
    </header>
    <div>
      <div class="flex flex-col border-y border-gray-900/10 space-y-4 divide-y divide-gray-200 dark:divide-gray-600 dark:border-gray-600">
        <div class="pt-4">
          <h2 class="text-lg font-semibold">
            Your available YouCoin: {{ gamer.balance }}
          </h2>
        </div>

        <div class="sm:col-span-3 py-6">
          <label for="gift-amount" class="block text-sm font-medium leading-6">Gift Card Amount</label>
          <span class="text-xs text-gray-400">Max amount: {{ max_amount }}</span>
          <div v-if="product.data.denominations && Object.keys(product.data.denominations).length >= 1" class="mt-2">
            <select
              id="gift-amount" v-model="form.amount"
              class="w-full border-gray-300 dark:border-gray-700 dark:bg-white/[0.05] dark:text-gray-300 focus:border-youpay-teal dark:focus:border-gray-700 focus:ring-youpay-teal dark:focus:ring-youpay-teal rounded-md shadow-sm leading-7"
            >
              <option class="text-black" value="0" selected>$0.00</option>
              <option class="text-black" v-for="(formatted, amount) in product.data.denominations" :key="amount" :value="amount">{{ formatted }}</option>
            </select>
          </div>
          <div v-else-if="product.data.variable" class="mt-2">
            <CurrencyInput
              class="mt-1 block w-full" type="text"
              v-model="form.amount"
              :currency="store.currency"
            />
          </div>
          <div v-else class="mt-2">
            <InputError class="mt-1" message="This product does not have any denominations" />
          </div>
          <InputError class="mt-1" :message="form.errors.amount" />
        </div>
      </div>

      <div class="mt-6 flex items-center justify-end gap-x-6">
        <Link
          :href="route('gift.index')"
          class="items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-center text-xs font-semibold text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 dark:bg-white/[0.03] dark:border-gray-500 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-800"
        >
          Cancel
        </Link>
        <PrimaryButton @click="submit" :disabled="form.processing">
          Redeem Points
        </PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Store} from "@/web/youpay-me-v3/types/gifting";
import {Head, Link} from "@inertiajs/vue3";
import StoreLogo from "@/web/youpay-me-v3/Components/Blocks/StoreLogo.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import CurrencyInput from "@/web/youpay-me-v3/Components/Form/CurrencyInput.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import type {Gamer} from "@/web/youpay-me-v3/types/games";

const props = defineProps<{
  gamer: Gamer,
  max_amount: string,
  store: Store,
  product_id: number,
  product: any
}>();

const form = useForm('post', route('redeem.store', props.store.slug ?? props.store.domain), {
  amount: 0,
});

function submit() {
  form.post();
}
</script>
