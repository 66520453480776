
export interface warriorConfig {
    token: string;
    src: string;
    submitUrl: string;
    merchantUUID: string;
    apiKey: string;
    jsUrl: string;
    acceptedCardTypes: string;
    environment: string;
}

export interface address {
    id: number,
    name: string,
    email?: string,
    first_name: string,
    last_name: string,
    phone: string,
    address_1: string,
    address_2: string,
    suburb: string,
    state: string,
    state_code: string,
    country: string,
    country_code: string,
    postcode: string,
    parent_id?: string,
    primary: boolean,
    created_at: string,
    updated_at: string,
}

export interface IpDetails {
    ip: string;
    country: string;
    country_code: string;
    region: string;
    region_code: string;
    latitude: number;
    longitude: number;
    is_eu: boolean;
}

export function compileStyles(theme: string, isMobile: boolean): object {
    let styles = {
        formLayout: isMobile ? 1 : 0,
        // cardTypeWidth: '28px', // forces it to be the correct size
        payframeHeightScaling: 'dynamic',
        backgroundColor: 'rgb(255 255 255)', // dark: rgb(255 255 255 / 0.05)
        textColor: 'rgb(55, 65, 81)', // gray-300: rgb(209 213 219), gray-700: rgb(55 65 81)
        border: '1px solid rgb(209 213 219)', // gray-300: rgb(209 213 219), gray-700: rgb(55 65 81)
        fontFamily: 'Inter, sans-serif',
        errorTextColor: 'rgb(220 38 38)', // dark: rgb(248 113 113)
        errorBackgroundColor: 'rgb(255 255 255 / 0.05)', // dark: rgb(255 255 255 / 0.05)
        errorBorderColor: 'rgb(220 38 38)',
        fontSize: '16px',
        fontSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap',
        borderRadius: '0.375rem',
        width: '100%',
        cardTypeDisplay: 'left',
        marginBottom: '20px',
        fieldLabelStyle: {
            display: 'block',
            color: 'rgb(55, 65, 81)', // gray-300: rgb(209 213 219), gray-700: rgb(55 65 81),
            marginBottom: '0.5rem',
            marginRight: '10px',
            // marginTop: '0.5rem'
        },
        placeholderText: {
            name: 'none',
            number: 'none',
            expiry: 'none',
            cvv: 'none',
        },
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        fieldHeight: '44px',
    };
    if (theme === 'dark') {
        styles.textColor = 'rgb(209 213 219)';
        styles.backgroundColor = 'rgb(255 255 255 / 0.05)';
        styles.fieldLabelStyle.color = 'rgb(209 213 219)';
        styles.border = '1px solid rgb(55 65 81)';
        styles.errorTextColor = 'rgb(248 113 113)';
        styles.errorBorderColor = 'rgb(248 113 113)';
    }

    return styles;
}

let theme = localStorage.youpayTheme ?? 'system';
if (theme === 'system') {
    theme = (window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light'
}
let isMobile = window.matchMedia('(max-width: 768px)').matches;

function getSummary(data: any) {
    return {
        total: {
            amount: data.amount,
            label: data.description,
        },
        // customer: {
        //     name: data.address.first_name + ' ' + data.address.last_name,
        //     country: data.address.country_code,
        //     state: data.address.state,
        //     city: data.address.suburb,
        //     address: data.address.address_1 + (data.address.address_2 ? ', ' + data.address.address_2 : ''),
        //     postCode: data.address.postcode,
        //     phone: data.address.phone,
        // },
        addCard: false,
        recurring: false,
        style: theme,
        transaction: {
            transactionProduct: data.description,
            transactionReferenceID: data.reference,
            //storeID: "store123",
            // custom1: "one",
            // custom2: "two",
            // custom3: "three",
            currency: data.currency,
        },
        googlePay: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            // buttonHeight: '45px',
            // buttonWidth: '100%',
            // marginTop: '10px',
            // marginBottom: '10px',
        },
        applePay: {
        //   buttonHeight: '45px',
        //   buttonWidth: '100%',
        //   marginTop: '10px',
        //   marginBottom: '10px',
        },
        wallets: [
            // 'googlepay',
            // 'applepay',
            'basic-card',
        ],
        payframe: {
            threeDS: true,
            submitUrl: warriorConfig.submitUrl,
            styles: compileStyles(theme, isMobile),
            // styles: {
            //   fontFamily: 'Open Sans, sans-serif',
            //   fontSrc: ['https://fonts.googleapis.com/css?family=Open+Sans'],
            // },
            tdsStyles: {
              width: "500px",
              height: "500px",
            }
        }
    }
}

// @ts-ignore
function processResponseEvent(mwPayframe, callback, args) {
    const summary = getSummary(PaymentData);
    var responseCode = mwPayframe.responseCode;
    var responseMessage = mwPayframe.responseMessage;
    var tdsCheck = mwPayframe.tdsCheck;
    var status = args[0];
    var payframeToken = args[1];
    var payframeKey = args[2];

    if (status !== 'HAS_TOKEN') {
        console.log('Error:', responseMessage, responseCode, status, args);
        // @ts-ignore
        formObject.setError('error', responseMessage);
        formObject.processing = false;
        // resetPayframe();
        return;
    }
    if (!tdsCheck) {
        callback(payframeToken, payframeKey);
    }
    // @ts-ignore
    tdsCheck.mwCallback = function (liabilityShifted, threeDSToken) {
        if (liabilityShifted) {
            callback(payframeToken, payframeKey, threeDSToken, liabilityShifted);
        } else {
            console.log('Error:', 'Liability not shifted', liabilityShifted, threeDSToken);
            // @ts-ignore
            formObject.setError('error', 'Could not verify liability shift, please try another card.');
            resetPayframe(true);
        }
    }
    // Fix iframe size
    tdsCheck.loaded = () => {
        const width = parentDiv.clientWidth;
        const iframes = parentDiv.getElementsByTagName('iframe');
        for (let i = 0; i < iframes.length; i++) {
            iframes[i].style.width = width + 'px';
            iframes[i].style.maxWidth = '100%';
        }
    }
    tdsCheck.checkTDS(mwPayframe.payframeToken, mwPayframe.payframeKey, summary.total.amount, summary.transaction.currency, summary.transaction.transactionReferenceID);
}

// @ts-ignore
let payFrameFn = null;
let formObject = null;
let loading: boolean = false;

export function submitPayframe() {
    // @ts-ignore
    payFrameFn.submitPayframe();
}

let warriorConfig: warriorConfig;
let parentDiv: any;
let PaymentData: object;

export function resetPayframe(destroy: boolean = false) {
    formObject.processing = false;
    if (destroy) {
        // @ts-ignore
        payFrameFn.tdsCheck?.destroy();
        payFrameFn.deploy();
    }
    // @ts-ignore
    payFrameFn.reset();
}

function resetIframeSize(mwPayframe) {
    const iframe = mwPayframe.mwIframe;
    // parent div width
    iframe.style.width = iframe.parentElement.clientWidth + 'px';
    iframe.style.maxWidth = '100%';
}

export function loadScript(config: warriorConfig, callback: Function, form: any, cardDataDiv: any) {
    warriorConfig = config;
    parentDiv = cardDataDiv;
    formObject = form;
    // amount needs to be 2 decimal places
    let amount = parseFloat(form.PaymentData.amount).toFixed(2);
    PaymentData = {
        reference: form.PaymentData.reference,
        description: form.PaymentData.description,
        amount: amount,
        currency: form.PaymentData.currency,
    }
    console.log('PaymentData:', PaymentData);

    const setupCallback = () => {
        const summary = getSummary(PaymentData);
        // @ts-ignore
        MerchantWarrior.createWithToken(warriorConfig.token, {
            environment: warriorConfig.environment,
            autoProcess: false,
            // @ts-ignore
        }).then((mwarrior) => {
            console.log('Created', mwarrior.version);
            // @ts-ignore
            mwarrior.loadPayframe('getPayframeToken', 'cardData', summary).then((mwPayframe) => {
                mwPayframe.loading = function() {
                    loading = true;
                };
                mwPayframe.loaded = function() {
                    loading = false;
                    const iframe = mwPayframe.mwIframe;
                    setTimeout(() => {
                        const currentHeight = parseInt(iframe.height);
                        // iframe.height = (currentHeight + 10) + 'px';
                        // iframe.style.height = (currentHeight + 10) + 'px';
                    }, 1000);

                    // parent div width
                    iframe.style.width = iframe.parentElement.clientWidth + 'px';
                    iframe.style.maxWidth = '100%';
                }
                mwPayframe.mwPayframeValidated = function() {
                    formObject.processing = false;
                    const iframe = mwPayframe.mwIframe;
                    // parent div width
                    iframe.style.width = iframe.parentElement.clientWidth + 'px';
                    iframe.style.maxWidth = '100%';
                }
                mwPayframe.mwCallback = function() {
                    //Example of success and error scenarios below
                    processResponseEvent(mwPayframe, callback, arguments);
                };
                mwPayframe.deploy();
                payFrameFn = mwPayframe;
                console.log('Payframe Deployed', mwPayframe);
            });
            // @ts-ignore
            mwarrior.paymentRequest(summary).then((element) => {
                element.mount('wallet-buttons');
            });
            // @ts-ignore
            mwarrior.on('payment-complete', (status, response, third) => {
                console.log('Payment Completed', status, response, third);
            });
        });
    }

    const script = document.createElement('script');
    script.src = config.jsUrl;
    script.onload = setupCallback;
    document.head.appendChild(script);
}
