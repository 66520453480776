<template>
  <Head title="Update your profile" />

  <div class="text-gray-900 dark:text-white flex-shrink-0 w-full">
    <div class="max-w-3xl mx-auto p-8 bg-white dark:bg-white/5 rounded-lg ">
      <header class="pb-8 text-center flex items-center flex-col gap-y-6">
        <RegisterBreadcrumbs class="" :current-step="2" />
        <h1 class="text-4xl font-bold">
          Personalise your profile
        </h1>
        <Link :href="route('gift.index')" class="text-youpay-navy dark:text-youpay-pink flex items-center gap-x-2">
          skip this step and add a gift
          <ArrowRightIcon class="h-4 w-4 inline-block" aria-hidden="true" />
        </Link>
      </header>
      <div>
        <div class="grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6 flex-grow">

          <div class="col-span-full gap-x-1 rounded-md bg-green-50 px-3 py-1 text-sm text-green-700 ring-1 ring-inset ring-indigo-700/10 flex items-center">
            <InformationCircleIcon class="h-6 w-6 text-green-700" aria-hidden="true" />
            <div class="p-2">
              This information will be displayed on your profile page.
            </div>
          </div>

          <h2 class="text-youpay-navy dark:text-youpay-pink text-lg font-semibold col-span-full ">Personalisation Settings</h2>

          <div class="col-span-full">
            <div class="flex justify-between">
              <InputLabel for="display_name" value="Display Name" />
            </div>

            <TextInput
              id="display_name"
              type="text"
              class="mt-1 block w-full"
              v-model="form.display_name"
              @change="form.validate('display_name')"
              :error="form.errors.display_name"
              required
              autocomplete="display_name"
            />

            <InputError class="mt-2" :message="form.errors.display_name"/>
            <div v-if="!showUsername" @click="showUsername = true" class="mt-2 pl-1 leading-7 text-gray-400 cursor-pointer">
              <div v-if="form.username.length" class="gap-x-2 flex items-center">
                <span>Your username will be:</span>
                <button type="button" class="text-youpay-pink">youpay.me/{{form.username}}</button>
                <PencilIcon class="w-3 h-3 inline-block text-gray-400 dark:text-gray-200" />
              </div>
              <div v-else>
                Generating your username... Clicjk here to set it manually.
              </div>
            </div>
          </div>

          <div v-if="showUsername" class="col-span-full">
            <div class="flex justify-between">
              <InputLabel for="username" value="Username" />
            </div>

            <div class="mt-2">
              <div class="flex py-0.5 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus-within:ring-2 focus-within:ring-youpay-teal dark:focus-within:ring-youpay-teal dark:bg-white/[0.05] bg-white"
                   :class="form.errors.username ? 'ring-red-300 dark:ring-red-700' : ''"
              >
                <span class="flex select-none items-center pl-3 text-gray-500 dark:text-gray-400 sm:text-sm">youpay.me/</span>
                <input type="text" @change="form.validate('username')" v-model="form.username" name="username" id="username"
                       class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 dark:placeholder-gray-600"
                       placeholder="example" />
              </div>
            </div>

            <InputError class="mt-2" :message="form.errors.username"/>
          </div>

          <div class="col-span-full">
            <div class="flex justify-between">
              <InputLabel for="description" value="Profile Bio" />
            </div>

            <TextAreaInput
              id="description"
              type="text"
              class="mt-1 block w-full"
              v-model="form.description"
              @change="form.validate('description')"
              :error="form.errors.description"
            />

            <InputError class="mt-2" :message="form.errors.description"/>
            <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">This will appear in your profile, wishlists, search and other areas.</p>
          </div>

          <div class="col-span-full sm:col-span-3">
            <InputLabel for="avatar" value="Your Profile Picture" class="mb-4" optional />
            <UploadFile v-model="form.avatar" />
            <InputError class="mt-2" :message="form.errors.avatar"/>
          </div>
          <div class="col-span-full sm:col-span-3">
            <InputLabel for="banner" value="Your Banner Image" class="mb-4" optional />
            <UploadFile v-model="form.banner" />
            <InputError class="mt-2" :message="form.errors.banner"/>
          </div>

        </div>

        <div class="mt-6">
          <PrimaryButton @click="submit" :disabled="form.processing" class="w-full">
            Save and Continue
          </PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Head, usePage, Link} from "@inertiajs/vue3";
import { useForm } from 'laravel-precognition-vue-inertia';
import {User} from "@/web/youpay-me-v3/types";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import CountryList from "@/web/youpay-me-v3/Components/Form/CountryList.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import DashLayout from "@/web/youpay-me-v3/Layouts/DashLayout.vue";
import UploadFile from "@/web/youpay-me-v3/Components/Form/UploadFile.vue";
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import {
  ChevronDownIcon,
  InformationCircleIcon,
  LockOpenIcon,
  LockClosedIcon,
  ArrowRightIcon,
  PencilIcon
} from "@heroicons/vue/24/outline";
import {ref} from "vue";
import RegisterBreadcrumbs from "@/web/youpay-me-v3/Components/Blocks/RegisterBreadcrumbs.vue";
import PrivacyBadge from "@/web/youpay-me-v3/Components/Blocks/PrivacyBadge.vue";

const showAdvanced = ref(false);

defineOptions({ layout: DashLayout })

const user: User | null = usePage().props.auth;

const props = defineProps<{
  description: string;
  searchable: boolean;
  anon: boolean;
  country: string;
}>();

const form = useForm('post', route('setup.personal'), {
  username: user?.username ?? '',
  display_name: user.display_name ?? '',
  avatar: user.avatar ?? '',
  banner: user.banner ?? '',
  description: props.description ?? '',
  terms: false,
});

const showUsername = ref(true);

const submit = () => {
  form.submit({
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
    }
  });
};
</script>