<template>
  <div class="grid grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8 dark:text-white">
    <div class="pt-2 md:col-span-1">
      <h2 class="text-base font-semibold leading-7 text-gray-700 dark:text-white">
        Two Factor Authentication
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Two Factor Authentication adds an extra layer of security to your account.
<!--        For more information please <a href="https://youpay.freshdesk.com/support/solutions/articles/51000168207-two-factor-authentication" target="_blank" class="text-blue-500 font-semibold">click here</a>.-->
      </p>
    </div>
    <div class="md:col-span-2 max-w-md">
      <div v-if="!enabled">
        <div class="mt-4 flex flex-col gap-y-6">
          <p class="font-semibold">
            Scan this QR code with your Google Authenticator App.
          </p>
          <div class="flex items-center justify-center py-2 w-full">
            <!-- if this is a SVG, then display it, else try to show an image -->
            <span v-html="google2fa_url" v-if="google2fa_url.includes('<svg')" alt="QR Code error, please use the manual code" />
            <img :src="google2fa_url" v-else alt="QR Code error, please use the manual code" />
          </div>
          <p class="text-sm text-gray-600 dark:text-gray-300">
            Alternatively, you can use the code:
            <code class="bg-gray-100 dark:text-gray-900 rounded ml-2 px-2 py-1 font-mono">{{ secret }}</code>
          </p>

          <div class="">
            <InputLabel for="secret" value="Enter the pin from Google Authenticator app:" />
            <TextInput v-model="enableForm.secret" id="secret" type="text" required class="mt-1 block w-full" @keydown.enter="enableForm.submit" />
            <InputError class="mt-2" :message="enableForm.errors.secret"/>

            <PrimaryButton @click.prevent="enableForm.submit" type="submit" class="mt-4">
              Enable 2FA
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div v-else class="h-full flex flex-col justify-center">
        <div class="rounded-md bg-green-100 p-4 dark:bg-green-600/50 max-w-md border border-green-200 dark:border-green-600/40 shadow-sm">
          <div class="flex">
            <div class="flex-shrink-0">
              <CheckCircleIcon class="h-5 w-5 text-green-500 dark:text-green-100" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-green-800 dark:text-white">
                Two factor authentication is enabled.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="enabled" class="grid grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8 dark:text-white">
    <div class="pt-2 md:col-span-1">
      <h2 class="text-base font-semibold leading-7 text-gray-700 dark:text-white">
        Disable Two Factor Authentication
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        If you no longer wish to use Two Factor Authentication, you can disable it here.
      </p>
    </div>
    <div class="md:col-span-2 max-w-2xl">
      <div>
        <p class="mt-4">
          To disable 2FA, you will need to enter your current password.
        </p>
        <div class="mt-4 max-w-md">
          <InputLabel for="current-password" value="Current Password" />
          <TextInput v-model="disableForm.currentPassword" id="current-password" type="password" required class="mt-1 block w-full" @keydown.enter="disableForm.submit" />
          <InputError class="mt-2" :message="disableForm.errors.currentPassword"/>
        </div>
        <PrimaryButton @click.prevent="disableForm.submit" type="submit" class="mt-4" >
          Disable 2FA
        </PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import { ref, reactive } from 'vue';
import {usePage} from '@inertiajs/vue3';
import {useForm} from "laravel-precognition-vue-inertia";
import RecentGatewayGifts from "@/web/youpay-me-v3/Components/Blocks/RecentGatewayGifts.vue";
import PrimaryLink from "@/web/youpay-me-v3/Components/Buttons/PrimaryLink.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import {CheckCircleIcon} from '@heroicons/vue/24/outline';

const props = defineProps<{
  enabled: boolean;
  secret: string;
  google2fa_url: string;
}>();

const enableForm = useForm('post', route('settings.2fa.enable2fa'), {
  secret: '',
});

const disableForm = useForm('post', route('settings.2fa.disable2fa'), {
  currentPassword: '',
});

defineOptions({ layout: SettingsLayout })
</script>