<template>
  <Head title="Your Purchases" />
  <div class="pt-8 dark:text-whiteh-full flex-grow">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <div class="flex flex-row items-bottom justify-between">
            <h1 class="font-semibold flex flex-row items-center gap-x-2 leading-6 text-gray-900 dark:text-white text-xl">
              My Referrals
              <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{ count }}</span>
            </h1>
          </div>
          <p class="mt-2 text-sm font-extralight text-gray-700 dark:text-gray-100">
            All users who have signed up using my referral link.
          </p>
          <p class="mt-4 text-sm text-gray-700 dark:text-gray-100">
            My referral link:
            <div class="mt-1 bg-black/5 dark:bg-gray-800 rounded-md flex items-center max-w-[450px] w-full justify-between border border-black/10 overflow-hidden border-1 dark:border-gray-600">
              <input id="referral-link" type="text" :value="link" readonly class="text-center text-xs bg-transparent border-none focus:outline-none w-full" @focus="selectAllInput" />
              <a class="px-4 py-2 bg-black/5" @click="copyToClipboard(link)">
                <ClipboardDocumentIcon v-if="!coppied" class="w-6 h-6 text-gray-400 flex-grow dark:text-gray-200" />
                <ClipboardDocumentCheckIcon v-else class="w-6 h-6 text-green-600 dark:text-green-300" />
              </a>
            </div>
          </p>
        </div>
<!--        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">-->
<!--          <button type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Add user</button>-->
<!--        </div>-->
      </div>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-3">Display Name</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Username</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Gifts Sent</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Gifts Received</th>
              </tr>
              </thead>
              <tbody class="bg-white dark:bg-white/5">
              <tr v-for="(referral, index) in referrals.data" :key="index" class="even:bg-gray-50 dark:even:bg-white/20">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-3">{{ referral.display_name }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-200">
                  <Link :href="route('profile.show', referral.username)" class="text-youpay-navy dark:text-youpay-pink" target="_blank" >
                    {{ referral.username }}
                  </Link>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-200">TBA</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-200">TBA</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Head, usePage, Link} from '@inertiajs/vue3';
import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline'

defineProps<{
  link?: string;
  count?: number;
  referrals?: any[];
}>();

import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import {ref} from "vue";

const coppied = ref(false);

const copyToClipboard = (value: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(value)
  } else {
    const textarea = document.createElement('textarea')
    textarea.value = value
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }
  coppied.value = true
}

const selectAllInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  target.select()
}

defineOptions({ layout: SettingsLayout })
</script>