<template>
  <div>
    <button @click="toggle" class="btn btn-primary flex flex-row gap-x-1 items-center">
      <FingerPrintIcon class="h-6 w-6 cursor-pointer text-gray-400 dark:text-gray-600"/>
    </button>
    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-50" @close="toggle">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 dark:bg-black/50 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                <DialogPanel class="pointer-events-auto w-screen max-w-7xl">
                  <div class="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-900 py-6 shadow-xl">
                    <div class="px-4 sm:px-6">
                      <div class="flex items-start justify-between">
                        <DialogTitle class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">
                          Fingerprint Data
                        </DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button" class="relative rounded-md bg-white dark:bg-white/10 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500" @click="open = false">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="relative mt-2 flex-1 px-4 sm:px-6 overflow-y-scroll">
                      <p class="pb-4 dark:text-white">
                        <a :href="'https://dashboard.fingerprint.com/subscriptions/sub_ros3ZgNB1M8vCR/visits/' + request_id" target="_blank" rel="noopener noreferrer" class="underline">View in FingerprintJS</a>.
                      </p>
                      <pre class="dark:text-white text-wrap text-sm" v-if="!loading" v-html="fingerprint_data"></pre>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon, FingerPrintIcon } from '@heroicons/vue/24/outline'

const open = ref(false)

const props = defineProps<{
  request_id: string;
}>()
const toggle = () => {
  open.value = !open.value
}

const fingerprint_data = ref(false)

const loading = ref(true)

watch(() => open.value, async (value) => {
  if (value && fingerprint_data.value === false) {
    loading.value = true
    const response = await fetch(route('umpire.finger', {request_id: props.request_id}))
    const data = await response.json()
    fingerprint_data.value = data.data
    loading.value = false
  }
})
</script>