<template>
  <Head title="Coinflow Setup"/>

  <div class="max-w-6xl mx-auto px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8 dark:text-white">
    <div class="flex flex-col items-center justify-center p-6">
      <div class="flex items-center relative w-[200px] justify-between mb-4">
        <div class="absolute inset-0 top-0 z-0 flex items-center" aria-hidden="true">
          <div class="h-[1px] w-full bg-gray-400"/>
        </div>

        <span
          class="inline-flex z-10 w-12 h-12 items-center justify-center rounded-full bg-cover"
          :class="user.avatar ? '' : 'bg-youpay-pink'"
          :style="user.avatar ? 'background-image: url(\'' + user.avatar + '\')' : ''"
        >
          <span v-if="!user.avatar" class="text-base font-medium leading-none text-white">{{ user.initials }}</span>
        </span>

        <a href="#" class="flex z-10 h-8 w-8 items-center justify-center rounded-full border border-gray-400 bg-white">
          <LinkIcon class="h-4 w-4 text-black"/>
        </a>
        <div
          class="flex items-center bg-black dark:bg-gray-700 z-10 text-white w-12 h-12 rounded-full justify-center">
          <img src="@/web/youpay-me-v3/Icons/coinflow.png" class="p-1">
        </div>
      </div>
      <h1 class="text-lg font-semibold ml-10 py-2">Connect a Coinflow account to receive cash gifts</h1>
    </div>
    <div class="bg-white dark:bg-[#10141e] p-8 rounded-lg shadow-md">
      <p class="text-gray-600 dark:text-white text-center mb-8 max-w-3xl mx-auto">
        By setting up a Coinflow account, you can receive gifts for anything you want with
        the funds ultimately able to be withdrawn to your bank account
      </p>
      <nav class="w-full py-2">
        <ol role="list" class="flex flex-col md:flex-row gap-y-4 justify-start items-start w-full">
          <li v-for="(step, stepIdx) in steps" :key="step.name"
              :class="[4 === steps.length - 1 ? 'md:w-1/4' : 'md:w-1/3', 'relative flex flex-row md:block gap-x-4']">
            <div class="relative flex items-center md:justify-center">
              <div v-if="stepIdx === 0" class="bg-white dark:bg-[#10141e] absolute w-1/2 h-full z-10 left-0 hidden md:flex"></div>
              <div v-if="stepIdx === (steps.length - 1)"
                   class="bg-white dark:bg-[#10141e] absolute w-1/2 h-full z-10 right-0 hidden md:flex"></div>
              <div class="absolute inset-0 top-0 z-0 items-center hidden md:flex" aria-hidden="true">
                <div class="h-0.5 w-full bg-youpay-navy"/>
              </div>
              <a href="#"
                 class="relative z-20 flex h-8 w-8 items-center justify-center rounded-full border border-youpay-navy bg-white">
                <Component :is="step.icon" class="h-5 w-5 text-youpay-navy" aria-hidden="true"/>
                <span class="sr-only">{{ step.name }}</span>
              </a>
            </div>
            <div class="flex flex-col md:items-center md:text-center w-full pt-2 gap-y-1 md:gap-y-2 md:px-3 ">
              <span class="font-semibold text-gray-900 dark:text-white">{{ step.name }}</span>
              <span class="md:px-3 text-xs md:text-sm text-gray-700 dark:text-gray-100">{{ step.desc }}</span>
            </div>
          </li>
        </ol>
      </nav>
      <a :href="route('settings.coinflow.register')" target="_blank" class="block text-center mt-6 w-full py-2 px-4 bg-youpay-navy-light text-white rounded hover:bg-youpay-navy transition duration-300">
        Setup Coinflow
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import {BuildingLibraryIcon, CreditCardIcon, LinkIcon, GiftIcon, WalletIcon} from "@heroicons/vue/24/outline";
import {User} from "@/web/youpay-me-v3/types";
import {Head, usePage} from "@inertiajs/vue3";

const user = <User>usePage().props.auth;

const props = defineProps<{
  avatar: string,
  canWithdraw: boolean
}>();

const steps = computed(() => {
  let list = [
    {
      name: 'Your gift gets paid',
      desc: 'Your fans pay for your gift using traditional payment methods like Visa or Mastercard',
      icon: GiftIcon
    },
    {
      name: 'The payment is processed via Coinflow',
      desc: 'Coinflow processes the payment via their secure blockchain network',
      icon: CreditCardIcon
    },
  ];
  if (!props.canWithdraw) {
    list.push({
      name: 'Funds are sent to your chosen crypto wallet',
      desc: 'You withdraw the funds to an external crypto wallet in USDC',
      icon: WalletIcon
    });
    list.push({
      name: 'You withdraw the funds to your bank account',
      desc: 'The funds are withdrawn to your regular bank account in your bank account\'s currency',
      icon: BuildingLibraryIcon
    });
  } else {
    list.push({
      name: 'Funds are sent to your bank account',
      desc: 'The funds are withdrawn to your regular bank account in your bank account\'s currency',
      icon: BuildingLibraryIcon
    });
  }
  return list;
});

defineOptions({layout: SettingsLayout})

</script>