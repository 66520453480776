<template>
  <div>
    <h2 class="pt-2 text-lg font-medium text-gray-900 dark:text-gray-50">
      Your message to {{ username }}
    </h2>
    <p class="mb-2 text-xs leading-6 text-gray-400">Optional note to let the gift recipient know who you are</p>
    <InputLabel for="message" value="Name to display"/>
    <TextInput v-model="form.display_name" class="mt-2 block w-full" />
    <InputLabel for="message" class="mt-2" :value="'Your message for ' + username" />

    <TextAreaInput
      class="mt-2"
      v-model="form.message"
      :error="form.errors.message"
      @change="debouncedUpdateMessage"
      @keyup="debouncedUpdateMessage"
    />
    <InputError class="mt-2" :message="form.errors.message"/>
  </div>
</template>

<script setup lang="ts">
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import {debounce} from "lodash";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import {Profile} from "@/web/youpay-me-v3/types";

const props = defineProps<{
  payer?: Profile,
  username: string,
  bag_id: number,
  message?: string
}>();

const form = useForm('put', route('checkout.add-message', { bag: props.bag_id }), {
  display_name: props.payer?.display_name ?? '',
  message: props.message ?? ''
});

const debouncedUpdateMessage = debounce(() => {
  form.validate('message');
}, 500);
</script>