<template>
  <div class="bg-youpay-teal-ultralight dark:bg-transparent h-full grow lg:py-24 dark:text-white">
    <div class="mx-auto max-w-2xl lg:max-w-7xl sm:px-6 lg:px-8">
      <div class="relative isolate overflow-hidden bg-white dark:bg-gray-900 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24 sm:shadow-lg">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
          <div class="lg:row-start-2 lg:max-w-md">
            <h2 class="text-3xl text-center lg:text-left font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl pb-4">
             Unlock more with Coinflow
            </h2>
            <div class="w-full px-2 sm:px-6 md:px-12 lg:px-0 mt-2">
              <a :href="route('settings.coinflow.register')" target="_blank" class="inline-flex items-center justify-center px-8 w-full py-3 text-base font-medium text-white bg-youpay-pink rounded-md shadow-sm bg-gradient-to-br from-youpay-pink to-youpay-navy-light hover:from-youpay-pink-dark hover:to-youpay-navy-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-youpay-pink">
                Register Now
              </a>
            </div>
          </div>
          <img src="@/web/youpay-me-v3/Icons/coinflow-dashboard-example.png" alt="Product screenshot" class="hidden lg:block relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-black/10 dark:ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none" width="2432" height="1442" />
          <div class="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-gray-300 dark:lg:border-white/10 lg:pt-10">
            <dl class="max-w-xl space-y-8 text-base leading-7 text-gray-700 dark:text-gray-300 lg:max-w-none">
              <div v-for="feature in features" :key="feature.name" class="relative">
                <dt class="ml-9 inline-block font-semibold text-gray-700 dark:text-white">
                  <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-violet-400" aria-hidden="true" />
                  {{ feature.name }}
                </dt>
                {{ ' ' }}
                <dd class="">{{ feature.desc }}</dd>
              </div>
              <div class="relative">
                <SecondaryLink :href="route('settings.coinflow.details')">Learn more about Coinflow</SecondaryLink>
              </div>
            </dl>
          </div>
        </div>
        <div class="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu" aria-hidden="true">
          <div class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {BuildingLibraryIcon, GiftIcon, UserGroupIcon, CurrencyDollarIcon} from "@heroicons/vue/24/outline";
import {computed, ref} from "vue";
import SecondaryLink from "@/web/youpay-me-v3/Components/Buttons/SecondaryLink.vue";

const features = computed(() => {
  let list = [
    {
      name: 'Global Cash Gifting',
      desc: 'Receive cash gifts from anywhere in the world',
      icon: GiftIcon
    },
    {
      name: 'Instant Payouts',
      desc: 'Money deposited directly to your chosen wallet or bank',
      icon: BuildingLibraryIcon
    },
  ];
  list.push({
    name: '"YouPay Me Now" button',
    desc: 'Receive surprise gifts for any value from your fans',
    icon: CurrencyDollarIcon
  });
  list.push({
    name: 'Crowdgifts and Group Gifting',
    desc: 'For those big milestones and special occasions',
    icon: UserGroupIcon
  });
  return list;
});

const open = ref<boolean>(false);
</script>