<template>
  <nav class="dark:bg-gray-900 bg-gray-100 shadow">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <a href="/" class="flex flex-shrink-0 items-center">
            <youpay-color-logo class="pt-1 h-5 sm:h-7 w-auto dark:text-white" full-logo />
          </a>
        </div>
        <div class="flex items-center">
          <div class="ml-4 flex flex-shrink-0 items-center gap-x-4">
            <CurrencySelector v-if="isCheckout"/>
            <ThemeDropdown class="hidden"/>
            <div class="flex-1 items-center justify-end" :class="isCheckout ? 'hidden md:flex' : 'flex'">
              <Link :href="route('login')"
                    class="inline-flex items-center gap-x-1.5 rounded-md bg-youpay-navy px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-youpay-teal">
                <BoltIcon class="-ml-0.5 h-5 w-5 hidden sm:inline" aria-hidden="true"/>
                Log in
              </Link>
              <Link :href="route('register')"
                    class="ml-4 items-center gap-x-1.5 rounded-md bg-youpay-navy px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-youpay-teal inline-flex"
              >
                <UserIcon class="-ml-0.5 h-5 w-5 hidden sm:inline" aria-hidden="true"/>
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import {BoltIcon, UserIcon} from '@heroicons/vue/20/solid'
import {Link, router} from "@inertiajs/vue3";
import ThemeDropdown from "@/web/youpay-me-v3/Components/Blocks/ThemeDropdown.vue";
import YoupayColorLogo from "@/web/youpay-me-v3/Components/YouPay/youpayColorLogo.vue";
import YoupayIcon from "@/web/youpay-me-v3/Components/YouPay/youpayIcon.vue";
import {computed, ref} from "vue";
import CurrencySelector from "@/web/youpay-me-v3/Components/Buttons/CurrencySelector.vue";

const currentRoute = ref(null);
router.on('navigate', () => {
  currentRoute.value = route().current();
})

const isCheckout = computed(() => {
  // if currentRoute.value has the string 'checkout' in it, return true
  return (typeof currentRoute.value === 'string') && currentRoute.value.includes('checkout');
})

</script>