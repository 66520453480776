<template>
  <MainLayout>
    <div class="mx-auto h-full w-full lg:flex lg:flex-row-reverse lg:px-2 flex-grow">
      <!-- Order summary -->
      <section aria-labelledby="summary-heading" class="flex w-full flex-col bg-gray-50 dark:bg-gray-800">
        <div class="flex items-center justify-between p-6 lg:pb-0">
          <h2 id="summary-heading" class="dark:text-gray-50 lg:sr-only">
            Gift Bag
          </h2>
          <div @click="show_summary = !show_summary" class="cursor-pointer font-medium text-youpay-navy dark:text-youpay-pink lg:hidden">
            <span v-if="show_summary">Hide full summary</span>
            <span v-if="!show_summary">Show full summary</span>
          </div>
        </div>
        <ul
          role="list"
          class="flex-auto overflow-y-auto px-6 divide-y divide-gray-200 dark:divide-gray-600 lg:inline"
          :class="show_summary ? 'inline' : 'hidden'"
        >
          <CartItem v-for="item in cart.items" :key="item.id" :item="item" />
        </ul>

        <div class="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6 dark:border-gray-700 dark:bg-gray-800">
          <dl
            class="text-sm font-medium text-gray-500 dark:text-gray-200"
            :class="show_summary ? 'space-y-6 mt-4 lg:mt-10'  : 'lg:space-y-6 lg:mt-4'"
          >
            <div
              class="flex justify-between"
              :class="show_summary ? 'flex' : 'hidden lg:flex'"
            >
              <dt>Subtotal</dt>
              <dd class="text-gray-900 dark:text-gray-50">{{ cart.sub_total }}</dd>
            </div>

            <div class="flex justify-between">
              <dt>Shipping</dt>
              <dd class="text-gray-900 dark:text-gray-200">Calculated</dd>
            </div>

            <div
              class="flex items-center justify-between border-gray-200 text-gray-900 dark:border-gray-700 dark:text-gray-50"
              :class="show_summary ? 'border-t pt-6' : 'lg:border-t lg:pt-6'"
            >
              <dt>Total</dt>
              <dd class="text-base">{{ cart.total }}</dd>
            </div>
          </dl>
        </div>
      </section>

      <!-- Checkout form -->
      <section aria-labelledby="payment-heading" class="w-full flex-auto overflow-y-auto px-4 pb-10 dark:text-gray-50 sm:px-6 sm:pt-6 lg:px-8 lg:pt-0 lg:pb-24">
        <div class="mx-auto max-w-2xl pt-4">
          <slot class="mt-4" />
        </div>
      </section>
    </div>
  </MainLayout>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {Cart} from "@/web/youpay-me-v3/types/checkout";
import {Profile, User} from "@/web/youpay-me-v3/types";
import CartItem from "@/web/youpay-me-v3/Components/Gifting/CartItem.vue";
import MainLayout from "@/web/youpay-me-v3/Layouts/MainLayout.vue";
import {CogIcon} from "@heroicons/vue/24/outline";

// Get Props
defineProps<{
  auth: User,
  profile: Profile,
  cart: Cart,
  gift: any,
  store: any,
  product: any,
}>();

// Mobile mode state
const show_summary = ref(false);

const customise = () => {
  console.log('customise')
}
</script>