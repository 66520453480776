<template>
  <Head title="Select a Store" />

  <div class="w-full h-full flex-grow dark:bg-white/5">
    <div class="max-w-5xl pb-18 mx-auto">
      <ExpressGift v-if="user"  class="px-2 md:px-4 xl:px-2 mt-6" />

      <StoreGrid :type="type" />
    </div>
  </div>
</template>
<script setup lang="ts">
import StoreGrid from "@/web/youpay-me-v3/Components/Gifting/StoreGrid.vue";
import {usePage} from "@inertiajs/vue3";
import {User} from "@/web/youpay-me-v3/types";
import ExpressGift from "@/web/youpay-me-v3/Components/Buttons/ExpressGift.vue";

const user: User|null = usePage().props.auth;

defineProps<{
  type: string;
}>();
</script>