// Importing the required module
import axios from 'axios';

// Defining the interface for the response data
export interface CountryInfo {
    "About Us": string,
    ip: string,
    success: boolean,
    type: string,
    continent: string,
    continent_code: string,
    country: string,
    country_code: string,
    region: string,
    region_code: string,
    city: string,
    latitude: number,
    longitude: number,
    is_eu: boolean,
    postal: string,
    calling_code: string,
    capital: string,
    borders: string,
    flag: {
        img: string,
        emoji: string,
        emoji_unicode: string
    },
    connection: {
        asn: number,
        org: string,
        isp: string,
        domain: string
    },
    timezone: {
        id: string,
        abbr: string,
        is_dst: boolean,
        offset: number,
        utc: string,
        current_time: string
    }
}

// Function to fetch the country information
export async function fetchCountryInfo(): Promise<CountryInfo> {
    const response = await axios.get('/api/whois');
    const data: CountryInfo = response.data;
    return data;
}