<template>
  <Head title="Update Social Links" />
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">

    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Social Profiles</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Link all of your social profiles.</p>
    </div>

    <form class="md:col-span-2 flex flex-col gap-y-3">
      <div v-for="(social, index) in socials" :key="'i' + index">
        <div>
          <label :for="`social-${index}`" class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">{{ social.name }}</label>
          <div class="mt-2 flex rounded-md shadow-sm ring-gray-300 dark:ring-gray-600 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <span class="inline-flex items-center bg-gray-100 dark:bg-white/10 rounded-l-md border border-r-0 border-gray-300 dark:border-gray-500 px-3 text-gray-500 dark:text-gray-300 sm:text-sm">
              {{ social.base_url }}
            </span>
            <input @change="onUpdate(social.slug)" type="text" :name="social.name" :id="`social-${index}`" class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-indigo-600 dark:ring-gray-500 dark:placeholder:text-gray-500 dark:text-white placeholder:text-gray-400 focus:ring-2 dark:bg-white/[0.05]  sm:text-sm sm:leading-6" :placeholder="social.placeholder" v-model="form[social.slug]" />
          </div>
        </div>
      </div>

      <div v-if="form.custom_urls.length" v-for="(link, index) in form.custom_urls" :key="`custom-${index}`">
        <div>
          <div class="flex flex-row justify-between">
            <label class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Custom Link</label>
            <button type="button" @click="form.custom_urls.splice(index, 1)" class="px-2">
              <XMarkIcon class="h-4 w-4 text-gray-400 dark:text-gray-500" />
            </button>

          </div>
          <div class="mt-2 flex flex-row gap-x-6">
            <input type="text" class="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-indigo-600 dark:ring-gray-500 dark:placeholder:text-gray-500 dark:text-white placeholder:text-gray-400 focus:ring-2 dark:bg-white/[0.05]  sm:text-sm sm:leading-6" placeholder="Link Title" v-model="link.title" />
            <input type="text" class="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-indigo-600 dark:ring-gray-500 dark:placeholder:text-gray-500 dark:text-white placeholder:text-gray-400 focus:ring-2 dark:bg-white/[0.05]  sm:text-sm sm:leading-6" placeholder="https://example.com" v-model="link.url" />
          </div>
        </div>
      </div>

      <div class="pt-2 pb-4 border-b dark:border-gray-600 flex flex-row justify-between">
        <SecondaryButton @click.prevent="addCustomLink">
          <PlusIcon class="h-4 w-4 inline-block mr-1" />
          <span>Add a custom link</span>
        </SecondaryButton>
      </div>

      <div class="mt-4 flex justify-end">
        <PrimaryButton @click.prevent="submit">
          Save & Update Socials
        </PrimaryButton>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import {onBeforeMount, ref, watch} from 'vue';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import { PlusIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import {useForm} from "laravel-precognition-vue-inertia";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import SecondaryButton from "@/web/youpay-me-v3/Components/Buttons/SecondaryButton.vue";

interface social {
  slug: string;
  name: string;
  base_url: string;
  alt_base_url: string;
  placeholder: string;
}

const socials: social[] = [
  {
    slug: 'facebook_url',
    name: 'Facebook',
    base_url: 'https://facebook.com/',
    alt_base_url: '',
    placeholder: 'rose.tyler',
  },
  {
      slug: 'twitter_url',
      name: 'X / Twitter',
      base_url: 'https://twitter.com/',
      alt_base_url: 'https://x.com/',
      placeholder: 'amelia_pond',
  },
  {
    name: 'Twitch',
    slug: 'twitch_url',
    base_url: 'https://twitch.tv/',
    alt_base_url: '',
    placeholder: 'rory.williams',
  },
  {
    name: 'Instagram',
    slug: 'instagram_url',
    base_url: 'https://instagram.com/',
    alt_base_url: '',
    placeholder: 'martha.jones',
  },
  {
    name: 'OnlyFans',
    slug: 'only_fans',
    base_url: 'https://onlyfans.com/',
    alt_base_url: '',
    placeholder: 'jack.harkness',
  },
  {
    name: 'TikTok',
    slug: 'tiktok_url',
    base_url: 'https://tiktok.com/@',
    alt_base_url: '',
    placeholder: 'donna.noble',
  },
];


const props = defineProps<{
  socials?: {
    facebook_url: string;
    twitter_url: string;
    twitch_url: string;
    instagram_url: string;
    only_fans: string;
    tiktok_url: string;
    custom_urls: {
      title: string;
      url: string;
    }[];
  }
}>();

const social_data = props.socials ?? [];

for (const [key, value] of Object.entries(social_data)) {
    if (key !== 'custom_urls') {
        social_data[key] = value ? value.replace(socials.find(s => s.slug === key)?.base_url, '') : '';
        if (socials.find(s => s.slug === key)?.alt_base_url) {
            social_data[key] = social_data[key].replace(socials.find(s => s.slug === key)?.alt_base_url, '');
        }
    }
}

const form = useForm('post', route('settings.socials.post'), {
  facebook_url: props.socials?.facebook_url || '',
  twitter_url: props.socials?.twitter_url || '',
  twitch_url: props.socials?.twitch_url || '',
  instagram_url: props.socials?.instagram_url || '',
  only_fans: props.socials?.only_fans || '',
  tiktok_url: props.socials?.tiktok_url || '',
  custom_urls: props.socials?.custom_urls || [],
});

const onUpdate = (slug: string) => {
  const social = socials.find(s => s.slug === slug);

  if (!social) {
    return;
  }

  form[social.slug] = form[social.slug].replace(social.base_url, '');
  if (social.alt_base_url) {
      form[social.slug] = form[social.slug].replace(social.alt_base_url, '');
  }
};

const addCustomLink = () => {
  form.custom_urls.push({
    title: '',
    url: '',
  });
};

const submit = () => {
  form.transform((data: Object) => {
      for (const [key, value] of Object.entries(data)) {
        if (key !== 'custom_urls') {
          data[key] = value ? socials.find(s => s.slug === key)?.base_url + value : '';
        }
      }
      return data;
    }
  ).submit({
    preserveScroll: true,
  });
};

defineOptions({ layout: SettingsLayout })
</script>
