<template>
  <div>
    <div v-if="addAddress">
      <address-form :form="form"></address-form>
      <span v-if="hasContacts" class="pt-2 mt-2 block text-blue-500 font-semibold cursor-pointer" @click="useSavedAddress">
        Use a saved address
      </span>
    </div>
    <div v-else>
      <InputLabel value="Billing Address" class="pb-2"/>
      <div v-if="!showContactList" class="bg-white dark:bg-white/5 border border-gray-400 dark:border-white/10 shadow rounded-lg p-4">
        <div>
          <div class="flex flex-row justify-between items-end w-full">
            <div class="flex flex-row items-center gap-x-2">
              <span class="font-semibold pb-1">
                {{ currentAddress?.name }}
              </span>
              <span class="text-xs text-gray-400">
                {{ currentAddress?.phone }}
              </span>
            </div>
            <button @click.prevent="showContactList = true" class="text-blue-500 font-semibold text-sm py-1">
              Change
            </button>
          </div>
          <p>{{ currentAddress?.address_1 }}{{ currentAddress?.address_2 ? ', ' + currentAddress?.address_2 : '' }}</p>
          <p>{{ currentAddress?.suburb }}, {{ currentAddress?.state }} {{ currentAddress?.postcode }} {{ currentAddress?.country }}</p>
        </div>
      </div>
      <div v-else>
        <ul class="flex flex-col gap-y-2">
          <li @click="selectAddress(contact)" v-for="contact in contacts" :key="contact.id" class="bg-white dark:bg-white/5 border border-gray-400 cursor-pointer hover:dark:bg-white/10 dark:border-white/10 shadow rounded-lg p-4">
            <div>
              <div class="flex flex-row items-center gap-x-2">
                <span class="font-semibold pb-1">
                  {{ contact.name }}
                </span>
                <span class="text-xs text-gray-400">
                  {{ contact.phone }}
                </span>
              </div>
              <p>{{ contact.address_1 }}{{ contact.address_2 ? ', ' + contact.address_2 : '' }}</p>
              <p>{{ contact.suburb }}, {{ contact.state }} {{ contact.postcode }} {{ contact.country }}</p>
            </div>
          </li>
          <li @click="showAddressForm" class="bg-white dark:bg-white/5 border border-gray-400 cursor-pointer hover:dark:bg-white/10 dark:border-white/10 shadow rounded-lg p-4">
            <div class="flex flex-row items-center w-full gap-x-2">
              <PlusIcon class="w-7 h-7 border border-gray-400 text-white rounded-md flex items-center justify-between p-1 mr-3" />
              <span class="font-semibold">Add New Address</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type {address, IpDetails} from "@/web/youpay-me-v3/helpers/MerchantWarrior";
import AddressForm from "@/web/youpay-me-v3/Components/Form/AddressForm.vue";
import {PlusIcon} from "@heroicons/vue/24/outline";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

const props = defineProps<{
  form: any,
  contacts?: address[],
  autofill: address,
  ipdetails?: IpDetails
}>();

const hasContacts = props.contacts && props.contacts?.length > 0;
const addAddress = ref(!hasContacts);
const showContactList = ref(false);
let currentAddress = ref(props.contacts?.find((contact) => contact.primary));
if (!currentAddress.value && props.contacts && props.contacts?.length > 0) {
  currentAddress.value = props.contacts[0];
}

const setFormFields = (contact: address) => {
  if (contact.first_name?.length > 0) {
    props.form.first_name = contact.first_name;
  }
  if (contact.last_name?.length > 0) {
    props.form.last_name = contact.last_name;
  }
  if (contact.phone?.length > 0) {
    props.form.phone = contact.phone;
  }
  if (contact.email && contact.email?.length > 0) {
    props.form.email = contact.email;
  }
  props.form.address_1 = contact.address_1;
  props.form.address_2 = contact.address_2;
  props.form.suburb = contact.suburb;
  props.form.state = contact.state;
  props.form.postcode = contact.postcode;
  props.form.country_code = contact.country_code;
};

const showAddressForm = () => {
  setFormFields(props.autofill);
  addAddress.value = true;
};

if (!currentAddress.value) {
  showAddressForm();
}

const selectAddress = (contact: address) => {
  setFormFields(contact);
  currentAddress.value = contact;
  showContactList.value = false;
};

const useSavedAddress = () => {
  addAddress.value = false;
  showContactList.value = false;
  if (currentAddress.value) {
    setFormFields(currentAddress.value);
  }
};

</script>
