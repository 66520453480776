<template>
  <Head title="2FA Verification" />

  <div class="text-gray-900 dark:text-white flex-shrink-0 w-full md:pt-12">
    <div class="max-w-xl mx-auto p-8 bg-white dark:bg-gray-800 rounded-lg ">
      <h1 class="text-2xl font-bold text-center text-gray-800 dark:text-white">2FA Verification</h1>
      <p class="text-center text-gray-600 dark:text-gray-100">
        Enter the pin from your 2FA Authenticator app to authenticate your login.
      </p>

      <form>
        <div class="mt-4">
          <InputLabel for="one_time_password" value="One Time Password" />
          <TextInput
            v-model="form.one_time_password"
            @keyup="autoSubmit"
            @change="autoSubmit"
            id="one_time_password"
            name="one_time_password"
            maxlength="6"
            required
            autofocus
            autocomplete="off"
            inputmode="numeric"
            pattern="[0-9]*"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div class="mt-4">
          <PrimaryButton
            @click="submit"
            type="submit"
            class="w-full"
          >
            Verify
          </PrimaryButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Head} from "@inertiajs/vue3";
import {useForm} from "laravel-precognition-vue-inertia";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";

const form = useForm('post', route('settings.2fa.2faVerify'),
  {
    one_time_password: "",
  });

const submit = () => {
  form.submit();
};

const autoSubmit = () => {
  if (form.one_time_password.length === 6 && !form.processing) {
    submit();
  }
};

</script>