<template>
  <div class="mt-4 pb-6 w-full">
    <h1 class="text-xl py-3 text-youpay-black border-b-1.5 border-gray-100 px-3">
      Add to your wishlist
    </h1>
    <div
        v-for="product in data.products"
        class="
          rounded-xl bg-white hover:bg-gray-200 hover:border-white px-3 overflow-hidden border-b-1.5 border-gray-100 w-full
        "
    >
      <div class="flex w-full flex-row items-center p-3">
        <div class="uppercase tracking-wide text-sm text-youpay-navy font-semibold flex-grow">
<!--          shrink to max 50 characters -->
          {{ product.name.substring(0, 30) }}
          {{ product.name.length > 30 ? '...' : ''}}
        </div>
        <div class="block mt-1 text-lg leading-tight font-medium text-black mr-2">
          {{ product.price }}
        </div>
        <div class="bg-gray-700 rounded-xl flex-shrink-0 flex flex-row">
          <a :href="product.url" target="_blank" class="hover:bg-gray-900 px-3 text-white rounded-xl">
            View
          </a>
          <button class="hover:bg-gray-900 text-white px-3 rounded-xl">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";

const data = defineProps<{
  products: {
    name: string;
    price: string;
    url: string;
  }
}>();

</script>
