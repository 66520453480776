<template>
  <Head title="Coinflow"/>

  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        Coinflow Dashboard
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        View your payments and payouts
      </p>
    </div>
    <form class="md:col-span-2 flex flex-col justify-center">
      <div class="flex items-start flex-col gap-y-4">
        <PrimaryLink target="_blank" :href="route('settings.coinflow.login')" class="font-ligh">
          {{ isSetup ? 'Visit the Coinflow Dashboard' : 'Complete Coinflow Setup' }}
        </PrimaryLink>
      </div>
      <span v-if="!isSetup" class="block mt-2 text-xs text-gray-500 dark:text-orange-300">Finish setting up your account to start creating gifts</span>
    </form>
  </div>
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Coinflow Settings</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Change your coinflow settings.</p>
    </div>

    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full">
          <label for="surprise" class="flex items-center">
            <Checkbox id="surprise" name="surprise" v-model:checked="form.surprise" />
            <span  class="ml-2 text-sm text-gray-600 dark:text-gray-400">
              Allow users to send me surprise gifts
            </span>
          </label>

          <span class=" text-youpay-pink text-xs pt-2 block font-semibold">
            This enables a "YouPay Me Now" button on your profile so you can be gifted any amount.
          </span>
        </div>
      </div>

      <div class="mt-8 flex flex-row justify-end">
        <PrimaryButton @click.prevent="submit" type="submit">
          Update Gifting Settings
        </PrimaryButton>
      </div>
    </form>
  </div>
  <div class="grid grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div class="pt-2 md:col-span-1">
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        Your Recent Coinflow Gifts
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Visit the Coinflow Dashboard to see the related payments
      </p>
    </div>
    <form class="md:col-span-2 w-full">
      <RecentGatewayGifts :presents="presents"/>
    </form>
  </div>
  <div v-if="user.can?.createConnect" class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div class="pt-2">
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        All in on Coinflow?
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Convert your existing Stripe Connect gifts to Coinflow gifts.
      </p>
    </div>
    <form class="md:col-span-2 flex items-center">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full flex items-center justify-center w-full flex-col gap-y-4">
          <a target="_blank" :href="route('settings.coinflow.convert')"
             class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center">
            Convert Stripe Gifts to Coinflow
          </a>
        </div>
      </div>
    </form>
  </div>
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div class="pt-2">
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        Need Help?
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Visit the Help Center for more information on Coinflow
      </p>
    </div>
    <form class="md:col-span-2 flex items-center">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="sm:col-span-3">
          <div class="flex items-center flex-col gap-y-4">
            <a href="https://youpay.freshdesk.com/support/solutions/folders/51000458808"
               class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center text-xs"
               target="_blank">
              Visit the Help Center
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import {User} from "@/web/youpay-me-v3/types";
import {Head, usePage} from "@inertiajs/vue3";
import PrimaryLink from "@/web/youpay-me-v3/Components/Buttons/PrimaryLink.vue";
import RecentGatewayGifts from "@/web/youpay-me-v3/Components/Blocks/RecentGatewayGifts.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import {useForm} from "laravel-precognition-vue-inertia";

const user = <User>usePage().props.auth;

const props = defineProps<{
  isSetup: boolean;
  canWithdraw: boolean;
  presents: any
  surprise: boolean;
}>();

const form = useForm('post', route('settings.coinflow.surprise'), {
  surprise: props.surprise ?? true,
});

const submit = () => {
  form.submit({
    preserveScroll: true
  });
};

defineOptions({layout: SettingsLayout})

</script>