<template>
  <button @click="share">
    <slot>
      <span class="flex flex-row items-center gap-x-4 text-md">
        Share
        <ShareIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </span>
    </slot>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="open = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
            <TransitionChild class="min-w-[20rem]" as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-700 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 dark:text-white pb-2 lg:text-lg">Share this {{ type }}</DialogTitle>
                  <div class="mt-2">
                    <input type="text" :value="url" readonly class="text-center sm:text-left w-full border-gray-300 rounded-md shadow-sm focus:border-youpay-teal focus:ring-youpay-teal sm:text-sm" />
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="button" class="inline-flex w-full justify-center rounded-md bg-youpay-navy dark:bg-youpay-pink px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="copyToClipboard">
                    {{ coppied ? 'Copy URL' : 'Copy URL'}}
                  </button>
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="open = false" ref="cancelButtonRef">Cancel</button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </button>
</template>

<script setup lang="ts">
import {ref, computed, inject} from 'vue'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionRoot,
  TransitionChild
} from '@headlessui/vue'
import { ShareIcon } from "@heroicons/vue/24/outline";

const open = ref(false);
const coppied = ref(false);

const props = withDefaults(defineProps<{
  url: string,
  type?: string
}>(), {
  type: 'Gift'
})

// trim the equal sign at the end of the string
const url = computed(() => props.url.replace(/=$/, ''));
const trackEvent = inject('trackEvent');

const share = () => {
  trackEvent('share', {
    type: props.type,
    url: props.url
  });

  if (navigator.share) {
    navigator.share({
      title: 'Share this link',
      url: url.value
    }).catch(() => {
      showDialog.value = true
    })
  } else {
    open.value = true
  }
}

const copyToClipboard = () => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(url.value)
  } else {
    const textarea = document.createElement('textarea')
    textarea.value = url.value
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }
  coppied.value = true
  open.value = false
}

const closeDialog = () => {
  showDialog.value = false
}
</script>
