<template>
  <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5">
    <li v-for="profile in profiles" :key="profile.username">
      <div class="relative col-span-1 overflow-hidden rounded-lg h-full bg-transparent text-left shadow-md">
        <div :style="profile.banner? `background-image: url(${profile.banner});` : ''" class="absolute top-0 w-full bg-gradient-to-r via-purple-500 bg-cover bg-center aspect-[3/1] h-[80px] md:h-[150px] from-youpay-navy-light to-youpay-pink"></div>

        <div class="flex h-full flex-col bg-white pt-[50px] md:pt-[90px] dark:bg-white/[0.08]">
          <div class="relative z-10 flex flex-1 flex-grow flex-col px-4 pb-4">
            <div v-if="profile.avatar" class="rounded-md border-4 border-white overflow-hidden  h-22 w-22">
              <img
                @click="router.visit(route('profile.show', profile.username))"
                class="inline-block cursor-pointer h-22 object-cover w-22"
                :src="profile.avatar"
                :alt="profile.display_name"
              />
            </div>
            <div v-else class="inline-block rounded-md bg-white border-4 border-white">
              <UserCircleIcon class="h-22 w-22 text-slate-400 dark:text-slate-600" />
            </div>
            <h3 class="mt-3 flex h-5 flex-row items-center text-sm font-medium text-gray-900 dark:text-gray-50">
              <span class="leading-3">{{ profile.display_name }}</span>
              <span v-if="profile.verified" class="pl-2">
                <CheckCircleIcon class="h-5 w-5 text-blue-500" aria-hidden="true" />
              </span>
            </h3>
            <div class="mt-1 hidden md:flex flex-grow flex-col justify-start">
              <div class="sr-only">Details</div>
              <div class="text-sm font-medium text-gray-500 dark:text-gray-100 flex flex-col lg:flex-row gap-x-2">
                <span>{{ '@' + profile.username }}</span>
              </div>
              <div class="sr-only">About</div>
              <div class="hidden md:block mt-3 text-sm text-gray-400 line-clamp-2 dark:text-gray-100">{{ profile.description }}</div>
            </div>
          </div>
          <div class="bg-white dark:bg-white/[0.03]">
            <div class="-mt-px flex  border-t divide-x divide-gray-200 dark:divide-gray-700 dark:border-gray-700">
              <div class="flex w-0 flex-1">
                <Link
                  :href="route('profile.show', profile.username)"
                  class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  <EyeIcon class="h-5 w-5 text-gray-400 dark:text-gray-100" aria-hidden="true" />
                  <span>
                    Wishlist
                  </span>
                </Link>
              </div>
              <div class="-ml-px flex w-0 flex-1">
                <Link
                  :href="route('follow', profile.username)"
                  method="put"
                  @click="trackEvent('follow', profile.username)"
                  preserve-scroll
                  :disabled="!auth?.id"
                  as="button"
                  v-if="!auth?.id || !following?.includes(profile.id)"
                  class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 dark:text-gray-50"
                  :class="!auth?.id ? 'opacity-20' : ''"
                >
                  <PlusIcon class="h-5 w-5 text-gray-400 dark:text-gray-100" aria-hidden="true" />
                  Follow
                </Link>
                <Link
                  :href="route('unfollow', profile.username)"
                  method="delete"
                  as="button"
                  @click="trackEvent('unfollow', profile.username)"
                  preserve-scroll
                  v-else-if="auth?.id"
                  class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-green-600 dark:text-green-600"
                >
                  <CheckCircleIcon class="h-5 w-5 text-green-700 dark:text-green-700" aria-hidden="true" />
                  Following
                </Link>

                <!-- Not Logged In -->
                <button v-else disabled class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 opacity-20 dark:text-gray-50" >
                  <PlusIcon class="h-5 w-5 text-gray-400 dark:text-gray-100" aria-hidden="true" />
                  Follow
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import {CheckCircleIcon, EyeIcon, PlusIcon} from "@heroicons/vue/20/solid";
import {Link, router, usePage} from "@inertiajs/vue3";
import {UserCircleIcon} from "@heroicons/vue/24/outline";
import {Profile} from "@/web/youpay-me-v3/types";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";

const auth = usePage().props.auth;

defineProps<{
  profiles: Profile[]
  following?: number[]
}>();
</script>