<template>
  <div class="inline-flex rounded-md shadow-sm bg-gradient-to-r from-purple-500 to-youpay-pink-dark shrink-0">
    <!-- Main Add Gift Button -->
    <Link :href="route('stores')"
          type="button"
          class="relative inline-flex items-center rounded-l-md text-white
          px-3 py-2 text-sm md:font-medium
          shadow-sm
          focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
          focus-visible:outline-youpay-teal"
    >
      <PlusIcon class="-ml-0.5 pr-0.5 md:pr-0 h-5 w-5" aria-hidden="true"/>
      <span class="">Add Gift</span>
    </Link>

    <!-- Dropdown Button -->
    <Menu as="div" class="relative block">
      <MenuButton class="relative inline-flex items-center rounded-r-md
                      px-2 py-2 text-white border-l border-white/50 dark:border-black/50
                     focus:z-10">
        <span class="sr-only">Open options</span>
        <ChevronDownIcon class="h-5 w-5" aria-hidden="true"/>
      </MenuButton>
      <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
        <MenuItems class="absolute right-0 z-10 -mr-1 mt-2 w-42 origin-top-right
                          rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5
                          dark:ring-white dark:bg-gray-800 dark:ring-opacity-10
                          focus:outline-none">
          <div class="py-1">
            <MenuItem v-for="item in items" :key="item.name">
              <a @click="visitRoute(item.href)" class="flex flex-row items-center text-gray-700 dark:text-gray-100 px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer gap-x-2">
<!--                <PlusIcon class="h-5 w-5 text-gray-400 dark:text-gray-100 inline-block"/>-->
                <span class="">
                  {{ item.name }}
                </span>
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, PlusIcon } from '@heroicons/vue/20/solid'
import {Link, router, usePage} from '@inertiajs/vue3'
import {User} from "@/web/youpay-me-v3/types";
import {computed} from "vue";

const user = <User>usePage().props.auth;

//visa / master
// coinflow
// Stripe connect
// Gift card
// Partner stores
const items = computed(() => {
  let links = [
    { name: 'Visa / Mastercard', href: route('create-gift', 'visa') },
  ];
  let coinflowPage = route('settings.coinflow.index');
  if (user.can.createCoinflow) {
    coinflowPage = route('create-gift', 'coinflow')
  }
  links.push({ name: 'Coinflow', href: coinflowPage });
  if (user.can.createConnect) {
    links.push({ name: 'Stripe Connect', href: route('create-gift', 'connect') });
  }
  links.push({ name: 'Gift Card', href: route('gift.stores.giftcards') });
  links.push({ name: 'Partner Stores', href: route('gift.stores.shopify') });
  links.push({ name: 'Add Wishlist', href: route('wishlist.create') });
  let fundPage = route('settings.coinflow.index');
  if (user.can.createCoinflow) {
    fundPage = route('fund.create')
  }
  links.push({ name: 'Add Crowdgift', href: fundPage});
  return links;
});

const visitRoute = (href: string) => {
  router.visit(href);
};

</script>