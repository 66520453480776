<template>
  <div class="fixed inset-0 pointer-events-none z-50 overflow-hidden">
    <div
      v-for="(confetti, index) in confettiItems"
      :key="index"
      class="confetti"
      :style="{
        left: `${confetti.x}%`,
        top: `${confetti.y}%`,
        transform: `rotate(${confetti.rotation}deg)`,
        animationDuration: `${confetti.duration}s`,
        animationDelay: `${confetti.delay}s`,
      }"
    >
      <svg v-if="confetti.shape === 'heart'" width="20" height="20" viewBox="0 0 20 20">
        <path :fill="confetti.color" d="M10 3.22l-.61-.6a5.5 5.5 0 0 0-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 0 0-7.78-7.77l-.61.61z"/>
      </svg>
      <svg v-else-if="confetti.shape === 'star'" width="20" height="20" viewBox="0 0 20 20">
        <path :fill="confetti.color" d="M10 1l2.5 6.5H19l-5 4.5 2 6.5-6-4.5-6 4.5 2-6.5-5-4.5h6.5z"/>
      </svg>
      <svg v-else width="20" height="20" viewBox="0 0 20 20">
        <circle :fill="confetti.color" cx="10" cy="10" r="10"/>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, onMounted, computed, ComputedRef} from 'vue';
import {usePage} from "@inertiajs/vue3";

interface ConfettiItem {
  x: number;
  y: number;
  color: string;
  rotation: number;
  duration: number;
  delay: number;
  shape: 'heart' | 'star' | 'circle';
}

const allColors = [
  '#FF8E00', // Orange
  '#FFEE00', // Yellow
  '#008E00', // Green
  '#780088', // Purple
  '#00C0FF', // Blue
  '#FFFFFF', // White
  '#FF69B4', // Hot Pink
  '#00C0FF', // Blue
  '#FFFFFF', // White
  '#FF69B4', // Hot Pink
];
const season = usePage().props.season ?? 'default';

const currentColors: ComputedRef<string[]> = computed(() => {
  if (season === 'pride') {
    return [
      '#00C0FF', // Blue
      '#FFFFFF', // White
      '#FF69B4', // Hot Pink
    ];
  }
  if (season === 'halloween') {
    return [
      '#FF8E00', // Orange
      '#000000', // Black
      '#FFFFFF', // White
    ];
  }
  if (season === 'christmas') {
    return [
      '#FF0000', // Red
      '#1ca41c', // Green
      '#FFFFFF', // White
    ];
  }
  return allColors;
});

const shapes: ('heart' | 'star' | 'circle')[] = ['heart', 'star', 'circle'];

const confettiItems = ref<ConfettiItem[]>([]);

const createConfetti = (count: number) => {
  const items: ConfettiItem[] = [];
  for (let i = 0; i < count; i++) {
    items.push({
      x: Math.random() * 100,
      y: Math.random() * 100,
      color: currentColors.value[Math.floor(Math.random() * currentColors.value.length)],
      rotation: Math.random() * 360,
      duration: 5 + Math.random() * 2,
      delay: Math.random(),
      shape: 'heart',
    });
  }
  confettiItems.value = items;
};

onMounted(() => {
  createConfetti(Math.floor(Math.random() * 27) + 42);
});
</script>

<style scoped>
.confetti {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;
  animation: fall linear forwards;
}

@keyframes fall {
  0% {
    transform: translateY(-10vh) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(560deg);
    opacity: 0;
  }
}
</style>