<template>
  <div class="flex flex-col items-center justify-center sm:py-12 sm:flex-grow h-full">
    <div
      class="w-full sm:max-w-xl xl:max-w-2xl mx-auto sm:rounded-xl overflow-hidden border border-gray-200 dark:border-white/10">
      <div class="bg-white dark:bg-white/5 dark:text-white">
        <div class="flex items-center justify-center" :class="{'bg-white': store.logo === store.dark_logo}">
          <img :src="store.logo" :alt="store.title" class="h-auto max-w-[800px] xl:max-w-[900px]"/>
        </div>
        <div class="border-t border-gray-200 dark:border-white/10 p-4">
          <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 gap-x-4 gap-y-4">
            <div class="xs:col-span-2 sm:col-span-3 text-sm xl:text-base font-semibold text-gray-500 dark:text-gray-100">
              <span class="uppercase">{{ is_link ? 'Redeem Link' : 'Redeem Code' }}</span>
              <div
                class="mt-1 bg-black/5 dark:bg-white/5 rounded-md flex items-center w-full justify-between border border-black/10 overflow-hidden border-1 dark:border-gray-600">
                <input id="referral-link" type="text" :value="code" readonly
                       class="dark:text-gray-200 font-bold bg-transparent border-none focus:outline-none w-full"
                       @focus="selectAllInput"/>

                <a v-if="is_link" class="px-4 py-2 bg-black/5 cursor-pointer" @click="visitLink(code)">
                  <LinkIcon class="w-6 h-6 text-gray-400 flex-grow dark:text-gray-200"/>
                </a>
                <a v-else class="cursor-pointer px-4 py-2 bg-black/5" @click="copyToClipboard(code, 'code')">
                  <ClipboardDocumentIcon v-if="!codeCopied" class="w-6 h-6 text-gray-400 flex-grow dark:text-gray-200"/>
                  <ClipboardDocumentCheckIcon v-else class="w-6 h-6 text-green-600 dark:text-green-300"/>
                </a>
              </div>
            </div>
            <div v-if="pin && pin.length"
                 class="xs:col-span-1 sm:col-span-2 text-sm xl:text-base font-semibold text-gray-500 dark:text-gray-100">
              PIN / Challenge Token
              <div
                class="mt-1 bg-black/5 dark:bg-white/5 rounded-md flex items-center w-full justify-between border border-black/10 overflow-hidden border-1 dark:border-gray-600">
                <input type="text" :value="pin" readonly
                       class="p-2 leading-6 dark:text-gray-200 font-bold bg-transparent border-none focus:outline-none w-full"
                       @focus="selectAllInput"/>
                <a class="cursor-pointer px-4 py-2 bg-black/5" @click="copyToClipboard(pin, 'pin')">
                  <ClipboardDocumentIcon v-if="!pinCopied" class="w-6 h-6 text-gray-400 flex-grow dark:text-gray-200"/>
                  <ClipboardDocumentCheckIcon v-else class="w-6 h-6 text-green-600 dark:text-green-300"/>
                </a>
              </div>
            </div>
            <div v-if="expiry" class="col-span-1 text-sm xl:text-base font-semibold text-gray-500 dark:text-gray-100">
              Expires
              <div
                class="mt-1 leading-6 p-2 font-bold dark:text-gray-200 bg-black/5 dark:bg-white/5 rounded-md flex items-center w-full justify-between border border-black/10 border-1 dark:border-gray-600">
                {{ expiry }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="instructions && instructions.length"
           class="bg-gray-100 dark:bg-white/10 text-gray-500 dark:text-gray-200 p-4 text-sm">
        <div class="font-semibold pb-1 xl:text-lg">How to redeem:</div>
        <span class="text-xs xl:text-sm xl:leading-6" v-html="instructions"></span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from "vue";
import {Store} from "@/web/youpay-me-v3/types/gifting.d";
import {ClipboardDocumentCheckIcon, ClipboardDocumentIcon, LinkIcon} from "@heroicons/vue/24/outline";

const codeCopied = ref(false);
const pinCopied = ref(false);

const copyToClipboard = (value: string, type: 'code' | 'pin') => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(value)
  } else {
    const textarea = document.createElement('textarea');
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  const isCode = type === 'code';
  codeCopied.value = isCode;
  pinCopied.value = !isCode;

  setTimeout(() => {
    codeCopied.value = false;
    pinCopied.value = false;
  }, 2000);
};

const selectAllInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  target.select()
}

withDefaults(defineProps<{
  code: string;
  pin: string;
  expiry?: string;
  is_link?: boolean;
  store: Store;
  image?: string;
  instructions?: string;
}>(), {
  is_link: false,
  expiry: '',
  image: '',
  instructions: '',
});

const visitLink = (link: string) => {
  window.open(link, '_blank')
}
</script>