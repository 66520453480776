<template>
  <fieldset class="">
    <slot>
      <div class="flex items-center justify-between">
        <legend class="text-sm leading-7 font-semibold">
          Wishlist Settings <span v-if="!user" class="pl-4 text-xs text-gray-500">Only for logged in users</span>
        </legend>
        <a :href="route('wishlist.create')" class="text-youpay-pink cursor-pointer text-xs" target="_blank">Create a new wishlist</a>
      </div>
    </slot>
    <div v-if="!user"></div>
    <div v-else class="">
      <Listbox as="div" v-model="myValue">
<!--        <ListboxLabel class="block text-sm leading-6 text-gray-900 dark:text-gray-200">-->
<!--          <a :href="route('wishlist.create')" class="text-youpay-pink cursor-pointer" target="_blank">Click here to create a new wishlist</a>-->
<!--        </ListboxLabel>-->
        <div class="relative mt-2">
          <ListboxButton class="relative w-full cursor-default rounded-md bg-white dark:bg-white/5 py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span class="block truncate">{{ myValue }}</span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ListboxOption as="template" v-for="(wishlist, index) in wishlistList" :key="index" :value="wishlist" v-slot="{ active, selected }">
                <li :class="[active ? 'bg-youpay-navy text-white' : 'text-gray-900 dark:text-white', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ wishlist }}</span>

                  <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-400', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
  </fieldset>
</template>

<script setup>
import {onMounted, ref, computed, watch} from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import {Link, usePage} from "@inertiajs/vue3";

const props = defineProps({
  selected_wishlist: {
    type: String,
    required: true
  },
  wishlists: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['update:modelValue'])
const myValue = ref(props.modelValue)
const user = usePage().props.auth;

const wishlistList = computed(() => {
  const lists = props.wishlists;
  lists['zen'] = 'No Wishlist'; // Zen for last option.
  return lists;
})

watch(myValue, (value) => {
  if (!user) {
    return;
  }
  // Find key where value is equal to value
  const wishlist = Object.keys(props.wishlists).find(key => props.wishlists[key] === value)
  emit('update:modelValue', wishlist)
})

onMounted(() => {
  if (!user) {
    return;
  }
  if (props.selected_wishlist === 'zen') {
    myValue.value = wishlistList.value['zen']
    return;
  }
  if (props.wishlists[props.selected_wishlist]) {
    myValue.value = props.wishlists[props.selected_wishlist]
  } else {
    // get the first item in props.wishlists, remembering its an object
    myValue.value = props.wishlists[Object.keys(props.wishlists)[0]]
  }
})
</script>