<template>
  <div class="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden dark:bg-gray-700">
    <div class="px-4 py-6 sm:px-6 lg:hidden">
      <div class="mx-auto flex max-w-lg">
        <a href="#">
          <span class="sr-only">Your Company</span>
          <img src="https://tailwindui.com/img/logos/mark.svg?color=indigo&amp;shade=600" alt="" class="h-8 w-auto" />
        </a>
      </div>
    </div>

    <h1 class="sr-only">Create Your Cart</h1>

    <!-- Mobile order summary -->
    <section aria-labelledby="order-heading" class="bg-gray-50 dark:bg-gray-800 px-4 py-6 sm:px-6 lg:hidden">
      <Disclosure as="div" class="mx-auto max-w-lg" v-slot="{ open }">
        <div class="flex items-center justify-between">
          <h2 id="order-heading" class="text-lg font-medium text-gray-900 dark:text-white">Your Cart</h2>
          <DisclosureButton class="font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500">
            <span v-if="open">Hide full summary</span>
            <span v-if="!open">Show full summary</span>
          </DisclosureButton>
        </div>

        <DisclosurePanel>
          <ul role="list" class="divide-y divide-gray-200 border-b border-gray-200">
            <li v-for="product in order.cart_items" :key="product.id" class="flex space-x-6 py-6">
              <img :src="product.src" :alt="product.title" class="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center" />
              <div class="flex flex-col justify-between space-y-4">
                <div class="space-y-1 text-sm font-medium">
                  <h3 class="text-gray-900 dark:text-gray-200">{{ product.title }}</h3>
                  <p class="text-gray-900 dark:text-gray-200">{{ product.formatted_total }}</p>
                  <p class="text-gray-500 dark:text-gray-400">{{ product.variants_display }}</p>
                </div>
                <div class="flex space-x-4">
                  <button type="button" class="text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500">Edit</button>
                  <div class="flex border-l border-gray-300 pl-4">
                    <button type="button" class="text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500">Remove</button>
                  </div>
                </div>
              </div>
            </li>
          </ul>

<!--          <form class="mt-10">-->
<!--            <label for="discount-code-mobile" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Discount code</label>-->
<!--            <div class="mt-1 flex space-x-4">-->
<!--              <input type="text" id="discount-code-mobile" name="discount-code-mobile" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-white dark:border-gray-300 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 sm:text-sm" />-->
<!--              <button type="submit" class="rounded-md bg-gray-200 dark:bg-gray-900 px-4 text-sm font-medium text-gray-600 dark:text-gray-400 hover:bg-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Apply</button>-->
<!--            </div>-->
<!--          </form>-->

          <dl class="mt-10 space-y-6 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div class="flex justify-between">
              <dt>Subtotal</dt>
              <dd class="text-gray-900 dark:text-gray-200">{{ order.formatted_subtotal }}</dd>
            </div>
<!--            <div class="flex justify-between">-->
<!--              <dt class="flex">-->
<!--                Discount-->
<!--                <span class="ml-2 rounded-full bg-gray-200 dark:bg-gray-900 px-2 py-0.5 text-xs tracking-wide text-gray-600">{{ discount.code }}</span>-->
<!--              </dt>-->
<!--              <dd class="text-gray-900 dark:text-gray-200">-{{ discount.amount }}</dd>-->
<!--            </div>-->
            <div v-for="total in order.cart_totals" :key="total.key" class="flex justify-between">
              <dt>{{ total.title }}</dt>
              <dd class="text-gray-900 dark:text-gray-200">{{ total.formatted_value }}</dd>
            </div>
          </dl>
        </DisclosurePanel>

        <p class="mt-6 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 pt-6 text-sm font-medium text-gray-900 dark:text-gray-200">
          <span class="text-base">Total</span>
          <span class="text-base">{{ order.formatted_total }}</span>
        </p>
      </Disclosure>
    </section>

    <!-- Cart summary -->
    <section aria-labelledby="summary-heading" class="hidden w-full max-w-md xl:max-w-xl flex-col bg-gray-50 dark:bg-gray-800 lg:flex">
      <h2 id="summary-heading" class="sr-only">Cart summary</h2>

      <ul role="list" class="flex-auto divide-y divide-gray-200 dark:divide-gray-700 overflow-y-auto px-6">
        <li v-for="product in order.cart_items" :key="product.id" class="flex space-x-6 py-6">
          <img :src="product.src" :alt="product.title" class="h-40 w-40 flex-none rounded-md bg-gray-200 dark:bg-gray-700 object-cover object-center" />
          <div class="flex flex-col justify-between space-y-4">
            <div class="space-y-1 text-sm font-medium">
              <h3 class="text-gray-900 dark:text-gray-200">{{ product.title }}</h3>
              <p class="text-gray-900 dark:text-gray-200">{{ product.formatted_total }}</p>
              <p class="text-gray-500 dark:text-gray-400">{{ product.variants_display }}</p>
            </div>
            <div class="flex space-x-4">
              <button type="button" class="text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500">Edit</button>
              <div class="flex border-l border-gray-300 pl-4">
                <button type="button" class="text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500">Remove</button>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="sticky bottom-0 flex-none border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 p-6">
<!--        <form class="mb-10">-->
<!--          <label for="discount-code" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Discount code</label>-->
<!--          <div class="mt-1 flex space-x-4">-->
<!--            <input type="text" id="discount-code" name="discount-code" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-white dark:border-gray-300 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 sm:text-sm" />-->
<!--            <button type="submit" class="rounded-md bg-gray-200 dark:bg-gray-900 px-4 text-sm font-medium text-gray-600 dark:text-gray-400 hover:bg-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Apply</button>-->
<!--          </div>-->
<!--        </form>-->

        <dl class=" space-y-6 text-sm font-medium text-gray-500 dark:text-gray-400">
          <div class="flex justify-between">
            <dt>Subtotal</dt>
            <dd class="text-gray-900 dark:text-gray-200">{{ order.formatted_subtotal }}</dd>
          </div>
<!--          <div class="flex justify-between">-->
<!--            <dt class="flex">-->
<!--              Discount-->
<!--              <span class="ml-2 rounded-full bg-gray-200 dark:bg-gray-900 px-2 py-0.5 text-xs tracking-wide text-gray-600">{{ discount.code }}</span>-->
<!--            </dt>-->
<!--            <dd class="text-gray-900 dark:text-gray-200">-{{ discount.amount }}</dd>-->
<!--          </div>-->
          <div v-for="total in order.cart_totals" :key="total.key" class="flex justify-between">
            <dt>{{ total.title }}</dt>
            <dd class="text-gray-900 dark:text-gray-200">{{ total.formatted_value }}</dd>
          </div>
          <div class="flex items-center justify-between border-t border-gray-200 dark:border-gray-700 pt-6 text-gray-900 dark:text-gray-200">
            <dt class="text-base">Total</dt>
            <dd class="text-base">{{ order.formatted_total }}</dd>
          </div>
        </dl>
      </div>
    </section>

    <!-- Checkout form -->
    <section aria-labelledby="payment-heading" class="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0">
      <div class="mx-auto max-w-lg xl:max-w-xl">
        <div class="hidden pb-16 pt-10 lg:flex">
          <a href="#">
            <span class="sr-only">Your Company</span>
            <img src="https://tailwindui.com/img/logos/mark.svg?color=indigo&amp;shade=600" alt="" class="h-8 w-auto" />
          </a>
        </div>

        <form>
          <div>
            <div>
              <h2 class="text-lg font-medium text-gray-900 dark:text-white">Contact information</h2>

              <YpInput
                  id="email"
                  label="Email address"
                  class="mt-4"
              >
                <template #optional>
                  <span>Have an account? Login</span>
                </template>
              </YpInput>
            </div>

            <div class="mt-10 border-t border-gray-200 pt-10">
              <h2 class="text-lg font-medium text-gray-900 dark:text-white">Shipping information</h2>

              <div class="mt-4 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4">
                <YpInput
                    id="first_name"
                    label="First name"
                  />

                <YpInput
                    id="last_name"
                    label="Last name"
                />

                <YpInput
                    id="phone"
                    label="Phone"
                    class="sm:col-span-2"
                />

                <YpInput
                    id="address"
                    label="Address"
                    class="sm:col-span-2"
                />

                <YpInput
                    id="address_2"
                    label="Apartment, suite, etc."
                    class="sm:col-span-2"
                />

                <YpInput
                    id="city"
                    label="City"
                />

                <YpInput
                    id="country"
                    label="County"
                />

                <YpInput
                    id="state"
                    label="State / Province"
                />

                <YpInput
                    id="postcode"
                    label="Postal code"
                />
              </div>
            </div>

            <div class="mt-10 border-t border-gray-200 pt-10">
              <RadioGroup v-model="selectedDeliveryMethod">
                <RadioGroupLabel class="text-lg font-medium text-gray-900 dark:text-white">Delivery method</RadioGroupLabel>

                <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  <RadioGroupOption as="template" v-for="deliveryMethod in deliveryMethods" :key="deliveryMethod.id" :value="deliveryMethod" v-slot="{ checked, active }">
                    <div :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'ring-2 ring-indigo-500' : '', 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none']">
                      <span class="flex flex-1">
                        <span class="flex flex-col">
                          <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ deliveryMethod.title }}</RadioGroupLabel>
                          <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">{{ deliveryMethod.turnaround }}</RadioGroupDescription>
                          <RadioGroupDescription as="span" class="mt-6 text-sm font-medium text-gray-900">{{ deliveryMethod.price }}</RadioGroupDescription>
                        </span>
                      </span>
                      <CheckCircleIcon v-if="checked" class="h-5 w-5 text-indigo-600" aria-hidden="true" />
                      <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>

            <button type="submit" class="mt-6 w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Create Link</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script setup>
import YpInput from "../../components-old/YpInput.vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import {
  CheckCircleIcon,
  ChevronDownIcon,
  TrashIcon,
  LockClosedIcon
} from '@heroicons/vue/20/solid'
// import {useCartStore} from "@youpay/stores";
import {computed} from "vue";
import ProfileLayout from "@/web/youpay-me-v3/Layouts/ProfileLayout.vue";

const cartStore = useCartStore();
const order = computed(() => cartStore.order);

const deliveryMethods = [
  { id: 1, title: 'Standard', turnaround: '4–10 business days', price: '$5.00' },
  { id: 2, title: 'Express', turnaround: '2–5 business days', price: '$16.00' },
];

defineOptions({ layout: ProfileLayout })

</script>
