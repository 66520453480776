<template>
  <Head title="The Bunker" />
  <div class="flex-grow h-full mx-auto max-w-[1700px] w-full lg:px-8">
    <div class="px-4 lg:px-0">
      <h1 class="py-4 text-3xl dark:text-white">
        The Bunker
      </h1>
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex flex-wrap md:space-x-8" aria-label="Tabs">
          <a v-for="tab in tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-violet-500 text-violet-400' : 'border-transparent text-gray-500 hover:border-violet-200 hover:text-violet-400', 'flex w-1/2 md:w-auto whitespace-nowrap border-b-2 px-2 py-4 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined">
            {{ tab.name }}
            <span v-if="tab.count" :class="[tab.current ? 'bg-violet-100 text-violet-700' : 'bg-gray-100 text-gray-900', 'ml-3 rounded-full px-2.5 py-0.5 text-xs font-medium inline-block']">
              {{ tab.count }}
            </span>
          </a>
        </nav>
      </div>
    </div>
    <div class="mt-4 flex flex-col">
      <div v-for="order in orders" class="relative bg-white dark:bg-white/5 rounded mb-6 border" :class="order.expanded ? 'border-youpay-teal' : ''">
        <div class=" border-b border-gray-200 dark:border-gray-700 px-4 pt-3 pb-6 sm:px-6">
          <div class="flex flex-col">
            <div class="-ml-4 -mt-4 flex flex-col md:flex-row md:items-center justify-between">
              <div class="ml-4 mt-4">
                <div class="flex items-center flex-wrap gap-y-2">
                  <div class="flex-shrink-0 flex md:flex-row gap-x-2">
                    <img class="h-12 w-12 object-cover rounded-xl" v-if="order.shopper.avatar" :src="order.shopper.avatar" alt="" />
                    <UserCircleIcon v-else class="h-12 w-12 p-1 text-gray-400 dark:text-gray-600" />
                    <img class="h-12 w-12 object-cover rounded-xl" v-if="order.items[0].image" :src="order.items[0].image" alt="" />
                  </div>
                  <div class="ml-4">
                    <div class="flex items-center">
                      <component :is="order.gateway === 'Coinflow' ? CoinflowIcon : StripeIcon" class="h-6 w-6 mr-2 text-gray-500 dark:fill-white" aria-hidden="true" />
                      <a :href="order.nova" target="_blank" class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                        #{{ order.id }} - {{ order.total }}
                      </a>
                    </div>
                    <p class="text-sm text-gray-500">
                      <a href="#">{{ order.shopper.name }} - @{{ order.shopper.username }}</a>
                    </p>
                  </div>
                  <div class="ml-4">
                    <p class="text-sm text-gray-500">
                      <span class="font-semibold">Created:</span> {{ order.created_at }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="ml-4 mt-4 flex justify-between gap-y-1 gap-x-4 flex-shrink-0">
                <OrderAction :order_id="order.id" action="deny" class="text-gray-900 bg-red-100 dark:bg-red-700/10 ring-red-300 dark:ring-red-900 hover:bg-gray-50">
                  <XMarkIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-red-600" aria-hidden="true" />
                  <span>Deny</span>
                </OrderAction>
                <OrderAction :order_id="order.id" action="fu" :options="emails" lass="text-gray-900 bg-yellow-100 dark:bg-yellow-700/10 ring-yellow-300 dark:ring-yellow-900 hover:bg-yellow-50">
                  <PaperAirplaneIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-yellow-400 dark:text-yellow-400" aria-hidden="true" />
                  <span>FU</span>
                </OrderAction>
                <OrderAction :order_id="order.id" action="capture" class="text-gray-900 bg-green-100 dark:bg-green-700/10 ring-green-300 dark:ring-green-900 hover:bg-gray-50">
                  <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-green-700 dark:text-green-400" aria-hidden="true" />
                  <span>Capture</span>
                </OrderAction>
              </div>
            </div>
            <OrderFlags class="pt-2 pb-4 md:py-0" :order="order" :available-tags="availableTags" />
            <!-- order links -->
            <div class="py-2 flex flex-row items-center justify-center gap-x-2 md:justify-start">
              <span class="text-xs font-semibold dark:text-white">Links:</span>
              <ul class="flex flex-wrap items-center justify-center gap-x-2">
                <li v-for="(link, name) in order.links" class="flex items-center gap-x-1">
                  <a :href="link" target="_blank" class="text-xs text-gray-500 dark:text-gray-400">
                    {{ name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- exapand - show/hide, middle of the page -->
          <button @click="order.expanded = !order.expanded" type="button" class="rounded-md border border-gray-300 dark:border-gray-700 absolute bg-gray-100 dark:bg-gray-900 -bottom-4 block py-2 left-[50%] -ml-[50px] w-[100px] dark:text-white text-xs mt-4">
            {{ order.expanded ? 'Hide' : 'Show' }} Details
          </button>
        </div>
        <div v-if="order.expanded" class="flex flex-col divide-y divide-gray-200 dark:divide-gray-700 pb-5">
          <div class="flex flex-col lg:flex-row divide-y md:divide-y-0 divide-gray-200 dark:divide-gray-700 pb-5">
            <AccountDetails type="Shopper" :account="order.shopper" class="flex-shrink-0 px-4 sm:px-6 py-4 lg:w-1/2" />
            <AccountDetails type="Payer" :account="order.payer" class="flex-shrink-0 px-4 sm:px-6 py-4 lg:w-1/2">
              <div class="mt-2 pt-2 border-t dark:border-gray-800 grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-5 w-full">
                <div class="col-span-1 md:col-span-2 flex flex-col text-wrap">
                  <span class="text-xs font-bold text-gray-500 dark:text-gray-200">
                    Checkout Details
                  </span>
                  <span class="mt-1 text-xs text-gray-900 dark:text-white">
                    Payment method: {{ order.gateway }} - {{ order.payment_method }}
                  </span>
                </div>
                <div class="col-span-1 flex flex-col text-wrap">
                  <span class="text-xs text-gray-900 dark:text-white text-wrap break-words">
                    {{ order.customer?.email }}
                  </span>
                  <span class="mt-1 text-xs text-gray-900 dark:text-white text-wrap break-words">
                    {{ order.customer?.phone }}
                  </span>
                </div>
                <div class="col-span-1 md:col-span-2 flex flex-col text-wrap">
                  <span class="text-xs text-gray-900 dark:text-white text-wrap break-words">
                    {{ order.customer?.name }} <br />
                    {{ order.customer?.address }}
                  </span>
                </div>
              </div>
            </AccountDetails>
          </div>
          <div class="px-4 py-4">
            <h3 class="font-semibold dark:text-white pb-2">Order Items:</h3>
            <div class=" grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-3 lg:grid-cols-5 w-full  text-gray-900 dark:text-gray-100">
              <a v-for="item in order.items" :key="item.id" class="flex space-x-4 pb-2 md:pb-1 items-center border-b md:border-b-0 border-gray-200 dark:border-gray-600" :href="item.url" target="_blank">
                <img :src="item.image" class="h-18 w-18 flex-none rounded-lg bg-gray-100 dark:bg-gray-800 object-cover object-center" />
                <div class="flex flex-col">
                  <div class="flex flex-row justify-start gap-x-2 md:gap-x-4">
                    <span class="flex-grow-0 inline-flex items-center rounded-md bg-indigo-400/10 px-1.5 py-1 text-xs leading-none text-indigo-600 font-light ring-1 ring-inset ring-indigo-400/20 dark:text-indigo-300">
                      {{ item.type }}
                    </span>
                    <span v-if="item.store" class="flex-grow-0 inline-flex items-center rounded-md bg-cyan-400/10  px-1 py-1 text-xs leading-none text-cyan-600 font-light ring-1 ring-inset ring-cyan-400/50 dark:text-cyan-300">
                      {{ item.store }}
                    </span>
                  </div>
                  <span class="font-semibold">
                    {{ item.title }}
                  </span>
                  <span>
                    {{ item.total }}
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Pagination :links="pagination" class="pt-4 pb-8 px-4" />
    </div>

  </div>
</template>

<script setup lang="ts">
import { XMarkIcon, PaperAirplaneIcon, CheckIcon } from '@heroicons/vue/20/solid'
import AccountDetails from "@/web/youpay-me-v3/Components/Umpire/AccountDetails.vue";
import CoinflowIcon from "@/web/youpay-me-v3/Icons/CoinflowIcon.vue";
import {StripeIcon} from "vue3-simple-icons";
import OrderFlags from "@/web/youpay-me-v3/Components/Umpire/OrderFlags.vue";
import OrderAction from "@/web/youpay-me-v3/Components/Umpire/OrderAction.vue";
import Pagination from "@/web/youpay-me-v3/Components/Blocks/Pagination.vue";
import {UserCircleIcon} from "@heroicons/vue/24/outline";
import {Head} from "@inertiajs/vue3";

const props = defineProps<{
  type: string,
  orders: any[],
  pagination?: any;
  emails: string[],
  count: {
    all: number,
    coinflow: number,
    connect: number,
    stripe: number,
    followup: number,
    pending: number,
  },
  availableTags: string[],
}>();

const tabs = [
  {
    name: 'All Review',
    href: route('umpire.dashboard'),
    count: props.count.all,
    current: props.type === 'all'
  },
  {
    name: 'CF Review',
    href: route('umpire.dashboard', { type: 'coinflow' }),
    count: props.count.coinflow,
    current: props.type === 'coinflow'
  },
  {
    name: 'Connect Review',
    href: route('umpire.dashboard', { type: 'connect' }),
    count: props.count.connect,
    current: props.type === 'connect'
  },
  {
    name: 'Stripe Review',
    href: route('umpire.dashboard', { type: 'stripe' }),
    count: props.count.stripe,
    current: props.type === 'stripe'
  },
  {
    name: 'Follow Up',
    href: route('umpire.dashboard', { type: 'followup' }),
    count: props.count.followup,
    current: props.type === 'followup'
  },
  {
    name: 'Capturing',
    href: route('umpire.dashboard', { type: 'pending' }),
    count: props.count.pending,
    current: props.type === 'pending'
  },
  // { name: 'Fulfillments', href: '#', count: '6', current: false },
  // { name: 'Moderate Accounts', href: '#', count: '4', current: false },
  // { name: 'Reports', href: '#', current: false },
]

</script>