function getClassFromStatus(status: string): string {
    switch (status) {
        case 'Completed':
            return 'text-green-500';
        case 'Under Review':
            return 'text-orange-500';
        case 'Action Required':
            return 'text-red-500';
        case 'Processing':
            return 'text-youpay-yellow';
        case 'Pending':
            return 'text-yellow-500';
        case 'Fulfilled':
            return 'text-green-500';
        default:
            return 'text-gray-500';
    }
}
function getBorderClassFromStatus(status: string): string {
    switch (status) {
        case 'Completed':
            return 'border-green-500';
        case 'Under Review':
            return 'border-orange-500';
        case 'Action Required':
            return 'border-red-500';
        case 'Processing':
            return 'border-yellow-500';
        case 'Pending':
            return 'border-yellow-500';
        case 'Fulfilled':
            return 'border-green-500';
        default:
            return 'border-gray-500';
    }
}

export const statusClass = (status: string) => {
    return `text-sm font-medium ${getClassFromStatus(status)}`;
};

export const borderClass = (status: string) => {
    return `border ${getBorderClassFromStatus(status)}`;
}