declare global {
    interface Window {
        FreshworksWidget?: (...args: any[]) => void;
        gtag?: (...args: any[]) => void;
        klaviyo?: any;
        gtagBackup?: (...args: any[]) => void;
    }
}

import './bootstrap';
import 'maz-ui/css/main.css'
import './app.css';

import { createApp, h } from 'vue';
import {createInertiaApp, router, usePage} from '@inertiajs/vue3';
import Vapor from './helpers/vapor';
import { ZiggyVue } from 'ziggy-js';
import MainLayout from "@/web/youpay-me-v3/Layouts/MainLayout.vue";
import InstantSearch from 'vue-instantsearch/vue3/es';
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import * as Sentry from "@sentry/vue";
import { initTheme, cleanupTheme } from './helpers/Theme';
import {
    fpjsPlugin
} from '@fingerprintjs/fingerprintjs-pro-vue-v3';

// use tiny-emitter for event bus
import mitt from 'mitt'
import {User} from "@/web/youpay-me-v3/types";
const emitter = mitt();

window.addEventListener('popstate', (event) => {
    console.log('popstate', event);
    const url = event.state?.url;
    if (url && url.includes('shopify') && url.includes('create')) {
        // go back to previous page
        window.history.back();
    } else if (url && url.includes('shopify') && url.includes('shipping')) {
        // reload page
        window.location.reload();
    }
});

window.gtagBackup = (...args: any[]) => {
    if (window?.gtag) {
        console.log('submitting-gtag', args);
        window.gtag(...args);
        if (typeof klaviyo !== "undefined") {
            klaviyo.push(["track", args[0], args]);
        }
    } else {
        console.log('gtag not loaded on second time', args);
    }
}

const mainGtag = (...args: any[]) => {
    if (window?.gtag) {
        console.log('gtag', args);
        window.gtag(...args);
        if (typeof klaviyo !== "undefined") {
            klaviyo.push(["track", args[0], args]);
        }
    } else {
        console.log('gtag not loaded');
        setTimeout(() => {
            window.gtagBackup(...args);
        }, 2000);
    }
}

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL ?? import.meta.env.ASSET_URL);

const GOOGLE_MAPS_API_KEY = import.meta.env.SCRIPT_MAPS_API_KEY;

router.on('error', (event) => {
    console.log('error occured', event);
});

router.on('navigate', (event) => {
    console.log('navigated', event, event.srcElement);
    // Get the element that triggered the event
    trackEvent('navigate', route().current(), event.detail.page.url);
    // get current user id
    const user = <User>usePage().props.auth;
    if (user) {
        Sentry.setUser({
            fullName: user.name,
            username: user.username,
            email: user.email,
        });

        Sentry.feedbackIntegration({
            useSentryUser: {
                name: 'fullName',
                email: 'email',
            },
        });

        if (FreshworksWidget) {
            FreshworksWidget('identify', 'ticketForm', {
                name: user.name,
                email: user.email,
            });
        }
    }
    const track = user?.track;
    if (track) {
        mainGtag('set', 'user_id', track.$id);
        if (window.klaviyo) {
            window.klaviyo.identify(track);
        } else {
            setTimeout(() => {
                if (window.klaviyo) {
                    window.klaviyo.identify(track);
                }
            }, 2000);
        }
    }
});

const appName = 'YouPay';

polyfillCountryFlagEmojis();


function resetFreshdeskZIndex() {
    // find div #freshworks-container and change the existing z-index (inline styled) to 10
    const freshworksContainer = document.querySelector('#freshworks-container iframe');
    if (freshworksContainer) {
        // change zindex without changing the existing style
        freshworksContainer.style['z-index'] = '10';
    } else {
        setTimeout(() => {
            // wait a second and try again
            resetFreshdeskZIndex();
        }, 1000);
    }

}
function loadFreshdeskWidget() {
    const script = document.createElement('script');
    script.src = 'https://aus-widget.freshworks.com/widgets/51000003670.js';
    script.async = true;
    script.defer = true;
    script.onload = resetFreshdeskZIndex;
    document.head.appendChild(script);
}

createInertiaApp({
    title: (title) => {
        return title ? `${title} - ${appName}` : appName;
    },
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        let page = pages[`./Pages/${name}.vue`]
        if (!page) {
            console.log('page not found', `./Pages/${name}.vue`);
            return null;
        }
        page.default.layout = page.default.layout || MainLayout;
        return page
    },
    progress: {
        color: '#F796B9',
    },
    setup({ el, App, props, plugin }) {
        let app = createApp({ render: () => h(App, props) });
        const feedback = Sentry.feedbackIntegration({
            id: "feedback-form",
            autoInject: false,
            // Additional SDK configuration goes in here, for example:
            colorScheme: "system",
            triggerLabel: "Feedback",
            formTitle: "Send Feedback",
            submitButtonLabel: "Send Feedback",
            messagePlaceholder: "What's your feedback? Have a support request, email support@youpay.co",
            onFormOpen: () => {
                if (FreshworksWidget) {
                    FreshworksWidget('hide');
                }
                // After #feedback-form h2, add a div (with id "support-form"), containing a link to our support page
                const supportLink = document.createElement("div");
                supportLink.id = "support-form";
                supportLink.innerHTML = `
                        <style>
                          #support-form a {
                            padding-left: 5px;
                            color: #2F3A93;
                            text-decoration: underline;
                            cursor: pointer;
                          }
                          #support-form p {
                            margin: 0;
                          }
                          @media (prefers-color-scheme: dark) {
                            #support-form a {
                              color: #F796B9;                                    
                            }
                          }
                        </style>
                        <p>Looking for support?
                            <a href="https://support.youpay.co/" target="_blank" rel="noopener noreferrer">
                                Click here to contact us
                            </a>
                        </p>
                    `;
                const form = document.querySelector("#feedback-form")?.shadowRoot;
                if (form?.querySelector("#support-form")) {
                    return;
                }
                form?.querySelector("h2")?.after(supportLink);
            },
            onFormClose: () => {
                if (FreshworksWidget) {
                    FreshworksWidget('show');
                }
            }
        });
        Sentry.init({
            app,
            dsn: "https://1345fecfa975d86bc10b435b9d4d886d@o4507167976587264.ingest.us.sentry.io/4507167985893376",
            environment: import.meta.env.MODE === 'production' ? 'production' : 'development',
            integrations: [
                feedback
            ],
            sampleRate: 0.01
        });
        initTheme();
        app.use(plugin)
            .use(InstantSearch)
            .use(ZiggyVue, Ziggy)
            .provide('mapsApiKey', GOOGLE_MAPS_API_KEY)
            .provide('eventHub', emitter)
            .provide('asset', Vapor.asset)
            .use(fpjsPlugin, {
                loadOptions: {
                    apiKey: "c1ss2iQLeAQFPRNZTUDO"
                },
            })
            .provide('trackEvent', trackEvent)
            .provide('feedback', feedback)
            .mount(el);
        loadFreshdeskWidget();
    },
});
