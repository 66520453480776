<template>
  <div>
    <button @click="toggle" class="btn btn-primary flex flex-row gap-x-1 items-center">
      <DocumentTextIcon class="h-6 w-6 cursor-pointer" :class="order.notes.length ? 'text-yellow-400 dark:text-yellow-600' : 'text-gray-400 dark:text-gray-600'"/>
      <!-- count -->
      <span v-if="order.notes.length" class="text-xs rounded-full bg-gray-200 dark:bg-gray-700 dark:text-white py-0.5 px-1.5">
        {{ order.notes.length }}
      </span>
    </button>
    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-50" @close="toggle">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 dark:bg-black/50 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                  <div class="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-900 py-6 shadow-xl">
                    <div class="px-4 sm:px-6">
                      <div class="flex items-start justify-between">
                        <DialogTitle class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">Order Notes</DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button" class="relative rounded-md bg-white dark:bg-white/10 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500" @click="open = false">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="relative mt-6 flex-1 px-4 sm:px-6">
                      <div>
                        <div class="flex justify-between">
                          <InputLabel for="note" value="Add Note" />
                        </div>

                        <TextAreaInput
                          id="note"
                          type="text"
                          class="mt-1 block w-full"
                          v-model="form.note"
                          @change="form.validate('note')"
                          :error="form.errors.note"
                        />
                        <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">Add a note to this order</p>

                        <InputError class="mt-2" :message="form.errors.note"/>

                        <PrimaryButton class="mt-3" @click="submit" >Add Note</PrimaryButton>
                      </div>
                      <!-- List all existing notes -->
                      <div class="mt-6 overflow-y-scroll">
                        <span class="text-sm font-semibold text-gray-900 dark:text-gray-50">Current Notes</span>
                        <div class="flex flex-col gap-y-4 mt-2">
                          <div v-for="note in order.notes" :key="note.id" class="">
                            <p class="p-2 bg-gray-100 dark:bg-white/5 dark:text-white text-sm rounded">
                              {{ note.note }}
                            </p>
                            <div class="flex items-center gap-x-2 pt-1">
                              <!-- email -->
                              <span class="text-xs text-gray-400 dark:text-gray-600">{{ note.created_by }}</span>
                              <span class="text-xs text-gray-400 dark:text-gray-600">{{ note.created_at }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon, DocumentTextIcon } from '@heroicons/vue/24/outline'
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import {PlusCircleIcon} from "@heroicons/vue/20/solid";

const open = ref(false)

const props = defineProps<{
  order: any;
}>()

const form = useForm('post', route('umpire.order.note', props.order.id), {
  note: ''
});

const toggle = () => {
  open.value = !open.value
}

const submit = () => {
  if (form.processing) return;
  form.submit({
    onSuccess: () => {
      form.reset();
    }
  })
}
</script>