<template>
  <Head :title="profile.username + ' has requested a gift'" />
  <div class="mx-auto sm:pl-8 xl:pl-12 3xl:pl-28 sm:pr-8 2xl:max-w-[1200px] 3xl:max-w-[1500px] 4xl:max-w-[1700px] dark:text-white pb-12" :class="bag ? 'mb-20' : ''">
    <div class="px-4 pt-4 pb-2 md:px-6 lg:py-10 flex flex-row justify-between">
      <Link v-if="!gift.anon" :href="route('profile.show', profile.username)" class="text-sm font-semibold leading-7 text-gray-800 dark:text-white">
        <span aria-hidden="true">&larr;</span>
        Back to profile
      </Link>
      <span v-else>
        {{ owner ? 'You are the owner of this gift' : '' }}
      </span>
      <div class="flex flex-row items-center gap-x-4 text-sm lg:text-md">
        <ActionDropdown v-if="owner" :items="[
          { name: 'Edit Gift', href: route('gift.update', [gift.id]) },
          { name: 'Delete Gift', href: route('gift.destroy', gift.id), method: 'DELETE' },
        ]" />
        <ShareUrl :url="gift.share_url" class="text-sm lg:text-md" />
      </div>
    </div>
    <div class="relative flex flex-col lg:flex-row h-full w-full">
      <div class="p-4 lg:p-0 mx-auto overflow-x-hidden lg:max-w-[40%] 2xl:max-w-[45%] items-center flex flex-col flex-grow-0 flex-shrink relative">
        <img :src="gift.image" class="h-auto max-h-[300px] lg:max-h-none lg:w-full" />
        <div v-if="gift.store" class="bg-white absolute py-1 px-2 right-0 mr-8 bottom-0 mb-8">
          <StoreLogo :store="gift.store" class="w-[70px] " />
        </div>
      </div>

      <div class="px-6 flex-grow flex flex-col ">
        <div class="">
          <div class="flex flex-row justify-between items-center ">
            <h1 class="text-gray-800 dark:text-gray-200">Purchase this gift for {{ gift.anon ? 'someone' : profile.username }}</h1>
            <div class="flex flex-row justify-start pb-2 items-center">
              <span class="flex-grow-0 inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs leading-none text-indigo-600 font-light ring-1 ring-inset ring-indigo-400/20 dark:text-indigo-300">
                {{ gift.type }}
              </span>
              <ProductTypeLegend class="ml-2" />
            </div>
          </div>
          <div class="mt-0 mb-4">
            <div class="lg:w-[75%] flex flex-col">
              <h2 class="text-2xl lg:text-2xl 3xl:text-2xl 4xl:text-3xl pb-1 flex-shrink">{{ gift.title }}</h2>
              <span aria-label="price" class="text-xl font-semibold block">{{ gift.formatted_amount }}</span>
            </div>
          </div>
          <div v-if="gift.cart?.items && gift.cart?.items.length > 1">
            <h3 class="text-sm font-semibold text-gray-900 dark:text-gray-50">Gift Items:</h3>
            <ul
              role="list"
              class="flex-auto overflow-y-auto divide-y divide-gray-200 dark:divide-gray-600 xl:inline"
            >
              <li v-for="item in gift.cart?.items" :key="item.id" class="flex py-6 space-x-6">
                <img :src="item.image" class="h-15 w-15 flex-none rounded-md bg-gray-200 object-cover object-center" />
                <div class="text-sm flex flex-col justify-between font-medium space-y-1">
                  <div class="pb-1 flex flex-row gap-x-4 items-center">
                    <h3 class="font-semibold text-gray-900 dark:text-gray-50">{{ item.title }}</h3>
                    <span v-if="item.quantity > 1" class="text-xs text-gray-500 dark:text-gray-200">x {{ item.quantity }}</span>
                  </div>
                  <p class="pt-2 text-sm text-gray-900 dark:text-gray-50">{{ item.total }}</p>
                </div>
              </li>
            </ul>
            <h3 v-if="gift.description && gift.description.length > 0" class="text-sm font-semibold text-gray-900 dark:text-gray-50 pb-1">About this Gift:</h3>
          </div>
          <div class="prose prose-sm dark:prose-invert pb-6 min-h-[80px]" v-html="cleanedHtml" />
        </div>

        <div v-if="gift.status === 'active'">
          <div v-if="owner" class="flex flex-col items-center pb-4 gap-y-4">
            <ShareUrl
              :url="gift.share_url"
              class="flex justify-center rounded-md w-full
                bg-gradient-to-r via-purple-500 from-youpay-navy-light to-youpay-pink
                px-4 py-3 text-sm font-semibold text-white shadow-sm
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                focus-visible:outline-youpay-teal"
            >
              <span class="flex flex-row items-center gap-x-4">
                <ShareIcon class="h-5 w-5 text-gray-100" aria-hidden="true" />
                Share your gift
              </span>
            </ShareUrl>
            <span>
              or
            </span>
          </div>
          <div class="-mt-px flex rounded-md divide-x-2 border-t border-b border-x dark:border-x-0 dark:border-b-0 bg-youpay-navy dark:bg-youpay-navy-light dark:border-youpay-navy divide-white/30 border-youpay-navy-light dark:divide-white/20 overflow-hidden">
            <div v-if="gift.baggable && !gift.anon" class="-ml-px flex w-0 flex-1">
              <Link
                v-if="!bag?.items?.find(item => item.gift_id === gift.id)"
                :href="route('giftbag.add', gift.id)"
                method="put"
                preserve-scroll
                as="button"
                class="relative cursor-pointer inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-white hover:bg-youpay-navy-light dark:hover:bg-youpay-navy dark:text-white"
              >
                <PlusIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                Add to cart
              </Link>
              <Link
                v-else
                :href="route('giftbag.remove', [bag.id, gift.id])"
                method="delete"
                preserve-scroll
                as="button"
                class="relative cursor-pointer inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-white hover:bg-youpay-navy-light dark:hover:bg-youpay-navy dark:text-white"
              >
                <MinusIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                Remove
              </Link>
            </div>
            <div class="flex w-0 flex-1">
              <Link v-if="!gift.baggable" :href="route('shopify.checkout', [profile.username, gift.cart_id])" class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-white hover:bg-youpay-navy-light dark:hover:bg-youpay-navy dark:text-white bg-youpay-navy">
                <ShoppingCartIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                Checkout
              </Link>
              <Link v-else :href="route('giftbag.add.checkout', gift.id)" method="post" class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-white hover:bg-youpay-navy-light dark:hover:bg-youpay-navy dark:text-white">
                <ShoppingCartIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                Checkout
              </Link>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="-mt-px flex rounded-md bg-gray-200 divide-x border-t border-b border-x dark:border-x-0 dark:border-b-0 dark:bg-white/[0.1] dark:border-gray-700 divide-gray-200 border-gray-200 dark:divide-gray-700">
            <div class="flex w-0 flex-1">
              <span class="cursor-not-allowed  relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3  border border-transparent py-4 text-sm font-semibold text-gray-900 dark:hover:bg-gray-600 dark:text-white">
                {{ statusText }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GiftBagBar :bag="bag" :profile="profile" />
</template>

<script setup lang="ts">
import {Head, Link, router} from "@inertiajs/vue3";
import ProfileLayout from "@/web/youpay-me-v3/Layouts/ProfileLayout.vue";
import {onMounted, ref, inject, computed,} from 'vue'
import {Profile, User} from "@/web/youpay-me-v3/types";
import {marked} from "marked";
import DOMPurify from "dompurify";
import {MinusIcon, PlusIcon, ShoppingCartIcon} from "@heroicons/vue/24/outline";
import {Bag} from "@/web/youpay-me-v3/types/checkout";
import GiftBagBar from "@/web/youpay-me-v3/Components/Gifting/GiftBagBar.vue";
import {ShareIcon} from "@heroicons/vue/24/outline";
import ShareUrl from "@/web/youpay-me-v3/Components/Blocks/ShareUrl.vue";
import StoreLogo from "@/web/youpay-me-v3/Components/Blocks/StoreLogo.vue";
import PrimaryLink from "@/web/youpay-me-v3/Components/Buttons/PrimaryLink.vue";
import ActionDropdown from "@/web/youpay-me-v3/Components/Buttons/ActionDropdown.vue";
import ProductTypeLegend from "@/web/youpay-me-v3/Components/Blocks/ProductTypeLegend.vue";

defineOptions({ layout: ProfileLayout })

// Props
const props = defineProps<{
  profile: Profile,
  owner: boolean,
  anon: boolean,
  bag: Bag,
  auth: User,
  gift: any,
}>();

const statusText = computed(() => {
  if (props.gift.status === 'soldout') {
    return 'Someone has already paid for this gift!';
  }

  if (props.gift.status === 'processing') {
    return 'Payment Processing';
  }
  if (props.gift.status === 'expired') {
    return 'Expired';
  }
  if (props.gift.status === 'cancelled') {
    return 'Cancelled';
  }
  if (props.gift.status === 'disabled') {
    return 'Disabled';
  }
  if (props.gift.status === 'active') {
    return 'There is an error with this gift.';
  }
});

const cleanedHtml = computed(() => {
  if (props.gift && props.gift.description && props.gift.description.length > 0) {
    const dirty = marked.parse(props.gift.description);
    const clean = DOMPurify.sanitize(dirty, {
      FORBID_TAGS: ['style', 'a'],
      ALLOW_DATA_ATTR: false,
      ALLOW_ARIA_ATTR: false
    });
    return clean;
  }
  return '';
});

const trackEvent: Function = inject('trackEvent');

onMounted(async () => {
  trackEvent('view_item', {
    currency: props.gift.currency,
    value: props.gift.amount,
    items: [
      {
        item_id: props.gift.id,
        item_name: props.gift.title,
        item_category: props.gift.type,
        item_variant: props.gift.store?.name,
        price: props.gift.amount,
        quantity: 1,
        currency: props.gift.currency,
      }
    ]
  })
});
</script>