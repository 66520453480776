<template>
  <div class="flex flex-col items-center justify-center md:items-start">
    <div class="flex flex-row items-center gap-x-2 mt-2 gap-y-2 md:gap-y-0">
      <FingerprintDataView v-if="order.request_id" :request_id="order.request_id" />
      <OrderHistory :order="order" />
      <AddNote :order="order" />
      <div class="flex flex-row items-center gap-x-2 md:border-x px-2 border-gray-400 dark:border-gray-700">
        <span v-for="tag in order.tags"
              class="text-xs px-1 py-0.5 rounded leading-none cursor-pointer bg-gray-200 dark:bg-gray-700 dark:text-white">
          {{ tag }}
        </span>
        <AddTag :order_id="order.id" :available-tags="availableTags">
          <PlusCircleIcon class="h-6 w-6 text-gray-400 dark:text-gray-600 cursor-pointer"/>
        </AddTag>
      </div>
      <div class="flex flex-row gap-x-2">
        <div class="flex flex-row items-center gap-x-2">
        <span v-for="flag in order.flags" :class="flagClass(flag.type)"
              class="text-xs px-1 py-0.5 rounded leading-none cursor-pointer"
              @click="showFlagDetails(flag)"
        >
            {{ flag.name }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="flagsVisible.length" class="flex flex-col gap-y-4 py-4 pb-5">
      <div v-for="flag in flagsVisible" :class="flag.type === 'red' ? 'border-red-500' : 'border-yellow-500'"
           class="border-l pl-4">
        <div class="flex flex-row items-center gap-x-2 pb-0.5">
          <h3 class="text-lg font-semibold leading-6 text-gray-900 dark:text-white pr-4">{{ flag.name }}</h3>
          <button
            class="rounded-md border border-gray-200 dark:border-gray-700 bg-black dark:bg-white dark:text-black text-white text-xs px-2"
            type="button"
            @click="removeFlagDetails(flag)">
            Hide Details
          </button>
        </div>
        <div class="flex flex-col items-center gap-x-2 text-wrap">
          <p v-if="flag.data" class="text-sm text-gray-500 dark:text-white">{{ JSON.stringify(flag.data) }}</p>
          <p v-if="flag.description?.length" class="text-sm text-gray-500 dark:text-white">{{ flag.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {Ref, ref} from "vue";
import {PlusCircleIcon} from "@heroicons/vue/20/solid";
import AddTag from "@/web/youpay-me-v3/Components/Umpire/AddTag.vue";
import AddNote from "@/web/youpay-me-v3/Components/Umpire/AddNote.vue";
import OrderHistory from "@/web/youpay-me-v3/Components/Umpire/OrderHistory.vue";
import FingerprintDataView from "@/web/youpay-me-v3/Components/Umpire/FingerprintDataView.vue";

const props = defineProps<{
  order: any;
  availableTags: string[];
}>();

interface Flag {
  name: string;
  slug: string;
  type: string;
  description: string;
  data: Record<string, any>;
}

const flagsVisible: Ref<Flag[]> = ref([]);

const showFlagDetails = (flag) => {
  if (flagsVisible.value.includes(flag)) {
    removeFlagDetails(flag);
    return;
  }
  flagsVisible.value.push(flag);
}

// red, yellow, green
const flagClass = (type) => {
  if (type === 'green') {
    return 'bg-green-500 text-white';
  }
  return type === 'red' ? 'bg-red-500 text-white' : 'bg-yellow-500 text-black';
}

const removeFlagDetails = (flag) => {
  flagsVisible.value.splice(flagsVisible.value.indexOf(flag), 1);
}
</script>