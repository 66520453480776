<template>
  <!-- Trophy: Pentagon, border, blue, icon in the middle -->
  <div class="flex flex-col justify-center items-center" :class="(trophy.isUnlocked ? '' : 'opacity-30 hover:opacity-100')">
    <div class="w-32 h-32">
      <div class="w-full h-full flex text-blue-300 items-center justify-center relative">
        <img :src="lightTrophyIcon" alt="Trophy" class="w-full h-full hidden dark:block" />
        <img :src="darkTrophyIcon" alt="Trophy" class="w-full h-full block dark:hidden" />
        <div class="absolute w-full h-full flex items-center justify-center">
          <Component v-if="isIcon" :is="getIcon" class="dark:text-black/65 text-white/70 w-[50%] h-[50%]" />
          <img v-else :src="icon" alt="Trophy" class="w-[80%] h-[80%]" />
        </div>
      </div>
    </div>
    <div class="text-center text-blue-300 text-sm font-semibold mt-2 px-2">{{ trophy.name }}</div>
  </div>
</template>

<script setup lang="ts">
import { UserCircleIcon, GiftIcon, GiftTopIcon, CakeIcon, BanknotesIcon, UserPlusIcon, BugAntIcon, CurrencyDollarIcon, DevicePhoneMobileIcon, ShoppingCartIcon, TagIcon, ShareIcon, StarIcon, TruckIcon, WalletIcon } from "@heroicons/vue/20/solid";
import {computed} from "vue";
import Vapor from "@/web/youpay-me-v3/helpers/vapor";

interface Trophy {
  icon: string;
  name: string;
  description: string;
  isUnlocked: boolean;
}

const props = defineProps<{
  trophy: Trophy
}>();

const lightTrophyIcon = computed(() => {
  const level = '04';
  return Vapor.asset('images/trophies/level-' + level + '-dark.png');
});

const darkTrophyIcon = computed(() => {
  const level = '04';
  return Vapor.asset('images/trophies/level-' + level + '-light.png');
});

const getIcon = computed(() => {
  switch (props.trophy.icon) {
    case 'wallet':
      return WalletIcon;
    case 'truck':
      return TruckIcon;
    case 'star':
      return StarIcon;
    case 'share':
      return ShareIcon;
    case 'tag':
      return TagIcon;
    case 'cart':
      return ShoppingCartIcon;
    case 'mobile':
      return DevicePhoneMobileIcon;
    case 'dollar':
      return BanknotesIcon;
    case 'bug':
      return BugAntIcon;
    case 'profile':
      return UserCircleIcon;
    case 'cake':
      return CakeIcon;
    case 'plus':
      return UserPlusIcon;
    case 'wishlist':
      return GiftTopIcon;
    case 'gift':
      return GiftIcon;
    case 'user':
      return UserCircleIcon;
    default:
      return GiftIcon;
  }
});
const isIcon = computed(() => !props.trophy.icon.startsWith('http'));
</script>