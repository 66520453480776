<template>
  <Head title="Update Profile" />
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
        <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Private Information</h2>
        <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">This information is kept private and is not shared with anyone.</p>
    </div>

    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div class="col-span-full sm:col-span-3">
            <InputLabel for="first_name" value="First Name" />

            <TextInput
              id="first_name"
              type="text"
              class="mt-1 block w-full"
              v-model="form.first_name"
              @change="form.validate('first_name')"
              :error="form.errors.first_name"
              required
              autocomplete="first_name"
            />
            <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">This can be your preferred name</p>

            <InputError class="mt-2" :message="form.errors.first_name"/>
          </div>

          <div class="col-span-full sm:col-span-3">
            <InputLabel for="last_name" value="Last Name" />

            <TextInput
              id="last_name"
              type="text"
              class="mt-1 block w-full"
              v-model="form.last_name"
              @change="form.validate('last_name')"
              :error="form.errors.last_name"
              required
              autocomplete="last_name"
            />

            <InputError class="mt-2" :message="form.errors.last_name"/>
          </div>

          <div class="col-span-full">
            <InputLabel for="email" value="Email address" />

            <TextInput
              id="email"
              type="email"
              class="mt-1 block w-full"
              v-model="form.email"
              @change="form.validate('email')"
              :error="form.errors.email"
              required
              autocomplete="email"
            />
            <InputError class="mt-2" :message="form.errors.email"/>

            <div v-if="user.email_pending" class="mt-3 py-1 flex justify-between gap-x-4">
              <span class="text-xs dark:text-gray-200">
                <span class="text-red-900 dark:text-red-400">Pending email verification:</span> {{ user.email_pending }}
              </span>
              <div class="flex divide-x divide-gray-300 dark:divide-gray-700">
                <Link :href="route('email-verification.clear')" class="px-2 text-xs text-youpay-navy dark:text-blue-500">Clear</Link>
                <Link :href="route('email-verification.resend')" class="px-2 text-xs text-youpay-navy dark:text-blue-500">Resend</Link>
              </div>
            </div>

          </div>

          <div class="col-span-full">
            <InputLabel for="dob" value="Birth date" />

            <TextInput
              id="dob"
              type="date"
              :max="new Date(new Date().setFullYear(new Date().getFullYear() - 10)).toISOString().split('T')[0]"
              class="mt-1 block w-full"
              v-model="form.dob"
              @change="form.validate('dob')"
              :error="form.errors.dob"
              required
              autocomplete="dob"
            />

            <InputError class="mt-2" :message="form.errors.dob"/>
          </div>

          <div class="col-span-full">
            <InputLabel for="email" value="Phone Number" class="pb-1" />

            <MazPhoneNumberInput
              v-model="form.phone"
              @change="form.validate('phone')"
              class="overflow-visible"
              show-code-on-list
              label=""
              color="info"
              :preferred-countries="['AU', 'FR', 'BE', 'DE', 'US', 'GB']"
              :ignored-countries="['AC']"
              @update="results = $event"
              :error="form.invalid('phone')"
            />

            <InputError class="mt-2" :message="form.errors.phone"/>
          </div>
        </div>

        <div class="mt-8">
          <PrimaryButton @click.prevent="submit" :disabled="form.processing" :class="form.processing ? 'opacity-20' : ''">Save Details</PrimaryButton>
        </div>
    </form>
  </div>
  <div class="">
    <DeleteUserForm />
  </div>
</template>

<script setup lang="ts">
import DeleteUserForm from './Partials/DeleteUserForm.vue';
import {Head, usePage, Link} from '@inertiajs/vue3';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import {ref} from "vue";
import {useForm} from "laravel-precognition-vue-inertia";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import {User} from "@/web/youpay-me-v3/types";
const submitted = ref(false)
const results = ref()
const user = <User>usePage().props.auth;

const form = useForm('post', route('settings.private.update'), {
  first_name: user.first_name ?? '',
  last_name: user.last_name ?? '',
  email: user.email ?? '',
  dob: user.dob ?? '',
  phone: user.phone ?? '',
});

const submit = (e) => {
  e?.preventDefault();

  if (form.validating) {
    form.cancel();
  }
  submitted.value = true;

  form.submit({
    preserveScroll: false,
  });
};
defineOptions({ layout: SettingsLayout })
</script>