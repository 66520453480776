<template>
  <Head title="Reset Password" />

  <div class="bg-white dark:bg-white/5 mx-auto rounded-xl md:max-w-2xl w-full px-8 py-12 md:mt-12 h-full flex-grow md:flex-grow-0">
    <h1 class="text-2xl md:text-3xl font-bold text-gray-800 pb-4 dark:text-white">Update Your Password</h1>
    <p class="text-sm md:text-base leading-6 text-gray-600 dark:text-gray-300 pb-4">
      This page will expire in 15 minutes. If you need to reset your password again, please request a new link.
    </p>
    <form class="dark:text-black">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full">
          <InputLabel for="new-password" value="New password" />
          <TextInput
            id="new-password"
            type="password"
            class="mt-2 block w-full"
            v-model="form.password"
            :error="form.errors.password"
            required
          />
          <InputError class="mt-2" :message="form.errors.password"/>
        </div>

        <div class="col-span-full">
          <InputLabel for="confirm-password" value="Confirm password" />
          <TextInput
            id="confirm-password"
            type="password"
            class="mt-2 block w-full"
            v-model="form.password_confirmation"
            :error="form.errors.password_confirmation"
            required
          />
          <InputError class="mt-2" :message="form.errors.password_confirmation"/>
        </div>
      </div>

      <div class="mt-5 flex">
        <PrimaryButton @click.prevent="submit">
          Update Password
        </PrimaryButton>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";

const props = defineProps<{
  url: string;
}>();

import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
const form = useForm('post', props.url, {
  password: '',
  password_confirmation: '',
});

const submit = () => {
  form.submit();
};

// defineOptions({ layout: SettingsLayout })
</script>
