<template>
  <Head title="Verify Users"/>

  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        Verify Users
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Find and verify users who have sent you gifts
      </p>
    </div>
    <form method="post" @submit.prevent="submitForm" class="md:col-span-2 flex flex-col gap-y-4 justify-center max-w-md">
      <InputLabel value="Search for a user:" />
      <TextInput v-model="form.search" placeholder="Search for a user" @keyup.enter.prevent="submitForm" />
      <PrimaryButton @click.prevent="submitForm" class="font-light">
        Search
      </PrimaryButton>
    </form>
  </div>

  <div v-if="users?.length" class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 sm:px-6 md:grid-cols-3 lg:px-8 lg:py-12">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        Users
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Here are the users that match your search
      </p>
    </div>
    <div class="md:col-span-2 flex flex-col gap-y-4 justify-center max-w-md">
      <ul role="list" class="divide-y divide-gray-100 bg-white px-4 rounded-lg">
        <li v-for="person in users" :key="person.username" class="flex justify-between gap-x-6 py-5">
          <div class="flex min-w-0 gap-x-4">
            <img v-if="person.avatar" class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="person.avatar" alt="" />
            <UserCircleIcon v-else class="h-12 w-12 flex-none rounded-full bg-gray-50" />
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-gray-900">
                <a :href="person.url" class="hover:underline">{{ person.display_name }}</a>
              </p>
              <p class="mt-1 flex text-xs leading-5 text-gray-500">
                {{ person.username }}
              </p>
            </div>
          </div>
          <div class="flex shrink-0 items-center gap-x-6">
            <div class="flex flex-col items-end">
              <p class="text-sm leading-6 text-gray-900">{{ person.pronouns }}</p>
              <div v-if="person.verified" class="mt-1 flex items-center gap-x-1.5">
                <div class="flex-none rounded-full bg-emerald-500/20 p-1">
                  <div class="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                </div>
                <p class="text-xs leading-5 text-gray-500">Verified</p>
              </div>
              <div v-else class="mt-1 flex items-center gap-x-1.5">
                <div class="flex-none rounded-full bg-red-500/20 p-1">
                  <div class="h-1.5 w-1.5 rounded-full bg-red-500" />
                </div>
                <p class="text-xs leading-5 text-gray-500">Not Verified</p>
              </div>
            </div>
            <Menu as="div" class="relative flex-none">
              <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span class="sr-only">Open options</span>
                <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
              </MenuButton>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                    <a :href="person.url" target="_blank" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer']">View profile</a >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a v-if="!person.verified" @click="verifyUser(person)" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer']">
                      Verify User
                    </a>
                    <a v-else @click="unverifyUser(person)" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer']">
                      Unverify User
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import {User} from "@/web/youpay-me-v3/types";
import {Head, usePage} from "@inertiajs/vue3";
import PrimaryLink from "@/web/youpay-me-v3/Components/Buttons/PrimaryLink.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid'
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import {UserCircleIcon} from "@heroicons/vue/24/outline";

const user = <User>usePage().props.auth;

const props = defineProps<{
  users?: any;
  search?: string;
}>();

const form = useForm('get', route('settings.restricted.verify-users'), {
  search: props.search ?? '',
})

const submitForm = (e) => {
  e.preventDefault();
  form.submit();
}

const verifyForm = useForm('post', route('settings.restricted.verify'), {
  type: '',
  username: '',
})

const verifyUser = (user) => {
  verifyForm.type = 'verify';
  verifyForm.username = user.username;
  verifyForm.submit({
    onSuccess: () => {
      user.verified = true;
    }
  });
}

const unverifyUser = (user) => {
  verifyForm.type = 'unverify';
  verifyForm.username = user.username;
  verifyForm.submit({
    onSuccess: () => {
      user.verified = false;
    }
  });
}

defineOptions({layout: SettingsLayout})

</script>