<template>
  <Head title="Checkout" />
  <form class="mt-6">
    <div class="pb-4">
      <h1 class="text-3xl dark:text-white">
        Pay for {{ profile.username }}
      </h1>

      <PayerMessage
        v-if="can_message"
        class="pt-4"
        :bag_id="bag.id"
        :message="message"
        :username="profile.username"
      />
    </div>

    <CheckoutLogin :bag_id="bag.id" />

    <h2 id="billing-info-heading" class="pb-4 text-lg font-medium text-gray-900 dark:text-gray-50">Billing information</h2>

    <div class="mb-4">
      <InputLabel for="email" value="Email Address"/>
      <TextInput
        id="email"
        type="email"
        class="mt-1 block w-full"
        v-model="form.email"
        @change="form.validate('email')"
        :error="form.errors.email"
        required
        autocomplete="email"
      />

      <InputError class="mt-2" :message="form.errors.email"/>
    </div>

    <div id="address-element">
      <!-- Stripe Elements Placeholder -->
    </div>
    <div id="payment-element" class="pt-3">
      <!-- Stripe Elements Placeholder -->
    </div>


    <div class="mt-6">
      <label class="flex items-center">
        <Checkbox name="terms" v-model:checked="form.terms" @update:checked="form.validate('terms')" />
          <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
            I agree to the <a :href="route('terms')" target="_blank" class="underline">Terms, Conditions & Privacy Policies</a>
          </span>
      </label>
      <InputError class="mt-2" :message="form.errors.terms"/>
    </div>

    <div v-if="submitted && form.hasErrors" class="mt-6 text-sm text-red-600 dark:text-red-400">
      Looks like there are some errors in your form. Please review and try again.
    </div>

    <button
      type="submit"
      @click.prevent="submit"
      class="mt-6 w-full rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-youpay-navy-light hover:bg-youpay-navy-alt focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      <span v-if="!form.processing">
        Pay {{ bag.total }}
      </span>
      <span v-else>
        Processing...
      </span>
    </button>

    <InputError class="mt-4 text-center" :message="form.errors.stripe"/>
  </form>
</template>

<script setup lang="ts">
import { Head, Link } from "@inertiajs/vue3";
import ProfileLayout from "@/web/youpay-me-v3/Layouts/ProfileLayout.vue";
import {onMounted, ref, inject} from 'vue'
import {StripeAddress, Bag, AutoFill, compileForAnalytics, StripeConfig} from "@/web/youpay-me-v3/types/checkout.d";
import {Profile, User} from "@/web/youpay-me-v3/types";
import { useForm } from 'laravel-precognition-vue-inertia';
import {
  getStripeElements,
  setupStripeElements,
  getStripeContainer,
  setupAddressElement,
  setupPaymentElement,
  getStripeAddress,
  addAddressWatcher
} from "@/web/youpay-me-v3/stripe";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import {ChevronDownIcon} from "@heroicons/vue/24/outline";
import CartItems from "@/web/youpay-me-v3/Components/Checkout/CartItems.vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import CheckoutLayout from "@/web/youpay-me-v3/Layouts/CheckoutLayout.vue";
import PayerMessage from "@/web/youpay-me-v3/Components/Checkout/PayerMessage.vue";
import CheckoutLogin from "@/web/youpay-me-v3/Components/Checkout/CheckoutLogin.vue";

defineOptions({ layout: CheckoutLayout })

// Get Props
const props = defineProps<{
  auth?: User,
  profile: Profile,
  bag: Bag,
  message: string,
  addresses?: Array<StripeAddress>,
  autofill?: AutoFill,
  stripeConfig: StripeConfig,
  can_message: boolean,
}>();
const submitted = ref(false);

// Mobile mode state
const show_summary = ref(false);

// Setup the form
const form = useForm('post', route('checkout.process', {
  account: props.profile.username,
  bag: props.bag.id,
}), {
  email: props.autofill?.email ?? '',
  message: props.autofill?.message ?? '',
  gateway: 'stripe',
  terms: false,
});

/**
  * Submit the Payment
  * TODO: Handle errors
  */
const submit = async function(e) {
  e.preventDefault();
  // Validate the form
  if (!form.terms) {
    form.setError('terms', "You must agree to the terms and conditions");
    return;
  }
  form.clearErrors('stripe');
  await form.touch(['email', 'terms']).validate();
  if (form.hasErrors) {
    submitted.value = true;
    return;
  }
  form.processing = true;

  getStripeContainer().confirmPayment({
    elements: getStripeElements(),
    redirect: "if_required", // todo: confirm we never redirect
    confirmParams: {
      payment_method_data: {
        billing_details: {
          email: email.value,
        },
      }
    },
  }).then(async result => {
    console.log(result);
    if (result.error) {
      console.log("Payment failed");
      console.log(result.error);
      form.processing = false;
      form.setError('stripe', result.error.message);

      // Inform the customer that there was an error.
    } else if (result.paymentIntent && result.paymentIntent.status === "requires_capture") {

      // Track the event
      try {
        const gaData = compileForAnalytics(props.profile.username, props.bag);
        gaData.transaction_id = result.paymentIntent.id;
        trackEvent('purchase', gaData);
      } catch (e) {} // Silently fail

      // Add extra data and submit the form
      const address = await getStripeAddress();
      form.transform(
        (data: Object) => ({
          ...data,
          details: address,
          payment_intent: result.paymentIntent.id,
        })
      ).submit({
        preserveScroll: true,
      });

    } else {
      form.processing = false;
      console.log("Payment failed");
      // Something went wrong message? or do we already have enough errors?
    }
  });
}

const watchAddress = function(details) {
  if (!details.complete) {
    return;
  }
  const gaData = compileForAnalytics(props.profile.username, props.bag);
  gaData.payment_type = 'stripe';
  trackEvent('add_payment_info', gaData);
}

/**
 * Load Stripe
 */
onMounted(async () => {
  trackEvent('begin_checkout', compileForAnalytics(props.profile.username, props.bag));

  await setupStripeElements(props.stripeConfig);
  setupAddressElement(props.autofill ? {
    firstName: props.autofill.firstName,
    lastName: props.autofill.lastName,
    phone: props.autofill.phone,
  } : null, props.addresses);
  setupPaymentElement();

  addAddressWatcher(watchAddress);
});
</script>