<template>
  <Head title="Gifting Settings" />
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">

    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Twitter Autotweet</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Authorise YouPay to tweet on your behalf</p>
    </div>
    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full">
          <a v-if="!twitter_auth" :href="route('twitter.authorize')" class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center">
            Authorise Twitter
          </a>
          <span v-else class="text-sm text-gray-600 dark:text-gray-400">
            You have already authorised YouPay to tweet on your behalf. <br />
            Click here to <a :href="route('twitter.revoke')" class="text-youpay-pink">revoke our access</a>.
          </span>
        </div>
        <div class="col-span-full ">
          <label for="x_link" class="flex items-center">
            <Checkbox id="x_link" name="x_link" v-model:checked="form.x_link" @update:checked="form.validate('x_link')" />
            <span  class="ml-2 text-sm text-gray-600 dark:text-gray-400">
              Include a link to your wishlist in the tweet.
            </span>
          </label>
        </div>
      </div>

      <div class="mt-8 flex flex-row justify-end hidden">
        <PrimaryButton @click.prevent="submit" type="submit">
          Update Gifting Settings
        </PrimaryButton>
      </div>
    </form>

    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Gifting Settings</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Change your gift settings.</p>
    </div>

    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full">
          <label for="forever" class="flex items-center">
            <Checkbox id="forever" name="forever" v-model:checked="form.forever" @update:checked="form.validate('forever')" />
            <span  class="ml-2 text-sm text-gray-600 dark:text-gray-400">
              Keep the gift in your wishlist even after someone purchases it for you.
            </span>
          </label>

          <span class=" text-youpay-pink text-xs pt-2 block font-semibold">
            Excludes Partner Stores where the gift is shipped directly to you
          </span>
        </div>
      </div>

      <div class="mt-8 flex flex-row justify-end">
        <PrimaryButton @click.prevent="submit" type="submit">
          Update Gifting Settings
        </PrimaryButton>
      </div>
    </form>
  </div>
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Privacy Settings</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Change your privacy settings.</p>
    </div>

    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">

        <div class="col-span-full">
          <label for="searchable" class="flex items-center">
            <Checkbox id="searchable" name="searchable" v-model:checked="form.searchable"
                      @update:checked="form.validate('searchable')"/>
            <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                Show my profile in the search and on search engines.
              </span>
          </label>
        </div>

        <div class="col-span-full">
          <label for="can_message" class="flex items-center">
            <Checkbox id="can_message" name="can_message" v-model:checked="form.can_message"
                      @update:checked="form.validate('can_message')"/>
            <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                Allow people to send me messages when buying me gifts.
              </span>
          </label>
        </div>

        <div class="col-span-full">
          <label for="all_gifts" class="flex items-center">
            <Checkbox id="all_gifts" name="all_gifts" v-model:checked="form.all_gifts"
                      @update:checked="form.validate('all_gifts')"/>
            <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                Show all of my gifts on the primary wishlist.
              </span>
          </label>
        </div>

        <div class="col-span-full">
          <label for="anon" class="flex items-center">
            <Checkbox id="anon" name="anon" v-model:checked="form.anon" @update:checked="form.validate('anon')"/>
            <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                Private profile, only show my profile to people who follow me.
              </span>
          </label>
        </div>
      </div>

      <div class="mt-8 flex flex-row justify-end">
        <PrimaryButton @click.prevent="submit" type="submit">
          Update Privacy Settings
        </PrimaryButton>
      </div>
    </form>
  </div>

</template>

<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import {ref} from "vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";

const props = defineProps<{
  twitter_auth: boolean;
  forever?: boolean;
  searchable?: boolean;
  anon?: boolean;
  x_link: boolean;
  require_approval?: boolean;
  can_message?: boolean;
  all_gifts?: boolean;
  allow_following_messages?: boolean;
}>();

const vvalue = ref(false)

const form = useForm('post', route('settings.privacy.update'), {
  forever: props.forever ?? true,
  searchable: props.searchable ?? true,
  anon: props.anon ?? false,
  require_approval: props.require_approval ?? false,
  allow_following_messages: props.allow_following_messages ?? true,
  can_message: props.can_message ?? true,
  all_gifts: props.all_gifts ?? true,
  x_link: props.x_link ?? true,
});

const submit = () => {
  form.submit({
    preserveScroll: true
  });
};

defineOptions({ layout: SettingsLayout })
</script>