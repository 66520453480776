<template>
  <Head title="Dashboard" />

  <div class="w-full h-full flex-grow bg-right relative"
       :style="backgroundStyle">
    <div v-if="season === 'halloween'" class="absolute z-0 inset-0 bg-gradient-to-b from-youpay-teal-ultralight/70 via-youpay-teal-ultralight/10 to-youpay-teal-ultralight/5 dark:from-black/70 dark:via-black/90 dark:to-black/80 transition-opacity" />
    <div class="inset-0 z-10 relative overflow-y-auto py-8 px-3 sm:px-6 lg:px-8 lg:py-10 3xl:px-20 xl:py-16">

      <h1 class="text-xl sm:text-2xl md:text-3xl font-bold text-gray-900 dark:text-gray-100 mb-8 px-2">
        Welcome back {{ user.display_name }}
      </h1>

      <div class="mx-auto grid md:grid-cols-9 xl:grid-cols-12 gap-4 lg:gap-6">
        <Card :cols="3" :rows="1" class="flex gap-y-4 lg:gap-6" :bg="false">
          <div class="overflow-hidden rounded-lg bg-white dark:bg-white/10 px-4 py-5 shadow sm:p-6">
            <div class="text-sm md:text-base flex justify-between items-center">
              <span class="text-gray-800 dark:text-gray-100 font-medium">Total received</span>
              <span class="text-xs">this month</span>
            </div>
            <div class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white">{{ total_month_revenue }}</div>
          </div>
          <div class="overflow-hidden rounded-lg bg-white dark:bg-white/10 px-4 py-5 shadow sm:p-6">
            <div class="text-sm md:text-base flex justify-between items-center">
              <span class="text-gray-800 dark:text-gray-100 font-medium">Total received</span>
              <span class="text-xs">all time</span>
            </div>
            <div class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white">{{ total_revenue }}</div>
          </div>

<!--          <Card :cols="3" :rows="1" class="flex gap-y-4">-->
<!--            <CharityWidget :data="charity" />-->
<!--          </Card>-->
        </Card>


        <Card :cols="3" :rows="1" class="order-6 lg:order-none">
          <CardHeader title="Latest Updates"></CardHeader>
          <ul role="list" class="divide-y divide-gray-200 dark:divide-white/5">
            <li v-for="(article, key)  in updates" :key="key" class="relative flex items-center space-x-4 py-4" v-show="article.show ?? true">
              <div class="min-w-0 flex-auto">
                <div class="flex items-center gap-x-3">
                  <h2 class="min-w-0 text-sm font-semibold leading-6 dark:text-white">
                    <a :href="article.href" :target="article.target ?? '_self'" class="flex gap-x-2">
                      <span class="truncate line-clamp-1">{{ article.title }}</span>
                      <span class="absolute inset-0" />
                    </a>
                  </h2>
                </div>
                <div class="mt-2 flex items-center gap-x-2.5 text-xs leading-5 text-gray-800 dark:text-gray-400">
                  <p class="truncate">{{ article.description }}</p>
<!--                  <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 flex-none fill-gray-300">-->
<!--                    <circle cx="1" cy="1" r="1" />-->
<!--                  </svg>-->
<!--                  <p class="whitespace-nowrap">{{ article.Text }}</p>-->
                </div>
              </div>
              <div :class="[categorys[article.category], 'rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset hidden 2xl:block']">{{ article.category }}</div>
              <ChevronRightIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </li>
          </ul>
        </Card>

        <Card :cols="3" :rows="1" class="order-5 lg:order-none">
          <CardHeader title="Your recent gifts"></CardHeader>
          <ul v-if="presents.length"
              role="list" class="divide-y pb-6 divide-gray-200 dark:divide-white/5">
            <li @click="router.visit(route('settings.orders.show', order_item.order_id))" v-for="order_item in presents" :key="order_item.email" class="relative flex justify-between gap-x-6 py-3 hover:bg-gray-50 dark:hover:bg-white/5 cursor-pointer">
              <div class="flex items-center min-w-0 gap-x-4">
                <img class="h-9 w-9 flex-none rounded-full bg-gray-50" :src="order_item.image" alt="" />
                <p class="text-sm leading-6 text-gray-900 dark:text-white line-clamp-1">
                  {{ order_item.title }}
                </p>
              </div>
              <div class="flex shrink-0 items-center gap-x-4">
                <div class="hidden sm:flex sm:flex-col sm:items-end">
                  <p class="text-sm leading-6 text-gray-900 dark:text-white">{{ order_item.total }}</p>
                </div>
                <ChevronRightIcon class="h-5 w-5 flex-none text-gray-400 dark:text-gray-100" aria-hidden="true" />
              </div>
            </li>
          </ul>
          <span v-else class="leading-10">
            You have not received any gifts yet. <br>
            Click here to get started and <Link :href="route('stores')" class="text-youpay-navy-light dark:text-youpay-pink">create a gift</Link>.
          </span>
          <div v-if="presents.length" class="flex justify-end">
            <Link :href="route('settings.presents')" class="text-sm text-youpay-navy dark:text-youpay-pink font-semibold">see more</Link>
          </div>
        </Card>

        <!--Quick actions card: view stripe connect dashboard, view coinflow dashboard, create gift, create wishlist-->
        <Card :cols="3" :rows="1" class="flex gap-y-3 ">
          <CardHeader title="Quick Actions" />
          <div class="flex flex-col gap-y-4">
            <Link :href="route('settings.coinflow.index')" class="flex items-center justify-between p-4 bg-white dark:bg-white/10 rounded-lg dark:shadow-sm transition-all">
              <div class="flex items-center gap-x-4 w-full">
                <IconCoinflow class="w-8 h-8 dark:fill-white" />
                <div class="flex-grow">
                  <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                    Coinflow
                  </h3>
                  <p class="hidden 2xl:block text-sm text-gray-800 dark:text-gray-400">View your Coinflow details</p>
                </div>
                <ChevronRightIcon class="h-6 w-6 flex-none text-gray-400 dark:text-gray-100" aria-hidden="true" />
              </div>
            </Link>
            <Link v-if="user?.can?.createConnect" :href="route('connect.index')" class="flex items-center justify-between p-4 bg-white dark:bg-white/10 rounded-lg dark:shadow-sm transition-all">
              <div class="flex items-center gap-x-4 w-full">
                <IconStripe class="w-8 h-8 dark:fill-white" />
                <div class="flex-grow">
                  <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                    Stripe
                  </h3>
                  <p class="hidden 2xl:block text-sm text-gray-800 dark:text-gray-400">View your Stripe details</p>
                </div>
                <ChevronRightIcon class="h-6 w-6 flex-none text-gray-400 dark:text-gray-100" aria-hidden="true" />
              </div>
            </Link>
            <Link :href="route('stores')" class="flex items-center justify-between p-4 bg-white dark:bg-white/10 rounded-lg dark:shadow-sm transition-all">
              <div class="flex items-center gap-x-4 w-full">
                <GiftIcon alt="Gift" class="w-8 h-8" />
                <div class="flex-grow">
                  <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Create a Gift</h3>
                  <p class="hidden 2xl:block text-sm text-gray-800 dark:text-gray-400">Add gifts to your wishlist</p>
                </div>
                <PlusIcon class="h-6 w-6 flex-none text-gray-400 dark:text-gray-100" aria-hidden="true" />
              </div>
            </Link>
            <Link :href="route('wishlist.create')" class="flex items-center justify-between p-4 bg-white dark:bg-white/10 rounded-lg dark:shadow-sm transition-all">
              <div class="flex items-center gap-x-4 w-full">
                <GiftTopIcon alt="Wishlist" class="w-8 h-8" />
                <div class="flex-grow">
                  <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Create a Wishlist</h3>
                  <p class="hidden 2xl:block text-sm text-gray-800 dark:text-gray-400">Make a wishlist for your gifts</p>
                </div>
                <PlusIcon class="h-6 w-6 flex-none text-gray-400 dark:text-gray-100" aria-hidden="true" />
              </div>
            </Link>
          </div>
        </Card>
<!--        <Card :cols="3" :rows="1" class="flex gap-y-4 2xl:hidden">-->
<!--          <CardHeader title="Charity Donation" />-->
<!--          <CharityWidget :data="charity" />-->
<!--        </Card>-->

      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CardHeader from "@/web/youpay-me-v3/Components/Cards/CardHeader.vue";
import Card from "@/web/youpay-me-v3/Components/Cards/Card.vue";
import {User} from "@/web/youpay-me-v3/types";
import {usePage, Link, router, Head} from "@inertiajs/vue3";
import { ChevronRightIcon, ArrowTopRightOnSquareIcon, GiftIcon, PlusIcon, GiftTopIcon } from '@heroicons/vue/20/solid'
import IconCoinflow from "@/web/youpay-me-v3/Icons/IconCoinflow.vue";
import IconStripe from "@/web/youpay-me-v3/Icons/IconStripe.vue";
import {computed} from "vue";
const season = usePage().props.season;

const backgroundStyle = computed(() => {
  if (season === 'halloween') {
    return {
      backgroundImage: 'url("https://images.unsplash.com/photo-1475762638009-d74671ecd29b?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  }
});

const flags = usePage().props.featureFlags;
const categorys = {
  News: 'text-gray-800 dark:text-gray-400 bg-gray-100 dark:bg-gray-400/10 ring-gray-400/20',
  Feature: 'text-indigo-500 dark:text-indigo-400 bg-indigo-400/20 dark:bg-indigo-400/10 ring-indigo-400/30',
  Exclusive: 'text-youpay-pink-dark dark:text-youpay-pink bg-youpay-pink/20 dark:bg-youpay-pink/20 ring-youpay-pink/30',
  Beta: 'text-green-500 dark:text-green-400 bg-green-400/20 dark:bg-green-400/10 ring-green-400/30',
}
const updates = [
  // {
  //   href: route('customer.settings.privacy'),
  //   title: 'Coinflow - Cash Gifts',
  //   description: 'Receive cash gifts anywhere in the world',
  //   category: 'Beta',
  //   target: '_self',
  // },
  {
    title: 'Crowdgifting now live!',
    description: 'For those big milestones and gifts',
    href: 'https://support.youpay.co/support/solutions/articles/51000418610-how-to-enable-the-crowdgift-feature',
    target: '_blank',
    category: 'Feature',
  },
  {
    title: 'New YouPay Me Now button',
    description: 'Enable to receive surprise gifts',
    href: 'https://youpay.freshdesk.com/support/solutions/articles/51000418446-what-is-the-youpay-me-now-button-',
    target: '_blank',
    category: 'Feature',
  },
  {
    href: route('settings.coinflow.index'),
    title: 'Coinflow cash gifting live',
    // title: 'Coinflow has launched!',
    category: 'Feature',
    description: 'Setup for instant payouts',
    target: '_self',
    show: true
  },
  // {
  //   href: route('settings.privacy'),
  //   title: 'Autotweet your gifts',
  //   description: 'Share your latest gifts with fans!',
  //   category: 'Feature',
  //   target: '_self',
  // },
  // {
  //   href: route('partners'),
  //   title: 'YouPay Partners',
  //   description: 'Exclusive perks for YouPay users',
  //   category: 'Exclusive',
  //   target: '_self',
  // },
  {
    href: 'https://youpay.co/wishlist/a-wishlist-makeover-guide/',
    title: 'A Wishlist Makeover Guide',
    description: 'Tips to attract more gifters',
    category: 'News',
    target: '_blank',
  },
  // {
  //   id: 4,
  //   href: '#',
  //   title: 'Protocol',
  //   description: 'Deploys from GitHub',
  //   category: 'News',
  // },
]
const user = <User>usePage().props.auth;

const props = defineProps<{
  total_month_revenue: string,
  total_revenue: string,
  presents: any[],
  // charity: {
  //   personal: number;
  //   total: number;
  // }
}>();
</script>
