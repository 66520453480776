<script setup lang="ts">
import { computed } from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps<{
    checked: boolean;
    value?: any;
    disabled?: boolean;
}>();

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});
</script>

<template>
    <input
        type="checkbox"
        :value="value"
        v-model="proxyChecked"
        :disabled="disabled"
        class="rounded border-gray-300 dark:border-gray-500 text-youpay-navy shadow-sm focus:ring-youpay-teal dark:focus:ring-youpay-teal dark:focus:ring-offset-youpay-teal-active"
        :class="disabled ? 'cursor-not-allowed bg-gray-100 dark:bg-gray-400' : 'dark:bg-gray-700'"
    />
</template>
