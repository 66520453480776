<template>
  <Head title="Reset Password"/>

  <div class="w-full">
    <h1 class="text-3xl font-bold text-gray-800 dark:text-gray-200 pb-4">Reset Password</h1>

    <form @submit.prevent="submit">
      <div>
        <InputLabel for="email" value="Your Email"/>

        <TextInput
          id="email"
          type="email"
          class="mt-1 block w-full"
          v-model="form.email"
          disabled
          required
          autocomplete="username"
        />

        <InputError class="mt-2" :message="form.errors.email"/>
      </div>

      <div class="mt-4">
        <InputLabel for="password" value="New Password"/>

        <TextInput
          id="password"
          type="password"
          class="mt-1 block w-full"
          v-model="form.password"
          required
          autocomplete="new-password"
        />

        <InputError class="mt-2" :message="form.errors.password"/>
      </div>

      <div class="mt-4">
        <InputLabel for="password_confirmation" value="Confirm Password"/>

        <TextInput
          id="password_confirmation"
          type="password"
          class="mt-1 block w-full"
          v-model="form.password_confirmation"
          required
          autocomplete="new-password"
        />

        <InputError class="mt-2" :message="form.errors.password_confirmation"/>
      </div>

      <div class="flex items-center justify-end mt-4">
        <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
          Reset Password
        </PrimaryButton>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import GuestLayout from '../../Layouts/MainLayout.vue';
import InputError from '../../Components/Form/InputError.vue';
import InputLabel from '../../Components/Form/InputLabel.vue';
import PrimaryButton from '../../Components/Buttons/PrimaryButton.vue';
import TextInput from '../../Components/Form/TextInput.vue';
import {Head, useForm} from '@inertiajs/vue3';

defineOptions({layout: GuestLayout});

const props = defineProps<{
  email: string;
  token: string;
}>();

const form = useForm({
  token: props.token,
  email: props.email,
  password: '',
  password_confirmation: '',
});

const submit = () => {
  form.post(route('password.store'), {
    onFinish: () => {
      form.reset('password', 'password_confirmation');
    },
  });
};
</script>
