<template>
  <input
      class="border-gray-300 dark:border-gray-700 dark:bg-white/[0.05] dark:text-gray-300 focus:border-youpay-teal dark:focus:border-gray-700 focus:ring-youpay-teal dark:focus:ring-youpay-teal rounded-md shadow-sm"
      :class="error ? 'border-red-300 dark:border-red-700' : ''"
      v-model="current"
      @focus="focused"
      @blur="unfocused"
      @keydown="validateInput"
      @keyup.enter.prevent
      @change="unfocused"
      ref="input"
  />
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';
import { currency as MazCurrency } from 'maz-ui'

const props = defineProps<{
  modelValue: string|number|null;
  currency: string;
  error?: string;
}>();

const emit = defineEmits(['update:modelValue']);

const input = ref<HTMLInputElement | null>(null);

onMounted(() => {
  if (input.value?.hasAttribute('autofocus')) {
    input.value?.focus();
  }
});

const amount = ref(props.modelValue);
const current = ref(props.modelValue)
const formatted = (value) => {
  const number = Number(value);
  const currency = props.currency;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(number);
};


const priceFormatted = computed(() =>
  MazCurrency(amount.value, 'en-US', { currency: props.currency }),
)

const focused = () => {
  if (amount.value == 0) {
    current.value = '';
    return;
  }
  current.value = amount.value;
};

const unfocused = () => {
  let value = current.value;
  // remove any non-numeric characters except fullstop
  value = value.toString().replace(/[^\d.]/g, '');
  value = Math.round(value);
  amount.value = value;
  current.value = priceFormatted.value;
  emit('update:modelValue', value);
};

const validateInput = (e) => {
  // if event is not a number, fullstop, backspace, delete, enter, arrow keys, comma or tab, prevent default
  if (!/[\d\.]|Backspace|Delete|Enter|ArrowLeft|ArrowRight|Tab/.test(e.key)) {
    e.preventDefault();
  }
}

watch(() => props.modelValue, (value) => {
  if (value === amount.value) {
    return;
  }
  amount.value = value;
  current.value = formatted(value);
});

defineExpose({ focus: () => input.value?.focus() });

onMounted(() => {
  unfocused();  // format on mount
});
</script>