<template>
  <div v-if="bag" class="fixed bottom-20 md:bottom-8 left-0 w-full z-10">
    <div
      class="mx-4 md:mx-auto max-w-xl flex items-center gap-6 px-4 py-4 rounded-lg bg-gray-50 dark:bg-gray-800/90 dark:text-white drop-shadow-[0_5px_25px_rgba(0,0,0,0.35)] ring-1 ring-gray-500/60 dark:ring-gray-700 backdrop-blur-sm ">
      <div class="block">
        <button
          @click.prevent="open = true"
          type="button"
          class="group relative flex flex-shrink-0 items-center justify-center rounded-full focus:outline-none h-full w-14"
          aria-label="Checkout"
        >
          <div class="absolute -inset-3 md:hidden"></div>
          <shopping-cart-icon class="w-8 h-8 text-slate-900 dark:text-white"/>
        </button>
      </div>
      <div @click.prevent="open = true"
           class="mb-[env(safe-area-inset-bottom)] flex flex-1 flex-col justify-center gap-3 overflow-hidden cursor-pointer">
        <span class="text-center text-sm font-bold leading-6 md:text-left">
          {{ bag?.items.length }} {{ bag?.items.length === 1 ? 'item' : 'items' }} <span
          class="hidden sm:inline">in your gift bag for {{ profile.username }}</span>
        </span>
      </div>
      <Link
        :href="route('checkout.details', bag.id)"
        :only="['bag','can_message','has_connect','autofill','contacts','ipdetails']"
        type="submit"
        class="flex justify-center rounded-md bg-youpay-navy dark:bg-youpay-navy-light dark:hover:bg-youpay-navy px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-youpay-navy-alt focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-youpay-teal"
      >
        Checkout
      </Link>
    </div>
  </div>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/70">
          <div
            class="w-full h-full bg-gradient-to-tr transition-opacity from-youpay-yellow/[0.3] via-youpay-pink/[0.3] to-youpay-teal/[0.3]"></div>
        </div>
      </TransitionChild>

      <div v-if="bag" class="fixed inset-0 z-50 overflow-hidden text-gray-800 dark:text-white">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                             enter-from="translate-x-full" enter-to="translate-x-0"
                             leave="transform transition ease-in-out duration-500 sm:duration-700"
                             leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel
                class="pointer-events-auto w-screen max-w-md lg:max-w-lg xl:max-w-2xl 3xl:max-w-3xl 4xl:max-w-4xl 5xl:max-w-5xl">
                <div class="flex h-full flex-col bg-white shadow-xl divide-y divide-gray-200 dark:bg-gray-900">
                  <div class="flex w-full flex-col bg-gray-50 dark:bg-gray-700 h-full">
                    <div class="flex items-start justify-between pt-6 px-6">
                      <DialogTitle class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                        Your Gifts for {{ profile.username }}
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center gap-x-6">
                        <button type="button"
                                class="rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-white/[0.05] dark:text-gray-100"
                                @click="open = false">
                          <span class="sr-only">Close</span>
                          <XMarkIcon class="h-5 w-5" aria-hidden="true"/>
                        </button>
                      </div>
                    </div>
                    <CartItems :summary="true" :bag="bag" :profile="profile"/>

                    <div v-if="!bag.hasCoinflow" class="px-6 pb-4 flex flex-row items-center  gap-x-2 dark:bg-white/5">
                      <InformationCircleIcon class="h-6 w-6 text-blue-700 dark:text-blue-400 inline-block"/>
                      <span class="text-sm text-gray-500 dark:text-gray-200">
                        Currency can be changed in the checkout
                      </span>
                    </div>
                    <div class="px-6 dark:bg-white/5">
                      <PrimaryLink :only="['bag']" class="w-full rounded-none py-4 mb-5"
                                   :href="route('checkout.index', bag?.id)">
                        Checkout
                      </PrimaryLink>
                    </div>

                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {InformationCircleIcon, ShoppingCartIcon, XMarkIcon} from "@heroicons/vue/24/outline";
import {Link} from "@inertiajs/vue3";
import {Bag} from "@/web/youpay-me-v3/types/checkout";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import CartItems from "@/web/youpay-me-v3/Components/Checkout/CartItems.vue";
import {ref, watch} from 'vue';
import {Profile} from "@/web/youpay-me-v3/types";
import PrimaryLink from "@/web/youpay-me-v3/Components/Buttons/PrimaryLink.vue";

const open = ref(false);

const props = defineProps<{
  bag?: Bag,
  profile: Profile,
}>();

watch(() => props.bag, (bag, oldBag) => {
  if (!oldBag && bag) {
    open.value = true;
  }
  if (typeof oldBag?.items?.length === 'number' && typeof bag?.items?.length === 'number') {
    if (oldBag?.items?.length < bag?.items?.length) {
      open.value = true;
    }
  }
  if (oldBag && !bag) {
    open.value = false;
  }
  if (!bag) {
    open.value = false;
  }
})
</script>