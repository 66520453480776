<template>
  <Head title="Checkout"/>

  <div class="">
    <div class="pb-4">
      <h1 class="text-3xl dark:text-white">
        Pay for {{ profile.username }}
      </h1>
    </div>

    <CheckoutLogin :bag_id="bag.id" />

    <h2 id="contact-info-heading" class="pt-2 text-lg font-medium text-gray-900 dark:text-gray-50">
      Billing information
    </h2>
    <p class="mb-4 text-xs leading-6 text-gray-400">These details are kept private.</p>
    <div class="grid gap-y-4 gap-x-4 grid-cols-6">
      <div class="col-span-full">
        <InputLabel for="email" value="Email Address"/>
        <TextInput
          id="email"
          v-model="form.email"
          :error="form.errors.email"
          autocomplete="email"
          class="mt-1 block w-full"
          required
          type="email"
          @change="form.validate('email')"
        />
        <InputError :message="form.errors.email" class="mt-2"/>
      </div>

      <div class="col-span-full">
        <ContactsForm :form="form" :contacts="contacts" :autofill="autofill" :ipdetails="ipdetails" />
      </div>

      <div v-if="profile.can_message" class="col-span-full">
        <PayerMessage
          class=""
          :payer="payer"
          :bag_id="bag.id"
          :message="bag.message"
          :username="profile.username"
        />
      </div>

      <FingerprintData v-if="!hasFinger" :bag_id="bag.id" />

      <div class="col-span-full">
        <div class="mt-4">
          <label class="flex items-center">
            <Checkbox v-model:checked="form.terms" name="terms" @update:checked="form.validate('terms')"/>
            <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                By proceeding I confirm I am purchasing a gift for {{ profile.username }} & will not receive a product or service for this payment. I have read & agree to the <a :href="route('terms')" class="underline" target="_blank">Terms of Service & Privacy policy</a>.
              </span>
          </label>
          <InputError :message="form.errors.terms" class="mt-2"/>
          </div>
        </div>
      </div>

    <InputError :message="form.errors.error" class="mt-2"/>

    <button
      class="mt-6 w-full rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-youpay-navy-light hover:bg-youpay-navy-alt focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      type="submit"
      @click="submit"
    >
      <span v-if="!form.processing">
        Proceed to Payment
      </span>
      <span v-else>
        Processing...
      </span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import {Head} from "@inertiajs/vue3";
import {Bag} from "@/web/youpay-me-v3/types/checkout.d";
import {Profile, User} from "@/web/youpay-me-v3/types";
import {useForm} from 'laravel-precognition-vue-inertia';
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import type {address, IpDetails} from "@/web/youpay-me-v3/helpers/MerchantWarrior";
import CheckoutLayout from "@/web/youpay-me-v3/Layouts/CheckoutLayout.vue";
import CheckoutLogin from "@/web/youpay-me-v3/Components/Checkout/CheckoutLogin.vue";
import PayerMessage from "@/web/youpay-me-v3/Components/Checkout/PayerMessage.vue";
import {debounce} from "lodash";
import ContactsForm from "@/web/youpay-me-v3/Components/Checkout/ContactsForm.vue";
import {onMounted, ref} from "vue";
import DonationToggle from "@/web/youpay-me-v3/Components/Blocks/DonationToggle.vue";
import FingerprintData from "@/web/youpay-me-v3/Components/Checkout/FingerprintData.vue";

defineOptions({layout: CheckoutLayout})

// Get Props
const props = defineProps<{
  has_donation: boolean,
  payer: Profile,
  auth?: User,
  profile: Profile,
  bag: Bag,
  autofill: address,
  contacts?: address[]
  ipdetails?: IpDetails,
  has_connect: boolean,
  has_coinflow: boolean,
  hasFinger: boolean,
}>();

const primaryAddress = props.contacts?.find((contact) => contact.primary);

const form = useForm('post', route('checkout.details', props.bag.id), {
  first_name: primaryAddress?.first_name ?? '',
  last_name: primaryAddress?.last_name ?? '',
  phone: primaryAddress?.phone ?? '',
  email: props.auth?.email ?? '',
  address_1: primaryAddress?.address_1 ?? '',
  address_2: primaryAddress?.address_2 ?? '',
  suburb: primaryAddress?.suburb ?? '',
  state: primaryAddress?.state ?? '',
  postcode: primaryAddress?.postcode ?? '',
  country_code: primaryAddress?.country_code ?? '',
  terms: false,
  nsure_device_id: window?.nSureSDK?.getDeviceId()
});

const submit = async function (e) {
  e.preventDefault();

  form.submit()
}

const updateCountryCode = (code:string) => form.address.country = code;
const debounceUpdateCountryCode = debounce(updateCountryCode, 500);

// Hide currency selector during Coinflow
function hideCurrency() {
  let currencySelector = document.getElementById('currencySelector');
  if (currencySelector && props.has_coinflow) {
    currencySelector.style.display = 'none';
  } else if (!currencySelector) {
    setTimeout(() => {
      hideCurrency();
    }, 50);
  }
}

onMounted(() => {
  hideCurrency();
});
</script>