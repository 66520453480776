<template>
  <div :class="status ? [ borderClass(status), ' rounded-lg px-3 py-1 my-2'] : 'flex items-end w-full justify-end max-w-6xl'">
    <div @click="open=true"
         :class="status ? 'flex items-center gap-x-2 flex-row' : 'w-72 top-0 flex items-center justify-center gap-x-1 rounded-md bg-indigo-50 px-3 py-1 text-sm text-indigo-700 ring-1 ring-inset ring-indigo-700/10'"
    >
      <InformationCircleIcon class="" :class="status ? [statusClass(status), 'h-5 w-5'] : 'h-6 w-6 text-indigo-700'" aria-hidden="true" />
      <div v-if="!status" class="p-2">
        <p class="text-xs">What do the order statuses mean?</p>
      </div>
      <span v-else :class="[statusClass(status), 'text-lg']">{{ status }}</span>
    </div>
  </div>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 dark:bg-black/80 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <TransitionChild class="min-w-[20rem]" as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-700 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 dark:text-white pb-2 lg:text-lg">
                  About Order Statuses
                </DialogTitle>
                <div class="mt-2">
                  <ul class="text-sm text-gray-700 dark:text-gray-100">
                    <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                      <span class="font-semibold" :class="statusClass('Action Required')">Action Required:</span>
                      <span>We require you to update something in your account to receive your gift. We have sent you instructions via email.</span>
                    </li>
                    <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                      <span class="font-semibold" :class="statusClass('Under Review')">Under Review:</span>
                      <span>Our security team is currently reviewing the payment source of this gift. Reviews are normally completed very quickly, however sometimes we require additional information from you that may add a slight delay. This is part of our process to protect our customers from chargebacks.</span>
                    </li>
                    <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                      <span class="font-semibold" :class="statusClass('Fulfilled')">Fulfilled:</span>
                      <span>The order has been completed and fulfilled.</span>
                    </li>
                    <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                      <span class="font-semibold" :class="statusClass('Processing')">Processing:</span>
                      <span>Your order is currently being processed and will be sent to you shortly.</span>
                    </li>
                    <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                      <span class="font-semibold" :class="statusClass('Pending')">Pending:</span>
                      <span>The order has been created but payment is still pending.</span>
                    </li>
                    <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                      <span class="font-semibold" :class="statusClass('Cancelled')">Cancelled:</span>
                      <span>The order has been cancelled.</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="open = false" ref="cancelButtonRef">Cancel</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

</template>
<script setup lang="ts">
import {InformationCircleIcon} from "@heroicons/vue/24/outline";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {ref} from "vue";
import { statusClass, borderClass } from '@/web/youpay-me-v3/helpers/Orders';

const open = ref(false);

defineProps<{
  status: string
}>();
</script>