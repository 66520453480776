<template>
  <HelloBar v-if="showHelloBar" class="z-10" />
  <div class="h-full bg-youpay-teal-ultralight dark:bg-gray-950 min-h-screen flex flex-col">
    <navbar v-if="user" class="h-[60px] md:h-[65px] w-full z-50" />
    <NavbarGuest v-else class="h-[65px] w-full z-50" />

    <Notifications />

    <main class="h-full w-full flex-grow flex flex-col">
      <slot />
    </main>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import Navbar from "@/web/youpay-me-v3/Components/Blocks/Navbar.vue";
import Notifications from "@/web/youpay-me-v3/Components/Blocks/Notifications.vue";
import NavbarGuest from "@/web/youpay-me-v3/Components/Blocks/NavbarGuest.vue";
import {User} from "@/web/youpay-me-v3/types";
import {usePage} from "@inertiajs/vue3";
import HelloBar from "@/web/youpay-me-v3/Components/HelloBar.vue";
import {computed} from "vue";
import Footer from "@/web/youpay-me-v3/Components/Blocks/Footer.vue";
const user = <User>usePage().props.auth;

const currentRoute = computed(() => route().current());

const isCheckout = computed(() => {
  return (typeof currentRoute.value === 'string') && currentRoute.value.includes('checkout');
});

const showHelloBar = computed(() => {
  return user && !user.can.createCoinflow && !isCheckout.value;
});
</script>