<template>
  <label :for="disabled ? 'disabled' : randomId">
    <slot>
      <div
        class="flex gap-x-6 gap-y-4"
         :class="[
           disabled ? '' : 'cursor-pointer',
            isColumn ? 'md:flex-col items-center' : 'flex-row',
         ]"
      >
        <div v-if="!disabled && preview !== ''" class="lg:h-42 overflow-hidden rounded-md flex items-center">
          <img :src="preview" class="w-30 lg:w-42 rounded-md" />
        </div>
        <div v-else
             class="w-30 flex items-center"
             :class="isColumn ? '' : 'lg:h-42'"
        >
          <PhotoIcon class="w-26 md:w-30" :class="disabled ? 'text-gray-200 dark:text-white/[0.05]' : 'text-gray-300'" aria-hidden="true" />
        </div>
        <div class="flex flex-col justify-center items-center gap-y-1">
          <span
            class="rounded-md px-5 py-1.5 text-sm font-semibold shadow-sm  "
            :class="
            disabled
              ? 'text-white bg-gray-200 dark:bg-white/[0.02] dark:text-gray-100/[0.05] cursor-not-allowed'
              : 'ring-1 ring-inset ring-gray-300 dark:ring-gray-500 bg-white dark:text-white dark:bg-white/[0.05] dark:hover:bg-white/[0.1] hover:bg-gray-50 focus:ring focus:ring-youpay-teal'"
          >
            <span v-if="preview !== ''">Change</span>
            <span v-else>Upload</span>
          </span>
          <p class="mt-2 text-xs md:text-xs leading-5 " :class="disabled ? 'dark:text-gray-600 text-gray-200' : 'dark:text-gray-400 text-gray-600'">JPG, GIF or PNG. 1MB max.</p>
        </div>
      </div>
    </slot>
    <input :id="randomId" :name="randomId" @change="upload" type="file" class="sr-only" ref="uploads" accept="image/jpeg,image/png,image/gif" />
  </label>
</template>

<script setup lang="ts">
import {ref, watch} from "vue";
import Vapor from "@/web/youpay-me-v3/helpers/vapor";
import {PhotoIcon} from "@heroicons/vue/24/solid";

const props = defineProps<{
  isColumn?: boolean;
  modelValue: string;
  disabled?: boolean;
}>();

const emit = defineEmits(['update:modelValue', 'progress', 'updatePreview']);
const uploads = ref('uploads');
const randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
const preview = ref(props.modelValue);

const upload = () => {
  const file = uploads?.value?.files ? uploads?.value?.files[0] : null;
  console.log(uploads?.value);
  if (!file) {
    console.log('no file', file)
    return;
  }
  Vapor.store(file, {
    progress: progress => {
      emit('progress', Math.round(progress * 100));
    }
  }).then(response => {
    console.log(response);
    preview.value = URL.createObjectURL(file)
    emit('updatePreview', preview.value);
    const path = response.key + '.' + response.extension;
    emit('update:modelValue', path);
  }).catch(error => {
    console.log(error);
    if (error.response && error.response.status === 429) {
      const timeRemaining = error.response.headers['x-ratelimit-reset'] - Math.round(Date.now() / 1000);
      emit('error', 'Error: You have uploaded too many images. Please try again in: ' + timeRemaining + ' seconds.');
      setTimeout(() =>emit('error', ''), timeRemaining * 1000); // clear the error after the time remaining
      return;
    }
    emit('error', 'There was an error uploading your image. Please try again.');
  });
}

watch(() => props.modelValue, (value) => {
  if (value.includes('tmp/')) return;
  preview.value = value;
});

</script>
