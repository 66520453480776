<template>
  <div class="flex justify-center items-center">
    <div class="relative w-40 h-40">
      <div v-for="(color, index) in allColors" :key="index"
           class="absolute inset-0 rounded-full border-4 border-t-transparent border-l-transparent"
           :style="{
             borderRightColor: color,
             borderBottomColor: color,
             animation: `spin ${3 + index * 0.5}s linear infinite`,
             width: `${100 - index * 8}%`,
             height: `${100 - index * 8}%`,
             top: `${index * 4}%`,
             left: `${index * 4}%`
           }">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const allColors = [
  '#c70808',
  '#f89914',
  '#06cc22',
  '#8107ec',
  '#55CDFC',
  '#F7A8B8',
  '#FFFFFF',
  '#F7A8B8',
  '#55CDFC'
];
</script>

<style scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.animate-spin {
  animation: spin 3s linear infinite;
}

.animate-spin-reverse {
  animation: spin-reverse 2s linear infinite;
}
</style>