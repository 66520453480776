<template>
  <div
    v-show="hasResults || chatActive"
  >
    <div v-if="!isUrl && pages?.length">
      <div v-if="query.length !== 0" class="border-y border-gray-200 bg-white dark:bg-gray-700 px-4 pt-5 pb-3">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Pages</h3>
      </div>
      <ul static class="max-h-96 scroll-py-3 overflow-y-auto p-3">
        <li v-for="item in pages" :key="item.id">
          <Link :href="route(item.route)"
                class="
                      cursor-pointer flex select-none rounded-xl p-3
                      hover:bg-gray-100 hover:dark:bg-gray-700
                    "
                :class="[false ? 'bg-gray-100 dark:bg-gray-700' : '']"
          >
            <div :class="['flex h-10 w-10 flex-none items-center justify-center rounded-lg', item.color]">
              <component :is="icons[item.icon]" class="h-6 w-6 text-white" aria-hidden="true"/>
            </div>
            <div class="ml-4 flex-auto flex justify-center w-full flex-col">
              <p class="block w-full text-sm font-medium hover:text-gray-900 hover:dark:text-gray-100"
                 :class="[false ? 'text-gray-900 dark:text-gray-100' : 'text-gray-700 dark:text-gray-300']">
                {{ item.name }}
              </p>
              <p class="block w-full text-sm"
                 :class="[false ? 'text-gray-700 dark:text-gray-300' : 'text-gray-500 dark:text-gray-400']">
                {{ item.description }}
              </p>
            </div>
          </Link>
        </li>
      </ul>
    </div>
    <chat-widget :query="query" @toggleChat="toggleChat" />
    <div v-if="!isUrl && query.length !== 0 && stores.length">
      <div class="border-y border-gray-200 bg-white dark:bg-gray-700 px-4 pt-5 pb-3">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Stores</h3>
      </div>
      <ul role="list" class="w-full divide-y divide-slate-400/20 bg-white text-[0.8125rem] leading-5 text-slate-900 shadow-black/5 ring-1 ring-slate-700/10">
        <li v-for="store in stores" :key="store.domain" class="flex items-center p-4 w-full">
          <img :src="store.logo" alt="" class="hidden dark:visible h-10 w-auto flex-none rounded-full">
          <img :src="store.dark_logo" alt="" class="dark:hidden visible h-10 w-auto flex-none rounded-full">
          <div class="ml-4 flex-auto">
            <div class="font-medium">{{ store.title }}</div>
            <div v-if="store.industry" class="mt-1 text-slate-700">{{ store.industry }}</div>
          </div>
          <Link href="#" class="pointer-events-auto ml-4 flex-none rounded-md px-2 py-[0.3125rem] font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50">
            Create Gift
          </Link>
        </li>
      </ul>
    </div>
    <div v-if="!isUrl && query.length !== 0 && accounts.length">
      <div class="border-y border-gray-200 bg-white dark:bg-gray-700 px-4 pt-5 pb-3">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Users</h3>
      </div>
      <ul role="list" class="w-full divide-y divide-slate-400/20 bg-white dark:bg-gray-700 text-[0.8125rem] leading-5 text-slate-900 shadow-black/5 ring-1 ring-slate-700/10">
        <li v-for="user in accounts" :key="user.username" class="flex items-center p-4 w-full">
          <img :src="user.avatar" alt="" class="h-10 w-10 flex-none rounded-full">
          <div class="ml-4 flex-auto">
            <div class="font-medium">{{ user.name }}</div>
            <div class="mt-1 text-slate-700">@{{ user.username }}</div>
          </div>
          <Link :href="route('profile.show', user.username)" class="pointer-events-auto ml-4 flex-none rounded-md px-2 py-[0.3125rem] font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50">
            View Profile
          </Link>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="query?.length !== 0 && !hasResults && !chatActive" class="px-6 py-14 text-center text-sm sm:px-14">
    <ExclamationCircleIcon type="outline" name="exclamation-circle" class="mx-auto h-6 w-6 text-gray-400 dark:text-gray-500" />
    <p class="mt-4 font-semibold text-gray-900 dark:text-gray-100">No results found</p>
    <p class="mt-2 text-gray-500 dark:text-gray-400">No components found for this search term. Please try again.</p>
  </div>
</template>

<script>
import { createSuitMixin, createWidgetMixin } from 'vue-instantsearch/vue3/es';
import ChatWidget from "@/web/youpay-me-v3/Components/Chat/ChatWidget.vue";
import {Link} from "@inertiajs/vue3";
import {
  BanknotesIcon,
  ExclamationCircleIcon,
  GiftIcon, GlobeAltIcon,
  PencilSquareIcon,
  UserCircleIcon,
  LockClosedIcon
} from "@heroicons/vue/24/outline";

const connectStateResults = (renderFn, unmountFn = () => {}) => (
  widgetParams = {}
) => ({
  init({ instantSearchInstance }) {
    renderFn(
      {
        state: undefined,
        results: undefined,
        instantSearchInstance,
        widgetParams,
      },
      true
    );
  },

  render({ results, instantSearchInstance, state, scopedResults }) {
    const resultsCopy = Object.assign({}, results);

    const stateCopy = Object.assign({}, state);

    renderFn(
      {
        results: resultsCopy,
        state: stateCopy,
        // added this
        scopedResults,
        instantSearchInstance,
        widgetParams,
      },
      false
    );
  },

  dispose() {
    unmountFn();
  },
});

export default {
  components: {ExclamationCircleIcon, Link, ChatWidget},
  mixins: [
    createWidgetMixin({ connector: connectStateResults }),
    createSuitMixin({ name: 'StateResults' }),
  ],
  data() {
    return {
      chatActive: true,
      icons: {
        'GiftIcon': GiftIcon,
        'PencilSquareIcon': PencilSquareIcon,
        'BanknotesIcon': BanknotesIcon,
        'UserCircleIcon': UserCircleIcon,
        'GlobeAltIcon': GlobeAltIcon,
        'LockClosedIcon': LockClosedIcon,
      }
    }
  },
  computed: {
    isUrl() {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const url = this.query.match(urlRegex)?.[0]
      if (!url) {
        return false; // no url found
      }
      return true
    },
    query() {
      return this.state?.state.query ?? '';
    },
    pages() {
      return this.state?.scopedResults && this.state.scopedResults.find((scopedResult) => {
        return scopedResult?.indexId === this.scout.prefix + 'pages';
      })?.results?.hits;
    },
    accounts() {
      return this.state?.scopedResults && this.state.scopedResults.find((scopedResult) => {
        return scopedResult?.indexId === this.scout.prefix + 'accounts';
      })?.results?.hits;
    },
    stores() {
      return this.state?.scopedResults && this.state.scopedResults.find((scopedResult) => {
        return scopedResult?.indexId === this.scout.prefix + 'stores';
      })?.results?.hits;
    },
    hasResults() {
      //state.scopedResults.0.results.nbHits
      // Loop through scopedResults and check if any of them have results
      // If so, return true
      // If not, return false
      return this.state?.scopedResults && this.state.scopedResults.some((scopedResult) => {
        return scopedResult?.results?.nbHits > 0;
      });
    },
    scout() {
      return this.$page.props.scout;
    },
  },
  methods: {
    toggleChat(chatActive) {
      this.chatActive = chatActive
    },
  },
};
</script>
