<template>
    <Head title="Dashboard" />
      <div class="py-5 w-2/4 mx-auto">
<!--        <Redeem image="" instructions="" code="" pin="" expiry="" />-->
<!--        <ImageGallery v-model="current_image" :images="images" />-->
<!--        <span class="py-5">-->
<!--          {{ current_image }}-->
<!--        </span>-->
      </div>
</template>

<script setup lang="ts">
import {Head} from "@inertiajs/vue3";
import ImageGallery from "@/web/youpay-me-v3/Components/Gifting/ImageGallery.vue";
import {ref} from "vue";
import Redeem from "@/web/youpay-me-v3/Pages/GiftCard/Redeem.vue";

const current_image = ref('')
const images = [
  'https://picsum.photos/seed/1/200/300',
  'https://picsum.photos/seed/2/200/300',
  'https://picsum.photos/seed/3/200/300',
  'https://picsum.photos/seed/4/200/300',
  'https://picsum.photos/seed/5/200/300',
];
current_image.value = images[0];

</script>