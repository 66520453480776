import {inject} from "vue";
import {Gift} from "@/web/youpay-me-v3/types/index";
import {debounce} from "lodash";

export const trackEvent = (...args: any[]) => {
    if (window?.gtag) {
        console.log('submitting-gtag', args);
        window.gtag('event', ...args);
        if (typeof klaviyo !== "undefined") {
            klaviyo.push(["track", args[0], args]);
        }
    } else {
        console.log('gtag not loaded');
        const newArgs = ['event', ...args];
        setTimeout(() => {
            gtagBackup(...newArgs);
        }, 2000);
    }
}

export const debounceTrackEvent = debounce(trackEvent, 500);

export const trackShopifyCheckout: Function = (gift: Gift, username: string) => {
    // If we manage to track in shopify land, lets change this back to start_checkout
    trackEvent('shopify_checkout', {
        currency: gift.currency,
        value: gift.amount,
        items: [
            {
                item_id: gift.id,
                item_name: gift.title,
                affiliation: username,
                quantity: 1,
                price: gift.amount,
            }
        ]
    })
}

export const trackAddToCart: Function = (gift: Gift, username: string) => {
    trackEvent('add_to_cart', {
        currency: gift.currency,
        value: gift.amount,
        items: [
            {
                item_id: gift.id,
                item_name: gift.title,
                affiliation: username,
                quantity: 1,
                price: gift.amount,
            }
        ]
    })
}
