<template>
  <div class="h-auto w-full min-h-full flex-grow">
    <header class="xl:p-6 p-4 bg-youpay-teal-ultralight dark:bg-gray-700 fixed flex gap-4 flex-row w-screen z-20">
      <div class="relative flex-grow shadow-sm">
        <MagnifyingGlassIcon class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" />
        <TextInput class="bg-white h-12 w-full border-0 pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Search users..." v-model="query" />
      </div>
      <div class="min-w-[15%]">
        <!-- Drop down to filter by category -->
        <Dropdown class="w-full">
          <template #trigger>
            <button type="button" class="flex items-center shadow-sm bg-white dark:bg-gray-900 dark:text-gray-200 h-12 p-2 px-4 w-full justify-between rounded-md">
              <span class="pr-4">{{ currentLeaderboard.label }}</span>
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </button>
          </template>
          <template #content>
            <div @click="changeBoard(board)" v-for="board in leaderboards" class="cursor-pointer block w-full px-4 py-2 text-left text-sm leading-5 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-800 transition duration-150 ease-in-out">
              {{ board.label }}
            </div>
          </template>
        </Dropdown>
      </div>
    </header>

    <ul role="list" class="mt-22 xl:mt-24 xl:m-6 m-4 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      <li v-for="(profile, index) in filteredProfiles" :key="profile.email" class="relative col-span-1 rounded-lg text-left shadow overflow-hidden bg-transparent">
        <div :style="profile.bannerUrl? `background-image: url(${profile.bannerUrl});` : ''" class="aspect-[3/1] min-h-[150px] absolute w-full top-0 bg-cover bg-center bg-gradient-to-r from-youpay-navy-light via-purple-500 to-youpay-pink"></div>

        <div class="mt-[90px] bg-white">
          <div class="flex flex-1 flex-col px-4 pb-8 relative z-10">
            <div class="flex items-center">
              <span class="text-xl font-bold mr-2">{{ profile.place }}</span>
              <img class="inline-block h-22 w-22 rounded-md border-4 border-white" :src="profile.avatar" :alt="profile.name" />
<!--                <div v-if="profile.badges" class="flex ml-2">-->
<!--                <span v-for="badge in profile.badges" :key="badge" class="mr-1">-->
<!--                  <img :src="badge" alt="Badge" class="h-5 w-5">-->
<!--                </span>-->
<!--                </div>-->
            </div>
            <h3 class="mt-3 h-5 text-sm font-medium text-gray-900 flex flex-row items-center">
              <span class="leading-3">{{ profile.name }}</span>
              <span v-if="profile.isVerified" class="pl-2">
                <CheckCircleIcon class="h-5 w-5 text-blue-500" aria-hidden="true" />
              </span>
            </h3>
            <dl class="mt-1 flex flex-grow flex-col justify-between">
              <dt class="sr-only">Details</dt>
              <dd class="text-sm font-medium text-gray-500">{{ '@' + profile.username }} - {{ profile.followers }} Followers</dd>
              <dt class="sr-only">About</dt>
              <dd class="mt-3 text-sm text-gray-400">{{ profile.description }}</dd>
            </dl>
          </div>
          <div>
            <div class="-mt-px flex divide-x border-t divide-gray-200">
              <div class="flex w-0 flex-1">
                <a
                    :href="route('profile')"
                    class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  View Profile
                </a>
              </div>
              <div class="-ml-px flex w-0 flex-1">
                <a
                    href="#"
                    class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <PlusIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Follow
                </a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import MainLayout from "@/web/youpay-me-v3/Layouts/MainLayout.vue";
import { ref, computed } from 'vue';
import {CheckCircleIcon, ChevronDownIcon, EyeIcon, MagnifyingGlassIcon, PlusIcon} from '@heroicons/vue/20/solid'
import Dropdown from "@/web/youpay-me-v3/Components/Buttons/Dropdown.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";

const query = ref('');

const leaderboards: Array<
    {
        label: string,
        value: string,
    }
> = [
    {
        label: 'Highest Points',
        value: 'points',
    },
    {
        label: 'Most Followers',
        value: 'followers',
    },
    {
        label: 'Biggest Gifter',
        value: 'gifter',
    },
    {
        label: 'Most Active',
        value: 'active',
    },
    {
        label: 'Most Popular',
        value: 'popular',
    },
];

let currentLeaderboard = ref(Object<{
    label: string,
    value: string,
}>(leaderboards[0]));

const profiles: Array<{
    place: number,
    name: string,
    username: string,
    email: string,
    followers: number,
    description: string,
    isVerified: boolean,
    avatar: string,
    bannerUrl: string | null,
}> = [
    {
        place: 1,
        name: 'Jane Cooper',
        username: 'janecooper',
        email: 'janecooper@example.com',
        followers: 1200,
        description: 'A paradigm representative',
        isVerified: true,
        avatar: 'https://i.pravatar.cc/150?img=1',
        bannerUrl: null,
    },
    {
        place: 2,
        name: 'John Doe',
        username: 'johndoe',
        email: 'johndoe@example.com',
        followers: 800,
        description: 'A software engineer',
        isVerified: false,
        avatar: 'https://i.pravatar.cc/150?img=2',
        bannerUrl: null,
    },
    {
        place: 3,
        name: 'Alice Johnson',
        username: 'alicejohnson',
        email: 'alicejohnson@example.com',
        followers: 1500,
        description: 'A product manager',
        isVerified: true,
        avatar: 'https://i.pravatar.cc/150?img=3',
        bannerUrl: null,
    },
    {
        place: 4,
        name: 'Bob Smith',
        username: 'bobsmith',
        email: 'bobsmith@example.com',
        followers: 500,
        description: 'A business analyst',
        isVerified: false,
        avatar: 'https://i.pravatar.cc/150?img=4',
        bannerUrl: null,
    },
    {
        place: 5,
        name: 'Charlie Brown',
        username: 'charliebrown',
        email: 'charliebrown@example.com',
        followers: 700,
        description: 'A project manager',
        isVerified: true,
        avatar: 'https://i.pravatar.cc/150?img=5',
        bannerUrl: null,
    },
    {
        place: 6,
        name: 'David Williams',
        username: 'davidwilliams',
        email: 'davidwilliams@example.com',
        followers: 900,
        description: 'A data scientist',
        isVerified: false,
        avatar: 'https://i.pravatar.cc/150?img=6',
        bannerUrl: null,
    },
    {
        place: 7,
        name: 'Emily Davis',
        username: 'emilydavis',
        email: 'emilydavis@example.com',
        followers: 1300,
        description: 'A UX designer',
        isVerified: true,
        avatar: 'https://i.pravatar.cc/150?img=7',
        bannerUrl: null,
    },
    {
        place: 8,
        name: 'Frank Miller',
        username: 'frankmiller',
        email: 'frankmiller@example.com',
        followers: 600,
        description: 'A digital marketer',
        isVerified: false,
        avatar: 'https://i.pravatar.cc/150?img=8',
        bannerUrl: null,
    },
];


const filteredProfiles = computed(() => {
  return profiles.filter(profile => profile.name.toLowerCase().includes(query.value.toLowerCase()));
});

const changeBoard = function (value: string) {
  currentLeaderboard = value;
};
</script>
