<template>
  <button @click="clickButton" type="button" class="relative inline-flex items-center rounded-md px-4 md:px-2 py-2 md:py-1 text-sm font-semibold dark:text-white shadow-sm ring-1 ring-inset">
    <slot />
    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-10" @close="open = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 dark:bg-black/50 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full" :class="iconClass">
                    <CheckIcon v-if="action === 'capture'" class="h-6 w-6 text-green-600" aria-hidden="true" />
                    <XMarkIcon v-if="action === 'deny'" class="h-6 w-6 text-red-600" aria-hidden="true" />
                    <EnvelopeIcon v-if="action === 'fu'" class="h-6 w-6 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                      {{ title }}
                    </DialogTitle>
                    <div class="mt-2" v-if="options?.length">
                      <select v-model="form.option" class="block w-full px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-white focus-visible:ring-opacity-60">
                        <option value="" selected>Skip Email</option>
                        <option v-for="(option, index) in options" :key="index" :value="option">
                          {{ option }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2" @click="submit">Confirm</button>
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" @click="open = false" ref="cancelButtonRef">Cancel</button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </button>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon, XMarkIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'
import {useForm} from "laravel-precognition-vue-inertia";
import { router } from '@inertiajs/vue3'

const props = defineProps<{
  order_id: number,
  action: string,
  options?: string[],
}>()

const open = ref(false);


const form = useForm('post', route('umpire.order.action', props.order_id), {
  type: props.action,
  option: '',
});

const iconClass = computed(() => {
  if (props.action === 'capture') {
    return 'bg-green-100 dark:bg-green-700/10 ring-green-300 dark:ring-green-900'
  } else if (props.action === 'deny') {
    return 'bg-red-100 dark:bg-red-700/10 ring-red-300 dark:ring-red-900'
  }
  if (props.action === 'fu') {
    return 'bg-yellow-100 dark:bg-yellow-700/10 ring-yellow-300 dark:ring-yellow-900'
  }
})

const title = computed(() => {
  if (props.action === 'capture') {
    return 'Capture Order'
  }
  if (props.action === 'deny') {
    return 'Deny Order'
  }
  if (props.action === 'fu') {
    return 'Follow Up Order'
  }
})

const clickButton = () => {
  open.value = true;
}

const submit = () => {
  form.submit({
    onSuccess: () => {
      open.value = false;
      // reload page
      window.location.reload();
    }
  });
}
</script>