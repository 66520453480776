<template>
  <Head :title="getTitle" />

  <div id="error-page" class="grid min-h-full grid-cols-1 bg-white lg:grid-cols-[max(50%,36rem),1fr] flex-grow">
    <header class="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
      <a href="/">
        <span class="sr-only">YouPay</span>
        <YoupayColorLogo class="h-8 w-auto" full-logo />
      </a>
    </header>
    <main class="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8" :class="themeVars.class ?? ''">
      <div class="max-w-lg">
        <p class="text-base font-semibold leading-8" :class="primaryColor">{{ code }}</p>
        <h1 class="mt-4 text-3xl font-bold text-gray-900 sm:text-5xl" :class="secondaryColor">{{ getTitle }}</h1>
        <p class="mt-6 text-base leading-7 text-gray-600">{{ getMessage }}</p>
        <div class="mt-10">
          <a href="/" class="text-sm font-semibold leading-7" :class="primaryColor"><span aria-hidden="true">&larr;</span> {{ backText }}</a>
        </div>
      </div>
    </main>
    <footer class="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
      <div class="border-t border-gray-100 bg-gray-50 py-10">
        <div>
        </div>

        <nav class="mx-auto text-xs flex flex-col w-full divide-y max-w-7xl gap-y-4 px-6 md:text-sm leading-7 text-gray-600 lg:px-8">

          <div class="flex gap-x-4 items-center w-full">
            <span class="font-semibold">Need Help?</span>
            <a href="https://support.youpay.co/" class="cursor-pointer">Visit Support</a>
            <svg viewBox="0 0 2 2" aria-hidden="true" class="h-0.5 w-0.5 fill-gray-300 hidden md:inline-block">
              <circle cx="1" cy="1" r="1" />
            </svg>
<!--            <a href="https://youpay-inc.statuspage.io/">Status</a>-->
<!--            <svg viewBox="0 0 2 2" aria-hidden="true" class="h-0.5 w-0.5 fill-gray-300">-->
<!--              <circle cx="1" cy="1" r="1" />-->
<!--            </svg>-->
<!--            <a href="https://www.facebook.com/youpayco">Find us on Facebook</a>-->
          </div>

          <div class="flex flex-row items-center gap-x-4 pt-4">
            <span class="font-semibold">Server Status:</span>
            <statuspage-widget src="https://youpay-inc.statuspage.io/"></statuspage-widget>
          </div>
        </nav>
      </div>
    </footer>
    <div class="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
      <img :src="themeVars.image" alt="" class="absolute inset-0 h-full w-full object-cover" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {Head} from "@inertiajs/vue3";
import ErrorLayout from "@/web/youpay-me-v3/Layouts/ErrorLayout.vue";
import {computed, onMounted} from "vue";
import YoupayColorLogo from "@/web/youpay-me-v3/Components/YouPay/youpayColorLogo.vue";

const props = defineProps<{
  code: number,
  title?: string,
  message?: string,
}>();

const getThemeValue = (key: string, code: number) => {
  if (typeof themeVars.value?.codes?.[code]?.[key] !== 'undefined') {
    return themeVars.value.codes[code][key];
  }
  if (typeof themeVars.value?.codes?.default?.[key] !== 'undefined') {
    return themeVars.value?.codes?.default?.[key];
  }
  return null
}

const getTitle = computed(() => {
  if (props.title) {
    return props.title;
  }

  if (window.location.hostname === 'youpay.link') {
    return 'Link Expired';
  }

  const themeTitle = getThemeValue('title', props.code);
  if (themeTitle) {
    return themeTitle;
  }

  switch (props.code) {
    case 401:
      return 'Unauthorized';
    case 403:
      return 'Forbidden';
    case 404:
      return 'Page Not Found';
    case 419:
      return 'Page Expired';
    case 429:
      return 'Too Many Requests';
    case 500:
      return 'Server Error';
    case 503:
      return 'Service Unavailable';
    default:
      return 'Error';
  }
});

const getMessage = computed(() => {
  if (props.message) {
    return props.message;
  }
  // if current domain is youpay.link, then show the message below
  if (window.location.hostname === 'youpay.link') {
    return 'Sorry, this link is no longer valid. Please contact the person who sent it to you.';
  }

  const themeMessage = getThemeValue('message', props.code);
  if (themeMessage) {
    return themeMessage;
  }

  switch (props.code) {
    case 401:
      return 'Sorry, you are not authorized to access this page.';
    case 403:
      return 'Sorry, you are forbidden from accessing this page.';
    case 404:
      return 'Sorry, the page you are looking for could not be found.';
    case 419:
      return 'Sorry, your session has expired. Please refresh the page and try again.';
    case 429:
      return 'Sorry, you are making too many requests to our servers.';
    case 500:
      return 'Sorry, something went wrong, please try again later.';
    case 503:
      return 'Sorry, we are temporarily unavailable for maintenance. Please try again later.';
    default:
      return 'Sorry, something went wrong.';
  }
});

const backText = computed(() => {
  const themeBackText = getThemeValue('back', props.code);
  if (themeBackText) {
    return themeBackText;
  }
  return 'Back to home';
});

const primaryColor = computed(() => {
  const themeColor = getThemeValue('color', props.code);
  if (themeColor) {
    return themeColor;
  }
  return 'text-youpay-navy';
})

const secondaryColor = computed(() => {
  const themeColor = getThemeValue('secondaryColor', props.code);
  if (themeColor) {
    return themeColor;
  }
  return 'text-youpay-black';
})

defineOptions({ layout: ErrorLayout })

const loadScript = (script) => {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    scriptTag.src = script;
    scriptTag.onload = resolve;
    scriptTag.onerror = reject;
    document.body.appendChild(scriptTag);
  });
}

const theme = computed(() => {
  // based on date: easter, halloween, christmas, new year, valentine
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  // easter
  if (month === 4 && (day === 4 || day === 5 || day === 6 || day === 7 || day === 8 || day === 9 || day === 10)) {
    return 'easter';
  }
  // halloween
  if (month === 10) {
    return 'halloween';
  }
  // black friday || cyber monday
  const dayOfWeek = date.getDay() + 1;
  if (month === 11 && ((dayOfWeek === 5 && day >= 23) || (dayOfWeek === 1 && day <= 29))) {
    return 'sales';
  }
  // christmas
  if (month === 11 || (month === 12 && day <= 25)) {
    return 'christmas';
  }
  // Boxing day
  if (month === 12 && day === 26) {
    return 'sales';
  }
  // new year
  if ((month === 12 && day >= 27) || (month === 1 && day <= 5)) {
    return 'new-year';
  }
});

const themeVars = computed( () => {
  switch (theme.value) {
    case "halloween":
      return {
        class: 'font-halloween tracking-wide',
        codes: {
          401: {
            title: "Unauthorized Haunting",
            message: "Boo! You're not allowed to haunt this page.",
          },
          403: {
            title: "Forbidden Dungeon",
            message: "Yikes! You're forbidden from entering this spooky dungeon.",
          },
          404: {
            title: 'This page has vanished',
            message: 'Boo, the page you\'re searching for must\'ve been scared away.',
          },
          419: {
            title: "Session Expired",
            message: "Your session has vanished like a ghost. Please refresh and try again.",
          },
          429: {
            title: "Too Many Spells",
            message: "Hold on, you're casting too many spells at once!",
          },
          500: {
            title: "Potion Error",
            message: "Something went wrong with our potion, please try again later.",
          },
          503: {
            title: "Service Unavailable",
            message: "Our witches are currently brewing new potions. Please try again later.",
          },
          'default': {
            color: "text-orange-500",
            secondaryColor: "text-purple-500",
            title: "Unknown Terror",
            message: "Something spooky happened. Try again if you dare.",
            back: 'Creep back home'
          }
        },
        image: 'https://images.unsplash.com/photo-1633380170808-9404cd630e82?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2564&q=80',
      }
    default:
      return {
        image: 'https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80'
      }
  }
});

onMounted(async () => {
  await loadScript('https://unpkg.com/@webcomponents/webcomponentsjs@2.1.3/webcomponents-bundle.js');
  loadScript('https://unpkg.com/@statuspage/status-widget/dist/index.js');
})
</script>

<style scoped>
  /*https://fonts.googleapis.com/css2?family=Creepster&display=swap*/
  @import url('https://fonts.googleapis.com/css2?family=Creepster&display=swap');
</style>