<template>
  <div>
    <div @click="open = true" class="cursor-pointer">
      <InformationCircleIcon class="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true" />
    </div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="open = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500/80 dark:bg-black/80 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <TransitionChild class="min-w-[20rem]" as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-700 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 dark:text-white pb-2 lg:text-lg">
                    Product Types
                  </DialogTitle>
                  <div class="mt-2">
                    <ul class="text-sm text-gray-700 dark:text-gray-100">
                      <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                        <span class="font-semibold text-indigo-400 dark:text-indigo-300">Coinflow:</span>
                        <span>A secure payment method that allows creators to receive payments directly, with enhanced privacy and lower fees.</span>
                      </li>
                      <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                        <span class="font-semibold text-indigo-400 dark:text-indigo-300">Wish:</span>
                        <span>An item or experience that the creator has added to their wishlist, which supporters can purchase as a gift.</span>
                      </li>
                      <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                        <span class="font-semibold text-indigo-400 dark:text-indigo-300">Gift Card:</span>
                        <span>A digital gift card that can be redeemed at specific stores or for general use.</span>
                      </li>
                      <li class="mb-2 flex flex-col gap-y-0.5 py-1">
                        <span class="font-semibold text-indigo-400 dark:text-indigo-300">Partner Store:</span>
                        <span>Products from stores that have partnered with YouPay to offer seamless gifting experiences.</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="open = false" ref="cancelButtonRef">Close</button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';

const open = ref(false);
</script>