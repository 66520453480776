<template>
  <Head :title="fund.title + ' - Crowdgift'"/>

  <div class="mx-auto w-full max-w-lg  md:max-w-7xl pt-4 px-4 md:px-6">
    <div class="w-full flex flex-row justify-between">
      <Link :href="route('profile.show', profile.username)"
            class="text-sm font-semibold leading-7 text-gray-800 dark:text-white">
        <span aria-hidden="true">&larr;</span>
        Back to {{ profile.username }}'s profile
      </Link>
    </div>
  </div>
  <div class="mx-auto w-full max-w-lg px-6 pt-4 pb-18 md:max-w-7xl lg:px-8 dark:text-white">
    <div class="pb-2 xs:pb-3 sm:pb-4 md:pb-6 flex justify-between flex-col md:flex-row gap-y-2">
      <h1 class="text-2xl font-bold text-gray-900 dark:text-gray-50 md:text-3xl">
        {{ fund.title }}
        <span class="hidden md:inline text-lg pl-2">{{ fund.amount_formatted2 }}</span>
      </h1>
      <div v-if="owner" class="flex flex-row items-center justify-end gap-x-4 text-sm lg:text-md">
        <ActionDropdown :items="[
          { name: 'Edit Fund', href: route('fund.edit', [profile.username, fund.url]) },
          { name: 'Delete Fund', href: route('fund.delete', [profile.username, fund.url]), method: 'DELETE' },
        ]" class="text-xs lg:text-sm"/>
        <ShareUrl :url="fund.share_url" class="text-xs lg:text-sm"/>
      </div>
    </div>
    <div
      class="grid grid-cols-1 grid-rows-1 items-start gap-x-4 gap-y-4 xs:gap-y-2 md:max-w-none md:grid-cols-3 lg:gap-x-8 lg:gap-y-8">
      <div class="h-36 xs:h-42 w-full overflow-hidden rounded-lg sm:h-72 md:h-[350px] md:hidden">
        <img :alt="fund.title" :src="fund.banner" class="h-full w-full object-cover">
      </div>

      <div class="hidden xs:flex md:hidden flex-row justify-end gap-x-2 pr-1">
        <span
          class="flex-grow-0 inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs leading-none text-indigo-600 font-light ring-1 ring-inset ring-indigo-400/20 dark:text-indigo-300">
          Crowdgift
        </span>
        <span
          class="flex-grow-0 inline-flex items-center rounded-md px-2 py-1 text-xs leading-none font-light ring-1 ring-inset text-youpay-pink-dark dark:text-youpay-pink bg-youpay-pink/20 dark:bg-youpay-pink/20 ring-youpay-pink/30">
          {{ fund.category_name }}
        </span>
      </div>

      <div class="md:col-start-3 md:row-end-1">
        <h2 class="sr-only">Summary</h2>
        <div
          class="flex flex-col gap-y-4 rounded-lg bg-gray-50 py-3 shadow-sm ring-1 ring-gray-900/5 dark:bg-white/5 lg:gap-y-6 lg:py-4">
          <div class="flex items-center px-3 lg:px-4" @click="router.visit(route('profile.show', profile.username))">
            <img v-if="fund.avatar" :alt="profile.username" :src="fund.avatar"
                 class="mr-6 rounded-lg w-18 h-18 lg:h-24 lg:w-24 object-cover">
            <UserCircleIcon v-else
                            class="mr-6 p-1 rounded-lg w-18 h-18 lg:h-24 lg:w-24 text-gray-400 dark:text-gray-600"/>
            <div class="flex flex-col text-gray-900 dark:text-gray-50">
              <a v-if="profile.display_name" :href="route('profile.show', profile.username)"
                 class="flex flex-row items-center">
                <span class="font-bold line-clamp-1">{{ profile.display_name }}</span>
                <span v-if="profile.verified" class="flex w-8 items-center p-1.5">
                  <CheckBadgeIcon aria-hidden="true" class="inline-block w-full text-youpay-teal"/>
                </span>
              </a>
              <a :href="route('profile.show', profile.username)">
                <span v-if="!profile.anon" class="text-sm">{{ '@' + profile.username }}</span>
              </a>
            </div>
          </div>
          <dl class="flex flex-wrap px-4 lg:px-6">
            <div class="flex-auto">
              <p class="text-sm text-gray-600 dark:text-gray-400">
                {{ fund.progress_formatted }} raised of <span class="text-lg pl-1">{{ fund.amount_formatted2 }}</span>
              </p>
              <div class="mt-2 w-full rounded-full bg-gray-200 h-2.5 dark:bg-gray-700">
                <div :style="{ width: `${(fund.progress / fund.amount) * 100}%` }"
                     class="rounded-full bg-indigo-600 h-2.5"
                ></div>
              </div>
              <div class="mt-2 flex items-center gap-x-2 text-xs text-gray-500 dark:text-gray-400">
                <UsersIcon aria-hidden="true" class="h-3 w-3 text-gray-400"/>
                <span>{{ fund.backers }} gifters</span>
                {{ Math.round((fund.progress / fund.amount) * 100 * 100) / 100 }}% gifted
              </div>
            </div>
          </dl>
          <div class="px-4 lg:px-6">
            <YouPayMeNow
              v-if="fund.status === 'active'"
              :fund_id="fund.id"
              :profile="profile"
              class="mx-auto w-full max-w-[400px]"
            >
              <template #title>
                {{ fund.title }}
              </template>
              <template #description>
                Help {{ profile.username }} reach their goal by contributing to this gift.
              </template>
              <template #button>
                Contribute to this gift
              </template>
            </YouPayMeNow>
          </div>
        </div>
      </div>

      <div class="md:col-span-2 md:row-span-2 md:row-end-2">
        <div class="mb-4 hidden h-64 w-full overflow-hidden rounded-lg md:h-[350px] md:block">
          <img :alt="fund.title" :src="fund.banner" class="h-full w-full object-cover">
        </div>

        <div class="hidden md:flex flex-row justify-end gap-x-2 pb-3 pr-3">
          <span
            class="flex-grow-0 inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs leading-none text-indigo-600 font-light ring-1 ring-inset ring-indigo-400/20 dark:text-indigo-300">
            Crowdgift
          </span>
          <span
            class="flex-grow-0 inline-flex items-center rounded-md px-2 py-1 text-xs leading-none font-light ring-1 ring-inset text-youpay-pink-dark dark:text-youpay-pink bg-youpay-pink/20 dark:bg-youpay-pink/20 ring-youpay-pink/30">
            {{ fund.category_name }}
          </span>
        </div>

        <div v-if="cleanedHtml?.length"
             class="w-full rounded-lg bg-white px-4 py-4 shadow-sm ring-1 ring-gray-900/5 dark:bg-white/5 md:px-8 md:py-8">
          <div class="prose prose-sm dark:prose-invert" v-html="cleanedHtml"/>
        </div>
      </div>

      <div class="hidden">
        <!-- Activity feed -->
        <h2 class="text-sm font-semibold leading-6 text-gray-900">Activity</h2>
        <ul class="mt-6 space-y-6" role="list">
          <li v-for="(activityItem, activityItemIdx) in activity" :key="activityItem.id" class="relative flex gap-x-4">
            <div
              :class="[activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center']">
              <div class="w-px bg-gray-200"/>
            </div>
            <template v-if="activityItem.type === 'commented'">
              <img :src="activityItem.person.imageUrl" alt=""
                   class="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"/>
              <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                <div class="flex justify-between gap-x-4">
                  <div class="text-xs leading-5 text-gray-500 py-0.5">
                    <span class="font-medium text-gray-900">{{ activityItem.person.name }}</span> commented
                  </div>
                  <time :datetime="activityItem.dateTime" class="flex-none text-xs leading-5 text-gray-500 py-0.5">
                    {{ activityItem.date }}
                  </time>
                </div>
                <p class="text-sm leading-6 text-gray-500">{{ activityItem.comment }}</p>
              </div>
            </template>
            <template v-else>
              <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                <CheckCircleIcon v-if="activityItem.type === 'paid'" aria-hidden="true"
                                 class="h-6 w-6 text-indigo-600"/>
                <div v-else class="rounded-full bg-gray-100 ring-1 ring-gray-300 h-1.5 w-1.5"/>
              </div>
              <p class="flex-auto text-xs leading-5 text-gray-500 py-0.5">
                <span class="font-medium text-gray-900">{{ activityItem.person.name }}</span> {{ activityItem.type }}
                the invoice.
              </p>
              <time :datetime="activityItem.dateTime" class="flex-none text-xs leading-5 text-gray-500 py-0.5">
                {{ activityItem.date }}
              </time>
            </template>
          </li>
        </ul>

        <!-- New comment form -->
        <div class="mt-6 flex gap-x-3">
          <img
            alt=""
            class="h-6 w-6 flex-none rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"/>
          <form action="#" class="relative flex-auto">
            <div
              class="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label class="sr-only" for="comment">Add your comment</label>
              <textarea id="comment" class="block w-full resize-none border-0 bg-transparent placeholder:text-gray-400 text-gray-900 py-1.5 focus:ring-0 sm:text-sm sm:leading-6" name="comment"
                        placeholder="Add your comment..."
                        rows="2"/>
            </div>

            <div class="absolute inset-x-0 bottom-0 flex justify-between py-2 pr-2 pl-3">
              <div class="flex items-center space-x-5">
                <div class="flex items-center">
                  <button class="flex h-10 w-10 items-center justify-center rounded-full text-gray-400 -m-2.5 hover:text-gray-500"
                          type="button">
                    <PaperClipIcon aria-hidden="true" class="h-5 w-5"/>
                    <span class="sr-only">Attach a file</span>
                  </button>
                </div>
                <div class="flex items-center">
                  <Listbox v-model="selected" as="div">
                    <ListboxLabel class="sr-only">Your mood</ListboxLabel>
                    <div class="relative">
                      <ListboxButton
                        class="relative flex h-10 w-10 items-center justify-center rounded-full text-gray-400 -m-2.5 hover:text-gray-500">
                <span class="flex items-center justify-center">
                  <span v-if="selected.value === null">
                    <FaceSmileIcon aria-hidden="true" class="h-5 w-5 flex-shrink-0"/>
                    <span class="sr-only">Add your mood</span>
                  </span>
                  <span v-if="!(selected.value === null)">
                    <span :class="[selected.bgColor, 'flex h-8 w-8 items-center justify-center rounded-full']">
                      <component :is="selected.icon" aria-hidden="true" class="h-5 w-5 flex-shrink-0 text-white"/>
                    </span>
                    <span class="sr-only">{{ selected.name }}</span>
                  </span>
                </span>
                      </ListboxButton>

                      <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                                  leave-to-class="opacity-0">
                        <ListboxOptions
                          class="absolute z-10 mt-1 -ml-6 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                          <ListboxOption v-for="mood in moods" :key="mood.value" v-slot="{ active }" :value="mood"
                                         as="template">
                            <li
                              :class="[active ? 'bg-gray-100' : 'bg-white', 'relative cursor-default select-none px-3 py-2']">
                              <div class="flex items-center">
                                <div :class="[mood.bgColor, 'flex h-8 w-8 items-center justify-center rounded-full']">
                                  <component :is="mood.icon" :class="[mood.iconColor, 'h-5 w-5 flex-shrink-0']"
                                             aria-hidden="true"/>
                                </div>
                                <span class="ml-3 block truncate font-medium">{{ mood.name }}</span>
                              </div>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                </div>
              </div>
              <button class="rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-2.5 py-1.5 hover:bg-gray-50"
                      type="submit">
                Comment
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <!-- Banner Image -->
  <div :class="bag ? 'mb-20' : ''" class="hidden h-full">
    <div class="mb-8 h-64 w-full overflow-hidden 2xl:h-96">
      <img :alt="fund.title" :src="fund.banner" class="h-full w-full object-cover">
    </div>

    <div
      class="mx-auto pb-12 dark:text-white sm:pr-8 sm:pl-8 xl:pl-12 2xl:max-w-[1200px] 3xl:max-w-[1500px] 3xl:pl-28 4xl:max-w-[1700px]">
      <div class="mb-6 flex items-center justify-center">
        <img v-if="fund.avatar" :alt="profile.username" :src="fund.avatar" class="mr-6 h-20 w-20 rounded-lg">
        <UserCircleIcon v-else class="mr-6 p-1 h-20 w-20 rounded-lg text-gray-400 dark:text-gray-600"/>
        <div class="flex flex-col pb-4 font-medium text-gray-900 dark:text-gray-50 md:pb-5">
                  <span v-if="profile.display_name" class="flex flex-row items-center">
                    <span class="text-lg font-bold line-clamp-1">{{ profile.display_name }}</span>
                    <a v-if="profile.verified" class="flex w-10 items-center p-2" @click="aboutVerified">
                      <CheckBadgeIcon aria-hidden="true" class="inline-block w-full text-youpay-teal"/>
                    </a>
                  </span>
          <a :href="route('profile.show', profile.username)">
            <span v-if="!profile.anon" class="text-md">{{ '@' + profile.username }}</span>
          </a>
          <span v-if="profile.pronouns" class="-mb-4 pt-1 text-xs text-gray-500 dark:text-gray-400">
            ({{ profile.pronouns }})
          </span>
        </div>
      </div>
      <div class="relative flex h-full w-full items-center justify-center">
        <div class="w-full max-w-2xl rounded-lg bg-gray-100 p-6 dark:bg-white/5">
          <div class="flex flex-row justify-between">
            <div></div>
            <div class="flex flex-row items-center gap-x-4 text-sm lg:text-md">
              <ActionDropdown v-if="owner" :items="[
                { name: 'Edit Fund', href: route('fund.edit', [profile.username, fund.url]) },
                { name: 'Delete Fund', href: route('fund.delete', [profile.username, fund.url]), method: 'DELETE' },
              ]"/>
            </div>
          </div>

          <div>
            <h1 class="mb-2 text-3xl font-bold">{{ fund.title }}</h1>
            <div class="mb-8 prose dark:prose-invert" v-html="cleanedHtml"></div>
          </div>
          <!-- Progress Information -->
          <div class="">
            <div class="mb-4 flex items-center justify-between px-1">
              <p class="text-2xl font-bold">My Goal: {{ fund.amount_formatted2 }}</p>
              <p class="text-sm text-gray-600 dark:text-gray-400">{{ fund.progress_formatted }} raised of
                {{ fund.amount_formatted }}</p>
            </div>

            <!-- Progress Bar -->
            <div class="mb-4 w-full rounded-full bg-gray-200 h-2.5 dark:bg-gray-700">
              <div :style="{ width: `${(fund.progress / fund.amount) * 100}%` }"
                   class="rounded-full bg-blue-600 h-2.5"></div>
            </div>

            <p class="mb-4 px-1">{{ Math.round((fund.progress / fund.amount) * 100 * 100) / 100 }}% funded</p>

            <YouPayMeNow
              v-if="fund.status === 'active'"
              :fund_id="fund.id"
              :profile="profile"
              class="mx-auto w-full max-w-[400px]"
            >
              <template #title>
                {{ fund.title }}
              </template>
              <template #description>
                Help {{ profile.username }} reach their goal by contributing to this fund.
              </template>
              <template #button>
                Contribute to this fund
              </template>
            </YouPayMeNow>
            <p v-else class="text-center font-semibold text-red-600">{{ statusText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GiftBagBar :bag="bag" :profile="profile"/>
</template>

<script lang="ts" setup>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import {
  UsersIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon as XMarkIconMini,
} from '@heroicons/vue/20/solid'
import {CheckCircleIcon} from '@heroicons/vue/24/solid'

const activity = [
  {id: 1, type: 'created', person: {name: 'Chelsea Hagon'}, date: '7d ago', dateTime: '2023-01-23T10:32'},
  {id: 2, type: 'edited', person: {name: 'Chelsea Hagon'}, date: '6d ago', dateTime: '2023-01-23T11:03'},
  {id: 3, type: 'sent', person: {name: 'Chelsea Hagon'}, date: '6d ago', dateTime: '2023-01-23T11:24'},
  {
    id: 4,
    type: 'commented',
    person: {
      name: 'Chelsea Hagon',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    comment: 'Called client, they reassured me the invoice would be paid by the 25th.',
    date: '3d ago',
    dateTime: '2023-01-23T15:56',
  },
  {id: 5, type: 'viewed', person: {name: 'Alex Curren'}, date: '2d ago', dateTime: '2023-01-24T09:12'},
  {id: 6, type: 'paid', person: {name: 'Alex Curren'}, date: '1d ago', dateTime: '2023-01-24T09:20'},
]
const moods = [
  {name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500'},
  {name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400'},
  {name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400'},
  {name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400'},
  {name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500'},
  {name: 'I feel nothing', value: null, icon: XMarkIconMini, iconColor: 'text-gray-400', bgColor: 'bg-transparent'},
]

const selected = ref(moods[5])


import {Head, Link, router} from "@inertiajs/vue3";
import ProfileLayout from "@/web/youpay-me-v3/Layouts/ProfileLayout.vue";
import {onMounted, ref, inject, computed,} from 'vue'
import {Profile, User} from "@/web/youpay-me-v3/types";
import {marked} from "marked";
import DOMPurify from "dompurify";
import {Bag} from "@/web/youpay-me-v3/types/checkout";
import GiftBagBar from "@/web/youpay-me-v3/Components/Gifting/GiftBagBar.vue";
import ShareUrl from "@/web/youpay-me-v3/Components/Blocks/ShareUrl.vue";
import ActionDropdown from "@/web/youpay-me-v3/Components/Buttons/ActionDropdown.vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import YouPayMeNow from "@/web/youpay-me-v3/Components/Buttons/YouPayMeNow.vue";
import {CheckBadgeIcon} from "@heroicons/vue/20/solid";
import {PencilIcon, UserCircleIcon} from "@heroicons/vue/24/outline";

// defineOptions({ layout: ProfileLayout })

// Props
const props = defineProps<{
  profile: Profile,
  owner: boolean,
  anon: boolean,
  bag: Bag,
  auth: User,
  fund: any,
}>();

const statusText = computed(() => {
  if (props.fund.status === 'soldout') {
    return 'Someone has already paid for this gift!';
  }

  if (props.fund.status === 'processing') {
    return 'Payment Processing';
  }
  if (props.fund.status === 'expired') {
    return 'Expired';
  }
  if (props.fund.status === 'cancelled') {
    return 'Cancelled';
  }
  if (props.fund.status === 'disabled') {
    return 'Disabled';
  }
  if (props.fund.status === 'active') {
    return 'There is an error with this fund.';
  }
});

const cleanedHtml = computed(() => {
  if (props.fund && props.fund.description && props.fund.description.length > 0) {
    const dirty = marked.parse(props.fund.description);
    const clean = DOMPurify.sanitize(dirty, {
      FORBID_TAGS: ['style', 'a'],
      ALLOW_DATA_ATTR: false,
      ALLOW_ARIA_ATTR: false
    });
    return clean;
  }
  return '';
});

onMounted(async () => {
  trackEvent('view_fund', {
    currency: 'USD',
    value: props.fund.amount,
    items: [
      {
        item_id: props.fund.id,
        item_name: props.fund.title,
        item_category: props.fund.type,
        item_variant: props.fund.type,
        price: props.fund.amount,
        quantity: 1,
        currency: props.fund.currency,
      }
    ]
  })
});
</script>
