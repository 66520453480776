<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed top-10 z-50 inset-0 flex px-4 py-6 items-start sm:p-6 lg:p-10">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-500 transition"
        enter-from-class="translate-x-full opacity-0"
        enter-to-class="translate-x-0 opacity-100"
        leave-active-class="transform transition ease-in duration-400"
        leave-from-class="translate-x-0 opacity-100"
        leave-to-class="translate-x-full opacity-0"
      >
        <div v-if="show && notification" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-sm shadow-lg dark:shadow-gray-800 ring-1" :class="bgColor">
          <div class="p-4">
            <div class="flex flex-row">
              <div class="flex-shrink-0">
                <component :is="notification.icon" class="h-6 w-6 lg:w-7 lg:h-7 text-gray-400" aria-hidden="true" :class="iconColor" />
              </div>
              <div class="ml-3 w-0 flex-1 flex-col flex justify-center " :class="textColor">
                <p class="text-sm lg:text-base font-medium lg:font-normal">{{ notification.title }}</p>
                <p v-if="notification.message" class="mt-1 text-sm">{{ notification.message }}</p>
              </div>
              <div class="ml-4 flex items-center flex-shrink-0">
                <button type="button" @click="show = false" class="inline-flex rounded-md p-1 bg-white/20 dark:bg-white/[0.05] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" :class="closeIconColor">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CheckCircleIcon, InformationCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import {computed, onMounted, ref, watch} from 'vue';
import {usePage} from "@inertiajs/vue3";

const show = ref(false);

watch(show, (val) => {
  setTimeout(() => {
      show.value = false;
    }, 10000);
});


const notification = computed(() => {
  let {success, error, notif} = usePage().props.flash;

  if (success) {
    show.value = true;
    return {
      icon: CheckCircleIcon,
      level: 'success',
      title: success
    };
  } else if (error) {
    show.value = true;
    return {
      icon: ExclamationCircleIcon,
      level: 'error',
      title: error
    };
  } else if (notif) {
    notif.icon = InformationCircleIcon;
    show.value = true;
    return notif;
  }
  return null;
});

const bgColor = computed(() => {
  if (notification.value?.level === 'success') {
    return 'dark:bg-gray-100 bg-gray-900 dark:ring-lime-400 ring-green-700 border-l-4 border-green-500 dark:border-green-600';
  } else if (notification.value?.level === 'error') {
    return 'dark:bg-red-50 bg-gray-900 dark:ring-red-300 ring-red-700 border-l-4 border-red-500 dark:border-red-600';
  }
  return 'dark:bg-gray-100 bg-gray-700 ring-gray-300 dark:ring-black';
});

const textColor = computed(() => {
  if (notification.value?.level === 'success') {
    return 'dark:text-black/95 text-green-100';
  } else if (notification.value?.level === 'error') {
    return 'dark:text-black/95 text-red-50';
  }
  return 'dark:text-black/95 text-gray-100';
});

const iconColor = computed(() => {
  if (notification.value?.level === 'success') {
    return 'dark:text-green-600 text-green-400';
  } else if (notification.value?.level === 'error') {
    return 'dark:text-red-600 text-red-400';
  }
  return 'text-gray-400 dark:text-gray-600';
});

const closeIconColor = computed(() => {
  if (notification.value?.level === 'success') {
    return 'dark:text-black/95 text-green-100';
  } else if (notification.value?.level === 'error') {
    return 'dark:text-red-900 text-red-50';
  }
  return 'text-gray-200 dark:text-gray-900';
});

watch(() => notification, () => {
  show.value = true;
}, {deep: true});

onMounted(() => {
  if (notification.value?.title) {
    show.value = true;
  }
});
</script>