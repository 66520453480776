<template>
  <section v-if="getSocials.length !== 0 || owner" class="mt-2 md:mt-8">
    <h2 class="hidden md:flex items-center font-mono text-sm font-medium leading-7 text-slate-900 dark:text-white">
      <svg aria-hidden="true" viewBox="0 0 10 10" class="h-2.5 w-2.5">
        <path d="M0 5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5Z" class="fill-indigo-300">
        </path>
        <path d="M6 1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V1Z" class="fill-blue-300">
        </path>
      </svg>
      <span class="ml-2.5">
        Where to follow me
      </span>
    </h2>
    <Link
      v-if="owner && view !== 'min' && getSocials.length === 0"
      :href="route('settings.socials')"
      class="text-xs text-youpay-navy hover:text-youpay-navy-light dark:text-youpay-pink">
      {{ getSocials.length === 0 ? 'Add your social links' : 'Update your socials' }}
    </Link>
    <ul role="list" class="mt-2 flex justify-center flex-row gap-3 text-base font-medium leading-7 text-slate-700 dark:text-white md:flex-col sm:flex-wrap sm:gap-4">
      <li v-for="social in getSocials" class="flex">
        <a class="flex items-center group p-3 md:p-0" :aria-label="social.title"
           :href="social.url"
           target="_blank"
           @click="trackEvent('social', {
             username: profile.username,
             url: social.url
           })"
        >
          <component :is="social.icon" class="h-4 w-4 fill-slate-400 group-hover:fill-slate-600 sm:h-6 sm:w-6" />
          <span class="ml-3 sm:ml-4 hidden sm:block">
            {{ social.title }}
          </span>
        </a>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import {Link} from "@inertiajs/vue3";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import {computed} from "vue";
import {LinkIcon} from "@heroicons/vue/20/solid";
import {FacebookIcon, InstagramIcon, OnlyfansIcon, TiktokIcon, TwitchIcon, TwitterIcon} from "vue3-simple-icons";
import TwitterXIcon from "@/web/youpay-me-v3/Icons/TwitterXIcon.vue";
import {Profile} from "@/web/youpay-me-v3/types";

const props = defineProps<{
  view: string;
  owner: boolean;
  profile: Profile;
}>();

const socials: Array<{
  title: string;
  icon: any;
  slug: string;
}> = [
  {
    title: 'Facebook',
    icon: FacebookIcon,
    slug: 'facebook_url',
  },
  {
    title: 'Instagram',
    icon: InstagramIcon,
    slug: 'instagram_url',
  },
  {
    title: 'X / Twitter',
    icon: TwitterXIcon,
    slug: 'twitter_url',
  },
  {
    title: 'Twitch',
    icon: TwitchIcon,
    slug: 'twitch_url',
  },
  {
    title: 'Tiktok',
    icon: TiktokIcon,
    slug: 'tiktok_url',
  },
  {
    title: 'OnlyFans',
    icon: OnlyfansIcon,
    slug: 'only_fans',
  },
  // 'custom_urls' => 'nullable|array',
  // 'custom_urls.*.url' => 'nullable|string|max:255|regex:/^https?:\/\/.*/',
  // 'custom_urls.*.title' => 'required_if:custom_urls.*.url,!=,|string|max:255',
];

const getSocials = computed(() => {
  const socialsArray = [];
  for (const social of socials) {
    if (props.profile.socials[social.slug]) {
      socialsArray.push({
        title: social.title,
        icon: social.icon,
        url: props.profile.socials[social.slug],
      });
    }
  }
  if (props.profile.socials.custom_urls) {
    for (const custom of props.profile.socials.custom_urls) {
      socialsArray.push({
        title: custom.title,
        icon: LinkIcon,
        url: custom.url,
      });
    }
  }
  return socialsArray;
});

</script>
