<template>
  <footer class="border-t dark:border-white/10 relative bg-white dark:bg-white/5 pb-16">
    <div class="mx-auto max-w-7xl overflow-hidden px-6 lg:px-8 py-12">
      <nav class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
        <div v-for="item in navigation.main" :key="item.name" class="pb-6">
          <a v-if="item.href" :href="item.href" :target="item.target ?? '_blank'" class="text-sm leading-6 text-gray-400 hover:text-gray-500">{{ item.name }}</a>
          <a v-else @click.prevent="item.action" class="text-sm leading-6 text-gray-400 hover:text-gray-500 cursor-pointer">{{ item.name }}</a>
        </div>
      </nav>
      <div class="mt-10 flex justify-center space-x-10">
        <a v-for="item in navigation.social" :key="item.name" :href="item.href" target="_blank" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">{{ item.name }}</span>
          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
        </a>
      </div>
      <p class="mt-10 text-center text-xs leading-5 text-gray-500">&copy; {{ year }} YouPay Pty Ltd. All rights reserved.</p>
    </div>
  </footer>
</template>

<script setup>
import {defineComponent, h, inject} from 'vue'

const year = new Date().getFullYear()

const feedback = inject('feedback');

const openFeedback = async () => {
  const form = await feedback.createForm();
  form.appendToDom();
  form.open();
}

const navigation = {
  main: [
    { name: 'Report', href: route('report-profile'), target: '_self' },
    { name: 'Feedback', action: openFeedback },
    { name: 'Support', href: 'https://support.youpay.co/knowledge' },
    { name: 'FAQ', href: 'https://youpay.co/help/' },
    { name: 'About', href: 'https://youpay.co/about/' },
    { name: 'Contact', href: 'https://youpay.co/contact/' },
    { name: 'Terms & Privacy', href: 'https://youpay.co/terms/' },
  ],
  social: [
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/youpay.official/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/youpay_official',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d: 'M13.308 10.469 20.38 2h-1.676l-6.142 7.353L7.658 2H2l7.418 11.12L2 22h1.676l6.486-7.765L15.342 22H21l-7.693-11.531Zm-2.296 2.748-.752-1.107L4.28 3.3h2.575l4.826 7.11.751 1.107 6.273 9.242h-2.574l-5.12-7.541Z',
            }),
          ]),
      }),
    },
    {
      name: 'Tiktok',
      href: 'https://www.tiktok.com/@lifewithyoupay',
      icon: defineComponent({
        render: () =>
          //<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.796 14.523a9.848 9.848 0 0 1-5.756-1.84v8.38a7.622 7.622 0 1 1-6.575-7.553v4.215a3.499 3.499 0 1 0 2.448 3.337V4.681h4.127c-.003.348.026.696.087 1.04a5.726 5.726 0 0 0 2.527 3.76 5.694 5.694 0 0 0 3.142.945v4.097Z" fill="#2D2D2D"></path></svg>
          h('svg', { fill: 'currentColor', viewBox: '0 0 33 33' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M27.796 14.523a9.848 9.848 0 0 1-5.756-1.84v8.38a7.622 7.622 0 1 1-6.575-7.553v4.215a3.499 3.499 0 1 0 2.448 3.337V4.681h4.127c-.003.348.026.696.087 1.04a5.726 5.726 0 0 0 2.527 3.76 5.694 5.694 0 0 0 3.142.945v4.097Z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
  ],
}
</script>
