<template>
  <Head title="Add a Gift" />
  <form @submit.prevent>
    <div class="flex flex-row justify-between items-end border-b border-gray-300 dark:border-gray-600 ">
      <h1 class="text-3xl font-semibold pb-4 flex-grow">
        Add a gift to your wishlist
      </h1>
      <StoreLogo :store="store" class="w-[150px] flex-grow-0" />
    </div>

    <p class="pt-6 pb-2 text-sm md:text-base md:tracking-tight">
      Fill in the fields below and we’ll give you a shareable payment link. Your shipping details will remain private.
    </p>

    <h2 id="contact-info-heading" class="mb-4 pt-4 text-lg font-medium text-gray-900 dark:text-gray-50">Contact information</h2>

    <div class="col-span-full">
      <InputLabel for="email" value="Email address" />

      <TextInput
        id="email"
        type="email"
        class="mt-1 block w-full"
        v-model="form.email"
        @change="form.validate('email')"
        :error="form.errors.email"
        required
        autocomplete="email"
      />

      <InputError class="mt-2" :message="form.errors.email"/>
    </div>

    <h2 id="shipping-info-heading" class="pt-6 pb-4 text-lg font-medium text-gray-900 dark:text-gray-50">Shipping information</h2>

    <div id="address-element">
      <!-- Stripe Elements Placeholder -->
    </div>

    <h2 @click="showAdvanced = !showAdvanced" class="pt-6 pb-4 flex cursor-pointer justify-between text-lg font-medium text-gray-900 dark:text-gray-50">
      <span>
        Customise the appearance
      </span>
      <span class="text-sm font-semibold leading-7 text-gray-600 dark:text-gray-200 cursor-pointer">
        <ChevronDownIcon class="h-5 w-5" aria-hidden="true"
          :class="showAdvanced ? 'transform rotate-180' : ''"
        />
      </span>
    </h2>

    <div v-if="showAdvanced" class="pt-6">
      <Wish
        class="relative"
        v-model="form.details"
        :errors="form.errors"
        error-key="details"
        :index="0"
        :currency="cart.currency"
        :showFetch="false"
        :showAmount="false"
      />
    </div>

    <div class="pt-6" :class="auth ? '' : 'hidden md:block'">
      <WishlistSelector v-model="form.wishlist" :wishlists="wishlists" :selected_wishlist="selected_wishlist">
        <legend class="pb-1 text-lg font-medium text-gray-900 dark:text-gray-50 block">
          Wishlist settings <span v-if="!auth" class="pl-4 text-xs text-gray-500">Only for logged in users</span>
        </legend>
      </WishlistSelector>
    </div>

    <div v-if="submitted && form.hasErrors" class="mt-6 text-sm text-red-600 dark:text-red-400">
      Looks like there are some errors. Once you've fixed them, press the "Continue to shipping" button.
    </div>

    <button
      type="button"
      :disabled="form.processing"
      @click.prevent="submit"
      class="mt-8 w-full rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-youpay-navy-light hover:bg-youpay-navy-alt focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      :class="form.processing ? 'opacity-50 cursor-not-allowed' : ''"
    >
      <span v-if="!form.processing">
        Calculate shipping options
      </span>
      <span v-else>
        Please wait...
      </span>
    </button>
  </form>
</template>

<script setup lang="ts">
// Imports
import {Head, usePage} from "@inertiajs/vue3";
import {onMounted, ref} from 'vue'
import {
  StripeAddress,
  AutoFill,
  Cart
} from "@/web/youpay-me-v3/types/checkout";
import {GiftDetails, Profile, User} from "@/web/youpay-me-v3/types";
import { useForm } from 'laravel-precognition-vue-inertia';
import {
  setupStripeElements,
  setupAddressElement,
  getStripeAddress,
  addAddressWatcher
} from "@/web/youpay-me-v3/stripe";
import WishlistSelector from "@/web/youpay-me-v3/Components/Gifting/WishlistSelector.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import Wish from "@/web/youpay-me-v3/Components/Gifting/Wish.vue";
import {ChevronDownIcon} from "@heroicons/vue/24/outline";
import StoreLogo from "@/web/youpay-me-v3/Components/Blocks/StoreLogo.vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import ShopifyLayout from "@/web/youpay-me-v3/Layouts/ShopifyLayout.vue";

// Basic Variables
const submitted = ref(false);
const showAdvanced = ref(false);

// Get Props
const props = defineProps<{
  auth: User,
  profile: Profile,
  selected_wishlist: string,
  wishlists: any,
  cart: Cart,
  details?: GiftDetails
  addresses?: Array<StripeAddress>,
  gift: any,
  autofill?: AutoFill,
  store: any,
  product: any,
}>();

// Setup the form
const form = useForm(
  'post',
  route('gift.shopify.update', props.gift.id
), {
  name: props.cart.shopper?.name ?? props.autofill?.name ?? null,
  email: props.cart.shopper?.email ?? props.autofill?.email ?? null,
  phone: props.cart.shopper?.phone ?? props.autofill?.phone ?? null,
  address: {
    line1: props.cart.shopper?.address?.address_1 ?? null,
    line2: props.cart.shopper?.address?.address_2 ?? null,
    city: props.cart.shopper?.address?.suburb ?? null,
    state: props.cart.shopper?.address?.state_code ?? null,
    postal_code: props.cart.shopper?.address?.postcode ?? null,
    country: props.cart.shopper?.address?.country_code ?? null,
  },
  wishlist: props.autofill?.wishlist ?? '',
  details: <GiftDetails>{
    title: props.details?.title ?? '',
    image: props.details?.image ?? '',
    quantity: props.details?.quantity ?? 1,
    amount: props.details?.amount ?? 0,
    description: props.details?.description ?? '',
  },
});

const watchAddress = function(details) {
  const data = details.value;
  form.name = data.name;
  form.phone = data.phone;
  form.address = {
    line1: data.address.line1,
    line2: data.address.line2,
    city: data.address.city,
    state: data.address.state,
    postal_code: data.address.postal_code,
    country: data.address.country,
  };
}

const submit = async function () {
  submitted.value = true;
  form.clearErrors();
  form.touch(['email']).validate();
  getStripeAddress();
  if (form.hasErrors) {
    return;
  }
  form.transform(
    (data: Object) => ({
      ...data,
      ...form
    })
  )
    .submit({
    preserveScroll: false,
    onSuccess: (page) => {
      console.log('success', page)
      trackEvent('gift_create_shipping', {
        product: props.product.id,
        type: props.product.type,
        store: props.store.title,
      });
    }
  });
}

const stripeKey = usePage().props.stripe.key;

/**
 * Load Stripe
 */
onMounted(async () => {
  await setupStripeElements({
    key: stripeKey
  });
  console.log(props.addresses);
  setupAddressElement(props.autofill ? {
    firstName: props.autofill.firstName ?? '',
    lastName: props.autofill.lastName ?? '',
    phone: props.autofill.phone ?? '',
    address: {
      country: props.autofill.country ?? '',
    }
  } : null, props.addresses);

  addAddressWatcher(watchAddress);

  trackEvent('gift_create_begin', {
    product: props.product.id,
    type: props.product.type,
    store: props.store.title,
  });
});

defineOptions({layout: ShopifyLayout})
</script>