<template>
  <div v-if="user" class="mt-2 mb-4 flex flex-row justify-between text-sm font-light">
    <span>Welcome back <span class="font-semibold text-youpay-navy dark:text-youpay-pink">{{ user.username }}</span></span>
    <span>Not you? <Link :href="route('checkout.logout', bag_id)" class="font-semibold text-youpay-navy dark:text-youpay-pink cursor-pointer" >Log Out</Link></span>
  </div>

  <div v-else class="mt-2 mb-4">
    <div class="flex flex-row gap-x-4 text-sm font-light">
      <span>Already have an account?</span>
      <a @click="showLoginField" class="font-semibold text-youpay-navy dark:text-youpay-pink cursor-pointer" >Log in</a>
    </div>
    <div v-if="showLogin" class="pt-4 pb-10 mb-8 border-y mt-4">
      <form class="space-y-6" @submit.prevent="submitLogin">
        <h2 class="font-semibold text-xl">Your Login Details</h2>
        <div>
          <InputLabel for="email" value="Email address" class="block font-medium text-gray-900 dark:text-white"/>

          <div class="mt-2">
            <TextInput
              id="email"
              type="email"
              class="mt-1 block w-full"
              v-model="loginForm.email"
              required
              autofocus
              autocomplete="username"
            />
          </div>

          <InputError class="mt-2" :message="loginForm.errors.email"/>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <InputLabel for="password" value="Password" class="block font-medium text-gray-900 dark:text-white"/>
            <div class="text-sm">
              <Link :href="route('password.request')" target="_blank" class="font-semibold text-youpay-navy dark:text-youpay-pink hover:text-youpay-navy dark:hover:text-pink-400">
                Forgot password?
              </Link>
            </div>
          </div>

          <div class="mt-2">
            <TextInput
              id="password"
              type="password"
              class="mt-1 block w-full"
              v-model="loginForm.password"
              required
              autocomplete="current-password"
            />
          </div>

          <InputError class="mt-2" :message="loginForm.errors.password"/>
        </div>

        <div>
          <PrimaryButton
            class="w-full"
            :class="{ 'opacity-25': loginForm.processing }" :disabled="loginForm.processing"
          >
            Sign in
          </PrimaryButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">

import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import {Link, usePage} from "@inertiajs/vue3";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import {User} from "@/web/youpay-me-v3/types";
import {useForm} from "laravel-precognition-vue-inertia";
import {ref} from "vue";

const props = defineProps<{
  bag_id: number
}>();

const user = <User>usePage().props.auth;

const loginForm = useForm('post', route('checkout.login', props.bag_id), {
  email: '',
  password: '',
});

const showLogin = ref(false);
const showLoginField = function () {
  showLogin.value = !showLogin.value;
}

const submitLogin = async function (e) {
  e.preventDefault();
  loginForm.processing = true;
  loginForm.submit({
    onSuccess: () => {
      location.reload();
    },
    onError: (error) => {
      console.error(error);
      loginForm.setError('error', 'There was an error with that request, please try again.');
    }
  });
}
</script>