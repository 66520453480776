import { ref } from 'vue';

const currentTheme = ref<string>('system');
const matcher = window.matchMedia('(prefers-color-scheme: dark)');
let listener: ((e: MediaQueryListEvent) => void) | null = null;

const applyColorScheme = (selectedTheme?: string) => {
    let appliedTheme = selectedTheme ? selectedTheme : currentTheme.value;

    if (appliedTheme === 'system') {
        appliedTheme = matcher.matches ? 'dark' : 'light';
    }

    if (appliedTheme === 'light') {
        document.documentElement.classList.remove('dark');
    }

    if (appliedTheme === 'dark') {
        document.documentElement.classList.add('dark');
    }
};

const initTheme = () => {
    if (localStorage.youpayTheme) {
        currentTheme.value = localStorage.youpayTheme;
    }
    applyColorScheme();

    listener = (e) => {
        if (currentTheme.value === 'system') {
            applyColorScheme();
        }
    };
    matcher.addEventListener('change', listener);
};

const cleanupTheme = () => {
    if (listener) {
        matcher.removeEventListener('change', listener);
    }
};

const setTheme = (theme: string) => {
    currentTheme.value = theme;
    localStorage.youpayTheme = theme;
    applyColorScheme(theme);
};

export { currentTheme, initTheme, cleanupTheme, setTheme };