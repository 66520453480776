<template>
  <Head title="Log in with Email"/>

  <div
    class="bg-white dark:bg-gray-700 md:bg-[url('/images/splash/bg-light.png')] md:dark:bg-[url('/images/splash/bg-dark.png')] bg-cover w-full h-full flex-grow bg-right relative flex md:items-center">
    <div
      class="inset-0 z-10 relative flex flex-col md:flex-row items-center h-full flex-grow overflow-y-auto gap-x-2 lg:gap-x-6">
      <div class="hidden md:block w-3/6 md:w-2/5 px-6 xl:w-3/5">
        <div class="gap-y-6 flex flex-col sm:mx-auto sm:w-full sm:max-w-md">
          <h2
            class="mt-6 leading-[2rem] lg:leading-[3rem] text-[42px] lg:text-[62px] font-semibold text-gray-900 dark:text-white flex flex-col gap-y-6">
            <span>The wishlist</span>
            <span>for everyone</span>
          </h2>
          <p class="mt-4 text-xl text-gray-600 dark:text-gray-200 font-light">
            Share what you want, and let someone else pay, without sharing your personal details.
          </p>
        </div>
      </div>
      <div class="flex flex-1 flex-col justify-center md:px-6">
        <div class="bg-white dark:bg-gray-700 md:dark:bg-gray-800 mx-auto md:rounded-lg px-6 py-12 lg:px-16">
          <div class="sm:mx-auto sm:w-full sm:max-w-lg text-center">
            <h1 class="lg:mt-0 text-2xl leading-9 font-semibold text-gray-900 dark:text-white">
              Email me a login link
            </h1>
          </div>

          <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" @submit.prevent="submit">
              <div>
                <InputLabel for="email" value="Email address"
                            class="block font-medium text-gray-900 dark:text-white"/>

                <div class="mt-2">
                  <TextInput
                    id="email"
                    type="email"
                    class="mt-1 block w-full"
                    v-model="form.email"
                    required
                    autofocus
                    autocomplete="username"
                  />
                </div>

                <InputError class="mt-2" :message="form.errors.email"/>
              </div>

              <div>
                <PrimaryButton
                  class="w-full"
                  :class="{ 'opacity-25': form.processing }" :disabled="form.processing || success"
                >
                  {{ success ? 'Check your inbox!' : 'Send me a magic link' }}
                </PrimaryButton>
              </div>

              <div class="mt-15 text-xs sm:mx-auto dark:text-white w-full text-center">
                Remember your password?
                <Link :href="route('login')" class="pl-1 text-youpay-navy dark:text-youpay-pink">Click here to
                  login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import InputError from '../../Components/Form/InputError.vue';
import InputLabel from '../../Components/Form/InputLabel.vue';
import PrimaryButton from '../../Components/Buttons/PrimaryButton.vue';
import TextInput from '../../Components/Form/TextInput.vue';
import {Head, Link, useForm} from '@inertiajs/vue3';
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import {ref} from "vue";
import GuestLayout from "@/web/youpay-me-v3/Layouts/GuestLayout.vue";

defineOptions({layout: GuestLayout});

const success = ref(false);

const form = useForm({
  email: '',
});

const submit = () => {
  form.post(route('email.login'), {
    onSuccess: () => {
      success.value = true;
      trackEvent('login', {
        method: 'email',
      });
    },
  });
};
</script>
