<template>
  <Head title="Edit Profile" />
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
          <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Personal Information</h2>
          <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Use a permanent address where you can receive mail.</p>
      </div>

      <form class="md:col-span-2">
          <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div class="col-span-full flex items-center gap-x-8">
                  <img :src="user.avatar" alt="" class="h-24 w-24 flex-none rounded-lg dark:bg-gray-800 bg-gray-200 object-cover" />
                  <div>
                      <button type="button" class="rounded-md dark:bg-white/10 bg-gray-200 px-3 py-2 text-sm font-semibold dark:text-white text-gray-700 shadow-sm hover:dark:bg-white/20 hover:bg-gray-300">Change avatar</button>
                      <p class="mt-2 text-xs leading-5 dark:text-gray-400 text-gray-600">JPG, GIF or PNG. 1MB max.</p>
                  </div>
              </div>

              <div class="sm:col-span-3">
                  <label for="first-name" class="block text-sm font-medium leading-6 dark:text-white text-gray-700">First name</label>
                  <div class="mt-2">
                      <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 dark:bg-white/5 bg-gray-200 py-1.5 dark:text-white text-gray-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                  </div>
              </div>

              <div class="sm:col-span-3">
                  <label for="last-name" class="block text-sm font-medium leading-6 dark:text-white text-gray-700">Last name</label>
                  <div class="mt-2">
                      <input type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 dark:bg-white/5 bg-gray-200 py-1.5 dark:text-white text-gray-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                  </div>
              </div>

              <div class="col-span-full">
                  <label for="email" class="block text-sm font-medium leading-6 dark:text-white text-gray-700">Email address</label>
                  <div class="mt-2">
                      <input id="email" name="email" type="email" autocomplete="email" class="block w-full rounded-md border-0 dark:bg-white/5 bg-gray-200 py-1.5 dark:text-white text-gray-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                  </div>
              </div>

              <div class="col-span-full">
                  <label for="username" class="block text-sm font-medium leading-6 dark:text-white text-gray-700">Username</label>
                  <div class="mt-2">
                      <div class="flex rounded-md dark:bg-white/5 bg-gray-200 ring-1 ring-inset dark:ring-white/10 ring-gray-200 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                          <span class="flex select-none items-center pl-3 dark:text-gray-400 text-gray-600 sm:text-sm">example.com/</span>
                          <input type="text" name="username" id="username" autocomplete="username" class="flex-1 border-0 bg-transparent py-1.5 pl-1 dark:text-white text-gray-700 focus:ring-0 sm:text-sm sm:leading-6" placeholder="janesmith" />
                      </div>
                  </div>
              </div>

              <div class="col-span-full">
                  <label for="timezone" class="block text-sm font-medium leading-6 dark:text-white text-gray-700">Timezone</label>
                  <div class="mt-2">
                      <select id="timezone" name="timezone" class="block w-full rounded-md border-0 dark:bg-white/5 bg-gray-200 py-1.5 dark:text-white text-gray-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black">
                          <option>Pacific Standard Time</option>
                          <option>Eastern Standard Time</option>
                          <option>Greenwich Mean Time</option>
                      </select>
                  </div>
              </div>
          </div>

          <div class="mt-8 flex">
              <button type="submit" class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-youpay-teal">Save</button>
          </div>
      </form>
  </div>

  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Change password</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Update your password associated with your account.</p>
    </div>

    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full">
          <label for="current-password" class="block text-sm font-medium leading-6 dark:text-white text-gray-700">Current password</label>
          <div class="mt-2">
            <input id="current-password" name="current_password" type="password" autocomplete="current-password" class="block w-full rounded-md border-0 dark:bg-white/5 bg-gray-200 py-1.5 dark:text-white text-gray-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
          </div>
        </div>

        <div class="col-span-full">
          <label for="new-password" class="block text-sm font-medium leading-6 dark:text-white text-gray-700">New password</label>
          <div class="mt-2">
            <input id="new-password" name="new_password" type="password" autocomplete="new-password" class="block w-full rounded-md border-0 dark:bg-white/5 bg-gray-200 py-1.5 dark:text-white text-gray-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
          </div>
        </div>

        <div class="col-span-full">
          <label for="confirm-password" class="block text-sm font-medium leading-6 dark:text-white text-gray-700">Confirm password</label>
          <div class="mt-2">
            <input id="confirm-password" name="confirm_password" type="password" autocomplete="new-password" class="block w-full rounded-md border-0 dark:bg-white/5 bg-gray-200 py-1.5 dark:text-white text-gray-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
          </div>
        </div>
      </div>

      <div class="mt-8 flex">
        <button type="submit" class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-youpay-teal">Save</button>
      </div>
    </form>
  </div>

  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Log out other sessions</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Please enter your password to confirm you would like to log out of your other sessions across all of your devices.</p>
    </div>

    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full">
          <label for="logout-password" class="block text-sm font-medium leading-6 dark:text-white text-gray-700">Your password</label>
          <div class="mt-2">
            <input id="logout-password" name="password" type="password" autocomplete="current-password" class="block w-full rounded-md border-0 dark:bg-white/5 bg-gray-200 py-1.5 dark:text-white text-gray-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
          </div>
        </div>
      </div>

      <div class="mt-8 flex">
        <button type="submit" class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-youpay-teal">Log out other sessions</button>
      </div>
    </form>
  </div>

  <div class="">
    <DeleteUserForm />
  </div>
</template>

<script setup lang="ts">
import MainLayout from '../../Layouts/MainLayout.vue';
import DeleteUserForm from './Partials/DeleteUserForm.vue';
import UpdatePasswordForm from './Partials/UpdatePasswordForm.vue';
import UpdateProfileInformationForm from './Partials/UpdateProfileInformationForm.vue';
import {Head, usePage} from '@inertiajs/vue3';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const user = usePage().props.auth;
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
  { name: 'Reports', href: '#', current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

defineProps<{
  mustVerifyEmail?: boolean;
  status?: string;
}>();

import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";

defineOptions({ layout: SettingsLayout })
</script>