<template>
  <div>
    <button @click="toggle" class="btn btn-primary">
      <slot />
    </button>

    <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
      <Dialog class="relative z-10" @close="toggle">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <DialogPanel class="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-20 overflow-hidden rounded-xl bg-gray-900 shadow-2xl transition-all">
              <Combobox @update:modelValue="onSelect">
                <div class="relative">
                  <MagnifyingGlassIcon class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                  <ComboboxInput class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-white focus:ring-0 sm:text-sm" placeholder="Add a tag..." @change="query = $event.target.value" />
                </div>

                <ComboboxOptions static as="ul" class="max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-20 overflow-y-auto">
                  <li class="p-2">
                    <h2 v-if="query === ''" class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-200">Available Tags</h2>
                    <ul class="text-sm text-gray-400">
                      <ComboboxOption v-for="(tag, index) in filteredTags" :key="index" :value="tag" as="template" v-slot="{ active }">
                        <li :class="['flex cursor-default select-none items-center rounded-md px-3 py-2', active && 'bg-gray-800 text-white']">
                          <TagIcon v-if="!tag.isNew" :class="['h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-500']" aria-hidden="true" />
                          <PlusCircleIcon v-else :class="['h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-500']" aria-hidden="true" />
                          <span class="ml-3 flex-auto truncate">{{ tag.isNew ? `Create "${tag.name}"` : tag.name }}</span>
                          <span v-if="active" class="ml-3 flex-none text-gray-400">{{ tag.isNew ? 'Create' : 'Add' }}</span>
                        </li>
                      </ComboboxOption>
                    </ul>
                  </li>
                </ComboboxOptions>
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { MagnifyingGlassIcon, PlusCircleIcon } from '@heroicons/vue/20/solid'
import { TagIcon } from '@heroicons/vue/24/outline'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { useForm } from "laravel-precognition-vue-inertia";

const props = defineProps<{
  order_id: number;
  availableTags: string[];
}>()

const open = ref<boolean>(false);
const query = ref<string>('');

const filteredTags = computed(() => {
  const lowercaseQuery = query.value.toLowerCase();
  const filtered = props.availableTags
    .filter((tag) => tag.toLowerCase().includes(lowercaseQuery))
    .map((tag) => ({ name: tag, isNew: false }));

  if (query.value && !filtered.some((tag) => tag.name.toLowerCase() === lowercaseQuery)) {
    filtered.push({ name: query.value, isNew: true });
  }

  return filtered;
})

const form = useForm('post', route('umpire.order.tag', props.order_id), {
  tag: ''
});

const toggle = () => {
  open.value = !open.value;
}

function onSelect(item) {
  if (item) {
    form.tag = item.name;
    form.submit({
      onSuccess: () => {
        open.value = false;
        query.value = '';
      }
    });
  }
}
</script>