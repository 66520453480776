<template>
  <Head title="Payment Settings" />

  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <img src="@/web/youpay-me-v3/Icons/coinflow.png" class="p-1 h-15 hidden dark:block">
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        {{ coinflow_setup ? 'Coinflow Dashboard' : 'Setup Coinflow' }}
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        {{ coinflow_setup ? 'View all of your recent coinflow orders' : 'Setup a Coinflow account to receive gifts' }}
      </p>

      <div v-if="coinflow_setup">
        <p class="mt-4 text-sm leading-6 dark:text-gray-400 text-gray-600">Going all in on Coinflow?</p>
        <a :href="route('settings.coinflow.convert')" class="rounded bg-indigo-50 px-2 py-1 text-xs text-indigo-600 shadow-sm hover:bg-indigo-100 dark:bg-indigo-500 dark:text-white">
          Convert Connect Gifts to Coinflow
        </a>
      </div>
    </div>
    <form class="md:col-span-2 flex items-center">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="sm:col-span-3">
          <div v-if="coinflow_setup" class="flex items-center flex-col gap-y-4">
            <a :href="route('settings.coinflow.login')" class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center">
              Visit the Coinflow Dashboard
            </a>
          </div>
          <PrimaryLink v-else :href="route('settings.coinflow.setup')">
            Setup Coinflow
          </PrimaryLink>
        </div>
      </div>
    </form>
  </div>
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-10 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <svg class="fill-[#635bff] h-15" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 468 222.5" style="enable-background:new 0 0 468 222.5" xml:space="preserve"><path class="st0" d="M414 113.4c0-25.6-12.4-45.8-36.1-45.8-23.8 0-38.2 20.2-38.2 45.6 0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5v-20c-6.8 3.4-14.6 5.5-24.5 5.5-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16h-25.8zM301.1 67.6c-9.8 0-16.1 4.6-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4zM223.8 61.7l25.1-5.4V36l-25.1 5.3zM223.8 69.3h25.1v87.5h-25.1zM196.9 76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4zM146.9 47.6l-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V135c-3.2 1.3-19 5.9-19-8.9V90.6h19V69.3h-19l.1-21.7zM79.3 94.7c0-3.9 3.2-5.4 8.5-5.4 7.6 0 17.2 2.3 24.8 6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6C67.5 67.6 54 78.2 54 95.9c0 27.6 38 23.2 38 35.1 0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8c9.3 4 18.7 5.7 27.3 5.7 20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7z"/></svg>

      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        {{ connect_setup ? 'Stripe Dashboard' : 'Setup Stripe Connect' }}
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        {{ connect_setup ? 'View all of your recent stripe orders' : 'Setup Stripe Connect to receive cash gifts' }}
      </p>
    </div>
    <form class="md:col-span-2 flex items-center">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full flex items-center">
          <a v-if="connect_setup" :href="route('connect.dashboard')" class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center">
            Visit the Stripe Dashboard
          </a>
          <PrimaryLink v-else :href="route('connect.setup')" class="items-center px-4 py-2 bg-youpay-navy-light text-white rounded-md font-semibold text-xs shadow-sm hover:bg-youpay-navy-alt focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 transition ease-in-out duration-150 text-center">
            Setup Stripe Connect
          </PrimaryLink>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import { BuildingLibraryIcon, CreditCardIcon, LinkIcon } from "@heroicons/vue/24/outline";
import {User} from "@/web/youpay-me-v3/types";
import {Head, usePage} from "@inertiajs/vue3";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import PrimaryLink from "@/web/youpay-me-v3/Components/Buttons/PrimaryLink.vue";

const flags: object = usePage().props.featureFlags;
const user = <User>usePage().props.auth;

const props = defineProps<{
  coinflow_setup: boolean
  connect_setup: boolean
}>();


defineOptions({ layout: SettingsLayout })

</script>