<template>
  <div v-if="user" class="w-full">
    <div @click="router.visit(route('create-gift'))" class="cursor-pointer px-2 bg-gradient-to-r via-purple-500 from-youpay-navy to-youpay-pink text-white shadow-lg rounded-lg">
      <div class="flex items-center">
        <GiftIcon class="w-13 h-13 md:w-20 md:h-20 ml-4 md:ml-6" />
        <div class="px-4 py-5 sm:p-6 w-full flex flex-col gap-y-1">
          <h3 class="text-base md:text-lg dark:text-white font-semibold">
            Create a Gift - Express
          </h3>
          <div class="flex items-center sm:justify-between">
            <div
              class="text-sm md:text-base text-gray-200 dark:text-gray-200"
            >
              <p class="hidden md:block">
                Add a gift for anything you want and have it paid instantly.
              </p>
              <p class="block md:hidden">
                Add a gift for anything you want
              </p>
            </div>
          </div>
        </div>
        <PlusIcon class="w-9 h-9 md:w-11 md:h-11 mr-4 md:mr-6 text-white dark:text-white" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {GiftIcon, PlusIcon} from "@heroicons/vue/24/outline";
import {router, usePage} from "@inertiajs/vue3";
import {User} from "@/web/youpay-me-v3/types";

const user: User|null = usePage().props.auth;
</script>