<template>
  <div class="flex items-center gap-x-3">
    <span class="text-gray-600 dark:text-gray-300">Theme</span>
    <div class="flex space-x-2">
      <span v-for="theme in themes" :key="theme.value" :class="{'bg-gray-300 dark:bg-gray-700 rounded-lg': currentTheme === theme.value}"
        @click.prevent="currentTheme = theme.value"
        class="p-2 cursor-pointer"
      >
        <Component :is="theme.icon" class="w-5 h-5 text-gray-600 dark:text-gray-300" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, onBeforeUnmount, watch, onBeforeMount} from 'vue';
import { SunIcon, MoonIcon, ComputerDesktopIcon } from "@heroicons/vue/24/outline";

const currentTheme = ref<string>('system');
const matcher = window.matchMedia('(prefers-color-scheme: dark)');
let listener: ((e: MediaQueryListEvent) => void) | null = null;

const applyColorScheme = (selectedTheme?: string) => {
  let appliedTheme = selectedTheme ? selectedTheme : currentTheme.value;

  if (appliedTheme === 'system') {
    appliedTheme = matcher.matches ? 'dark' : 'light';
  }

  if (appliedTheme === 'light') {
    document.documentElement.classList.remove('dark');
  }

  if (appliedTheme === 'dark') {
    document.documentElement.classList.add('dark');
  }

  if (appliedTheme === 'halloween') {
    document.documentElement.classList.add('dark');
    document.documentElement.classList.add('halloween');
  }
};

const themes = [
  {
    name: 'Light',
    value: 'light',
    icon: SunIcon,
  },
  {
    name: 'Dark',
    value: 'dark',
    icon: MoonIcon,
  },
  // {
  //   name: 'Halloween',
  //   value: 'halloween',
  //   icon: MoonIcon,
  // },
  {
    name: 'System',
    value: 'system',
    icon: ComputerDesktopIcon,
  },
];

onBeforeMount(() => {
  if (localStorage.youpayTheme) {
    currentTheme.value = localStorage.youpayTheme;
  }
  applyColorScheme();

  listener = (e) => {
    if (currentTheme.value === 'system') {
      applyColorScheme();
    }
  };
  matcher.addEventListener('change', listener);
});

onBeforeUnmount(() => {
  if (listener) {
    matcher.removeEventListener('change', listener);
  }
});

watch(currentTheme, (newTheme) => {
  localStorage.youpayTheme = newTheme;
  applyColorScheme(newTheme);
});
</script>