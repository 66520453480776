<template>
  <Head title="Create Crowdgift" />

    <div class="w-full h-full flex-grow bg-right relative pb-24">
      <div class="md:fixed z-0 inset-0 bg-gradient-to-tr from-youpay-yellow/[0.08] via-youpay-pink/[0.08] to-youpay-teal/[0.08] dark:from-youpay-navy-dark/[0.1] dark:via-pink-950/[0.1] dark:to-youpay-teal/[0.08] transition-opacity" />
      <div class="inset-0 z-10 relative overflow-y-auto md:p-20">
        <div class="max-w-xl md:rounded-lg mx-auto bg-white dark:bg-white/5 p-6">
          <div class="border-b border-white/10 pb-8">
              <h1 class="text-2xl lg:text-3xl mt-2 mb-6 leading-7 text-youpay-black dark:text-white">
                {{ fund?.id ? 'Edit' : 'Create' }} Crowdgift
              </h1>

              <div class="lg:mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                <div class="col-span-full">
                  <InputLabel for="title" value="Crowdgift Title" class="pb-2"/>

                  <TextInput
                    id="title"
                    type="text"
                    class="mt-1 block w-full"
                    v-model="form.title"
                    @change="form.validate('title')"
                    :error="form.errors.title"
                    required
                  />

                  <InputError class="mt-2" :message="form.errors.title"/>
                </div>

                <div class="col-span-full sm:col-span-4">
                  <div class="flex justify-between items-center">
                    <InputLabel class="leading-7 py-1" for="gift-amount" value="Fund Amount" />
                    <span class="dark:text-gray-600 text-sm flex gap-x-1">
                      <span v-if="product.variable">
                        {{ product.min }} - {{ product.max }}
                      </span>
                      <span>{{ product.currency }}</span>
                    </span>
                  </div>
                  <div class="mt-2">
                    <CurrencyInput
                      class="mt-1 block w-full" type="text"
                      v-model="form.amount"
                      @change="form.validate('amount')"
                      :currency="product.currency"
                    />
                  </div>
                  <InputError class="mt-1" :message="form.errors.amount" />
                </div>

                <div class="col-span-full sm:col-span-2">
                  <InputLabel for="category" value="Category" class="py-1"/>
                  <select
                    v-model="form.category"
                    id="category"
                    class="mt-2 w-full border-gray-300 py-1.5 dark:border-gray-700 dark:bg-white/[0.05] dark:text-gray-300 focus:border-youpay-teal dark:focus:border-gray-700 focus:ring-youpay-teal dark:focus:ring-youpay-teal rounded-md shadow-sm leading-7"
                  >
                    <option v-for="(category, key) in categories" :key="key" :value="key">{{ category }}</option>
                  </select>
                  <InputError class="mt-2" :message="form.errors.category"/>

                </div>

                <div class="col-span-full">
                  <InputLabel for="description" value="Description" class="pb-2"/>

                  <div class="mt-2">
                    <TextAreaInput id="description" v-model="form.description" :error="form.errors.description" @change="form.validate('description')" />
                  </div>
                  <InputError class="mt-2" :message="form.errors.description"/>

                  <p class="mt-2 pl-1 text-xs leading-6 text-gray-400">Write a story about your crowdgift. Markdown is supported.</p>
                </div>

                <div class="col-span-full">
                  <InputLabel for="image" value="Select a gift image" class="pb-3 pt-1" />
                  <div class="lg:ml-2 mt-2">
                    <div class="">
                      <SelectImage v-model="form.banner" :title="form.title" :error="form.errors.banner" :images="imageList" :default-image="form.banner" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div class="mt-6 flex flex-col sm:flex-row-reverse justify-between items-center gap-x-4 gap-y-18">
            <div class="flex flex-col gap-y-6 xs:flex-row items-center justify-end gap-x-4 md:gap-x-6 w-full">
              <Link as="button" :href="route('dashboard')" class="text-sm w-full xs:w-auto dark:font-semibold px-4 py-2 bg-gray-100 dark:bg-youpay-white/[0.05] rounded-md text-youpay-black dark:text-white focus-visible:outline focus-visible:outline-youpay-teal leading-6">Cancel</Link>
              <PrimaryButton @click.prevent="submit" :disabled="form.processing" class="leading-6 w-full xs:w-auto">
                Save Crowdgift
              </PrimaryButton>
            </div>

            <DangerButton v-if="fund?.id" @click.prevent="confirmFundDeletion" :disabled="form.processing" class="w-52">
              Delete Crowdgift
            </DangerButton>
          </div>
        </div>
    </div>
  </div>

  <Modal :show="confirmingFundDeletion" @close="closeModal">
    <div class="p-6">
      <h2 class="text-lg font-medium text-gray-900 dark:text-gray-100">
        Are you sure you want to delete your fund?
      </h2>

      <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
        This is irreversible and will delete the data associated with this fund.
      </p>

      <div class="mt-6 flex justify-end">
        <SecondaryButton @click="closeModal"> Cancel </SecondaryButton>

        <DangerButton
          class="ml-3"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
          @click.prevent="deleteFund"
        >
          Delete Fund
        </DangerButton>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import {Head, Link, usePage} from "@inertiajs/vue3";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import {ref} from "vue";
import UploadFile from "@/web/youpay-me-v3/Components/Form/UploadFile.vue";
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import {User} from "@/web/youpay-me-v3/types";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import DangerButton from "@/web/youpay-me-v3/Components/Buttons/DangerButton.vue";
import SecondaryButton from "@/web/youpay-me-v3/Components/Buttons/SecondaryButton.vue";
import Modal from "@/web/youpay-me-v3/Components/Blocks/Modal.vue";
import GiftAmount from "@/web/youpay-me-v3/Components/Gifting/GiftAmount.vue";
import CurrencyInput from "@/web/youpay-me-v3/Components/Form/CurrencyInput.vue";
import SelectImage from "@/web/youpay-me-v3/Components/Gifting/SelectImage.vue";

const user: User | null = usePage().props.auth;
interface Product {
  name: string,
  provider: string,
  type: string,
  currency: string,
  data: any,
  image: string,
  variable: boolean,
  min?: number,
  max?: number,
  availableDenominations: number[],
  denominations: number[], // new
  instructions?: string,
}


const props = defineProps<{
  fund?: {
    id: string;
    url: string;
    title: string,
    category: string,
    description: string,
    avatar: string,
    amount: number,
    banner: string,
  },
  categories: string[],
  product: Product,
}>();


const imageList = [
  // 'https://images.unsplash.com/photo-1507409613952-518459ac866e?q=80&w=1800&h=1100&auto=format&fit=crop',
  // 'https://images.unsplash.com/photo-1519681393784-d120267933ba?q=80&w=1800&h=1100&auto=format&fit=crop',
  'https://images.unsplash.com/photo-1551641506-ee5bf4cb45f1?q=80&w=1800&h=1100&auto=format&fit=crop', // tokyo
  'https://images.unsplash.com/photo-1511497584788-876760111969?q=80&w=1800&h=1100&auto=format&fit=crop',
  // specific
  'https://images.unsplash.com/photo-1653408604619-45713aad6d75?q=80&w=1800&h=1100&auto=format&fit=crop',
  // 'https://images.unsplash.com/photo-1658303033408-ff2a7e39a554?q=80&w=1800&h=1100&auto=format&fit=crop',

  'https://images.unsplash.com/photo-1512734099960-65a682cbfe2b?q=80&w=1800&h=1100&auto=format&fit=crop', // london
  'https://images.unsplash.com/photo-1485871981521-5b1fd3805eee?q=80&w=1800&h=1100&auto=format&fit=crop', // new york
  'https://images.unsplash.com/photo-1524396309943-e03f5249f002?q=80&w=1800&h=1100&auto=format&fit=crop', // paris

  'https://images.unsplash.com/photo-1532629345422-7515f3d16bb6?q=80&w=1800&h=1100&auto=format&fit=crop', // make a change
  'https://images.unsplash.com/photo-1614735241165-6756e1df61ab?q=80&w=1800&h=1100&auto=format&fit=crop', // treats
  'https://images.unsplash.com/photo-1481437156560-3205f6a55735?q=80&w=1800&h=1100&auto=format&fit=crop', // shopping
  'https://images.unsplash.com/photo-1468234847176-28606331216a?q=80&w=1800&h=1100&auto=format&fit=crop', // festival
  'https://images.unsplash.com/photo-1567401893414-76b7b1e5a7a5?q=80&w=1800&h=1100&auto=format&fit=crop', // clothing
]

const form = useForm('post', route('fund.store'), {
  id: props.fund?.id ?? null,
  title: props.fund?.title ?? '',
  amount: props.fund?.amount ?? 0,
  description: props.fund?.description ?? '',
  category: props.fund?.category ?? 'shopping',
  // avatar: props.fund?.avatar ?? user?.avatar ?? '',
  banner: props.fund?.banner ?? imageList[0],
});

const confirmingFundDeletion = ref(false);

const submit = (e: Event) => {
  e.preventDefault();
  form.submit();
}

const confirmFundDeletion = () => {
  confirmingFundDeletion.value = true;
};

const deleteFund = () => {
  form.delete(route('fund.delete', [user?.username, props.fund?.url]), {
    preserveScroll: true,
    onSuccess: () => closeModal(),
    onFinish: () => {
      form.reset();
    },
  });
};

const closeModal = () => {
  confirmingFundDeletion.value = false;
};
</script>
