<template>
  <div class="mx-auto w-full flex-grow max-w-lg bg-white p-8 dark:bg-white/5 dark:text-white pb-12">
    <h1 class="text-2xl font-semibold pt-2 pb-6 text-center text-youpay-black dark:text-white">Add a gift</h1>
    <div class="bg-gradient-to-l from-[#3747C7] to-[#1F2B89] dark:from-[#64FDFF] dark:to-youpay-teal rounded-lg shadow-lg p-6 flex flex-col gap-y-4 text-white dark:text-youpay-black relative overflow-hidden">
      <svg class="absolute top-0 left-0 z-0 text-black" viewBox="0 0 326 206" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.07">
          <g mask="url(#mask0_18_528)">
            <path d="M-16.9216 -69.9118H69.0616L-16.9216 16.1441V-69.9118Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M26.0701 -26.8898L69.0617 -69.9118V16.1441L26.0701 -26.8898Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M26.07 -26.8898L69.0616 16.1441H-16.9216L26.07 -26.8898Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M155.045 -69.9118V16.1441L69.0615 -69.9118H155.045Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M112.047 -26.8898L155.045 16.1441H69.0615L112.047 -26.8898Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M241.028 16.1441V-69.9118L327.012 16.1441H241.028Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M284.026 -26.8898L327.012 -69.9118V16.1441L284.026 -26.8898Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M155.045 16.1441V-69.9118L241.028 16.1441H155.045Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M327.011 15.8942H241.028L327.011 101.95V15.8942Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M284.02 58.9162L241.028 15.8942V101.95L284.02 58.9162Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M284.02 58.9162L241.028 101.95H327.011L284.02 58.9162Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M155.045 15.8942V101.95L241.028 15.8942H155.045Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M198.042 58.9162L155.045 101.95H241.028L198.042 58.9162Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M69.0615 101.95V15.8942L-16.9217 101.95H69.0615Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M26.064 58.9162L-16.9217 15.8942V101.95L26.064 58.9162Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M155.045 101.95V15.8942L69.0614 101.95H155.045Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M327.011 187.756L241.028 187.756L327.011 101.7L327.011 187.756Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M284.02 144.734L241.028 187.756L241.028 101.7L284.02 144.734Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M284.02 144.734L241.028 101.7L327.011 101.7L284.02 144.734Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M155.045 187.756L155.045 101.7L241.028 187.756L155.045 187.756Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M198.042 144.734L155.045 101.7L241.028 101.7L198.042 144.734Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M69.0615 101.7L69.0615 187.756L-16.9217 101.7L69.0615 101.7Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M26.064 144.734L-16.9217 187.756L-16.9217 101.7L26.064 144.734Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M155.045 101.7L155.045 187.756L69.0614 101.7L155.045 101.7Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M-16.9216 273.562L69.0616 273.562L-16.9216 187.506L-16.9216 273.562Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M26.0703 230.54L69.0619 273.562L69.062 187.506L26.0703 230.54Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M26.0702 230.54L69.0619 187.506L-16.9214 187.506L26.0702 230.54Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M155.045 273.562L155.045 187.506L69.0618 273.562L155.045 273.562Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M112.047 230.54L155.045 187.506L69.0618 187.506L112.047 230.54Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M241.028 187.506L241.028 273.562L327.012 187.506L241.028 187.506Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M284.026 230.54L327.012 273.562L327.012 187.506L284.026 230.54Z" stroke="currentColor" stroke-width="0.5"/>
            <path d="M155.045 187.506L155.045 273.562L241.028 187.506L155.045 187.506Z" stroke="currentColor" stroke-width="0.5"/>
          </g>
        </g>
      </svg>
      <div class="flex w-full justify-end p-1 z-10">
        <InformationCircleIcon v-if="product.instructions && product.instructions !== ''" class="h-6 w-6 text-white dark:text-youpay-black cursor-pointer" aria-hidden="true" @click="showInfo = !showInfo" />
        <span v-else class="h-6 w-6"></span>
      </div>
      <div class="z-10">
        <h3 class="text-md font-semibold py-1">{{ product.name }}</h3>
        <a :href="route('gift-select')" class="underline text-xs font-extralight cursor-pointer dark:font-semibold">Change</a>
      </div>
      <div class="flex w-full justify-end">
        <div v-if="product.provider === 'connect'" class="w-18 z-10 p-2 h-10 flex items-center rounded">
          <svg  class="fill-white dark:fill-[#635bff] h-20 md:h-30" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 468 222.5" style="enable-background:new 0 0 468 222.5" xml:space="preserve"><path class="st0" d="M414 113.4c0-25.6-12.4-45.8-36.1-45.8-23.8 0-38.2 20.2-38.2 45.6 0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5v-20c-6.8 3.4-14.6 5.5-24.5 5.5-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16h-25.8zM301.1 67.6c-9.8 0-16.1 4.6-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4zM223.8 61.7l25.1-5.4V36l-25.1 5.3zM223.8 69.3h25.1v87.5h-25.1zM196.9 76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4zM146.9 47.6l-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V135c-3.2 1.3-19 5.9-19-8.9V90.6h19V69.3h-19l.1-21.7zM79.3 94.7c0-3.9 3.2-5.4 8.5-5.4 7.6 0 17.2 2.3 24.8 6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6C67.5 67.6 54 78.2 54 95.9c0 27.6 38 23.2 38 35.1 0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8c9.3 4 18.7 5.7 27.3 5.7 20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7z"/></svg>
        </div>
        <div v-if="product.provider === 'coinflow'" class="w-16 z-10 h-12 pl-3 py-1 rounded bg-black">
          <CoinflowIcon class="h-10 w-10" />
        </div>
        <div v-else-if="type === 'visa'" class="z-10 -mt-2 h-12 flex items-end">
          <VisaMastercardIcon />
        </div>
        <div v-else-if="image" class="w-18 z-10 bg-white/90 p-2 h-10 flex items-center rounded">
          <img :src="image" class="h-auto w-full block" />
        </div>
      </div>
    </div>

    <div v-if="showInfo" class="mt-10 border border-gray-400 dark:border-white/10 p-4 rounded-lg bg-gray-50 dark:bg-white/5 dark:text-white">
      <div class="flex items-center gap-x-4">
        <InformationCircleIcon class="h-6 w-6" aria-hidden="true" />
        <h2 class="font-semibold flex-grow">Gift Details</h2>
        <button @click="showInfo = false" class="flex-shrink-0 underline text-gray-600 text-xs cursor-pointer">
          <XMarkIcon class="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <p class="mt-3 text-sm" v-html="product.instructions"></p>
    </div>

    <div class="mt-10 flex flex-row justify-between items-center gap-x-4">
      <h2 class="font-semibold">{{ type === 'connect' ? 'Cash Gift' : 'Gift Card' }} Details</h2>
      <a @click.prevent="showPrefill" class="flex-shrink-0 underline text-gray-600 text-xs cursor-pointer">
        Prefill from URL
      </a>
    </div>

    <div class="mt-4 flex flex-col gap-y-4">
      <FetchFromUrl v-show="prefillVisible" always-show class="col-span-full" v-model="form.gift" :denominations="product.denominations" />

      <div>
        <div class="flex justify-between items-center">
          <InputLabel class="leading-7 py-1" for="gift-amount" value="Gift Amount *" />
          <span class="dark:text-gray-600 text-sm flex gap-x-1">
            <span v-if="product.variable">
              {{ product.min }} - {{ product.max }}
            </span>
            <span>{{ form.gift.currency }}</span>
          </span>
        </div>
        <div class="mt-2">
          <GiftAmount v-if="!product.variable" :gift-card-amounts="product.denominations" v-model="form.gift.amount" :currency="form.gift.currency" />
          <CurrencyInput
            v-else
            class="mt-1 block w-full" type="text"
            v-model="form.gift.amount"
            @change="form.validate('gift.amount')"
            :currency="form.gift.currency"
          />
        </div>
        <InputError class="mt-1" :message="form.errors['gift.amount']" />
      </div>
      <div class="">
        <InputLabel class="leading-7 py-1" for="title" value="Gift Name *" />
        <TextInput
          id="title"
          type="text"
          class="mt-1 block w-full"
          v-model="form.gift.title"
          @change="form.validate('gift.title')"
          :error="form.errors['gift.title']"
          required
        />
        <InputError class="mt-2" :message="form.errors['gift.title']"/>
      </div>
      <div class="">
        <InputLabel class="leading-7 py-1" for="title" value="Gift Description" />
        <TextAreaInput
          id="title"
          type="text"
          class="mt-1 block w-full"
          v-model="form.gift.description"
          @change="form.validate('gift.description')"
          :error="form.errors['gift.description']"
        />
        <InputError class="mt-2" :message="form.errors['gift.description']"/>
      </div>

      <div>
        <InputLabel for="image" value="Select a gift image" class="pb-3 pt-1" />
        <SelectImage v-model="form.gift.image" :title="form.gift.title" :default-image="defaultImage" :error="form.errors['gift.image']" />
      </div>

      <div class="pt-6 pb-4 mt-2 border-t border-white/10">
        <WishlistSelector v-model="form.wishlist" :wishlists="wishlists" :selected_wishlist="selected_wishlist" />
      </div>

      <fieldset class="pt-4 pb-4  border-t border-white/10">
        <div @click="showAdvanced = !showAdvanced" class="flex items-center cursor-pointer justify-between">
          <legend class="text-sm font-semibold leading-7">
            Advanced Gift Settings
          </legend>
          <span class="cursor-pointer text-sm font-semibold leading-7 text-gray-600 dark:text-gray-200">
              <ChevronDownIcon class="h-5 w-5" aria-hidden="true" :class="showAdvanced ? 'transform rotate-180' : ''" />
            </span>
        </div>
        <div v-if="showAdvanced" class="mt-4 space-y-6">
          <div v-for="setting in settings.visible" :key="setting">
            <div class="relative flex gap-x-4" v-if="lang[setting]">
              <div class="flex items-center">
                <input v-model="preferences[setting]" :id="'setting-' + setting" type="checkbox" :checked="preferences[setting]" class="h-4 w-4 rounded border-gray-300 text-youpay-navy dark:text-youpay-navy-light focus:ring-purple-600" />
              </div>
              <label :for="'setting-' + setting" class="text-sm leading-6">
                  <span class="block text-sm">
                    {{ lang[setting].title }}
                  </span>
                <span class="text-xs text-gray-500 dark:text-gray-200">
                    <span v-if="false" class="font-semibold text-youpay-pink">Premium:</span>
                    {{ lang[setting].desc }}
                    <span v-if="lang[setting].beta" class="block text-xs text-youpay-yellow">
                      Beta feature: this may not work in all areas.
                    </span>
                  </span>
              </label>
            </div>
            <div v-else class="hidden">
              Missing setting: {{ setting }}
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <div v-if="type === 'visa'" class="hidden border-y border-white/10 py-4">
      <div class="flex items-center gap-x-4">
        <input @change="form.validate('visa_terms')" id="visa-terms" type="checkbox" v-model="form.visa_terms" class="h-4 w-4 rounded border-gray-300 text-youpay-navy dark:text-youpay-navy-light focus:ring-purple-600" />
        <label for="visa-terms" class="text-sm leading-6">
          {{ country === 'US' ? 'I understand that this Visa gift card gift can only be used in the USA.' : 'I understand that this Visa gift card gift can only be used outside the USA.' }}
          <Link class="underline cursor-pointer" :href="route('settings.addresses')">
            Change my country
          </Link>
        </label>
      </div>
      <InputError class="mt-2" :message="form.errors.visa_terms" />
    </div>

    <div class="flex flex-col gap-y-2 mt-8">
      <PrimaryButton
        class="w-full"
        type="submit"
        @click="submit(false)"
        :loading="form.processing"
        :disabled="form.processing"
        >
        Create my gift
      </PrimaryButton>

      <SecondaryButton
        class="w-full text-base leading-7"
        type="submit"
        @click="submit(true)"
        :loading="form.processing"
        :disabled="form.processing"
      >
        Create my gift and add another
      </SecondaryButton>
    </div>

  </div>
</template>

<script setup lang="ts">
import {VisaIcon} from "vue3-simple-icons";
import {Link} from "@inertiajs/vue3";
import {InformationCircleIcon, XMarkIcon} from "@heroicons/vue/24/outline";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import type { GiftDetails } from "@/web/youpay-me-v3/types/index.d";
import type {Settings} from "@/web/youpay-me-v3/types/gifting";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import SelectImage from "@/web/youpay-me-v3/Components/Gifting/SelectImage.vue";
import WishlistSelector from "@/web/youpay-me-v3/Components/Gifting/WishlistSelector.vue";
import {ChevronDownIcon} from "@heroicons/vue/24/outline";
import {onMounted, ref} from "vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import SecondaryButton from "@/web/youpay-me-v3/Components/Buttons/SecondaryButton.vue";
import CurrencyInput from "@/web/youpay-me-v3/Components/Form/CurrencyInput.vue";
import FetchFromUrl from "@/web/youpay-me-v3/Components/Gifting/FetchFromUrl.vue";
import GiftAmount from "@/web/youpay-me-v3/Components/Gifting/GiftAmount.vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import CoinflowIcon from "@/web/youpay-me-v3/Icons/CoinflowIcon.vue";
import VisaMastercardIcon from "@/web/youpay-me-v3/Icons/VisaMastercardIcon.vue";

const lang = {
  bundle: {
    title: 'Create a bundle',
    desc: 'Add more than one item to your gift request.',
  },
  forever: {
    title: 'Never Sell Out',
    desc: 'Keep the gift in the wishlist even after someone purchases it for you.',
  },
  partial: {
    title: 'Allow Partial Payments',
    desc: 'Allow users to gift you part of the gift payment.',
  },
  expire: {
    title: 'Auto Expire',
    desc: 'Automatically remove the gift from my wishlist after 90 days.',
  },
};

interface Product {
  name: string,
  provider: string,
  type: string,
  currency: string,
  data: any,
  image: string,
  variable: boolean,
  min?: number,
  max?: number,
  availableDenominations: number[],
  denominations: number[], // new
  instructions?: string,
}

const props = defineProps<{
  country: string,
  image: string,
  type: string,
  // store: Store,
  selected_wishlist: string,
  wishlists: any,
  settings: Settings,
  product: Product,
  product_id: number,
  details?: GiftDetails
}>();

const showAdvanced = ref(false);
const showInfo = ref(false);
const preferences = ref<Settings>(props.settings);


const form = useForm('post', route('create-gift'), {
  product: props.product_id,
  product_variable: props.product.variable,
  type: props.type,
  stay: false,
  visa_terms: false,
  wishlist: props.selected_wishlist,
  gift: <GiftDetails>{
    url: props.details?.url ?? '',
    currency: props.product.currency,
    title: props.details?.title ?? '',
    image: props.details?.image ?? props.product?.image ?? '',
    quantity: 1,
    amount: props.details?.amount ?? 0,
    description: props.details?.description ?? '',
  },
  settings: {
    // anon: props.settings.anon,
    forever: props.settings.forever,
    expire: props.settings.expire,
  },
});

const prefillVisible = ref(false);
const showPrefill = () => {
  prefillVisible.value = !prefillVisible.value;
};

const defaultImage = form.gift.image;

const isVariable = props.product.denominations && Object.keys(props.product.denominations).length >= 1;

const submit = (stay: Boolean) => {
  form.stay = stay;
  form.submit({
    preserveScroll: false,
    onSuccess:() => {
      const gaData = {
        product: props.product_id,
        type: props.product.type,
        flow: 'quick'
      };
      if (props.store?.title) {
        gaData.store = props.store.title;
      }
      trackEvent('gift_create_end', gaData);
      form.reset();
    },
  });
};

const updateCountryCode = (code: string) => {
  form.user.country = code;
};


onMounted(() => {
  const gaData = {
    product: props.product_id,
    type: props.product.type,
    flow: 'quick'
  };
  if (props.product?.name) {
    gaData.store = props.product.name;
  }
  trackEvent('gift_create_begin', gaData);
});
</script>