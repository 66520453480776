<template>
  <Listbox id="currencySelector" as="div" v-model="form.currency" class="relative">
    <ListboxButton class="relative w-full cursor-default rounded-md bg-white dark:bg-white/[0.075] py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300  dark:ring-gray-700 focus:outline-none focus:ring-2 focus:ring-youpay-teal sm:text-sm sm:leading-6">
      <span class="flex items-center">
        <span class="block truncate">{{ currentCurrency?.code }}</span>
      </span>
      <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </span>
    </ListboxButton>

    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 max-h-56 w-24 overflow-auto rounded-md bg-white dark:bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="currency in currencies" :key="currency.code" :value="currency.code" v-slot="{ active, selected }">
            <li :class="[active ? 'bg-youpay-navy-light text-white' : 'text-gray-900', 'relative dark:text-white cursor-default select-none py-2 pl-3 pr-9']">
              <div class="flex items-center">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ currency.code }}</span>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
  </Listbox>
</template>

<script setup>
import {computed, watch} from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import {useForm} from "laravel-precognition-vue-inertia";
import {usePage} from "@inertiajs/vue3";

const userCurrency = usePage().props.currency;
const currencies = [
  {
    code: 'USD',
    flagIcon: '',
  },
  {
    code: 'AUD',
    flagIcon: '',
  },
  {
    code: 'GBP',
    flagIcon: '',
  }
];

const form = useForm('post', route('currency'), {
  currency: userCurrency,
});

const currentCurrency = computed(() => {
  return currencies.find(currency => currency.code === form.currency);
});

watch(() => form.currency, (value) => {
  submit(value);
});

const submit = (value) => {
  console.log('value', value, form.currency);
  form.submit({
    preserveState: false,
    preserveScroll: false,
  });
};
</script>
