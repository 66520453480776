
export interface Store {
    id: number,
    title: string,
    domain: string,
    logo: string,
    dark_logo: string,
    store_type: string,
}

export interface Settings {
    bundle: boolean,
    edit: boolean,
    fetch: boolean,
    partial: boolean,
    forever: boolean,
    expire: boolean,
    visible: string[],
    premium: string[],
    language: {
        title: string,
        heading: string,
        description: string,
    }
}

export const lang = {
    bundle: {
        title: 'Create a bundle',
        desc: 'Add more than one item to your gift request.',
    },
    forever: {
        title: 'Never Sell Out',
        desc: 'Keep the gift in the wishlist even after someone purchases it for you.',
    },
    partial: {
        title: 'Allow Partial Payments',
        desc: 'Allow users to gift you part of the gift payment.',
    },
    expire: {
        title: 'Auto Expire',
        desc: 'Automatically remove the gift from my wishlist after 90 days.',
    },
    // anon: {
    //     title: 'Incognito Mode',
    //     desc: 'In addition to your normal privacy, also hide your username, display name and profile picture from the gift page.',
    //     beta: true,
    // },
};