<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="computedIndexName"
    class="flex flex-col dark:text-white rounded-md py-6 px-2 md:px-4 lg:px-6 max"
  >
    <ais-configure v-if="type" :filters="'store_type=' + type" />
    <div class="grid grid-cols-2 md:flex gap-y-4 justify-between mb-6 gap-x-2 md:gap-x-4">
      <ais-search-box class="col-span-full relative flex-grow border-b bg-white dark:bg-semigray rounded-md border-gray-200 dark:border-gray-700">
        <template v-slot="{ currentRefinement, isSearchStalled, refine }">
          <MagnifyingGlassIcon
            class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400 dark:text-gray-500"
            aria-hidden="true"/>
          <input
            class="md:min-w-full
            h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900
            dark:text-gray-100 placeholder:text-gray-400
            outline-none
            dark:placeholder:text-gray-500 focus:ring-0 sm:text-sm
          "
            placeholder="Start typing a store name"
            type="search"
            :value="currentRefinement"
            @input="refine($event.currentTarget.value)"
          >
          <span :hidden="!isSearchStalled">Loading...</span>
        </template>
      </ais-search-box>

      <div class="relative col-span-1">
        <ais-menu-select
          attribute="currency"
          :limit="50"
          ref="currencyRefinement"
          :class-names="{
            'ais-MenuSelect-select': 'flex items-center shadow-sm bg-white dark:bg-semigray dark:text-gray-200 h-12 p-2 pr-8 px-4 w-full justify-between rounded-md border-none',
          }"
        >
          <template v-slot:defaultOption>
            Currency
          </template>
        </ais-menu-select>
      </div>
      <div class="relative col-span-1">
        <ais-menu-select
          attribute="industry"
          :limit="50"
          :class-names="{
            'ais-MenuSelect-select': 'flex items-center shadow-sm bg-white dark:bg-semigray dark:text-gray-200 h-12 p-2 px-4 w-full justify-between rounded-md border-none',
          }"
        >
          <template v-slot:defaultOption>
            Category
          </template>
        </ais-menu-select>
      </div>
    </div>

    <div class="gap-4">
      <ais-infinite-hits>
        <template v-slot="{
        items,
        refinePrevious,
        refineNext,
        isLastPage,
      }">
          <ul class="grid gap-2 md:gap-4 lg:gap-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <li v-for="(store, index) in items" :key="store.id"  class="rounded-t-md relative cursor-pointer">
              <a @click.prevent="openStore(store)">
                <img v-if="getFlag(store.currency)" :src="getFlag(store.currency)" :alt="store.currency" class="absolute top-0 right-0 h-8 w-8 -mt-1 mr-1">
                <div class="mt-2 px-4 h-28 sm:h-40 flex items-center bg-gray-100 rounded-t">
<!--                  <img :src="store?.logo" alt="Store Logo" class="w-full hidden dark:block h-auto max-h-full">-->
                  <img :src="store?.dark_logo" alt="Store Logo" class="w-full block h-auto max-h-full">
                </div>
                <div class="bg-gray-200 dark:bg-gray-900 flex justify-between rounded-b-md items-center px-4 py-2">
                  <span class="line-clamp-2 text-xs md:text-base">{{ store?.title }}</span>
                  <ArrowRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </a>
            </li>
          </ul>
          <div v-if="!isLastPage" class="p-4 pt-8 w-full flex items-center  justify-center">
            <button
              @click="refineNext"
              class="max-w-[300px] w-full pointer-events-auto rounded-md px-4 py-2 text-center dark:bg-semigray dark:text-white font-medium shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50 dark:hover:bg-white/[0.02]"
            >
              View more
            </button>
          </div>
        </template>
      </ais-infinite-hits>
    </div>
  </ais-instant-search>
  <Modal :show="showCurrentStore" @close="showCurrentStore = null" max-width="3xl" :show-close="true">
    <div v-if="currentStore" class="p-6">
      <div class="flex flex-col-reverse gap-y-3 md:flex-row items-center gap-x-4 justify-between border-b border-gray-200 pb-6 mb-6">
        <div class="gap-y-3 flex flex-col justify-between items-stretch h-full font-medium text-gray-900 dark:text-gray-100 w-full">
          <span class="text-xl md:text-3xl">Add a gift to your wishlist</span>
          <span class="text-md md:text-lg">from {{ currentStore?.title }}</span>
          <a
            class="flex justify-center rounded-md
        bg-youpay-navy dark:bg-youpay-navy-light
        px-4 py-2 text-sm font-semibold text-white shadow-sm
        hover:bg-youpay-navy-alt
        dark:hover:bg-youpay-navy
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
        focus-visible:outline-youpay-teal
        dark:focus-visible:outline-youpay-teal"
            :href="'https://' + currentStore.domain"
            target="_blank"
          >
            Continue to {{ currentStore.title }}
          </a>
        </div>
        <div class="px-4 h-28 sm:h-40 flex items-center bg-gray-100 dark:bg-gray-800">
          <img :src="currentStore?.logo" alt="Store Logo" class="w-full hidden dark:block h-auto max-h-full">
          <img :src="currentStore?.dark_logo" alt="Store Logo" class="w-full block dark:hidden h-auto">
        </div>
      </div>

      <div class="mt-1 flex flex-col md:gap-y-1 text-sm md:text-lg text-gray-600 dark:text-gray-300">
        <p>
          <span class="font-semibold">{{ currentStore?.title }}</span> is a YouPay partner store.
        </p>
        <p>
          Visit <a :href="currentStore?.url" target="_blank" class="font-semibold underline">{{ currentStore?.domain }}</a> and choose YouPay in the cart.
        </p>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
import {Link, router, usePage} from "@inertiajs/vue3";
import {instantMeiliSearch} from '@meilisearch/instant-meilisearch'
import {MagnifyingGlassIcon} from "@heroicons/vue/20/solid";
import Modal from "@/web/youpay-me-v3/Components/Blocks/Modal.vue";
import PrimaryLink from "@/web/youpay-me-v3/Components/Buttons/PrimaryLink.vue";
import {inject, onMounted, ref, computed} from "vue";
import {CountryInfo, fetchCountryInfo} from "@/web/youpay-me-v3/helpers/geolocate";
//See: https://github.com/HatScripts/circle-flags import AU flag from 'country-flag-icons/3x2/AU.svg';
import au from 'circle-flags/flags/au.svg';
import us from 'circle-flags/flags/us.svg';
import gb from 'circle-flags/flags/gb.svg';
import nz from 'circle-flags/flags/nz.svg';
import ca from 'circle-flags/flags/ca.svg';
import eu from 'circle-flags/flags/european_union.svg';
import sg from 'circle-flags/flags/sg.svg';
import no from 'circle-flags/flags/no.svg';
import se from 'circle-flags/flags/se.svg';

const asset = inject('asset');

const scout = usePage().props.scout;

const searchClient = instantMeiliSearch(
  scout.host, // Host
  scout.search_key // API key
)

const props = defineProps<{
  type?: string;
  route_name?: string;
}>();

const currentStore = ref(null);
const showCurrentStore: boolean = ref(false);

const computedIndexName = computed(() => {
  return scout.prefix + 'stores:ranking_number:desc';
});

const openStore = (store) => {
  if (props.route_name) {
    router.visit(route(props.route_name, store.slug ?? store.domain));
    return;
  }

  if (store.store_type === 'shopify') {
    currentStore.value = store;
    showCurrentStore.value = true;
  }
  else {
    router.visit(route('gift.store.create', store.slug ?? store.domain));
  }
}

const currencyRefinement = ref('currencyRefinement');

const country = ref('');

const getFlag = (value: string) => {
  const codes = {
    'AUD': au,
    'USD': us,
    'GBP': gb,
    'GB': gb,
    'NZD': nz,
    'CAD': ca,
    'EUR': eu,
    'SGD': sg,
    'NOK': no,
    'SEK': se
  };
  if (typeof codes[value] === 'undefined') {
    return false;
  }
  return codes[value];
}

const doRefinement = (country_code: string) => {
  const allowedCurrencies = {
    'AU': 'AUD',
    'CA': 'CAD',
    'GB': 'GBP',
    'GBR': 'GBP',
    // 'NZ': 'NZD',
    'US': 'USD',
    "BE": "EUR",
    "DE": "EUR",
    "FR": "EUR",
    "NL": "EUR",
    "SG": "SGD",
    "NO": "NOK",
    "SE": "SEK"
  };
  if (typeof allowedCurrencies[country_code] === 'undefined') {
    return;
  }
  if (typeof currencyRefinement.value === 'undefined' || typeof currencyRefinement.value['refine'] !== 'function') {
    setTimeout(() => {
      doRefinement(country_code);
    }, 1000);
    return;
  }
  setTimeout(() => {
    currencyRefinement.value?.refine(allowedCurrencies[country_code]);
  }, 1000);
}

onMounted(() => {
  // get currency from url query parametres
  const urlParams = new URLSearchParams(window.location.search);
  let currency = urlParams.get('region');
  if (currency) {
    if (currency === 'EU') {
      currency = 'BE';
    }
    doRefinement(currency);
    return;
  }
  console.log('fetch country info')
  fetchCountryInfo().then((info: CountryInfo) => {
    console.log('got country info', info);
    country.value = info.country_code;
    doRefinement(info.country_code);
  });
})

</script>
