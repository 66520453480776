<script setup lang="ts">
import { onMounted, ref } from 'vue';

defineProps<{
  modelValue: string;
  error?: string;
}>();

defineEmits(['update:modelValue']);

const input = ref<HTMLInputElement | null>(null);

onMounted(() => {
    if (input.value?.hasAttribute('autofocus')) {
        input.value?.focus();
    }
});

defineExpose({ focus: () => input.value?.focus() });
</script>

<template>
  <input
      class="leading-7 border-gray-300 dark:border-gray-700 dark:bg-white/[0.05] dark:text-gray-300 focus:border-youpay-teal dark:focus:border-gray-700 focus:ring-youpay-teal dark:focus:ring-youpay-teal rounded-md shadow-sm"
      :class="error ? 'border-red-300 dark:border-red-600' : ''"
      :value="modelValue"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
      ref="input"
  />
</template>
