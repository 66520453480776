<template>
  <Head title="Report User Profile"/>

  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-xl text-center">
      <h1 class="mt-10 lg:mt-0 text-3xl md:text-4xl leading-9 font-semibold text-gray-900 dark:text-white">
        Report User Profile
      </h1>
      <p class="py-4 text-gray-700 dark:text-white flex flex-col gap-y-4">
        <span>
          Please report any content that you believe is inappropriate or offensive.
        </span>
        <span class="flex flex-col">
          <strong>This is not to be used for support.</strong>
          <span>Please visit our <a href="https://support.youpay.co/" class="text-youpay-navy dark:text-youpay-pink">Support Page</a> if you require support.</span>
        </span>
      </p>
    </div>

    <form method="POST" @submit.prevent="submit" class="mt-6 sm:mx-auto sm:w-full sm:max-w-sm flex flex-col space-y-6">
      <div>
        <InputLabel for="url" value="Reported URL" />

        <TextInput
          id="url"
          type="text"
          class="mt-1 block w-full"
          v-model="form.url"
          required
          autofocus
          autocomplete="url"
        />

        <InputError class="mt-2" :message="form.errors.url"/>
      </div>

      <div>
        <InputLabel for="reason" value="Reason for reporting" />

        <TextAreaInput
          id="reason"
          type="text"
          class="mt-1 block w-full"
          v-model="form.reason"
          autofocus
          autocomplete="reason"
        />
        <InputError class="mt-2" :message="form.errors.reason"/>
        <p class="mt-2 pl-1 text-xs leading-6 text-gray-600 dark:text-gray-300">Please include any relevant information about your report here.</p>
      </div>

      <PrimaryButton :disabled="form.processing">
        Report User Profile
      </PrimaryButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import {Head} from "@inertiajs/vue3";
import {useForm} from "laravel-precognition-vue-inertia";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";

const props = defineProps<{
  url?: string;
}>();

const form = useForm('post', route('report-profile'), {
  url: props.url ?? '',
  reason: '',
});

const submit = () => form.submit();
</script>