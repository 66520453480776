<template>
  <div class="relative overflow-hidden">
    <transition-group name="fade" tag="div" :css="false" class="flex">
        <img v-for="(image, index) in images" :key="image" v-show="index === currentIndex" :src="image" class="w-full h-full object-cover"/>
    </transition-group>
    <button @click="previous" class="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white p-2 rounded-r opacity-30 hover:opacity-80 ">
      <ChevronLeftIcon class="h-6 w-6 dark:text-black"/>
    </button>
    <button @click="next" class="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white p-2 rounded-l opacity-30 hover:opacity-80">
      <ChevronRightIcon class="h-6 w-6 dark:text-black"/>
    </button>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue';
import {ChevronRightIcon, ChevronLeftIcon} from "@heroicons/vue/20/solid";

const current_image = defineModel()

const props = defineProps({
  images: {
    type: Array,
    required: true,
  },
});

const previousIndex = ref(0);
const currentIndex = ref(0);

const next = () => {
  previousIndex.value = currentIndex.value;
  currentIndex.value = (currentIndex.value + 1) % props.images.length;
};

const previous = () => {
  previousIndex.value = currentIndex.value;
  currentIndex.value = (currentIndex.value - 1 + props.images.length) % props.images.length;
};

watch(() => currentIndex.value, (newIndex, oldIndex) => {
  current_image.value = props.images[newIndex];
});

watch(() => current_image.value, (newImage, oldImage) => {
  if (newImage !== props.images[currentIndex.value] && props.images.includes(newImage)) {
    currentIndex.value = props.images.indexOf(newImage);
  }
});
</script>