<template>
  <span class="flex flex-col justify-center items-center gap-x-6">
    <div v-if="getImage !== ''" class="lg:h-42 overflow-hidden rounded-md flex items-center">
      <img :src="getImage" class="w-30 lg:w-42 rounded-md" />
    </div>
    <div v-else class="flex items-center overflow-hidden">
      <ImageGallery v-model="currentImage" :images="images" />
    </div>
    <span @click="changeImage" class="mt-4 lg:mt-2 rounded-md px-5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 bg-white dark:text-white dark:bg-white/[0.05] dark:hover:bg-white/[0.1] hover:bg-gray-50 focus:ring focus:ring-youpay-teal">
      Change Image
    </span>
    <InputError class="mt-1" :message="error" />
  </span>
  <Modal :show="isModalOpen" @close="isModalOpen = false">
    <div class="p-6">
      <div class="hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500" v-model="currentTab">
          <option v-for="tab in tabs" :key="tab.name" :value="tab.slug">{{ tab.name }}</option>
        </select>
      </div>
      <div class="block">
        <div class="border-b border-gray-300 dark:border-gray-200">
          <nav class="-mb-px flex" aria-label="Tabs">
            <a v-for="(tab, index) in tabs" :key="tab.name" :class="[tab.slug === currentTab ? 'border-youpay-navy text-youpay-navy dark:border-youpay-pink dark:text-youpay-pink' : 'border-transparent text-gray-600 dark:text-gray-100 hover:border-gray-900 hover:text-gray-900', 'w-1/2 sm:w-1/3 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer', index === 0 ? 'hidden sm:block' : '']" :aria-current="tab.slug === currentTab ? 'page' : undefined" @click.prevent="currentTab = tab.slug">{{ tab.name }}</a>
          </nav>
        </div>
      </div>
      <div v-show="currentTab === 'default'" class="mt-6">
        <img :src="theDefault" class="h-48 w-auto max-w-full mx-auto" />
        <button @click="selectImage(theDefault)" type="button" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-youpay-navy dark:bg-youpay-pink hover:bg-youpay-navy-dark dark:hover:bg-youpay-pink-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-youpay-teal-dark">
          Use this image
        </button>
      </div>
      <div v-show="currentTab === 'find'" class="mt-6">
        <h2 class="text-youpay-navy dark:text-white font-semibold">Select an image</h2>
        <div class="grid grid-cols-3 sm:grid-cols-4 gap-4 mt-4">
          <a @click="selectImage(image)" v-for="image in images" :key="image" class="flex justify-center cursor-pointer">
            <img :src="image" class="w-full h-auto rounded-md" />
          </a>
        </div>
      </div>
      <div v-show="currentTab === 'upload'" class="mt-6 w-full">
        <div class="flex items-center justify-center">
          <UploadFile v-model="currentImage" @updatePreview="(value) => {previewImage = value; isModalOpen=false;}" />
        </div>
      </div>
      <div v-show="currentTab === 'ai'" class="mt-6">
        <h2 class="text-youpay-navy dark:text-white font-semibold flex gap-x-4 items-center">
          <span>AI Generated Image</span>
        </h2>

        <div v-if="aiLoading" class="flex flex-col items-center justify-center gap-y-4 mt-4">
          <PrideLoader />
          <div class="ml-2 text-youpay-navy dark:text-youpay-pink">This may take a minute...</div>
        </div>
        <div v-else-if="title === ''" class="mt-4">
          <p class="text-red-500 dark:text-red-400">Please enter a title to generate an image.</p>
        </div>
        <div v-else-if="aiError" class="mt-4">
          <p class="text-red-500 dark:text-red-400">
            There was an error generating this image, this is most likely due to the gift name you entered. Please try again with a different name.
          </p>
        </div>
        <div v-else class="flex flex-col sm:grid-cols-4 gap-4 mt-4">
          <div class="mx-auto h-64">
            <img :src="aiImage" class="h-full w-auto rounded-md" />
          </div>
          <button @click="selectAiImage" type="button" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-youpay-navy dark:bg-youpay-pink hover:bg-youpay-navy-dark dark:hover:bg-youpay-pink-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-youpay-teal-dark">
            Use this image
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import Modal from "@/web/youpay-me-v3/Components/Blocks/Modal.vue";
import UploadFile from "@/web/youpay-me-v3/Components/Form/UploadFile.vue";
import {PhotoIcon} from "@heroicons/vue/24/solid";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import ImageGallery from "@/web/youpay-me-v3/Components/Gifting/ImageGallery.vue";
import {defaultOptions} from "acorn";
import PrideLoader from "@/web/youpay-me-v3/Components/Blocks/PrideLoader.vue";

const isModalOpen = ref(false);
const currentImage = defineModel({
  required: true,
});
const previewImage = ref('');

const getImage: string = computed(() => {
  // if starts with tmp/ then it's a new image and needs to show the preview
  if (currentImage.value.startsWith('tmp/')) {
    console.log('preview image in use')
    return previewImage.value;
  }
  // if not in the images array, then show
  if (!images.value.includes(currentImage.value)) {
    return currentImage.value;
  }
  return '';
});

const props = withDefaults(defineProps<{
  title: string,
  defaultImage?: string,
  error: string,
  images?: any,
}>(), {
  images: ['https://cdn-001.youpay.ai/images/coffee.png', 'https://cdn-001.youpay.ai/images/food_shop.png', 'https://cdn-001.youpay.ai/images/food.png', 'https://cdn-001.youpay.ai/images/gift_card.png', 'https://cdn-001.youpay.ai/images/gift.png', 'https://cdn-001.youpay.ai/images/piggy_bank.png', 'https://cdn-001.youpay.ai/images/presents.png']
});

// If not set, use first image in images array
const theDefault = props.defaultImage ? props.defaultImage : props.images[0];

const currentTab = ref('find');
const tabs = [
  { name: 'Default Image', slug: 'default' },
  { name: 'Find an Image', slug: 'find' },
  { name: 'Upload an Image', slug: 'upload' },
  { name: 'AI Generated Image', slug: 'ai' },
];

const selectImage = (image: string) => {
  isModalOpen.value = false;
  currentImage.value = image;
}

const images = computed(() => {
  let images = props.images;
  // remove defaultImage from images
  images = images.filter((image: string) => image !== theDefault);
  // push defaultImage to the front
  images.unshift(theDefault);
  return images;
});

const aiImage = ref('');
const aiLoading = ref(true);
const aiError = ref(false);
const generateAIImage = async () => {
  aiError.value = false;
  aiLoading.value = true;
  if (props.title === '') {
    aiLoading.value = false;
    aiError.value = true;
    return;
  }
  axios.post(route('ai.image'), {
    title: props.title
  })
    .then(response => {
      aiImage.value = response.data.url;
      aiLoading.value = false;
    })
    .catch(error => {
      console.error(error);
      aiError.value = true;
      aiLoading.value = false;
    });
}

const selectAiImage = () => {
  isModalOpen.value = false;
  currentImage.value = aiImage.value;
}

const changeImage = () => {
  isModalOpen.value = true;
  if (currentTab.value === 'ai' && aiImage.value === '') {
    generateAIImage();
  }
}

watch(() => currentTab.value, (newTab, oldTab) => {
  if (newTab === 'ai') {
    if (aiImage.value === '') {
      generateAIImage();
    }
  }
});

</script>