<template>
  <div>
    <!-- if there is more than 15 amounts, use the select instead -->
    <div v-if="Object.keys(giftCardAmounts).length > 15" class="sm:col-span-3">
      <select
        id="gift-amount" v-model="selectedAmount"
        class="w-full border-gray-300 dark:border-gray-700 dark:bg-white/[0.05] dark:text-gray-300 focus:border-youpay-teal dark:focus:border-gray-700 focus:ring-youpay-teal dark:focus:ring-youpay-teal rounded-md shadow-sm leading-7"
      >
        <option class="text-black" value="0" selected>{{ formatMoney(0) }}</option>
        <option class="text-black" v-for="(formatted, amount) in giftCardAmounts" :key="amount" :value="amount">{{ formatted }}</option>
      </select>
    </div>
    <div v-else class="grid grid-cols-4 md:grid-cols-5 gap-4">
      <button
        v-for="(formatted, amount) in giftCardAmounts"
        :key="amount"
        class=" border border-gray-300 rounded-md shadow py-3 flex items-center justify-center cursor-pointer "
        :class="selectedAmount === amount ? 'bg-youpay-navy dark:bg-youpay-teal text-white outline outline-1 outline-blue-400 dark:outline-youpay-teal border-transparent dark:text-black' : 'bg-white dark:bg-white/5'"
        @click="selectAmount(amount)"
      >
        <span class="text-sm font-medium">{{ formatted }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const selectedAmount = defineModel<number | null>();

const props = defineProps<{
  giftCardAmounts: number[];
  currency: string;
}>();

const formatMoney = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: props.currency,
    // show currency symbol but not code
    currencyDisplay: 'narrowSymbol',
    // no decimal places
    minimumFractionDigits: 2,
  }).format(amount);
}

const selectAmount = (amount: number) => {
  selectedAmount.value = amount;
}
</script>