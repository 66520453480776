<template>
  <div class="mx-auto w-full flex-grow max-w-lg bg-white p-8 dark:bg-white/5 dark:text-white pb-12">
    <h1 class="text-2xl font-semibold pt-2 pb-6 text-center text-youpay-black dark:text-white">
      How do you want to fund your gift?
    </h1>
    <div class="space-y-4">
      <div v-for="option in options" v-show="showOption(option)">
        <label :for="option.value"
               class="flex items-center w-full rounded-md gap-x-4 outline outline-1 px-4 hover:outline-youpay-teal dark:bg-white/5"
               :class="option.value === selectedFunding ? 'outline-youpay-navy' : 'outline-gray-400'"
        >
          <span class="w-full pt-4" :class="option.value === 'visa' ? 'pb-4' : 'pb-4'">
            <span class="flex items-center w-full ">
              <input type="radio" :id="option.value" :value="option.value" v-model="selectedFunding" class="mr-2">
              <span class="flex items-center justify-between w-full px-4">
                <span>{{ option.label }}</span>
              </span>
            </span>

            <!-- No longer in use-->
            <span v-if="option.value === 'visa'" class="hidden">
              <span class="flex flex-row items-center justify-between w-full text-xs mt-1  mb-2">
                <span
                  class="xs:ml-10 text-[11px] sm:text-xs px-2 py-1 rounded-md bg-yellow-50 text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                  <span v-if="country === 'US'">
                    For use in the <strong>USA only</strong>
                  </span>
                  <span v-else>
                    For use <strong>outside</strong> the USA
                  </span>
                </span>
                <span>
                  <Link class="underline cursor-pointer " :href="route('settings.addresses')">
                    Change Country
                  </Link>
                </span>
              </span>
            </span>

          </span>
        </label>

      </div>
    </div>

    <PrimaryButton class="w-full mt-4" @click="nextStep" v-if="showButton">
      {{ buttonText }}
    </PrimaryButton>

    <PrimaryButton class="w-full mt-4" disabled v-else>
      Only available in the USA
    </PrimaryButton>

    <div class="mt-8">
      <h3 class="text-lg font-bold mb-1">About {{ currentFunding.label }}</h3>
      <p class="text-gray-600 py-2">
        {{ currentFunding.information }}
      </p>
      <a :href="currentFunding.link" target="_blank" class="text-blue-500">Learn more</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import {Link, router, usePage} from "@inertiajs/vue3";
import {User} from "@/web/youpay-me-v3/types";

const props = defineProps<{
  country: string;
}>();

const user = <User>usePage().props.auth;
const canCreateConnect = user?.can?.createConnect;
const canCreateCoinflow = user?.can?.createCoinflow;

const options = [
  {
    label: 'Visa or Mastercard Gift Card',
    value: 'visa',
    icon: 'visa',
    image: '',
    information: 'Receive a Visa or Mastercard eGift card in your email inbox, ready for immediate use online or anywhere Visa or Mastercard debit is accepted.',
    link: 'https://youpay.freshdesk.com/support/solutions/folders/51000442937',
  },
  {
    label: 'Coinflow - Cash Gift',
    value: 'coinflow',
    icon: 'cash',
    information: 'Receive a gift, directly deposited into your Coinflow account.',
    link: 'https://youpay.freshdesk.com/support/solutions/folders/51000415669',
  },
  {
    label: 'Stripe Connect - Cash Gift',
    value: 'cash-gift',
    icon: 'cash',
    information: 'Receive a cash gift, directly deposited into your bank account. Available in US only.',
    link: 'https://youpay.freshdesk.com/support/solutions/folders/51000415669',
  },
  {
    label: 'Gift Card',
    value: 'gift-card',
    icon: 'gift-cards',
    information: 'Receive a digital gift card in your email inbox, ready for immediate use at your chosen store.',
    link: 'https://youpay.freshdesk.com/support/solutions/folders/51000415667',
  },
  {
    label: 'YouPay Partner Store',
    value: 'partner-store',
    icon: 'youpay',
    information: 'Visit a partner store directly and choose YouPay when creating your cart. Gifts will be shipped directly to you from the partner store.',
    link: 'https://youpay.freshdesk.com/support/solutions/folders/51000415666',
  },
  {
    label: 'Crowdgift',
    value: 'crowdgift',
    icon: 'cash',
    information: 'Let your fans contribute variable amounts for those big milestones and gifts',
    link: 'https://support.youpay.co/support/solutions/articles/51000418610-how-to-enable-the-crowdgift-feature'
  },
];

const showOption = (option) => {
  if (option.value === 'cash-gift' && !canCreateConnect) {
    return false;
  }
  if (option.value === 'coinflow' && !canCreateCoinflow) {
    return false;
  }
  if (option.value === 'crowdgift' && !canCreateCoinflow) {
    return false;
  }
  return true;
}

const showButton = computed(() => {
  return (canCreateConnect && selectedFunding.value === 'cash-gift') || selectedFunding.value !== 'cash-gift';
})

const selectedFunding = ref('visa');

const currentFunding = computed(() => {
  return options.find(option => option.value === selectedFunding.value);
});

watch(() => selectedFunding.value, (newVal, oldVal) => {
  console.log('Selected funding:', newVal);
});

const buttonText = computed(() => {
  switch (selectedFunding.value) {
    case 'crowdgift':
    case 'coinflow':
    case 'visa':
    case 'cash-gift':
      return 'Continue';
    case 'gift-card':
      return 'Browse Gift Card Stores';
    case 'partner-store':
      return 'Browse Partner Stores';
  }
});

function nextStep() {
  switch (selectedFunding.value) {
    case 'visa':
      router.visit(
        route('create-gift', 'visa')
      );
      break;
    case 'crowdgift':
      router.visit(
        route('fund.create')
      );
      break;
    case 'coinflow':
      router.visit(
        route('create-gift', 'coinflow')
      );
      break;
    case 'cash-gift':
      router.visit(
        route('create-gift', 'connect')
      );
      break;
    case 'gift-card':
      router.visit(
        route('gift.stores.giftcards')
      );
      break;
    case 'partner-store':
      router.visit(
        route('gift.stores.shopify')
      );
      break;
  }
}
</script>