<template>
  <Head title="Dashboard" />
  <MainPanel>
    <ais-instant-search
      :search-client="searchClient"
      :index-name="scout.prefix + 'pages'"
    >
      <ais-search-box class="border-b border-gray-100 dark:border-gray-700">
        <template v-slot="{ currentRefinement, isSearchStalled, refine }">
          <MagnifyingGlassIcon
            class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400 dark:text-gray-500"
            aria-hidden="true"/>
          <input
            class="
            h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900
            dark:text-gray-100 placeholder:text-gray-400
            outline-none
            dark:placeholder:text-gray-500 focus:ring-0 sm:text-sm
          "
            placeholder="What do you want to do?"
            type="search"
            :value="currentRefinement"
            @input="refine($event.currentTarget.value)"
          >
          <span :hidden="!isSearchStalled">Loading...</span>
        </template>
      </ais-search-box>
      <ais-configure
        :hitsPerPage="8"
      />
      <ais-index :index-name="scout.prefix + 'stores'" />
      <ais-index :index-name="scout.prefix + 'accounts'" />

      <customStateResults />
    </ais-instant-search>
  </MainPanel>
</template>
<script setup lang="ts">
// import the debounce function
import {debounce} from 'lodash'
import {Head, Link, usePage, router} from '@inertiajs/vue3';
import MainLayout from "@/web/youpay-me-v3/Layouts/MainLayout.vue";
import SuggestGifts from "@/web/youpay-me-v3/Components/Chat/SuggestGifts.vue";
import GiftFromUrl from "@/web/youpay-me-v3/Components/Chat/GiftFromUrl.vue";
import ProfilesList from "@/web/youpay-me-v3/Components/Chat/ProfilesList.vue";
import CustomStateResults from "@/web/youpay-me-v3/Components/Chat/CustomStateResults.vue";
import {computed, onMounted, ref, watch} from 'vue'
import {Channel, Message} from '@/web/youpay-me-v3/Components/Chat/classes'
import {MagnifyingGlassIcon} from '@heroicons/vue/20/solid'
import {
  CalendarIcon,
  CodeBracketIcon,
  DocumentIcon,
  LinkIcon,
  PencilSquareIcon,
  BanknotesIcon,
  UserCircleIcon,
  LockClosedIcon,
  GlobeAltIcon,
  GiftIcon,
  PhotoIcon,
  TableCellsIcon,
  VideoCameraIcon,
  ViewColumnsIcon,
  Bars4Icon,
} from '@heroicons/vue/24/outline'
import axios from "axios";
import DashLayout from "@/web/youpay-me-v3/Layouts/DashLayout.vue";
import MainPanel from "@/web/youpay-me-v3/Layouts/MainPanel.vue";


// Random number for window
const user = usePage().props.auth

defineOptions({layout: DashLayout})

import instantsearch from 'instantsearch.js';
import {instantMeiliSearch} from '@meilisearch/instant-meilisearch'
import ChatWidget from "@/web/youpay-me-v3/Components/Chat/ChatWidget.vue";

const scout = usePage().props.scout;

const searchClient = instantMeiliSearch(
  scout.host, // Host
  scout.search_key // API key
)

const onStateChange: Function = (x, y, z) => {
  console.log(x, y, z)
}
</script>