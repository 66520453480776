import {Profile} from "@/web/youpay-me-v3/types/index";

export interface BagItem {
    id: number,
    gift_id: number,
    title: string,
    description: string,
    image: string,
    amount: number,
    quantity: number,
    total: string,
}

export interface Customer {
    id: number,
    name: string,
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    address: {
        id: string,
        name: string,
        first_name: string,
        last_name: string,
        phone: string,
        address_1: string,
        address_2: string,
        suburb: string,
        state: string,
        state_code: string,
        country: string,
        country_code: string,
        postcode: string,
        parent_id: string,
        primary: boolean,
        created_at: string,
        updated_at: string,
    },
}

export interface Bag {
    id: number,
    currency: string,
    message?: string,
    items: Array<BagItem>,
    sub_total: string,
    fee: string,
    total: string,
    raw_total: number,
    customer?: any,
    hasCoinflow: boolean,
}

export interface CartItem {
    id: number,
    title: string,
    description: string,
    image: string,
    amount: number,
    quantity: number,
    total: string,
}

export interface Cart {
    id: number,
    currency: string,
    items: Array<CartItem>,
    sub_total: string,
    shipping: string,
    total: string,
    raw_total: number,
}

export interface Order {
    id: number,
    currency: string,
    items: Array<{
        id: number,
        gift_id: number,
        title: string,
        description: string,
        image: string,
        amount: number,
        quantity: number,
        total: string,
    }>,
    message?: string,
    payer?: Profile,
    shopper?: Profile,
    sub_total: string,
    fee: string,
    total: string,
    raw_total: number,
}

export interface StripeConfig {
    key: string,
    secret: string,
}

export interface StripeAddress {
    name: string,
    phone: string,
    address: Array<{
        city: string,
        country: string,
        line1: string,
        line2: string,
        postal_code: string,
        state: string,
    }>,
}

export interface AutoFill {
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    phone: string | null,
    message: string | null,
    country: string | null,
}

interface gaCheckoutData {
    transaction_id?: string,
    currency: string,
    value: number,
    items: Array<{
        item_id: number,
        item_name: string,
        price: number,
        quantity: number,
        affiliation: string,
    }>,
}

export function compileForAnalytics(username, bag): gaCheckoutData {
    return {
        currency: bag.currency,
        value: bag.raw_total,
        items: bag.items.map(item => {
            return {
                item_id: item.gift_id,
                item_name: item.title,
                price: item.amount,
                quantity: item.quantity,
                affiliation: username,
            };
        }),
    }
}