<template>
  <div class="">
    <div class="-mt-2 min-h-[100px]">
      <div id="cardData" ref="cardDataDiv"></div>
      <div id="wallet-buttons"></div>
    </div>

    <InputError :message="form.errors.error" class="mt-2"/>

    <button
      class="mt-6 w-full rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-youpay-navy-light hover:bg-youpay-navy-alt focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      type="submit"
      @click.stop="submit"
    >
      <span v-if="!form.processing">
        Pay {{ props.bag.total }}
      </span>
      <span v-else>
        Processing...
      </span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, ref} from 'vue'
import {Bag, compileForAnalytics} from "@/web/youpay-me-v3/types/checkout.d";
import {Profile, User} from "@/web/youpay-me-v3/types";
import {useForm} from 'laravel-precognition-vue-inertia';
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import {loadScript, resetPayframe, submitPayframe} from "@/web/youpay-me-v3/helpers/MerchantWarrior";
import type {warriorConfig, address, IpDetails} from "@/web/youpay-me-v3/helpers/MerchantWarrior";
import CheckoutLayout from "@/web/youpay-me-v3/Layouts/CheckoutLayout.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";

defineOptions({layout: CheckoutLayout})

// Get Props
const props = defineProps<{
  bag: Bag,
  paymentConfig: warriorConfig,
  ipdetails?: IpDetails,
}>();


const form = useForm('post', route('checkout.payment', {
  bag: props.bag.id,
  recaptcha: '',
}), {
  gateway: 'warrior',
  PaymentData: { // TODO: Change name and refs
    reference: 'BAG-' + props.bag.id,
    description: 'BAG-' + props.bag.id,
    amount: props.bag.raw_total,
    currency: props.bag.currency,
  },
});

const submit = async function(e) {
  e.preventDefault();

  window.grecaptcha.ready(function() {
    window.grecaptcha
      .execute('6LfV_fIpAAAAAPhXfOeJ8bkNdftDqmWYxxGN1DM6', {action: 'submit'})
      .then(function(token) {
        form.recaptcha = token;
        performSubmit();
      });
  });
}
const performSubmit = async function() {
  if (form.hasErrors) {
    return;
  }

  form.processing = true;
  submitPayframe();
}

const completeCheckout = function (payframeToken: string, payframeKey: string, threeDSToken: string = '', liabilityShifted: boolean = false) {
  // try {
  //   const gaData = compileForAnalytics(props.profile.username, props.bag);
  //   // gaData.transaction_id = result.paymentIntent.id;
  //   trackEvent('purchase', gaData);
  // } catch (e) {} // Silently fail

  form.transform((data: Object) => ({
    ...data,
    payment_data: {
      accessToken: props.paymentConfig.config.token,
      payframeToken,
      payframeKey,
      threeDSToken,
      liabilityShifted,
    }
  })).submit({
    onError: (error) => {
      console.error(error);
      form.setError('error', 'There was an error with that request, please try again.');
      resetPayframe(true);
    }
  });
}

const cardDataDiv = ref('cardDataDiv');

/**
 * Load Stripe
 */
onMounted(async () => {
  loadScript(props.paymentConfig.config, completeCheckout, form, cardDataDiv.value);

  // load ReCaptcha
  const script = document.createElement('script');
  script.src = 'https://www.google.com/recaptcha/api.js?render=6LfV_fIpAAAAAPhXfOeJ8bkNdftDqmWYxxGN1DM6';
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
});
</script>