<template>
  <div class="">
    <PrimaryButton
      class="w-full flex items-center justify-center gap-x-2"
      @click="open = true"
    >
      <CurrencyDollarIcon class="h-4 animate-beat w-4 text-gray-100" aria-hidden="true"/>
      <slot name="button">
        YouPay Me Now
      </slot>
    </PrimaryButton>

    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-50" @close="open = false" :initialFocus="submitButton">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 dark:bg-black/60 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto w-full">
          <div class="bottom-0 flex items-end justify-end h-full md:min-h-full w-full md:items-center md:justify-center md:p-4 text-center">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 scale-95" enter-to="opacity-100 translate-y-0 scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 scale-100" leave-to="opacity-0 translate-y-4 scale-95">
              <DialogPanel class="relative transform overflow-hidden bg-white dark:bg-gray-900 rounded-xl text-left shadow-xl transition-all md:my-8 w-full md:max-w-xl">
                <div class="absolute right-0 top-0 pr-4 pt-4">
                  <button type="button" class="rounded-md bg-white dark:bg-white/5 p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="open = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="flex flex-row min-h-full">
                  <div class="hidden md:flex w-full md:w-1/3 bg-gray-50 dark:bg-white/5 p-6 items-center justify-center">
                    <div class="text-center mx-auto">
                      <img v-if="profile.avatar" :src="profile.avatar" :alt="profile.display_name" class="mx-auto w-30 h-30 md:h-38 md:w-38 rounded-full object-cover shadow-lg" />
                      <UserCircleIcon v-else class="h-auto w-30 md:w-48 p-8 text-gray-400 dark:text-gray-600" />
                      <h3 class="mt-4 text-lg font-semibold text-gray-900 dark:text-white">{{ profile.display_name }}</h3>
                    </div>
                  </div>
                  <div class="w-full md:w-2/3 py-6 px-4 md:px-6 flex flex-col gap-y-7 md:gap-y-4 justify-center">
                    <div class="flex flex-row items-center justify-center">
                      <span class="pr-5">
                        <img v-if="profile.avatar" :src="profile.avatar" :alt="profile.display_name" class="block md:hidden mx-auto h-auto w-15 rounded-full object-cover shadow-lg" />
                      </span>
                      <div class="md:text-center flex-grow">
                        <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 dark:text-white flex md:items-center md:justify-center">
                          <CurrencyDollarIcon class="h-6 w-6 text-green-500 mr-2" aria-hidden="true" />
                          <slot name="title">
                            YouPay Me Now
                          </slot>
                        </DialogTitle>
                        <div class="mt-2">
                          <p class="text-sm text-gray-500 dark:text-gray-200">
                            <slot name="description">
                              Send a gift of any value to {{ profile.display_name }}
                            </slot>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="flex items-center gap-x-2 justify-between">
                        <span class="rounded-full bg-gray-100 dark:bg-white/5 p-2">
                          <MinusIcon class="h-6 w-6 text-gray-500 cursor-pointer" @click="form.amount -= 10" />
                        </span>
                        <CurrencyInput
                          class="mt-1 block text-center w-full xs:border-none bg-gray-100 py-6 md:py-3 text-3xl md:text-xl font-bold"
                          v-model="form.amount"
                          @keyup.enter="submit"
                          currency="USD"
                          inputmode="decimal"
                          pattern="[0-9]*"
                        />
                        <span class="rounded-full bg-gray-100 dark:bg-white/5 p-2">
                          <PlusIcon class="h-6 w-6 text-gray-500 cursor-pointer" @click="form.amount += 10" />
                        </span>
                      </div>
                      <InputError class="mt-1" :message="form.errors.amount" />
                    </div>
                    <!-- Quick buttons to change the amount to $20, $50, $100 -->
                    <div class="flex justify-center gap-x-2">
                      <SecondaryButton
                        type="button"
                        class="w-1/3"
                        @click="form.amount = 20"
                      >
                        $20
                      </SecondaryButton>
                      <SecondaryButton
                        type="button"
                        class="w-1/3"
                        @click="form.amount = 50"
                      >
                        $50
                      </SecondaryButton>
                      <SecondaryButton
                        type="button"
                        class="w-1/3"
                        @click="form.amount = 111"
                      >
                        $111
                      </SecondaryButton>
                      <SecondaryButton
                        type="button"
                        class="w-1/3"
                        @click="form.amount = 1111"
                      >
                        $1111
                      </SecondaryButton>
                    </div>
                    <div class="">
                      <PrimaryButton
                        type="button"
                        class="w-full"
                        @click="submit"
                        ref="submitButton"
                      >
                        <slot name="submit">
                          Send Gift
                        </slot>
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {CurrencyDollarIcon, UserCircleIcon, XMarkIcon, PlusIcon, MinusIcon} from '@heroicons/vue/24/outline'
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import { Profile } from "@/web/youpay-me-v3/types";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import CurrencyInput from "@/web/youpay-me-v3/Components/Form/CurrencyInput.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import SecondaryButton from "@/web/youpay-me-v3/Components/Buttons/SecondaryButton.vue";

const props = defineProps<{
  profile: Profile,
  fund_id?: Number
}>();

const open = ref(false)
const submitButton = ref<HTMLButtonElement | null>(null)

const form = useForm('post', route('giftbag.surprise', props.profile.username), {
  amount: 0,
  fund_id: props.fund_id
});

const submit = () => {
  form.submit({
    onSuccess: () => {
      open.value = false;
    }
  });
}
</script>