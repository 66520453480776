<template>
  <div class="h-full">
    <div v-if="owner" class="h-full flex-grow text-youpay-black dark:text-white">
      <div class="h-full relative isolate overflow-hidden bg-youpay-teal-ultralight dark:bg-gray-800 px-6 pt-16 sm:px-16 md:pt-24 flex lg:px-24 lg:pt-0 items-center w-full">
        <div class="relative lg:flex lg:gap-x-20 w-full">
          <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">
            <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stop-color="#7775D6" />
                <stop offset="1" stop-color="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div class="mx-auto lg:max-w-md 2xl:max-w-xl lg:mx-0 lg:flex-auto lg:py-12">
            <h2 class="text-3xl font-bold tracking-tight text-youpay-black dark:text-gray-100 sm:text-4xl text-center lg:text-left">
              How do you want to get gifted?
            </h2>
            <p class="mt-6 text-lg leading-8 mb-6 text-youpay-black dark:text-gray-400 text-center lg:text-left">
              Let's get started by adding your first gift!
            </p>
            <div class="flex flex-col gap-y-4">
              <Link :href="user?.can?.createCoinflow ? route('create-gift', 'coinflow') : route('settings.coinflow.index')" class="flex items-center justify-between p-4 bg-white/70 dark:bg-white/10 rounded-lg shadow-sm transition-all">
                <div class="flex items-center gap-x-4 w-full">
                  <IconCoinflow class="w-8 h-8 dark:fill-white" />
                  <div class="flex-grow">
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                      Cash Gift via Coinflow
                    </h3>
                    <p v-if="!user?.can?.createCoinflow" class="text-xs xs:text-sm text-gray-800 dark:text-gray-400">
                      Setup Coinflow to receive cash gifts
                    </p>
                    <p v-else class="text-xs xs:text-sm text-gray-800 dark:text-gray-400">
                      Add a coinflow gift to your wishlist
                    </p>
                  </div>
                  <ChevronRightIcon class="h-6 w-6 flex-none text-gray-400 dark:text-gray-100" aria-hidden="true" />
                </div>
              </Link>
              <Link v-if="user?.can?.createConnect" :href="user?.can?.createConnectGift ? route('create-gift', 'connect') : route('connect.index')" class="flex items-center justify-between p-4 bg-white/70 dark:bg-white/10 rounded-lg shadow-sm transition-all">
                <div class="flex items-center gap-x-4 w-full">
                  <IconStripe class="w-8 h-8 dark:fill-white" />
                  <div class="flex-grow">
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                      Cash Gift via Stripe
                    </h3>
                    <p v-if="!user?.can?.createConnectGift" class="text-xs xs:text-sm text-gray-800 dark:text-gray-400">
                      Setup Stipe Connect to receive cash gifts
                    </p>
                    <p v-else class="text-xs xs:text-sm text-gray-800 dark:text-gray-400">
                      Add a Stripe Connect gift to your wishlist
                    </p>
                  </div>
                  <ChevronRightIcon class="h-6 w-6 flex-none text-gray-400 dark:text-gray-100" aria-hidden="true" />
                </div>
              </Link><Link :href="route('create-gift', 'visa')" class="flex items-center justify-between p-4 bg-white/70 dark:bg-white/10 rounded-lg shadow-sm transition-all">
              <div class="flex items-center gap-x-4 w-full">
                <CreditCardIcon alt="Gift" class="w-8 h-8" />
                <div class="flex-grow">
                  <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                    Prepaid Debit Card
                  </h3>
                  <p class="text-xs xs:text-sm text-gray-800 dark:text-gray-400">
                    Receive a Visa or Mastercard prepaid debit card
                  </p>
                </div>
                <PlusIcon class="h-6 w-6 flex-none text-gray-400 dark:text-gray-100" aria-hidden="true" />
              </div>
            </Link>
              <Link :href="route('stores')" class="flex items-center justify-between p-4 bg-white/70 dark:bg-white/10 rounded-lg shadow-sm transition-all">
                <div class="flex items-center gap-x-4 w-full">
                  <GiftIcon alt="Gift" class="w-8 h-8" />
                  <div class="flex-grow">
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                      Gift Card
                    </h3>
                    <p class="text-xs xs:text-sm text-gray-800 dark:text-gray-400">
                      Receive a gift card for your favorite store
                    </p>
                  </div>
                  <PlusIcon class="h-6 w-6 flex-none text-gray-400 dark:text-gray-100" aria-hidden="true" />
                </div>
              </Link>
            </div>
          </div>
          <div class="relative mt-16 h-80 lg:mt-8">
            <img class="absolute block dark:hidden left-0 top-0 w-[57rem] max-w-none rounded-md" src="@/web/youpay-me-v3/Icons/img-screen-nue-light-2.jpg" alt="App screenshot" width="1824" height="1080" />
            <img class="absolute hidden dark:block left-0 top-0 w-[57rem] max-w-none rounded-md" src="@/web/youpay-me-v3/Icons/img-screen-nue-dark-2.jpg" alt="App screenshot" width="1824" height="1080" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="xl:m-6 m-4 dark:text-white">
      <div class="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50 sm:text-5xl">{{ profile.username }} currently has no gifts on this wishlist</h1>
        <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300 sm:mt-6 sm:text-lg sm:leading-8">Let them know you want to send them something!</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Link, usePage} from "@inertiajs/vue3";
import {ChevronRightIcon, CreditCardIcon, PlusIcon} from "@heroicons/vue/20/solid";
import {GiftIcon, PencilSquareIcon} from "@heroicons/vue/24/solid";
import {UserCircleIcon} from "@heroicons/vue/24/outline";
import {Profile, User} from "@/web/youpay-me-v3/types";
import IconCoinflow from "@/web/youpay-me-v3/Icons/IconCoinflow.vue";
import IconStripe from "@/web/youpay-me-v3/Icons/IconStripe.vue";

const flags = usePage().props.featureFlags;
const user = <User>usePage().props.auth;

defineProps<{
  owner: boolean,
  profile: Profile
}>()

const links = [
  {
    name: 'Add a gift',
    href: route('gift.index'),
    description: 'Add a gift to your wishlist',
    icon: GiftIcon,
  },
  {
    name: 'Change your profile',
    href: route('settings.public'),
    description: 'Add a profile or banner image and give your profile some life!',
    icon: UserCircleIcon
  },
  {
    name: 'Add another wishlist',
    href: route('wishlist.create'),
    description: 'Start creating other wishlists.',
    icon: PencilSquareIcon
  },
  // {
  //   name: 'Purchased Gifts',
  //   href: route('settings.purchased'),
  //   description: 'View the gifts you have sent others.',
  //   icon: CurrencyDollarIcon,
  // },
]
</script>
