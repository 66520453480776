<template>
  <div class="h-full w-full bg-white dark:bg-gray-900 flex-grow">
  <main class="w-full">
    <header class="py-8 w-full bg-gray-100 dark:bg-gray-950  mx-auto">
      <div class="max-w-7xl mx-auto">
      <div class="md:flex justify-between items-center">
        <div class="flex md:justify-center items-center gap-x-4 px-6 lg:px-0">
          <Link class="relative flex items-start mb-2 md:p-2" :href="route('profile', profile.username)">
            <img class="w-30 h-30 rounded-xl" :src="profile.avatar" :alt="profile.username" />
          </Link>
          <div class="flex flex-col py-2 gap-y-3 dark:text-white">
            <div class="md:flex flex-row items-center gap-x-4" >
              <div class="text-2xl font-semibold leading-7">
                {{ profile.display_name }}
              </div>
              <div class="text-sm leading-6 font-extralight">
                @{{ profile.username }}
              </div>
            </div>

            <div class="">
              <div class="rounded-full box-content px-5 py-1 inline-flex items-center text-xs font-medium ring-1 ring-inset"
                :class="teirClass"
              >
                <span class="text-xl pr-2">{{ gamer.level.level }}</span>
                <span>{{ gamer.level.tier }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row items-center justify-between px-6 py-6 my-4 lg:pr-0 md:items-end gap-x-4 h-full md:flex-col gap-y-4 bg-gray-50 dark:bg-white/5 md:bg-transparent dark:md:bg-transparent">
          <div class="dark:text-white text-base md:text-xl lg:text-2xl">
            {{ gamer.balance }} YouCoin
          </div>
          <div class="flex-none">
            <Link :href="route('redeem', profile.username)" class="py-2 bg-youpay-navy-light hover:bg-blue-600 text-white px-4 rounded-lg text-sm font-semibold leading-5 focus:outline-none focus:ring focus:ring-blue-400/30">
              Redeem
            </Link>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-y-4 px-6 lg:px-0 gap-x-4 my-8">
        <div v-for="(stat, statIdx) in gamer.stats" :key="stat.name" class="h-full bg-white dark:bg-white/5 p-5 shadow-md rounded-lg flex flex-col items-center justify-center">
          <div class="text-xl md:text-2xl font-semibold leading-7 text-youpay-black dark:text-white">{{ stat.value }}</div>
          <div class="leading-6 text-xs md:text-sm text-youpay-black dark:text-gray-400">
            {{ stat.name }}
          </div>
        </div>
      </div>
      <!-- Level bar, showing the level currently on the left of the bar, the level coming on the right of the bar, and the current/levelXP in the center -->
      <div class="flex flex-col gap-x-4 gap-y-3 px-6 lg:px-0">
        <div class="flex-grow">
          <div class="relative h-2 bg-gray-200 dark:bg-white/5 rounded-full">
            <div class="absolute left-0 h-2 bg-youpay-navy-light rounded-full" :style="{ width: gamer.level.percent + '%' }"></div>
            <div class="absolute right-0 h-2 bg-gray-200 dark:bg-white/5 rounded-full" :style="{ width: (100 - gamer.level.percent) + '%' }"></div>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div class="text-xs font-semibold leading-5 text-gray-400 dark:text-gray-400">
            {{ gamer.level.label }}
          </div>
          <div class="text-xs font-semibold leading-5 text-gray-400 dark:text-gray-400">
            {{ gamer.level.progress }}
          </div>
          <div class="text-xs font-semibold leading-5 text-gray-400 dark:text-gray-400">
            {{ gamer.level.next_level_label }}
          </div>
        </div>
      </div>
      </div>
    </header>

    <div class="max-w-7xl mx-auto">
      <div class="py-8">
        <div class="text-base font-semibold leading-7 text-youpay-navy-dark dark:text-white pb-6 flex items-center justify-center flex-row gap-x-18">
          <a class="cursor-pointer border-blue-400 pb-4" :class="tab === 'trophies' ? 'border-b' : ''" @click="tab = 'trophies'">
            My Achievements
          </a>
          <a class="cursor-pointer border-blue-400 pb-4" :class="tab === 'activity' ? 'border-b' : ''" @click="tab = 'activity'">
            My Activity
          </a>
          <a class="cursor-pointer border-blue-400 pb-4" :class="tab === 'FAQ' ? 'border-b' : ''" @click="tab = 'FAQ'">
            FAQ
          </a>
        </div>
        <div class="mt-6">
          <div v-if="tab === 'trophies'" class="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-7 gap-x-4 gap-y-4">
            <div v-for="trophy in trophies" class="">
              <Trophy :trophy="trophy" />
            </div>
          </div>
          <div v-else class="w-full block">
            <table class="mt-6 w-full whitespace-nowrap text-left">
              <colgroup>
                <col class="w-full sm:w-4/12" />
                <col class="lg:w-4/12" />
                <col class="lg:w-2/12" />
                <col class="lg:w-1/12" />
              </colgroup>
              <thead class="border-b border-blue-800 dark:border-white/10 text-sm leading-6 text-youpay-navy dark:text-white">
              <tr>
                <th scope="col" class="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">Event</th>
                <th scope="col" class="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">Related</th>
                <th scope="col" class="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 lg:pr-20">XP</th>
                <th scope="col" class="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 lg:pr-20">Reward Points</th>
                <th scope="col" class="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">Created at</th>
              </tr>
              </thead>
              <tbody class="divide-y divide-blue-800 dark:divide-white/5">
              <tr v-for="item in gamer.activity" :key="item.id">
                <td class="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                  <div class="flex items-center gap-x-4">
                    <component :is="getIcon(item.event)" class="flex-none w-9 h-9 p-2 text-gray-700 dark:text-gray-400" />
                    <div class="truncate text-sm font-medium leading-6 dark:text-white">{{ item.event_name }}</div>
                  </div>
                </td>
                <td class="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                  <div class="flex gap-x-3">
                    <div class="font-mono text-sm leading-6 dark:text-gray-400" v-html="item.related"></div>
                    <div class="underline text-youpay-navy dark:text-youpay-pink hidden"></div>
                  </div>
                </td>
                <td class="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                  <div class="flex items-center justify-end gap-x-2">
                    <span class="text-sm font-semibold text-green-600 dark:text-green-400">{{ item.xp }}</span>
                    <component :is="item.xp_raw >= 0 ? ArrowUpIcon : ArrowDownIcon" class="w-4 h-4 text-green-600 dark:text-green-400" />
                  </div>
                </td>
                <td class="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                  <div class="flex items-center justify-end gap-x-2">
                    <span class="text-sm font-semibold text-green-600 dark:text-green-400">{{ item.points }}</span>
                    <component :is="item.points_raw >= 0 ? ArrowUpIcon : ArrowDownIcon" class="w-4 h-4 text-green-600 dark:text-green-400" />
                  </div>
                </td>
                <td class="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-700 dark:text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                  <time :datetime="item.date">{{ item.date_formatted }}</time>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
  </div>
</template>

<script setup lang="ts">
import {Link} from "@inertiajs/vue3";
import {UserIcon} from "@heroicons/vue/20/solid";
import {ArrowRightIcon, ArrowDownOnSquareIcon, ArrowUpIcon, ArrowDownIcon, GiftIcon, HeartIcon, ExclamationTriangleIcon, GiftTopIcon, QuestionMarkCircleIcon} from "@heroicons/vue/24/outline";
import PrimaryLink from "@/web/youpay-me-v3/Components/Buttons/PrimaryLink.vue";
import type {Profile} from "@/web/youpay-me-v3/types";
import Trophy from "@/web/youpay-me-v3/Components/Games/Trophy.vue";
import {computed, ref} from "vue";
import type {Gamer} from "@/web/youpay-me-v3/types/games";

const props = defineProps<{
  profile: Profile,
  gamer: Gamer,
  trophies: { icon: string, name: string, description: string, isUnlocked: boolean }[]
}>();

const tab = ref('trophies');

const teirClass = computed(() => {
  const tier = props.gamer.level.tier.toLowerCase();
  if (tier === 'bronze') return 'bg-yellow-400/10 text-yellow-400 ring-yellow-400/30';
  if (tier === 'silver') return 'bg-gray-300/30 text-gray-900 dark:text-white dark:bg-gray-400/20 dark:text-gray-100 ring-gray-400 dark:ring-gray-400';
  if (tier === 'gold') return 'bg-yellow-400/10 text-yellow-400 ring-yellow-400/30';
  if (tier === 'platinum') return 'bg-blue-400/10 text-blue-400 ring-blue-400/30';
  return 'bg-indigo-400/10 text-indigo-400 ring-indigo-400/30';
});

function getIcon(event: string) {
  switch (event) {
    case 'create_wishlist':
      return GiftTopIcon
    case 'new_present':
      return GiftIcon
    case 'new_order':
      return HeartIcon
    case 'points_expired':
      return ExclamationTriangleIcon
    case 'legacy_points':
      return ArrowDownOnSquareIcon
    default:
      return QuestionMarkCircleIcon
  }
}

</script>