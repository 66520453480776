<template>
  <Combobox as="div" v-model="selectedCountry">
    <div class="relative mt-2">
      <ComboboxInput
        class="w-full leading-7 border-gray-300 dark:border-gray-700 dark:bg-white/[0.05] dark:text-gray-300 focus:border-youpay-teal dark:focus:border-gray-700 focus:ring-youpay-teal dark:focus:ring-youpay-teal rounded-md shadow-sm"
        :class="error ? 'border-red-300 dark:border-red-700' : ''"
        @change="query = $event.target.value"
        :display-value="(country) => country?.name"
        aria-autocomplete="inline"
        autocomplete="nope"
      />

      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredCountries.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption v-for="country in filteredCountries" :key="country.code" :value="country" as="template" v-slot="{ active, selected }">
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ country.name }}
            </span>

            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'
import { getNames, getCode } from 'country-list'

const countries = getNames().map((name, index) => ({ id: index + 1, name, code: getCode(name) }))

const query = ref('')
const filteredCountries = computed(() => {
  let filtered = query.value === ''
    ? countries
    : countries.filter((country) => {
      return country.name.toLowerCase().includes(query.value.toLowerCase())
    })
  // Limit to 20 results
  return filtered.slice(0, 20)
})

const props = defineProps({
  error: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  }
})

let defaultCountry = countries.find((country) => country.code === props.modelValue);

const selectedCountry = ref(defaultCountry)

const emit = defineEmits(['update:modelValue'])

watch(() => selectedCountry.value, (newValue, oldValue) => {
  const country_code = newValue.code
  emit('update:modelValue', country_code)
})

watch(() => props.modelValue, (newValue, oldValue) => {
  const country_code = newValue
  console.log('newValue', newValue)
  selectedCountry.value = countries.find((country) => country.code === country_code)
});

</script>
