<template>
  <Popover as="nav" class="dark:bg-gray-900 bg-gray-100 shadow" v-slot="{ open, close }">
    <div class="mx-auto h-full flex items-center justify-between w-full px-4 sm:px-6 lg:px-8">
      <div class="flex">
        <!-- Logo -->
        <Link :href="route('dashboard')" class="flex flex-shrink-0 items-center">
          <youpay-color-logo class="pt-1 h-5 sm:h-7 w-auto block" full-logo/>
        </Link>

        <!-- Desktop Menu -->
        <div class="hidden sm:ml-6 md:flex sm:items-center space-x-2 lg:space-x-4">
          <Link v-for="item in navigation"
                :key="item.name"
                :href="route(item.route)"
                :class="[
                  currentRoute === item.route ?
                    'dark:bg-gray-800 bg-gray-100 dark:text-white text-black' :
                    'dark:text-gray-300 text-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 dark:hover:text-white hover:text-black',
                  'rounded-md px-3 py-2 text-xs md:text-sm font-medium'
                ]"
                v-show="item.enabled"
                :aria-current="currentRoute === item.route ? 'page' : undefined">
            {{ item.name }}
          </Link>
        </div>

      </div>
      <div class="flex items-center">
        <AddGift :class="isCheckout ? 'hidden lg:flex' : ''"/>


        <div class="ml-4 flex flex-shrink-0 items-center gap-x-4">
          <CurrencySelector v-if="isCheckout"/>

          <!-- Profile dropdown -->
          <PopoverButton
            class="flex rounded-full dark:bg-gray-800 bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-youpay-teal focus:ring-offset-2 focus:ring-offset-youpay-teal">
            <span class="sr-only">Open user menu</span>
            <span
              class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-cover"
              :class="user.avatar ? '' : 'bg-youpay-pink'"
              :style="user.avatar ? 'background-image: url(\'' + user.avatar + '\')' : ''"
            >
                <span v-if="!user.avatar" class="text-sm font-medium leading-none text-white">{{
                    user.initials
                  }}</span>
              </span>
          </PopoverButton>
        </div>
      </div>
    </div>

    <transition enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <PopoverPanel class="bg-white dark:bg-gray-800 border-y border-gray-200 dark:border-gray-900/50 shadow-lg md:rounded-b-lg w-full md:w-[250px] md:max-w-[600px] right-0 absolute md:mr-5">
        <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3 block md:hidden">
          <Link v-for="item in navigation" v-show="item.mobile && item.enabled" :key="item.name" as="a"
                :href="route(item.route)"
                :class="[item.current ? 'dark:bg-gray-900 bg-gray-100 dark:text-white text-black' : 'dark:text-gray-300 text-gray-700 dark:hover:bg-gray-700 hover:bg-gray-200 dark:hover:text-white hover:text-black', 'block rounded-md px-3 py-2 text-base font-medium']"
                :aria-current="item.current ? 'page' : undefined">{{ item.name }}
          </Link>
        </div>
        <div class="border-t md:border-t-0 dark:border-gray-700 border-gray-200 pb-3 pt-4">
          <div class="flex items-center px-5 sm:px-6">
            <div class="flex-shrink-0">
              <img v-if="user.avatar" class="h-10 w-10 rounded-full" :src="user.avatar" alt=""/>
              <span v-else class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                <span class="text-sm font-medium leading-none text-white">{{ user.initials }}</span>
              </span>
            </div>
            <div class="ml-3">
              <div class="text-base font-medium dark:text-white text-black">{{ user.name }}</div>
              <div class="text-sm font-medium dark:text-gray-400 text-gray-600">{{ user.username }}</div>
            </div>
          </div>
          <div class="mt-3 space-y-1 px-2 sm:px-3">
            <div v-for="item in userNav" :key="item.name">
              <Link v-if="item.href" as="a" :href="item.href"
                    class="block rounded-md px-3 py-1.5 text-base font-medium dark:text-gray-400 text-gray-600 dark:hover:bg-gray-700 hover:bg-gray-200 dark:hover:text-white hover:text-black">
                {{ item.name }}
              </Link>
              <a v-else-if="item.action" @click="item.action(close)"
                 class="cursor-pointer block rounded-md px-3 py-1.5 text-base font-medium dark:text-gray-400 text-gray-600 dark:hover:bg-gray-700 hover:bg-gray-200 dark:hover:text-white hover:text-black">
                {{ item.name }}
              </a>
            </div>
          </div>

          <div class="border-t dark:border-gray-700 border-gray-200 mt-4 pt-3 px-2 sm:px-3">
            <ThemeDropdown class="ml-3"/>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup lang="ts">
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover, PopoverButton, PopoverPanel
} from '@headlessui/vue'
import {Link, router, usePage} from "@inertiajs/vue3";
import ThemeDropdown from "@/web/youpay-me-v3/Components/Blocks/ThemeDropdown.vue";
import YoupayColorLogo from "@/web/youpay-me-v3/Components/YouPay/youpayColorLogo.vue";
import YoupayIcon from "@/web/youpay-me-v3/Components/YouPay/youpayIcon.vue";
import {computed, ref, onMounted, onUnmounted, inject} from "vue";
import {User} from "@/web/youpay-me-v3/types";
import CurrencySelector from "@/web/youpay-me-v3/Components/Buttons/CurrencySelector.vue";
import AddGift from "@/web/youpay-me-v3/Components/Buttons/AddGift.vue";

const user = <User>usePage().props.auth;
const flags: object = usePage().props.featureFlags;

const navigation = [
  {name: 'Dashboard', route: 'dashboard', enabled: true, mobile: true},
  {name: 'Profile', route: 'profile', enabled: true, mobile: false},
  {name: 'Rewards', route: 'play.home', enabled: flags?.gamer ?? false, mobile: true},
  {name: 'Partners', route: 'partners', enabled: true, mobile: true}
];

const currentRoute = ref(null);

const feedback = inject('feedback');

const openFeedback = async (close) => {
  close();
  const form = await feedback.createForm();
  form.appendToDom();
  form.open();
}

const userNav = computed(() => {
  let userNavigation = [
    {name: 'Your Profile', href: route('profile')},
    {name: 'Settings', href: route('settings.public')},
    {name: 'Give Feedback', action: openFeedback},
    // {name: 'Need Help?', action: openFeedback},
  ]
  if (user?.can?.stopImpersonating) {
    userNavigation.push({name: 'Stop Impersonating', href: route('impersonate.stop')})
  }
  if (user?.can?.viewAdmin) {
    userNavigation.push({name: 'Bunker', href: '/umpire/dashboard'})
    userNavigation.push({name: 'Admin', href: '/admin'})
  }
  userNavigation.push({name: 'Sign out', href: route('logout')});

  return userNavigation;
});


const isCheckout = computed(() => {
  return (typeof currentRoute.value === 'string') && currentRoute.value.includes('checkout');
})

router.on('navigate', () => {
  currentRoute.value = route().current();
})
</script>