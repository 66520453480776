<template>
  <Head title="Update your profile" />

  <div class="text-gray-900 dark:text-white flex-shrink-0 w-full">
    <div class="max-w-3xl mx-auto p-8 bg-white dark:bg-white/5 rounded-lg ">
      <header class="pb-8 text-center flex items-center flex-col gap-y-6">
        <RegisterBreadcrumbs class="" :current-step="3" />
        <h1 class="text-4xl font-bold">
          Private Details
        </h1>
        <Link :href="route('gift.index')" class="text-youpay-navy dark:text-youpay-pink flex items-center gap-x-2">
          skip this step and add a gift
          <ArrowRightIcon class="h-4 w-4 inline-block" aria-hidden="true" />
        </Link>
      </header>
      <div>
        <div class="grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6 flex-grow">
          <div class="col-span-full gap-x-1 rounded-md bg-indigo-50 px-3 py-1 text-sm text-indigo-700 ring-1 ring-inset ring-indigo-700/10 flex items-center">
            <InformationCircleIcon class="h-6 w-6 text-indigo-700" aria-hidden="true" />
            <div class="p-2">
              This information is private and will not be displayed on your profile.
            </div>
          </div>

          <div class="col-span-full pt-1">
            <h2 class="text-youpay-navy dark:text-youpay-pink text-lg font-semibold">Your Details</h2>
            <p class="pt-1 text-sm text-youpay-pink-dark dark:text-youpay-yellow">Please use your real name for verification and security purposes</p>
          </div>

          <div class="col-span-full sm:col-span-3">
            <InputLabel for="first_name" value="First Name" />

            <TextInput
              id="first_name"
              type="text"
              class="mt-1 block w-full"
              v-model="form.first_name"
              @change="form.validate('first_name')"
              :error="form.errors.first_name"
              required
              autocomplete="first_name"
            />
            <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">This can be your preferred name</p>

            <InputError class="mt-2" :message="form.errors.first_name"/>
          </div>


          <div class="col-span-full sm:col-span-3">
            <InputLabel for="last_name" value="Last Name" />

            <TextInput
              id="last_name"
              type="text"
              class="mt-1 block w-full"
              v-model="form.last_name"
              @change="form.validate('last_name')"
              :error="form.errors.last_name"
              required
              autocomplete="last_name"
            />

            <InputError class="mt-2" :message="form.errors.last_name"/>
          </div>


<!--          <div class="col-span-full">-->
<!--            <div class="flex justify-between">-->
<!--              <InputLabel for="country" value="Your Primary Address" />-->
<!--              <PrivacyBadge type="private" />-->
<!--            </div>-->

<!--            <div class="py-3 flex justify-between items-center">-->
<!--              <p class="text-sm font-light">202 Roma Street, Brisbane City, 4000, Australia</p>-->
<!--              <PencilIcon class="w-4 h-4 inline-block text-youpay-navy dark:text-gray-200" />-->
<!--            </div>-->

<!--            &lt;!&ndash;            <CountryList v-model="form.country" :error="form.errors.country" />&ndash;&gt;-->
<!--            <InputError class="mt-2" :message="form.errors.country"/>-->
<!--          </div>-->

          <div class="col-span-full">
            <InputLabel for="phone" value="Phone Number" class="pb-0.5" />

            <MazPhoneNumberInput
              v-model="form.phone"
              @change="form.validate('phone')"
              class="overflow-visible"
              fetch-country
              show-code-on-list
              label=""
              color="info"
              :preferred-countries="['AU', 'FR', 'BE', 'DE', 'US', 'GB']"
              :ignored-countries="['AC']"
              @update="results = $event"
              :error="form.invalid('phone') || form.invalid('country')"
            />

            <InputError class="mt-2" :message="form.errors.phone || form.errors.country"/>
          </div>

          <div class="col-span-full">
            <InputLabel for="country" value="Your Country" />
            <CountryList v-model="form.country" :error="form.errors.country" />
            <InputError class="mt-2" :message="form.errors.country"/>
          </div>

          <div class="col-span-full">
<!--            <div class="flex justify-between">-->
              <InputLabel for="dob" value="Date of birth" />
<!--              <PrivacyBadge type="private" />-->
<!--            </div>-->

            <TextInput
              id="dob"
              type="date"
              :max="new Date(new Date().setFullYear(new Date().getFullYear() - 10)).toISOString().split('T')[0]"
              class="mt-1 block w-full"
              v-model="form.dob"
              @change="form.validate('dob')"
              :error="form.errors.dob"
              required
              autocomplete="dob"
            />

            <InputError class="mt-2" :message="form.errors.dob"/>
          </div>

          <fieldset class="col-span-full pt-4 mt-4 border-t border-gray-200">
            <div @click="showAdvanced = !showAdvanced" class="flex cursor-pointer justify-between items-center">
              <div>
                <h2 class="text-youpay-navy dark:text-youpay-pink text-lg font-semibold col-span-full pt-2">Privacy Settings</h2>
                <div class="mt-1 text-xs sm:text-sm leading-6 text-gray-400 dark:text-gray-200">
                  Control how your profile is displayed to the public.
                </div>
              </div>
              <span class="cursor-pointer text-sm font-semibold leading-7 text-gray-600 dark:text-gray-200">
                <ChevronDownIcon class="h-5 w-5" aria-hidden="true" :class="showAdvanced ? 'transform rotate-180' : ''" />
              </span>
            </div>

            <div v-if="showAdvanced">
              <div class="mt-4 space-y-6">
                <div class="">
                  <label class="flex items-center">
                    <Checkbox name="searchable" v-model:checked="form.searchable" @update:checked="form.validate('searchable')" />
                    <labe for="anon" class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                      Show my profile in the search and on search engines.
                    </labe>
                  </label>
                </div>
                <div class="">
                  <label class="flex items-center">
                    <Checkbox name="anon" v-model:checked="form.anon" @update:checked="form.validate('anon')" />
                    <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                    Private profile, only show my profile to people who follow me.
                  </span>
                  </label>
                </div>
                <div class="">
                  <label class="flex items-center">
                    <Checkbox name="can_message" v-model:checked="form.can_message" @update:checked="form.validate('can_message')" />
                    <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                    Allow people to send me messages when buying me gifts.
                  </span>
                  </label>
                </div>
              </div>
            </div>
          </fieldset>

          <h2 class="text-youpay-navy dark:text-youpay-pink text-lg font-semibold col-span-full pt-4 mt-2 border-t border-gray-200">Terms of service</h2>

          <div class="col-span-full">
            <label class="flex items-center">
              <Checkbox name="terms" v-model:checked="form.terms" @update:checked="form.validate('terms')" />
              <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                  I agree to the <a :href="route('terms')" target="_blank" class="dark:text-youpay-pink text-youpay-navy underline">Terms, Conditions & Privacy Policies</a> of YouPay
                </span>
            </label>
            <InputError class="mt-2" :message="form.errors.terms"/>
          </div>
        </div>



        <div class="mt-6">
          <PrimaryButton @click="submit" :disabled="form.processing" class="w-full">
            Complete Registration
          </PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Head, usePage, Link} from "@inertiajs/vue3";
import { useForm } from 'laravel-precognition-vue-inertia';
import {User} from "@/web/youpay-me-v3/types";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import CountryList from "@/web/youpay-me-v3/Components/Form/CountryList.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import DashLayout from "@/web/youpay-me-v3/Layouts/DashLayout.vue";
import UploadFile from "@/web/youpay-me-v3/Components/Form/UploadFile.vue";
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import {
  ChevronDownIcon,
  InformationCircleIcon,
  LockOpenIcon,
  LockClosedIcon,
  ArrowRightIcon,
  PencilIcon
} from "@heroicons/vue/24/outline";
import {ref} from "vue";
import RegisterBreadcrumbs from "@/web/youpay-me-v3/Components/Blocks/RegisterBreadcrumbs.vue";
import PrivacyBadge from "@/web/youpay-me-v3/Components/Blocks/PrivacyBadge.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import {debounce} from "lodash";

const showAdvanced = ref(false);

defineOptions({ layout: DashLayout })

const user: User | null = usePage().props.auth;

const props = defineProps<{
  description: string;
  searchable: boolean;
  anon: boolean;
  country: string;
}>();

const form = useForm('post', route('setup.private'), {
  first_name: user?.first_name ?? '',
  last_name: user?.last_name ?? '',
  phone: user?.phone ?? '',
  country: props.country ?? '',
  searchable: props.searchable ?? true,
  anon: props.anon ?? false,
  dob: user.dob ?? '',
  terms: false,
  can_message: true,
});

const results = ref()

const submit = () => {
  form.submit({
    preserveScroll: false,
    onSuccess: () => {
      form.reset();
    }
  });
};
</script>