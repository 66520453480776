<template>
  <Head title="Register"/>

  <div
    class="bg-white dark:bg-gray-700 md:bg-[url('/images/splash/bg-light.png')] md:dark:bg-[url('/images/splash/bg-dark.png')] bg-cover w-full h-full flex-grow bg-right relative flex md:items-center ">
    <div
      class="inset-0 z-10 relative flex flex-col md:flex-row items-center h-full flex-grow overflow-y-auto gap-x-2 lg:gap-x-6">
      <div class="hidden md:block w-3/6 md:w-2/5 px-6 xl:w-3/5">
        <div class="gap-y-6 flex flex-col sm:mx-auto sm:w-full sm:max-w-md">
          <h2
            class="mt-6 leading-[2rem] lg:leading-[3rem] text-[42px] lg:text-[62px] font-semibold text-gray-900 dark:text-white flex flex-col gap-y-6">
            <span>The wishlist</span>
            <span>for everyone</span>
          </h2>
          <p class="mt-4 text-xl text-gray-600 dark:text-gray-200 font-light">
            Share what you want, and let someone else pay, without sharing your personal details.
          </p>
        </div>
      </div>
      <div class="bg-white dark:bg-gray-800 mx-auto md:rounded-lg px-6 pb-6 lg:px-8 md:mt-4 md:mr-4 md:mb-4 2xl:mr-auto">
        <div class="flex flex-1 flex-col justify-center text-center md:px-6">
          <h1 class="font-semibold mt-8 text-2xl md:text-3xl dark:text-white">Create Your YouPay Wishlist</h1>

          <Link
            :href="route('login')"
            class="block pt-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
          >
            Already registered? <span
            class="underline text-youpay-navy dark:text-youpay-pink">Click here to login</span>
          </Link>
        </div>

        <div class="w-full md:w-auto mx-auto p-0 md:p-6 mt-6">
          <div class="grid grid-cols-1 gap-x-6 gap-y-5 xs:grid-cols-6 flex-grow">
            <div class="col-span-full">
              <InputLabel for="display_name" value="Display Name"/>
              <TextInput
                id="display_name"
                type="text"
                class="mt-1 block w-full"
                v-model="form.display_name"
                @change="form.validate('display_name')"
                :error="form.errors.display_name"
                required
                autocomplete="display_name"
              />
              <InputError class="mt-2" :message="form.errors.display_name"/>
            </div>

            <div class="col-span-full xs:col-span-3">
              <InputLabel for="first_name" value="First Name"/>
              <TextInput
                id="first_name"
                type="text"
                class="mt-1 block w-full"
                v-model="form.first_name"
                @change="form.validate('first_name')"
                :error="form.errors.first_name"
                required
                autocomplete="first_name"
              />
              <InputError class="mt-2" :message="form.errors.first_name"/>
            </div>

            <div class="col-span-full xs:col-span-3">
              <InputLabel for="last_name" value="Last Name"/>

              <TextInput
                id="last_name"
                type="text"
                class="mt-1 block w-full"
                v-model="form.last_name"
                @change="form.validate('last_name')"
                :error="form.errors.last_name"
                required
                autocomplete="last_name"
              />

              <InputError class="mt-2" :message="form.errors.last_name"/>
            </div>


            <div class="col-span-full hidden">
              <InputLabel for="username" value="Username"/>

              <div class="mt-2">
                <div
                  class="flex py-0.5 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus-within:ring-2  focus-within:ring-indigo-600 dark:focus-within:ring-gray-700 dark:bg-white/[0.05] "
                  :class="form.errors.username ? 'ring-red-300 dark:ring-red-700' : ''"
                >
                  <span class="flex select-none items-center pl-3 text-gray-500 dark:text-gray-400 sm:text-sm">youpay.me/</span>
                  <input autofocus type="text" @change="form.validate('username')" v-model="form.username"
                         name="username" id="username"
                         class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 dark:placeholder-gray-600"
                         placeholder="example"/>
                </div>
              </div>

              <InputError class="mt-2" :message="form.errors.username"/>
            </div>

            <div class="col-span-full">
              <InputLabel for="email" value="Email address"/>

              <TextInput
                id="email"
                type="email"
                class="mt-1 block w-full"
                v-model="form.email"
                @change="form.validate('email')"
                :error="form.errors.email"
                required
                autocomplete="email"
              />

              <InputError class="mt-2" :message="form.errors.email"/>
            </div>

            <div class="col-span-full">
              <InputLabel for="country" value="Your Country"/>
              <CountryList v-model="form.country" :error="form.errors.country"/>
              <InputError class="mt-2" :message="form.errors.country"/>
            </div>

            <div class="col-span-full">
              <label class="flex items-center">
                <Checkbox name="terms" v-model:checked="form.terms"/>
                <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                  I agree to the <a :href="route('terms')" target="_blank" class="underline">Terms, Conditions & Privacy Policies</a>
                </span>
              </label>

              <InputError class="mt-2" :message="form.errors.terms"/>
            </div>


            <div v-if="submitted && form.hasErrors" class="col-span-full text-sm text-red-600 dark:text-red-400">
              Looks like there are some errors in your form. Please review and try again.
            </div>

            <div class="col-span-full flex items-center justify-end">
              <PrimaryButton class="w-full" @click.stop="submit" :class="{ 'opacity-25': form.processing }"
                             :disabled="form.processing">
                <span v-if="form.processing">Please wait...</span>
                <span v-else>Register</span>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputError from '../../Components/Form/InputError.vue';
import InputLabel from '../../Components/Form/InputLabel.vue';
import PrimaryButton from '../../Components/Buttons/PrimaryButton.vue';
import TextInput from '../../Components/Form/TextInput.vue';
import {Head, Link} from '@inertiajs/vue3';
import {onMounted, ref} from 'vue'
import {useForm} from 'laravel-precognition-vue-inertia';
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import {fetchCountryInfo} from "@/web/youpay-me-v3/helpers/geolocate";
import CountryList from "@/web/youpay-me-v3/Components/Form/CountryList.vue";
import GuestLayout from "@/web/youpay-me-v3/Layouts/GuestLayout.vue";

defineOptions({layout: GuestLayout});

const props = defineProps<{
  referral?: string;
  username?: string;
  country?: string;
}>();

const submitted = ref(false)
const form = useForm('post', route('register'), {
  first_name: '',
  last_name: '',
  username: props.username ?? '',
  display_name: '',
  email: '',
  referral: props.referral ?? '',
  country: props.country ?? '',
  terms: false,
});

const submit = () => {
  submitted.value = true;
  if (form.validating) {
    form.cancel();
    setTimeout(submit, 100);
    return;
  }
  form.submit({
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
      trackEvent('sign_up', {
        method: 'Quick',
      });
    },
  });
};

onMounted(() => {
  // fetchCountryInfo().then((country) => {
  //   console.log(country.country_code)
  // });
});
</script>
