<template>
  <Head title="Payment Details"/>

  <div class="pb-4">
    <h1 class="text-3xl dark:text-white">
      Pay for {{ profile.username }}
    </h1>
  </div>

  <!-- Review payment address details: -->
  <div class="pb-4">
    <h2 class="block py-4 text-lg dark:text-white">Review Payment Details</h2>
    <div>
      <div class="bg-white dark:bg-white/5 border border-gray-400 cursor-pointer hover:dark:bg-white/10 dark:border-white/10 shadow rounded-lg p-4">
        <div>
          <div class="flex items-center justify-between">
            <p class="font-semibold pb-1">{{ bag.customer?.name }}</p>
              <Link :href="route('checkout.details', bag.id)" :only="['autofill', 'contacts', 'can_message', 'message', 'can_message']" class="text-blue-500">Change</Link>
          </div>

          <p>{{ bag.customer?.email }}</p>
          <p>{{ bag.customer?.address?.address_1 }}{{ bag.customer?.address?.address_2 ? ', ' + bag.customer?.address?.address_2 : '' }}</p>
          <p>{{ bag.customer?.address?.suburb }}, {{ bag.customer?.address?.state }} {{ bag.customer?.address?.postcode }} {{ bag.customer?.address?.country }}</p>
        </div>
      </div>
    </div>
  </div>

  <h2 class="block py-4 text-lg dark:text-white">Payment Method</h2>
  <div class="">
    <component :is="currentGateway" :payment-config="paymentConfig" :bag="bag" />
  </div>
</template>

<script lang="ts" setup>
import {Head, Link} from "@inertiajs/vue3";
import {Bag, compileForAnalytics} from "@/web/youpay-me-v3/types/checkout.d";
import {Profile, User} from "@/web/youpay-me-v3/types";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import type {IpDetails} from "@/web/youpay-me-v3/helpers/MerchantWarrior";
import CheckoutLayout from "@/web/youpay-me-v3/Layouts/CheckoutLayout.vue";
import WarriorGateway from "@/web/youpay-me-v3/Components/Checkout/MerchantWarrior/WarriorGateway.vue";
import StripeGateway from "@/web/youpay-me-v3/Components/Checkout/StripeGateway.vue";
import {onMounted} from "vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";

defineOptions({layout: CheckoutLayout})

// Get Props
const props = defineProps<{
  auth?: User,
  profile: Profile,
  bag: Bag,
  paymentConfig: any,
  ipdetails?: IpDetails,
}>();

let currentGateway;
if (props.paymentConfig.gateway === 'warrior') {
  currentGateway = WarriorGateway;
} else {
  currentGateway = StripeGateway;
}

onMounted(async () => {
  trackEvent('begin_checkout', compileForAnalytics(props.profile.username, props.bag));
});
</script>