<template>
  <MainLayout v-if="view">
    <div class="relative flex h-full flex-grow flex-col md:flex-row">
      <header class="bg-gray-50 dark:bg-white/5 h-auto md:inset-y-0 md:left-0 md:flex md:items-start md:overflow-y-auto flex-shrink-0 md:w-72 xl:w-96 4xl:w-112">
        <div class="md:relative z-10 md:min-h-full md:flex-auto md:border-x md:border-gray-200 dark:md:border-gray-800 pb-2 pt-0 md:pb-12">
          <div
            :style="banner? `background-image: url(${banner});` : ''"
            class="absolute top-0 w-full bg-gradient-to-r via-purple-500 bg-cover bg-center from-youpay-navy-light to-youpay-pink xl:h-[150px] h-[110px]  md:h-[130px]"
          ></div>

          <div class="w-full px-3 lg:px-6 mt-[60px]">
            <div>
              <div class="relative flex flex-row items-start gap-x-6">
                <div class="h-32 md:h-40 flex items-end">
                  <a v-show="!showQr" @click.prevent="showQrCode" class="relative block w-32 overflow-hidden rounded-lg bg-gray-200 dark:bg-gray-700 sm:rounded-xl md:w-40 md:rounded-2xl max-h-[160px] sm:max-h-[190px]">
                    <img v-if="avatar" :alt="profile.display_name" fetchpriority="high" decoding="async" data-nimg="1" class="w-full max-h-full" style="color:transparent" :src="avatar">
                    <CameraIcon v-else-if="owner" class="h-full w-full p-8 text-gray-400 dark:text-gray-600" />
                    <UserCircleIcon v-else class="h-full w-full p-8 text-gray-400 dark:text-gray-600" />
                    <QrCodeIcon v-if="owner" class="absolute z-10 bottom-0 right-0 mr-1 mb-1 w-7 h-7 p-1 text-black bg-white/60 rounded-lg dark:text-gray-600" />
                    <div class="absolute inset-0 rounded-lg ring-1 ring-inset ring-black/10 sm:rounded-xl md:rounded-2xl"></div>
                  </a>
                  <a v-show="showQr" @click.prevent="showQr = false" class="relative block w-32 overflow-hidden p-3 rounded-lg bg-white sm:w-48 sm:rounded-xl md:w-40 md:rounded-2xl max-h-[160px] sm:max-h-[190px]">
                    <img fetchpriority="high" decoding="async" data-nimg="1" class="w-full max-h-full" style="color:transparent" :src="route('profile.qrcode', profile.username)">
                    <div class="absolute inset-0 rounded-lg ring-1 ring-inset ring-black/10 sm:rounded-xl md:rounded-2xl"></div>
                  </a>
                </div>
                <div class="flex flex-col pb-4 mt-14 font-medium text-gray-900 dark:text-gray-50 md:pb-5 md:hidden">
                  <span v-if="profile.display_name" class="flex flex-row items-center">
                    <span class="font-bold text-lg line-clamp-1">{{ profile.display_name }}</span>
                    <a v-if="profile.verified" @click="aboutVerified" class="flex w-10 items-center p-2">
                      <CheckBadgeIcon class="inline-block w-full text-youpay-teal" aria-hidden="true"  />
                    </a>
                  </span>
                  <a :href="route('profile.show', profile.username)" >
                    <span v-if="!profile.anon" class="text-md">{{ '@' + profile.username }}</span>
                  </a>
                  <span v-if="profile.pronouns" class="text-xs text-gray-500 dark:text-gray-400 pt-1 -mb-4">
                    ({{ profile.pronouns }})
                  </span>
                </div>
                <div v-if="wishlist" class="absolute right-0 bottom-8 flex flex-col font-medium text-gray-900 bottom-right dark:text-gray-50 xl:bottom-8">
                  <ShareUrl :url="is_primary ? route('profile.show', profile.username) : route('wishlist.show', [profile.username, wishlist.url ])" class="text-sm lg:text-md">
                    <ShareIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </ShareUrl>
                </div>

              </div>
              <div class="mt-6 text-left hidden md:block">
                <p class="flex flex-row items-center text-xl font-bold text-gray-900 dark:text-white">
                  <a :href="route('profile.show', profile.username)" >
                    {{ profile.display_name }}
                  </a>
                  <a v-if="profile.verified" @click="aboutVerified" class="flex w-8 items-center p-1 m-1 cursor-pointer">
                    <CheckBadgeIcon class="inline-block w-full text-youpay-teal" aria-hidden="true"  />
                  </a>
                </p>
                <p class="mt-1 text-sm font-light text-gray-900 dark:text-white">
                  <a v-if="!profile.anon" :href="route('profile.show', profile.username)" >
                    @{{ profile.username }}
                  </a>
                  <span v-if="profile.pronouns" class="text-xs text-gray-500 dark:text-gray-400 pl-1">
                    ({{ profile.pronouns }})
                  </span>
                </p>
              </div>
            </div>

            <div class="mt-4 w-full md:mt-8 5xl:w-[300px]">
              <div class="flex flex-row gap-x-2 gap-y-2">
                <SecondaryLink
                  :href="route('follow', profile.username)"
                  method="put"
                  preserve-scroll
                  as="button"
                  @click="trackEvent('follow', profile.username)"
                  v-if="user && !following"
                  class="w-2/5 md:w-1/6 xl:w-2/5 flex-grow flex items-center leading-5 justify-center gap-x-2"
                >
                  <HeartIcon class="inline md:hidden xl:inline h-4 animate-beat w-4 dark:text-gray-100" aria-hidden="true" />
                  Follow
                </SecondaryLink>
                <SecondaryLink
                  v-if="user && following"
                  :href="route('unfollow', profile.username)"
                  method="delete"
                  as="button"
                  @click="trackEvent('unfollow', profile.username)"
                  class="w-2/5 md:w-1/6 xl:w-2/5 flex-grow flex items-center leading-5 justify-center gap-x-2"
                  preserve-scroll
                >
                  Unfollow
                </SecondaryLink>
                <YouPayMeNow v-if="profile.surprise" :profile="profile" class="w-3/5 flex-grow"/>
              </div>
            </div>

            <ProfileBadges :badges="profile.badges" />

            <ProfileDescription
              :owner="owner"
              :view="view"
              :description="page_description?.length ? page_description : profile.description"
              />

            <ProfileSocials
              :profile="profile"
              :view="view"
              :owner="owner"
            />
          </div>
        </div>
      </header>
      <main id="wishlist-body" class="flex-grow border-t border-gray-200 bg-youpay-teal-ultralight dark:border-gray-800 dark:bg-white/10 md:relative md:border-t-0">
        <slot />
      </main>
    </div>
  </MainLayout>
  <Modal :show="showAboutVerified" @close="showAboutVerified = false">
    <div class="flex flex-row items-center gap-x-6 p-6">
      <CheckBadgeIcon class="h-10 w-10 text-youpay-teal" aria-hidden="true" />
      <div class="">
        <h2 class="text-lg font-medium text-gray-900 dark:text-gray-100">
          This user has been verified
        </h2>

        <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
          This user has been manually verified by the YouPay team.
        </p>
      </div>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import {usePage} from '@inertiajs/vue3';
import MainLayout from "@/web/youpay-me-v3/Layouts/MainLayout.vue";
import {computed, ref} from 'vue'
import {CameraIcon, HeartIcon, QrCodeIcon, ShareIcon, UserCircleIcon} from "@heroicons/vue/24/outline";
import {CheckBadgeIcon} from "@heroicons/vue/20/solid";
import ShareUrl from "@/web/youpay-me-v3/Components/Blocks/ShareUrl.vue";
import Modal from "@/web/youpay-me-v3/Components/Blocks/Modal.vue";
import Footer from "@/web/youpay-me-v3/Components/Blocks/Footer.vue";
import ProfileBadges from "@/web/youpay-me-v3/Components/Blocks/ProfileBadges.vue";
import ProfileSocials from "@/web/youpay-me-v3/Components/Blocks/ProfileSocials.vue";
import {Profile} from "@/web/youpay-me-v3/types";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import ProfileDescription from "@/web/youpay-me-v3/Components/Blocks/ProfileDescription.vue";
import SecondaryLink from "@/web/youpay-me-v3/Components/Buttons/SecondaryLink.vue";
import YouPayMeNow from "@/web/youpay-me-v3/Components/Buttons/YouPayMeNow.vue";

const user = usePage().props.auth;

const props = defineProps<{
  following?: boolean;
  view: string;
  wishlist?: any;
  is_primary?: boolean;
  profile: Profile,
  page_description?: string;
  upgrading?: boolean;
}>();

const showAboutVerified = ref(false);
const showQr = ref(false);
const aboutVerified = () => {
  showAboutVerified.value = true;
};

const owner = computed(() => {
  return props.profile.username === user?.username;
});

const showQrCode = (event) => {
  showQr.value = true;
};

const avatar = computed(() => {
  return props.wishlist ? props.wishlist.avatar : props.profile.avatar;
});

const banner = computed(() => {
  return props.wishlist ? props.wishlist.banner : props.profile.banner;
});

</script>
