<template>
  <MainLayout>
    <div class="flex flex-row grow dark:text-white">
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog class="relative z-50 xl:hidden" @close="sidebarOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div class="fixed inset-0 flex">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div class="bg-white dark:bg-gray-900 flex">
                  <SettingsNavBar />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <SettingsNavBar v-model="currentNavName" class="hidden lg:flex" />
      <div class="divide-y flex-grow dark:divide-white/5 divide-gray-200 flex-col flex relative">
        <div class="flex items-center gap-x-6 dark:text-white px-8 py-6">
          <button type="button" class="-m-2.5 p-2.5 dark:ltext-white lg:hidden" @click="sidebarOpen = true">
            <span class="sr-only">Open sidebar</span>
            <Bars3Icon class="h-8 w-8" aria-hidden="true" />
          </button>
          <h1 class="text-2xl font-bold">
            {{ currentNavName }}
          </h1>
        </div>
        <slot></slot>
      </div>
    </div>
  </MainLayout>
</template>
<script setup lang="ts">
// import the debounce function
import {ref, watch} from 'vue'
import MainLayout from "@/web/youpay-me-v3/Layouts/MainLayout.vue";
import SettingsNavBar from "@/web/youpay-me-v3/Components/Blocks/SettingsNavBar.vue";
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {XMarkIcon, Bars3Icon} from '@heroicons/vue/24/outline'

const sidebarOpen = ref(false)

const currentNavName = ref();

watch(currentNavName, (value) => {
  sidebarOpen.value = false;
})

</script>
