<template>
  <ul v-if="wishlists?.length" role="list"
      class="px-2 lg:px-4 grid grid-cols-2 gap-2 lg:gap-4 border-b border-gray-200 pb-2 lg:pb-4 dark:border-gray-800/[0.5] lg:grid-cols-3 xl:pb-6 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6">
    <li v-if="wishlists?.length" v-for="wishlist in wishlists" :key="wishlist.id"
        class="relative col-span-1 flex overflow-hidden rounded-lg bg-transparent text-left shadow-md">
      <div v-if="wishlist.anon" class="absolute top-1 right-1 z-10 rounded-md p-1 text-white bg-youpay-black/[0.5]">
        <EyeSlashIcon class="h-6 w-6"/>
      </div>
      <div @click="visitLink(wishlist.url)" :style="wishlist.banner ? `background-image: url(${wishlist.banner});` : ''"
           class="cursor-pointer absolute top-0 w-full bg-gradient-to-r via-purple-500 bg-cover bg-center h-[130px] from-youpay-navy-light to-youpay-pink"></div>

      <div class="flex flex-grow flex-col bg-white mt-[130px] dark:bg-white/[0.05]">
        <div class="relative z-10 flex flex-1 flex-col px-4 py-4 pb-5">
          <dl class="mt-1 flex flex-grow flex-col">
            <dt class="sr-only">Details</dt>
            <dd class="text-lg font-medium text-gray-700 line-clamp-1 dark:text-white cursor-pointer" @click="visitLink(wishlist.url)">{{ wishlist.title }}</dd>
            <dt class="sr-only">About</dt>
            <dd v-if="wishlist.description" class="mt-3 text-sm text-gray-400 line-clamp-2 dark:text-gray-100">{{ wishlist.description }}</dd>
          </dl>
        </div>
        <div>
          <div
            class="-mt-px flex border-t divide-x divide-gray-200 dark:bg-white/[0.02] dark:divide-gray-700 dark:border-gray-700">
            <div class="-ml-px flex w-0 flex-1">
              <Link
                :href="route('wishlist.show', [profile.username, wishlist.url])"
                class="relative inline-flex w-0 flex-1 cursor-pointer items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 dark:text-white dark:hover:bg-gray-600"
              >
                <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                View Wishlist
              </Link>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import {EyeSlashIcon, PlusIcon} from "@heroicons/vue/24/outline";
import {Link, router} from "@inertiajs/vue3";
import {EyeIcon} from "@heroicons/vue/20/solid";
import {Profile, WishlistInterface} from "@/web/youpay-me-v3/types";

const props = defineProps<{
  wishlists: WishlistInterface[],
  owner: boolean,
  is_primary: boolean,
  profile: Profile,
}>();

const visitLink = (url: string) => {
  router.visit(route('wishlist.show', [props.profile.username, url]))
};
</script>