<template>
  <Head title="Confirm Password"/>

  <div>
    <h1 class="text-3xl font-bold text-gray-800 dark:text-gray-200 pb-4">Confirm Password</h1>

    <div class="mb-4 text-sm text-gray-600 dark:text-gray-400">
      This is a secure area of the application. Please confirm your password before continuing.
    </div>

    <form @submit.prevent="submit">
      <div>
        <InputLabel for="password" value="Password"/>
        <TextInput
          id="password"
          type="password"
          class="mt-1 block w-full"
          v-model="form.password"
          required
          autocomplete="current-password"
          autofocus
        />
        <InputError class="mt-2" :message="form.errors.password"/>
      </div>

      <div class="flex justify-end mt-4">
        <PrimaryButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
          Confirm
        </PrimaryButton>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import GuestLayout from '../../Layouts/MainLayout.vue';
import InputError from '../../Components/Form/InputError.vue';
import InputLabel from '../../Components/Form/InputLabel.vue';
import PrimaryButton from '../../Components/Buttons/PrimaryButton.vue';
import TextInput from '../../Components/Form/TextInput.vue';
import {Head, useForm} from '@inertiajs/vue3';
import MainPanelLayout from "@/web/youpay-me-v3/Layouts/MainPanelLayout.vue";

defineOptions({layout: GuestLayout});

const form = useForm({
  password: '',
});

const submit = () => {
  form.post(route('password.confirm'), {
    onFinish: () => {
      form.reset();
    },
  });
};

</script>
