<template>
  <div>
    <button @click="toggle" class="btn btn-primary flex flex-row gap-x-1 items-center">
      <ClockIcon class="h-6 w-6 cursor-pointer" :class="order.history.length ? 'text-yellow-400 dark:text-yellow-600' : 'text-gray-400 dark:text-gray-600'"/>
      <!-- count -->
      <span v-if="order.history.length" class="text-xs rounded-full bg-gray-200 dark:bg-gray-700 dark:text-white py-0.5 px-1.5">
        {{ order.history.length }}
      </span>
    </button>
    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-50" @close="toggle">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 dark:bg-black/50 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                  <div class="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-900 py-6 shadow-xl">
                    <div class="px-4 sm:px-6">
                      <div class="flex items-start justify-between">
                        <DialogTitle class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">Order History</DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button" class="relative rounded-md bg-white dark:bg-white/10 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500" @click="open = false">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="relative mt-6 flex-1 px-4 sm:px-6">
                      <div class="mt-6 overflow-y-scroll">
                        <div class="flex flex-col gap-y-4 mt-2">
                          <div v-for="history in order.history" :key="history.id" class="">
                            <div class="p-2 bg-gray-100 dark:bg-white/5 dark:text-white text-sm rounded flex flex-col">
                              <span class="font-bold">Key</span>
                              <span class="mb-2">{{ history.key }}</span>
                              <span class="font-bold">Comment</span>
                              <span class="mb-2">{{ history.comment }}</span>
                              <span class="font-bold">Data</span>
                              <span class="">{{ history.data }}</span>
                            </div>
                            <div class="flex items-center gap-x-2 pt-1">
                              <span class="text-xs text-gray-400 dark:text-gray-600">{{ history.created_by }}</span>
                              <span class="text-xs text-gray-400 dark:text-gray-600">{{ history.created_at }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon, ClockIcon } from '@heroicons/vue/24/outline'

const open = ref(false)

const props = defineProps<{
  order: any;
}>()
const toggle = () => {
  open.value = !open.value
}
</script>