<template>
  <ul v-if="items.length" role="list"
      class="m-2 lg:m-4 grid gap-2 lg:gap-4 grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6">
    <li v-for="(gift, index) in items" :key="index" :id="'gift-hit-' + index" class="relative flex flex-col col-span-1 h-full">
      <div class="relative flex h-full text-left overflow-hidden bg-transparent shadow-md rounded-lg">
        <div @click="!owner && router.visit(route('gift.show', [profile.username, gift.id]))"
             :style="gift.image? `background-image: url(${imageHack(gift.image)});` : ''"
             class="absolute top-0 w-full bg-gradient-to-r via-purple-500 bg-cover bg-center from-youpay-navy-light to-youpay-pink h-[150px] lg:h-[180px]"
             :class="owner ? 'cursor-default' : 'cursor-pointer'"
        >
          <div v-if="owner" class="flex flex-row justify-between z-10">
            <div class="flex flex-row bg-white/90 rounded-br-xl">
              <button
                v-if="gift.pinned"
                @click.stop="pinGift(gift, !gift.pinned)"
                class="p-2 text-black cursor-pointer"
              >
                <MapPinIcon class="h-6 w-6"/>
              </button>
              <div v-if="!gift.pinned && canSort" class="flex flex-row">
                <button
                  @click.stop="orderGiftUp(index)"
                  class="p-2"
                  :disabled="index === 0 || (noOfPinnedGifts - 1) === index"
                  :class="index === 0 || noOfPinnedGifts === index || currentlyUpdating ? 'text-gray-400 cursor-not-allowed' : 'text-black'"
                >
                  <ChevronUpIcon class="h-5 w-5"/>
                </button>
                <button
                  @click.stop="orderGiftDown(index)"
                  class="p-2"
                  :disabled="index === items.length - 1"
                >
                  <ChevronDownIcon
                    class="h-5 w-5 text-black"
                    :class="currentlyUpdating ? 'text-gray-400 cursor-not-allowed' : 'text-black'"
                  />
                </button>
              </div>
            </div>
            <Menu as="div" class="relative block">
              <MenuButton class="relative inline-flex items-center rounded-bl-xl
                      px-2 py-2 text-white bg-white/90
                     focus:z-10">
                <span class="sr-only">Open options</span>
                <EllipsisVerticalIcon class="h-5 w-5 text-black"/>
              </MenuButton>
              <transition enter-active-class="transition ease-out duration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 -mr-1 mt-1 w-42 origin-top-right
                          rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5
                          dark:ring-white dark:bg-gray-800 dark:ring-opacity-10
                          focus:outline-none">
                  <div class="py-1">
                    <MenuItem v-for="(item, key) in gift_actions" :key="key">
                      <a @click="doGiftAction(gift, item.action)" class="flex flex-row items-center text-gray-700 dark:text-gray-100 px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer gap-x-2">
                        <component :is="item.icon" class="h-5 w-5 text-gray-400 dark:text-gray-100 inline-block"/>
                        <span class="">
                        {{ item.name }}
                      </span>
                      </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        <div
          class="relative flex flex-grow flex-col bg-white dark:bg-white/[0.05] mt-[150px] lg:mt-[180px]">
          <div class="flex flex-row justify-start pt-2 lg:pt-3 pr-3 pb-0 pl-3">
            <span class="flex-grow-0 inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs leading-none text-indigo-600 font-light ring-1 ring-inset ring-indigo-400/20 dark:text-indigo-300">
              {{ gift.type }}
            </span>
          </div>
          <div class="relative z-10 flex flex-1 flex-col px-4 pt-1 lg:pt-2 pb-4">
            <dl class="flex flex-grow flex-col">
              <dt class="sr-only">Details</dt>
              <dd class="text-lg font-medium text-gray-700 line-clamp-2 md:line-clamp-1 dark:text-white">
                <Link :href="route('gift.show', [profile.username, gift.id])">
                  {{ gift.title }}
                </Link>
              </dd>
              <dt class="sr-only">Amount</dt>
              <dd class="font-medium text-gray-500 text-md dark:text-white">
                {{ formatCurrency(gift.amount, gift.currency) }}
              </dd>
              <dt class="sr-only">About</dt>
              <dd v-if="gift.description" class="mt-2 lg:mt-3 text-sm text-gray-400 line-clamp-2 dark:text-gray-100">
                {{ cleanDesc(gift.description) }}
              </dd>
            </dl>
          </div>
          <div>
            <GiftButtons :gift="gift" :profile="profile" :bag="bag"/>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div v-else class="xl:m-6 m-4 dark:text-white">
    <!-- Tell them to add a gift -->
    <div class="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50 sm:text-5xl">{{ profile.username }} currently has no gifts on this wishlist</h1>
      <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300 sm:mt-6 sm:text-lg sm:leading-8">Let them know you want to send them something!</p>
    </div>
  </div>
  <Pagination v-if="pagination?.last_page > 1" :links="pagination" class="px-6 py-6" />
  <DeleteGiftModal v-model="giftToDelete"/>
</template>

<script setup lang="ts">
import {formatCurrency} from "@/web/youpay-me-v3/money";
import {MapPinIcon, PencilIcon, TrashIcon} from "@heroicons/vue/24/outline";
import {Link, router} from "@inertiajs/vue3";
import {ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon} from "@heroicons/vue/20/solid";
import {marked} from "marked";
import DOMPurify from "dompurify";
import DeleteGiftModal from "@/web/youpay-me-v3/Components/Profile/DeleteGiftModal.vue";
import {computed, inject, ref} from "vue";
import {Gift, Profile} from "@/web/youpay-me-v3/types";
import {Bag} from "@/web/youpay-me-v3/types/checkout";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {useForm} from "laravel-precognition-vue-inertia";
import GiftButtons from "@/web/youpay-me-v3/Components/Profile/GiftButtons.vue";
import Pagination from "@/web/youpay-me-v3/Components/Blocks/Pagination.vue";

const props = defineProps<{
  items: Gift[],
  owner: boolean,
  is_primary: boolean,
  profile: Profile,
  bag?: Bag,
  pagination: any
}>();

const giftToDelete = ref<Gift | null>(null);

const asset = inject('asset');
const cleanDesc = function (desc: string) {
  if (!desc || desc === '') {
    return '';
  }
  const dirty = marked.parse(desc);
  const clean = DOMPurify.sanitize(dirty, {
    USE_PROFILES: {html: false},
  });
  return clean.substring(0, 200);
}

const pinForm = useForm('post', route('gift.pin', 0), {});
const pinGift = function (gift: Gift, set_pin: boolean) {
  // Route: gift.pinned ? route('gift.unpin', gift.id) : route('gift.pin', gift.id)
  const url = set_pin ? route('gift.pin', gift.id) : route('gift.unpin', gift.id);
  pinForm.post(url, {
    onSuccess: () => {
      gift.pinned = set_pin;
      if (set_pin) {
        // move to top of list
        const index = props.items.indexOf(gift);
        props.items.splice(index, 1);
        props.items.unshift(gift);
      } else {
        // move to bottom of list
        const index = props.items.indexOf(gift);
        props.items.splice(index, 1);
        props.items.push(gift);
      }
    }
  });
}

const imageHack = function (image: string) {
  if (!image || image === '') {
    return '';
  }
  const old_url = 'https://assets.youpay.one/';
  // Messy hack to get around a user error
  if (image.includes(old_url)) {
    var after_domain = image.substring(26);
    var n = after_domain.indexOf('/');
    var new_image = after_domain.substring(n + 1);
    return asset(new_image);
  }
  return image;
}

const currentlyUpdating = ref(false);

const gift_actions = [
  {
    name: 'Pin / UnPin',
    icon: MapPinIcon,
    action: 'pin'
  },
  {
    name: 'Edit',
    icon: PencilIcon,
    action: 'update'
  },
  {
    name: 'Delete',
    icon: TrashIcon,
    action: 'delete'
  },
];

const doGiftAction = function (gift: Gift, action: string) {
  if (action === 'delete') {
    giftToDelete.value = gift;
  }
  if (action === 'update') {
    router.visit(route('gift.update', gift.id));
  }
  if (action === 'pin') {
    pinGift(gift, !gift.pinned);
  }
}

const swapGiftOrder = function (index1: number, index2: number) {
  if (currentlyUpdating.value) {
    return;
  }
  currentlyUpdating.value = true;
  const temp = props.items[index1];
  props.items[index1] = props.items[index2];
  props.items[index2] = temp;

  // Update gift orders and send request with gifts.*.id and gifts.*.order
  let gifts = [];
  for (let i = 0; i < props.items.length; i++) {
    gifts.push({id: props.items[i].id, sort_order: i});
  }
  // Send request
  sortForm.gifts = gifts;
  submitGiftSortOrder();
}

const noOfPinnedGifts = computed(() => {
  return props.items.filter(gift => gift.pinned).length;
});

const sortForm = useForm('post', route('gift.sort'), {
  gifts: []
});

const canSort = computed(() => {
  return props.pagination?.current_page === 1 && props.is_primary;
});

const submitGiftSortOrder = function () {
  sortForm.submit({
    preserveScroll: true,
    onSuccess: () => {
      currentlyUpdating.value = false;
    },
    onError: () => {
      currentlyUpdating.value = false;
    }
  });
}

const orderGiftUp = function (index) {
  swapGiftOrder(index, index - 1);
}

const orderGiftDown = function (index) {
  swapGiftOrder(index, index + 1);
}

</script>