<template>
  <div>
    <div v-if="props.query !== '' && loadingChat" >
<!--      should check for is URL before showing the searching text-->
<!--      Searching for results...-->
      <span class="text-center p-6 w-full block">
        one second while we check that website for you...
      </span>
    </div>
    <div v-else-if="chatResponse?.component">
      <component :is="components[chatResponse.component]" :data="chatResponse.data" />
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import {debounce} from 'lodash'
import {Head, Link, usePage, router} from '@inertiajs/vue3';
import MainLayout from "@/web/youpay-me-v3/Layouts/MainLayout.vue";
import SuggestGifts from "@/web/youpay-me-v3/Components/Chat/SuggestGifts.vue";
import GiftFromUrl from "@/web/youpay-me-v3/Components/Chat/GiftFromUrl.vue";
import ProfilesList from "@/web/youpay-me-v3/Components/Chat/ProfilesList.vue";
import {computed, onMounted, ref, watch} from 'vue'
import {Channel, Message} from '@/web/youpay-me-v3/Components/Chat/classes'
import {MagnifyingGlassIcon} from '@heroicons/vue/20/solid'
import {
  ExclamationCircleIcon,
} from '@heroicons/vue/24/outline'

const emit = defineEmits(['toggleChat'])

let props = defineProps<{
  query?: string;
}>();

const user = usePage().props.auth

const components: Object = {
  'SuggestGifts': SuggestGifts,
  'GiftFromUrl': GiftFromUrl,
};

interface ChatResponse {
  component?: String,
  data?: Object,
}

let chatResponse: ChatResponse = ref(Object({
  component: null,
  data: null,
  // component: 'GiftFromUrl',
  // data: {
  //   name: 'Test Product',
  //   price: '$10.00',
  //   url: 'https://google.com',
  //   images: [
  //     'https://www.jbhifi.com.au/cdn/shop/products/554944-Product-0-I-637848571978687075.jpg?v=1649227172'
  //   ]
  // }
  // component: SuggestGifts,
  // data: {
  //     products: [
  //       {
  //         name: 'Test Product',
  //         price: '$10.00',
  //         url: 'https://google.com',
  //       },
  //         {
  //             name: 'Test Product',
  //             price: '$10.00',
  //             url: 'https://google.com',
  //         },
  //     ]
  // }
}))
// Subscribe to private channel

const channelNumber = Math.floor(Math.random() * 1000000000)
const channelName = `Chat.${user.id}.${channelNumber}`;

// window.Echo.private(channelName)
//   .subscribed(() => {
//     const channel = new Channel({
//       type: 'private',
//       name: channelName,
//     });
//   }).listen('.PostChannelUpdate', (e) => {
//   console.log('PostChannelUpdate', e)
//   const json = JSON.parse(e.json)
//   loadingChat.value = false
//   if (json.error) {
//     chatResponse.value = null
//     console.log('error', json.error)
//     return
//   }
//   chatResponse.value = json
// }).listenToAll((e) => {
//   console.log('listenToAll', e)
// })

const loadingChat = ref(false);
const chatActive = computed(() => {
  return loadingChat.value || chatResponse.value !== null
});

// watch chatActive and emit toggleChat event
watch(() => chatActive.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    emit('toggleChat', newValue)
  }
})

const chatFunction: Function = () => {
  if (props.query.length === 0) {
    chatResponse.value = null
    return
  }
  // if less than 5 characters, don't search
  if (props.query.length < 3) {
    return
  }
  // if there is a url in the query, extract it
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const url = props.query.match(urlRegex)?.[0]
  if (!url) {
    return; // no url found
  }
  console.log('sending to backend')
  loadingChat.value = true
  // Post to backend
  axios.post(route('api.chat'), {
    channel: channelName,
    query: props.query,
  }).then((response) => {
    console.log('response', response);
    if (response?.data?.status === 'success') {
      loadingChat.value = false
      chatResponse.value = response.data;
    } else if (response?.data?.status === 'loading') {
      // Mark as loading?
      chatResponse.value = null;
    } else {
      // likely error
      loadingChat.value = false
    }
  }).catch((error) => {
    console.log('error', error)
  });
}

const debounceChatFunction = (wait = 400) => debounce(chatFunction, wait)
// Watch query with debounce of 500ms then call chatFunction
watch(() => props.query, debounceChatFunction())

// Watch
</script>