<template>
  <Head :title="'Order #' + order.id" />

  <div>
    <div class="px-4 lg:px-0 pb-6 md:px-6">
      <Link :href="route('profile.show', profile.username)" class="text-sm font-semibold leading-7 text-gray-800 dark:text-white">
        <span aria-hidden="true">&larr;</span>
        Back to {{ profile.username }}'s profile
      </Link>
    </div>
    <!-- Main Content -->
    <div class="px-0 md:px-8 pb-16 xl:px-8 max-w-4xl mx-auto">
      <div class="pb-8 mb-4 dark:border-gray-600 flex flex-col items-center">
<!--        <h1 class="text-base font-medium text-youpay-pink">Thank you!</h1>-->
        <p class="mt-2 text-3xl font-bold tracking-tight sm:text-5xl dark:text-white text-center">
          Your gift for {{ profile.username }} <br/>
          is being prepared!
        </p>
      </div>

      <div class="bg-white dark:bg-white/10 rounded-lg shadow px-6 py-6">
        <div class="flex flex-col sm:flex-row rounded-md bg-blue-50 p-4 mb-10" v-if="KlaviyoTracking && !signedUp">
          <BoltIcon class="h-6 w-6 text-indigo-700 hidden sm:block" />
          <div class="pl-0 sm:px-3 flex-1">
            <p class="text-sm text-blue-700 font-medium">Want to connect with more creators who want to be gifted?</p>
            <p class="text-sm text-blue-700 pt-3">Subscribe to receive access to wishlists from other similar people wanting gifts.</p>
          </div>
          <div class="pt-3 sm:pt-0">
            <button @click="signup" class="flex justify-center rounded-md bg-youpay-navy dark:bg-youpay-navy-light px-4 py-2 w-full sm:w-auto text-sm font-semibold text-white shadow-sm hover:bg-youpay-navy-alt dark:hover:bg-youpay-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-youpay-teal dark:focus-visible:outline-youpay-teal">
              Subscribe
            </button>
          </div>
        </div>

        <div class="flex rounded-md bg-green-50 p-4 mb-10" v-show="signedUp">
          <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
          <div class="px-3 flex-1">
            <p class="text-sm text-green-700 font-medium">You're now subscribed!</p>
            <p class="text-sm text-green-700 pt-3"> We'll send you an email in the near future with more information.</p>
          </div>
        </div>

      <order-detail :order="order" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Head, Link, router} from "@inertiajs/vue3";
import ProfileLayout from "@/web/youpay-me-v3/Layouts/ProfileLayout.vue";
import { onMounted, ref, inject,  } from 'vue'
import {Bag, compileForAnalytics, StripeConfig} from "@/web/youpay-me-v3/types/checkout.d";
import {Profile, User} from "@/web/youpay-me-v3/types";
import OrderDetail from "@/web/youpay-me-v3/Components/Blocks/OrderDetail.vue";
import CheckoutLayout from "@/web/youpay-me-v3/Layouts/CheckoutLayout.vue";
import {BoltIcon} from "@heroicons/vue/24/outline";
import {CheckCircleIcon} from "@heroicons/vue/24/outline";

defineOptions({ layout: CheckoutLayout })

// Props
defineProps<{
  auth: User,
  profile: Profile,
  order: Bag,
  stripeConfig: StripeConfig,
}>();

onMounted(async () => {
  // Get only the total amount, strip all currency symbols and other characters

  deferKlaviyo(() => {
    KlaviyoTracking.value = true;
  });
});

const signedUp = ref(false);
const KlaviyoTracking = ref(typeof klaviyo !== "undefined");

const signup = () => {
  klaviyo.push(["track", "Order Success - Opt in", {
    "Opt-in": "true"
  }]);
  signedUp.value = true;
}

const deferKlaviyo = (callback, counter = 0) => {
  if (counter > 3000) {
    return false;
  }

  if (typeof klaviyo !== "undefined") {
    callback();
    return false;
  }

  counter++;
  setTimeout(() => { deferKlaviyo(callback, counter); }, 200);
}



</script>
