<template>
  <div class="flex flex-row gap-x-3 md:gap-x-6 mx-2 md:mx-4 mt-4">
    <ExpressButton title="Add Gift" :icon="GiftIcon" color="pink" description="Create a gift for anything you want" @click="visitGift" />
    <ExpressButton title="Add Wishlist" :icon="GiftTopIcon" color="teal" @click="router.visit(route('wishlist.create'))">
      <div>
        <p class="hidden 2xl:block">
          Create a new wishlist to organise your gifts.
        </p>
        <p class="hidden md:block 2xl:hidden">
          Create a new wishlist
        </p>
      </div>
    </ExpressButton>
  </div>
</template>

<script setup lang="ts">

import ExpressButton from "@/web/youpay-me-v3/Components/Buttons/ExpressButton.vue";

import {GiftIcon, GiftTopIcon} from "@heroicons/vue/24/outline";
import {router, usePage} from "@inertiajs/vue3";
import {User} from "@/web/youpay-me-v3/types";

const visitGift = () => {
  if (user && canCreateConnect) {
    router.visit(route('create-gift'));
  } else {
    router.visit(route('stores'))
  }
}

const user: User | null = usePage().props.auth;
const canCreateConnect = user?.can?.createConnect;
</script>