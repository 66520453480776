<template>
  <div class="py-4 border-y">
    <div @click="showField = !showField" class="flex justify-between items-center text-sm font-medium leading-6 py-1">
      <span>
        Prefill from a URL (optional)
      </span>
      <span v-if="!alwaysShow" class="cursor-pointer text-sm font-semibold leading-7 text-gray-600 dark:text-gray-200 " :class="showField ? 'pb-2' : ''">
        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" :class="showField ? 'transform rotate-180' : ''" />
      </span>
    </div>
    <div
      v-show="alwaysShow || showField"
      class="flex mt-2 bg-white dark:bg-white/[0.05] rounded-md shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus-within:outline focus-within:outline-1 focus-within:outline-youpay-teal sm:max-w-xl">
      <input type="text" v-model="item.url" id="gift-url"
             class="block flex-1 py-2 px-3 border-0 bg-transparent dark:text-gray-300 focus:border-youpay-teal dark:focus:border-gray-700 focus:ring-0"
             placeholder="www.example.com/product/details"
      />
      <span @click="fetchUrl(item)"
            class="flex cursor-pointer select-none items-center bg-gray-300 rounded-r-md px-3 text-gray-500 dark:text-gray-200 dark:bg-gray-600 sm:text-sm"
      >
        Fetch
      </span>
    </div>

    <InputError class="mt-2" :message="fetchError"/>
  </div>
</template>
<script setup lang="ts">
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import {ref} from "vue";
import {ChevronDownIcon} from "@heroicons/vue/24/outline";

const item = defineModel();
const fetchError = ref('');
const props = defineProps({
  alwaysShow: {
    type: Boolean,
    default: false,
  },
  denominations: {
    type: Object,
    default: () => ({}),
  },
});
const showField = ref(false);

const setPrice = (price) => {
  if (props.denominations && Object.keys(props.denominations).length >= 1) {
    // find the closest denomination that is above the price
    for (let i = 0; i < Object.keys(props.denominations).length; i++) {
      let amount = Object.keys(props.denominations)[i];
      if (parseFloat(amount) < price) {
        continue;
      }
      return amount;
    }
  }
  return price;
}

const fetchUrl = (item) => {
  if (!item.url || item.url === '') {
    return;
  }
  trackEvent('fetch-product', {
    url: item.url,
  })
  axios.post(route('api.product'), {
    url: item.url,
  }).then((response) => {
    if (response.data) {
      trackEvent('fetch-product-success', {
        url: item.url,
      })
      item.title = response.data.name;
      item.description = response.data.description;
      item.image = response.data.images.length ? response.data.images[0] : '';
      let price = response.data.price;
      if (typeof price === 'string') {
        price = price.replace(/[^0-9.-]+/g,"");
        price = parseFloat(price);
      }
      item.amount = setPrice(price);
    }
  }).catch((error) => {
    trackEvent('fetch-product-error', {
      url: item.url,
      error: error,
    })
    if (error.response && error.response.status === 422) {
      fetchError.value = error.response.data.errors.url[0];
    } else {
      fetchError.value = 'There was an error fetching that url';
    }
  })
};

</script>