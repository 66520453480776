<template>
    <button
        :type="type"
        :disabled="disabled"
        @click="$emit('click', $event)"
        class="flex justify-center rounded-md leading-7
        bg-youpay-navy dark:bg-youpay-navy-light
        px-4 py-1.5 text-sm font-semibold text-white shadow-sm
        hover:bg-youpay-navy-alt
        dark:hover:bg-youpay-navy
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
        focus-visible:outline-youpay-teal
        dark:focus-visible:outline-youpay-teal"
        :class="disabled ? 'opacity-20' : ''"
    >
        <slot />
    </button>
</template>
<script setup lang="ts">
const props = defineProps<{
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
}>();
const type = props.type || 'submit';

defineEmits(['click']);

</script>