<template>
  <div class="py-4">
    <div v-if="canEdit">
      <div v-if="index !== 0" class="flex justify-between pb-6">
        <h3>Gift #{{ index + 1 }}</h3>
      </div>

      <div class="flex flex-col-reverse md:flex-row gap-x-6 ">
        <div class="pt-4 md:pt-6 md:pt-0 md:w-1/3 flex flex-col items-center gap-x-6" :class="showFetch ? 'justify-center' : 'justify-end'">
          <label class="block md:hidden text-sm font-medium leading-6 text-left w-full pb-4">Add an image</label>
          <SelectImage v-model="item.image" :title="item.title" :default-image="defaultImage" :error="errors && errorKey ? errors[errorKey + '.image'] ?? '' : ''" />
        </div>
        <div class="grid grid-cols-1 gap-x-6 gap-y-4 md:gap-y-6 sm:grid-cols-6 flex-grow">
          <FetchFromUrl v-if="showFetch" class="col-span-full" v-model="item" :denominations="denominations" />

          <div :class="showAmount ? 'sm:col-span-3' : 'col-span-full'">
            <label :for="'gift-title-' + index" class="block text-sm font-medium leading-6">Gift Name</label>
            <div class="mt-2">
              <TextInput
                class="mt-1 block w-full" type="text" :id="'gift-title-' + index"
                v-model="item.title"
                :error="errors && errorKey ? errors[errorKey + '.title'] ?? '' : ''"
              />
            </div>
            <InputError class="mt-1" :message="errors && errorKey ? errors[errorKey + '.title'] ?? '' : ''" />
          </div>
          <div v-if="showAmount && denominations && Object.keys(denominations).length >= 1"
               class="sm:col-span-3">
            <label :for="'gift-amount-' + index" class="block text-sm font-medium leading-6">Gift Amount</label>
            <div class="mt-2">
              <select
                :id="'gift-amount-' + index" v-model="item.amount"
                class="w-full border-gray-300 dark:border-gray-700 dark:bg-white/[0.05] dark:text-gray-300 focus:border-youpay-teal dark:focus:border-gray-700 focus:ring-youpay-teal dark:focus:ring-youpay-teal rounded-md shadow-sm leading-7"
              >
                <option class="text-black" value="0" selected>$0.00</option>
                <option class="text-black" v-for="(formatted, amount) in denominations" :key="amount" :value="amount">{{ formatted }}</option>
              </select>
            </div>
          </div>
          <div v-else-if="showAmount" class="sm:col-span-3">
            <label :for="'gift-amount-' + index" class="block text-sm font-medium leading-6">Gift Amount</label>
            <div class="mt-2">
              <CurrencyInput
                class="mt-1 block w-full" type="text"
                v-model="item.amount"
                :currency="currency"
              />
            </div>
            <InputError class="mt-1" :message="errors && errorKey ? errors[errorKey + '.amount'] ?? '' : ''" />
          </div>
          <div class="col-span-full">
            <label :for="'gift-desc-' + index" class="block text-sm font-medium leading-6">
              Gift Description
            </label>
            <div class="mt-2">
              <TextAreaInput :id="'gift-desc-' + index"  v-model="item.description" />
              <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">Write a small description about your gift.</p>
              <InputError class="mt-1" :message="errors && errorKey ? errors[errorKey + '.description'] ?? '' : ''" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-row items-center gap-x-4">
      <div v-if="item.image" class="flex flex-col justify-center items-center gap-x-6">
        <img :src="item.image" class="w-24 rounded-md" />
      </div>

      <div class="flex flex-auto flex-col">
        <div>
          <h4 class="font-medium text-lg text-gray-900 dark:text-gray-50">
            {{ item.title }}
          </h4>
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-200">{{ item.description }}</p>
        </div>
        <div class="flex flex-1">
          <dl class="flex flex-col gap-y-2 text-sm">
            <div class="flex">
              <dt class="font-medium text-gray-900 dark:text-gray-50">Quantity</dt>
              <dd class="ml-2 text-gray-700 dark:text-gray-100">{{ item.quantity }}</dd>
            </div>
            <div class="flex ">
              <dt class="font-medium text-gray-900 dark:text-gray-50">Price</dt>
              <dd class="ml-2 text-gray-700 dark:text-gray-100">{{ item.total }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import CurrencyInput from "@/web/youpay-me-v3/Components/Form/CurrencyInput.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import {ref} from "vue";
import SelectImage from "@/web/youpay-me-v3/Components/Gifting/SelectImage.vue";
import FetchFromUrl from "@/web/youpay-me-v3/Components/Gifting/FetchFromUrl.vue";

interface Item {
  id?: number
  title: string
  description: string
  amount: number
  quantity: number
  image: string
  url?: string
  total?: string
}

const item: Item = defineModel();

withDefaults(defineProps<{
  currency: string,
  errors?: object,
  errorKey?: string,
  index: number,
  showAmount: boolean,
  showQuantity?: boolean,
  showFetch: boolean,
  canEdit?: boolean,
  denominations?: any
}>(), {
  showAmount: true,
  showQuantity: true,
  showFetch: true,
  canEdit: true,
  denominations: null
})

const defaultImage = item?.value?.image;
</script>
