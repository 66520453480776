<script setup lang="ts">
import DangerButton from '../../../Components/Buttons/DangerButton.vue';
import InputError from '../../../Components/Form/InputError.vue';
import InputLabel from '../../../Components/Form/InputLabel.vue';
import Modal from '../../../Components/Blocks/Modal.vue';
import SecondaryButton from '../../../Components/Buttons/SecondaryButton.vue';
import TextInput from '../../../Components/Form/TextInput.vue';
import { useForm } from '@inertiajs/vue3';
import { nextTick, ref } from 'vue';

const confirmingUserDeletion = ref(false);
const confirmUserSuspend = ref(false);
const passwordInput = ref<HTMLInputElement | null>(null);

const form = useForm({
    password: '',
});

const confirmUserDeletion = () => {
    confirmingUserDeletion.value = true;

    nextTick(() => passwordInput.value?.focus());
};

const deleteUser = () => {
    form.delete(route('settings.delete-account'), {
        preserveScroll: true,
        onSuccess: () => closeModal(),
        onError: () => passwordInput.value?.focus(),
        onFinish: () => {
            form.reset();
        },
    });
};

const suspendUser = () => {
    form.post(route('settings.suspend-account'), {
        preserveScroll: true,
        onSuccess: () => closeModal(),
        onFinish: () => {
            form.reset();
        },
    });
};

const closeModal = () => {
    confirmingUserDeletion.value = false;
    confirmUserSuspend.value = false;

    form.reset();
};
</script>

<template>
  <section class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Need a break from YouPay?</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Disable your account temporarily. To reactivate your account, simply log in again.</p>
    </div>

    <form class="flex items-start md:col-span-2">
      <DangerButton @click.prevent="confirmUserSuspend = true">Disable my account</DangerButton>
    </form>
  </section>

  <section class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
          <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Delete account</h2>
          <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">No longer want to use our service? You can delete your account here. This action is not reversible. All information related to this account will be deleted permanently.</p>
      </div>

      <form class="flex items-start md:col-span-2">
          <DangerButton @click.prevent="confirmUserDeletion">Delete my account</DangerButton>
      </form>
  </section>

  <Modal :show="confirmingUserDeletion" @close="closeModal">
    <div class="p-6">
      <h2 class="text-lg font-medium text-gray-900 dark:text-gray-100">
        Are you sure you want to delete your account?
      </h2>

      <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
        Once your account is deleted, all of its resources and data will be permanently deleted. Please
        enter your password to confirm you would like to permanently delete your account. For more information, please read our <a href="https://youpay.freshdesk.com/support/solutions/articles/51000418173-account-deletion" class="text-blue-500">support page</a>.
      </p>

      <div class="mt-6">
        <InputLabel for="password" value="Password" class="sr-only" />

        <TextInput
          id="password"
          ref="passwordInput"
          v-model="form.password"
          type="password"
          class="mt-1 block w-3/4"
          placeholder="Password"
        />

        <InputError :message="form.errors.password" class="mt-2" />
      </div>

      <div class="mt-6 flex justify-end">
        <SecondaryButton @click="closeModal"> Cancel </SecondaryButton>

        <DangerButton
          class="ml-3"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
          @click.prevent="deleteUser"
        >
          Delete Account
        </DangerButton>
      </div>
    </div>
  </Modal>

  <Modal :show="confirmUserSuspend" @close="closeModal">
    <div class="p-6">
      <h2 class="text-lg font-medium text-gray-900 dark:text-gray-100">
        Are you sure you want to suspend your account?
      </h2>

      <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
        Once your account is suspended, you will need to relogin to reactivate your account.
      </p>

      <div class="mt-6 flex justify-end">
        <SecondaryButton @click="closeModal"> Cancel </SecondaryButton>

        <DangerButton
          class="ml-3"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
          @click.prevent="suspendUser"
        >
          Suspend Account
        </DangerButton>
      </div>
    </div>
  </Modal>
</template>
