<template>
  <div class="w-full cursor-pointer rounded-lg border md:border-2 bg-white shadow-md text-youpay-black dark:text-gray-800 md:px-2 dark:bg-white/5 py-2 md:py-1" :class="borderColor">
    <div class="h-full flex items-center justify-center">
      <div class="ml-2 md:ml-4 lg:ml-4">
        <component :is="icon" class="h-6 w-6 lg:h-12 lg:w-12" :class="iconColor" />
      </div>
      <div class="flex md:w-full flex-col md:gap-y-1 px-4 md:py-2">
        <h3 class="text-base md:font-semibold dark:text-white">
          {{ title }}
        </h3>
        <div class="hidden lg:flex items-center sm:justify-between">
          <div class="text-sm text-gray-700 dark:text-gray-200">
            <slot>
              <p>
                {{ description }}
              </p>
            </slot>
          </div>
        </div>
      </div>
      <div class="mr-2 hidden items-center justify-between rounded-md p-1 text-white md:mr-3 md:flex" :class="buttonColor">
        <PlusIcon class="h-5 w-5 text-white dark:text-white lg:h-8 lg:w-8"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PlusIcon} from "@heroicons/vue/24/outline";

const props = defineProps<{
  title: string,
  description?: string,
  icon: any,
  color: string,
}>();

const iconColor = props.color === 'teal' ? 'text-teal-500' : 'text-youpay-pink';
const borderColor = props.color === 'teal' ? 'border-teal-500' : 'border-youpay-pink';
const buttonColor = props.color === 'teal' ? 'bg-teal-500/80' : 'bg-youpay-pink/90';
</script>