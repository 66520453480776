<template>
  <Head :title="isLeaderboard ? 'Leaderboard' : 'Explore'" />
  <ais-instant-search
    class="mx-auto h-auto min-h-full w-full flex-grow max-w-[2600px]"
    :search-client="exploreSearchClient"
    :index-name="indexName"
  >
    <ais-configure
      :hits-per-page.camel="30"
    />
    <header class="z-20 flex w-full flex-row gap-4 p-4 xl:p-6">
      <ais-search-box class="relative flex-grow shadow-sm">
        <template v-slot="{ currentRefinement, isSearchStalled, refine }">
          <MagnifyingGlassIcon
            class="pointer-events-none absolute left-4 h-5 w-5 text-gray-400 top-3.5 dark:text-gray-500"
            aria-hidden="true"/>
          <input
            class="
              rounded-md
              bg-white dark:bg-white/[0.05] dark:text-gray-50 h-12 w-full border-0 pl-11 pr-4 text-gray-900 placeholder:text-gray-400 dark:placeholder:text-gray-200 focus:ring-0 sm:text-sm
            "
            placeholder="Search users..."
            type="search"
            :value="currentRefinement"
            @input="refine($event.currentTarget.value)"
          >
          <span :hidden="!isSearchStalled">Loading...</span>
        </template>
      </ais-search-box>
      <div class="min-w-[15%]">
        <ais-sort-by
          :class-names="{
            'ais-SortBy-select': 'flex items-center shadow-sm bg-white dark:bg-gray-900 dark:text-gray-200 h-12 p-2 px-4 w-full justify-between rounded-md border-none',
          }"
          ref="sortBy"
          :items="sort_list"
        >
          <template v-slot="{ items, currentRefinement, refine }">
            <Dropdown class="w-full">
              <template #trigger>
                <button type="button" class="flex h-12 w-full items-center justify-between rounded-md bg-white p-2 px-4 shadow-sm dark:bg-white/[0.05] dark:text-gray-200">
                  <span class="pr-4">{{ items?.find(item => item.value === currentRefinement)?.label ?? items[0].label }}</span>
                  <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </button>
              </template>
              <template #content>
                <div
                  v-for="item in items"
                  :key="item.value"
                  class="block w-full cursor-pointer px-4 py-2 text-left text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:bg-gray-100 focus:outline-none dark:text-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-800"
                  @click.prevent="refine(item.value)"
                >
                  {{ item.label }}
                </div>
              </template>
            </Dropdown>
          </template>
        </ais-sort-by>
      </div>
    </header>

    <ais-infinite-hits class="">
      <template v-slot="{
        items,
        refinePrevious,
        refineNext,
        isLastPage,
      }">
        <ul role="list" class="m-4 mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 xl:m-6 3xl:grid-cols-5 4xl:grid-cols-6">
          <li v-for="profile in items" :key="profile.username">
            <div class="relative col-span-1 overflow-hidden rounded-lg h-full bg-transparent text-left shadow-md">
              <div :style="profile.banner? `background-image: url(${profile.banner});` : ''" class="absolute top-0 w-full bg-gradient-to-r via-purple-500 bg-cover bg-center aspect-[3/1] h-[80px] md:h-[150px] from-youpay-navy-light to-youpay-pink"></div>

              <div class="flex h-full flex-col bg-white pt-[50px] md:pt-[90px] dark:bg-white/[0.05]">
                <div class="relative z-10 flex flex-1 flex-grow flex-col px-4 pb-4 md:pb-8">
                  <div class="flex flex-row gap-x-4">
<!--                    Blur if not following -->
                    <div v-if="profile.avatar" class="rounded-md border-4 border-white overflow-hidden">
                      <img
                           class="inline-block  h-22 w-22"
                           :class="props.following.includes(profile?.id) ? '' : 'filter blur-md'"
                           :src="profile.avatar"
                           :alt="profile.name"
                      />
                    </div>
                    <div v-else class="inline-block rounded-md bg-white border-4 border-white">
                      <UserCircleIcon class="h-22 w-22 text-slate-400 dark:text-slate-600" />
                    </div>
                    <div class="md:hidden font-medium mt-[30px] pt-2 text-gray-900 dark:text-gray-50 flex flex-col">
                      <span class="text-sm line-clamp-1">{{ profile.name }}</span>
                      <span class="text-xs">{{ '@' + profile.username }}</span>
                    </div>
                  </div>
                  <h3 class="hidden mt-3 md:flex h-5 flex-row items-center text-sm font-medium text-gray-900 dark:text-gray-50">
                    <span class="leading-3">{{ profile.name }}</span>
                    <span v-if="profile.isVerified" class="pl-2">
                      <CheckCircleIcon class="h-5 w-5 text-blue-500" aria-hidden="true" />
                    </span>
                  </h3>
                  <div class="mt-1 hidden md:flex flex-grow flex-col justify-start">
                    <div class="sr-only">Details</div>
                    <div class="text-sm font-medium text-gray-500 dark:text-gray-100 flex flex-col lg:flex-row gap-x-2">
                      <span>{{ '@' + profile.username }}</span>
                      <span class="hidden lg:inline">-</span>
                      <span class="text-xs md:text-sm">{{ profile.followers }} Followers</span>
                    </div>
                    <div class="sr-only">About</div>
                    <div class="hidden md:block mt-3 text-sm text-gray-400 line-clamp-2 dark:text-gray-100">{{ profile.description }}</div>
                  </div>
                </div>
                <div class="bg-white dark:bg-white/[0.02]">
                  <div class="-mt-px flex  border-t divide-x divide-gray-200 dark:divide-gray-700 dark:border-gray-700">
                    <div class="flex w-0 flex-1">
                      <Link
                          :href="route('profile.show', profile.username)"
                          class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        <EyeIcon class="h-5 w-5 text-gray-400 dark:text-gray-100" aria-hidden="true" />
                        <span>
                          <span class="hidden md:inline">View</span>
                          Profile
                        </span>

                      </Link>
                    </div>
                    <div class="-ml-px flex w-0 flex-1">
                      <Link
                        :href="route('follow', profile.username)"
                        method="put"
                        @click="trackEvent('follow', profile.username)"
                        preserve-scroll
                        :disabled="!auth?.id"
                        as="button"
                        v-if="!auth?.id || !props.following.includes(profile?.id)"
                        class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 dark:text-gray-50"
                        :class="!auth?.id ? 'opacity-20' : ''"
                      >
                        <PlusIcon class="h-5 w-5 text-gray-400 dark:text-gray-100" aria-hidden="true" />
                        Follow
                      </Link>
                      <Link
                          :href="route('unfollow', profile.username)"
                          method="delete"
                          as="button"
                          @click="trackEvent('unfollow', profile.username)"
                          preserve-scroll
                          v-else-if="auth?.id"
                          class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-green-600 dark:text-green-600"
                      >
                        <CheckCircleIcon class="h-5 w-5 text-green-700 dark:text-green-700" aria-hidden="true" />
                        Following
                      </Link>

                      <!-- Not Logged In -->
                      <button v-else disabled class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 opacity-20 dark:text-gray-50" >
                        <PlusIcon class="h-5 w-5 text-gray-400 dark:text-gray-100" aria-hidden="true" />
                        Follow
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="!isLastPage" class="mx-auto p-4 pb-6 w-[300px]">
          <button
            @click="refineNext"
            class="pointer-events-auto w-full rounded-md px-4 py-2 text-center font-medium shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50 dark:bg-white/[0.05] dark:text-white dark:hover:bg-white/[0.02]"
          >
            View more
          </button>
        </div>
      </template>
    </ais-infinite-hits>
  </ais-instant-search>
</template>

<script setup lang="ts">
import {Head, Link, usePage} from "@inertiajs/vue3";
import { EyeIcon, PlusIcon, CheckCircleIcon, MagnifyingGlassIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
import Dropdown from "@/web/youpay-me-v3/Components/Buttons/Dropdown.vue";
import {computed, inject, onMounted, ref} from "vue";
import {instantMeiliSearch} from "@meilisearch/instant-meilisearch";
import {User} from "@/web/youpay-me-v3/types";
import {UserCircleIcon} from "@heroicons/vue/24/outline";
import LeaderboardFeature from "@/web/youpay-me-v3/Components/Blocks/LeaderboardFeature.vue";

const trackEvent = inject('trackEvent');

interface Profile {
  name: string,
  username: string,
  banner: string | null,
  followers: number,
  description: string,
  isVerified: boolean,
  avatar: string,
}

const props = defineProps<{
  isLeaderboard: bool,
  auth: User|null,
  following: number[],
  sorting: {
    value: string,
    label: string,
  }[],
}>();

const scout = usePage().props.scout;
const exploreSearchClient = instantMeiliSearch(
  scout.host, // Host
  scout.search_key // API key
)

// const sorting = [//props.sorting ?? [
//   { value: 'created:desc', label: 'Newest' },
//   { value: 'created:asc', label: 'Oldest' },
// ];

const sort_list = props.sorting.map((item) => {
  return {
    value: scout.prefix + 'accounts:' + item.value,
    label: item.label,
  }
});

const indexName = computed(() => {
  return sort_list[0].value;
  return scout.prefix + 'accounts';
})

</script>
