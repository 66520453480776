<template>
  <div>
    <div id="payment-element" class="pt-3">
      <!-- Stripe Elements Placeholder -->
    </div>

    <InputError :message="form.errors.error" class="mt-2"/>

    <button
      class="mt-6 w-full rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-youpay-navy-light hover:bg-youpay-navy-alt focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      type="submit"
      @click.stop="submit"
    >
      <span v-if="!form.processing">
        Pay {{ props.bag.total }}
      </span>
      <span v-else>
        Processing...
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import {onMounted} from "vue";
import {Bag} from "@/web/youpay-me-v3/types/checkout";
import {
  getStripeContainer, getStripeElements,
  setupPaymentElement,
  setupStripeElements
} from "@/web/youpay-me-v3/stripe";
import {useForm} from "laravel-precognition-vue-inertia";
import type {IpDetails, warriorConfig} from "@/web/youpay-me-v3/helpers/MerchantWarrior";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";

// Get Props
const props = defineProps<{
  bag: Bag,
  paymentConfig: warriorConfig,
  ipdetails?: IpDetails,
}>();

// Setup the form
const form = useForm('post', route('checkout.payment', {
  bag: props.bag.id,
  recaptcha: '',
}), {
  gateway: 'stripe',
});

/**
 * Submit the Payment
 * TODO: Handle errors
 */
const submit = function(e) {
  e.preventDefault();
  form.processing = true;

  getStripeContainer().confirmPayment({
    elements: getStripeElements(),
    redirect: "if_required", // todo: confirm we never redirect
    confirmParams: {
      payment_method_data: {
        billing_details: {
          name: props.bag.customer.name,
          email: props.bag.customer.email,
          phone: props.bag.customer.phone,
          address: {
            line1: props.bag.customer.address.address_1,
            line2: props.bag.customer.address.address_2,
            city: props.bag.customer.address.suburb,
            state: props.bag.customer.address.state,
            postal_code: props.bag.customer.address.postcode,
            country: props.bag.customer.address.country_code,
          }
        }
      }
    }
  }).then(async result => {
    console.log(result);
    if (result.error) {
      console.log("Payment failed");
      console.log(result.error);
      form.processing = false;
      form.setError('error', result.error.message);

      // Inform the customer that there was an error.
    } else if (result.paymentIntent && result.paymentIntent.status === "requires_capture") {

      // Track the event
      // try {
      //   const gaData = compileForAnalytics(props.profile.username, props.bag);
      //   gaData.transaction_id = result.paymentIntent.id;
      //   trackEvent('purchase', gaData);
      // } catch (e) {} // Silently fail

      // Add extra data and submit the form
      form.transform(
        (data: Object) => ({
          ...data,
          payment_intent: result.paymentIntent.id,
        })
      ).submit({
        preserveScroll: false,
      });

    } else {
      form.processing = false;
      console.log("Payment failed");
      form.setError('error', 'There was an error processing your payment, please try again.');
      // Something went wrong message? or do we already have enough errors?
    }
  });
}

onMounted(async () => {
  await setupStripeElements(props.paymentConfig.config);
  setupPaymentElement();

  // load ReCaptcha
  const script = document.createElement('script');
  script.src = 'https://www.google.com/recaptcha/api.js?render=6LfV_fIpAAAAAPhXfOeJ8bkNdftDqmWYxxGN1DM6';
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);

  setTimeout(() => {
    window.grecaptcha.ready(function() {
      window.grecaptcha
        .execute('6LfV_fIpAAAAAPhXfOeJ8bkNdftDqmWYxxGN1DM6', {action: 'submit'})
        .then(function(token) {
          form.recaptcha = token;
        });
    });
  }, 1000);
});
</script>