<template>
  <Head title="Forgot Password"/>

  <div class="mx-auto max-w-4xl rounded-md bg-white p-8 text-gray-900 gift-page dark:bg-white/10 md:my-4 dark:text-white">
    <h1 class="text-3xl font-bold text-gray-800 dark:text-gray-200 pb-4">Reset Password</h1>

    <div class="mb-4 text-sm text-gray-600 dark:text-gray-400">
      Forgotten your password or never got one when you registered? No problem. Just let us know your email address and
      we will email you a password reset
      link that will allow you to create a new one.
    </div>

    <div v-if="status" class="mb-4 font-medium text-sm text-green-600 dark:text-green-400">
      {{ status }}
    </div>

    <form @submit.prevent="submit">
      <div>
        <InputLabel for="email" value="Email"/>

        <TextInput
          id="email"
          type="email"
          class="mt-1 block w-full"
          v-model="form.email"
          required
          autofocus
          autocomplete="username"
        />

        <InputError class="mt-2" :message="form.errors.email"/>
      </div>

      <div class="flex items-center justify-end mt-4">
        <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
          Email Password Reset Link
        </PrimaryButton>
      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import GuestLayout from '../../Layouts/MainLayout.vue';
import InputError from '../../Components/Form/InputError.vue';
import InputLabel from '../../Components/Form/InputLabel.vue';
import PrimaryButton from '../../Components/Buttons/PrimaryButton.vue';
import TextInput from '../../Components/Form/TextInput.vue';
import {Head, useForm} from '@inertiajs/vue3';

defineOptions({layout: GuestLayout});

defineProps<{
  status?: string;
}>();

const form = useForm({
  email: '',
});

const submit = () => {
  form.post(route('password.email'));
};

</script>
