<template>
  <Head title="Update Profile" />
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
        <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Public Information</h2>
        <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">These details will be visible to others.</p>
    </div>

    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full">
          <InputLabel for="display_name" value="Display Name" />

          <TextInput
            id="display_name"
            type="text"
            class="mt-1 block w-full"
            v-model="form.display_name"
            @change="form.validate('display_name')"
            :error="form.errors.display_name"
            required
            autocomplete="display_name"
          />

          <InputError class="mt-2" :message="form.errors.display_name"/>
        </div>
        <div class="col-span-full">
          <InputLabel for="username" value="Username" />

          <div class="mt-2">
            <div class="flex py-0.5 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus-within:ring-2 focus-within:ring-youpay-teal dark:focus-within:ring-youpay-teal dark:bg-white/[0.05] bg-white"
                 :class="form.errors.username ? 'ring-red-300 dark:ring-red-700' : ''"
            >
              <span class="flex select-none items-center pl-3 text-gray-500 dark:text-gray-400 sm:text-sm">youpay.me/</span>
              <input type="text" @change="form.validate('username')" v-model="form.username" name="username" id="username"
                     class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 dark:placeholder-gray-600"
                     placeholder="example" />
            </div>
          </div>

          <InputError class="mt-2" :message="form.errors.username"/>
        </div>
        <div class="col-span-full">
          <InputLabel for="pronouns" value="Your Pronouns" />

          <TextInput
            id="pronouns"
            type="text"
            class="mt-1 block w-full"
            v-model="form.pronouns"
            @change="form.validate('pronouns')"
            :error="form.errors.pronouns"
            required
            autocomplete="pronouns"
          />

          <InputError class="mt-2" :message="form.errors.pronouns"/>
        </div>
        <div class="col-span-full">
          <div class="sm:max-w-xl">
            <InputLabel for="description" value="Profile Byline" />

            <TextAreaInput
              id="description"
              type="text"
              class="mt-1 block w-full"
              v-model="form.description"
              @change="form.validate('description')"
              :error="form.errors.description"
            />

            <InputError class="mt-2" :message="form.errors.description"/>
            <p class="mt-2 pl-1 text-xs leading-6 text-gray-400">This can appear in your profile, search and other areas.</p>

          </div>
        </div>

        <div class="col-span-3">
          <InputLabel for="avatar" value="Your Profile Picture" class="mb-4"/>
          <UploadFile v-model="form.avatar" />
          <InputError class="mt-2" :message="form.errors.avatar"/>
        </div>
        <div class="col-span-3">
          <InputLabel for="banner" value="Your Banner Image" class="mb-4"/>
          <UploadFile v-model="form.banner" />
          <InputError class="mt-2" :message="form.errors.banner"/>
        </div>
        <div class="col-span-full mt-8">
          <span v-if="!user.moderated" class="pb-8 text-orange-500 dark:text-orange-400 block">
            <span class="font-semibold">Please note:</span> Your profile picutures are currently under review
          </span>
          <PrimaryButton @click.prevent="submit" :disabled="form.processing" :class="form.processing ? 'opacity-20' : ''">Update Profile</PrimaryButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import DeleteUserForm from './Partials/DeleteUserForm.vue';
import {Head, usePage, Link} from '@inertiajs/vue3';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import {ref} from "vue";
import {useForm} from "laravel-precognition-vue-inertia";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import UploadFile from "@/web/youpay-me-v3/Components/Form/UploadFile.vue";
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import {User} from "@/web/youpay-me-v3/types";
const submitted = ref(false)
const results = ref()
const user = <User>usePage().props.auth;

const form = useForm('post', route('settings.public.update'), {
  avatar: user.avatar ?? '',
  banner: user.banner ?? '',
  description: user.description ?? '',
  username: user.username ?? '',
  pronouns: user.pronouns ?? '',
  display_name: user.display_name ?? '',
});


const submit = (e) => {
  e?.preventDefault();

  if (form.validating) {
    form.cancel();
  }
  submitted.value = true;

  form.submit({
    preserveScroll: false,
  });
};
defineOptions({ layout: SettingsLayout })
</script>