<template>
  <div class="">
    <div class="flex flex-col md:flex-row md:items-center gap-x-4 pb-2">
      <div class="flex flex-row flex-wrap items-center gap-x-2">
        <h2 class="font-semibold text-gray-900 dark:text-white">
          {{ type }}:
        </h2>
        <div class="flex flex-row items-center gap-x-1">
          <span class="dark:text-white pr-2">{{ account.name }}</span>
          <CheckBadgeIcon v-if="account.verified" class="h-5 w-5 text-youpay-teal" aria-hidden="true" />
        </div>
      </div>
      <ul class="text-xs flex flex-row flex-wrap divide-x gap-y-2 items-center justify-center py-2 md:py-0">
        <li class="pr-2" v-for="(social, index) in getSocials" :key="social.title" :class="index !== 0 ? 'pl-2' : ''">
          <a :href="social.url" class="text-black dark:text-white" target="_blank">
            <component v-if="social.icon !== LinkIcon" :is="social.icon" class="h-4 w-4 dark:text-white dark:fill-white" />
            <span v-else>{{ social.title }}</span>
          </a>
        </li>
        <li v-for="(link, index) in account.account_links" class="px-2" :key="index">
          <a :href="link.url" target="_blank" class="text-black dark:text-white">{{ link.text }}</a>
        </li>
      </ul>
    </div>
    <div class="flex flex-col md:flex-row justify-center gap-x-6">
      <a :href="account.url" target="_blank" class="flex-shrink-0 mb-2 md:mb-0">
        <img v-if="account.avatar" class="h-24 w-24 md:h-16 md:w-16 rounded-xl" :src="account.avatar" alt="" />
        <UserCircleIcon v-else class="h-24 w-24 md:h-16 md:w-16 p-2 text-gray-400 dark:text-gray-600" />
      </a>
      <div class="flex flex-col">
        <div class="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-5 w-full">
          <div class="col-span-1 md:col-span-2 text-gray-900 dark:text-white flex flex-col gap-y-1 text-wrap">
            <a :href="account.url" target="_blank" class="text-xs font-bold text-gray-500 dark:text-gray-200">
              {{ account.display_name }}
              <TrashIcon v-if="account.deleted_at" class="h-6 w-6 text-red-500 dark:text-red-400 inline" aria-hidden="true" />
            </a>
            <a :href="account.url" target="_blank" class="text-xs text-gray-900 dark:text-white">@{{ account.username }}</a>
            <div class="flex gap-x-2 items-center">
              <span class="text-xs font-bold text-gray-500 dark:text-gray-200 text-wrap break-words">{{ account.email }}</span>
              <span class="text-xs text-gray-900 dark:text-white text-wrap break-words">{{ account.phone }}</span>
            </div>
            <div class="flex gap-x-2 items-center">
              <span class="text-xs font-bold text-gray-500 dark:text-gray-200">Locations:</span>
              <span class="text-xs text-gray-900 dark:text-white">{{ account.locations }}</span>
            </div>
          </div>
          <div class="col-span-1 flex flex-col">
            <span class="text-xs font-bold text-gray-500 dark:text-gray-200">Orders</span>
            <span class="mt-1 text-xs text-gray-900 dark:text-white">
              {{ account.paid_count }}<br />
              {{ account.gift_count }}
            </span>
          </div>
          <div class="col-span-1 md:col-span-2 flex flex-col text-wrap">
            <span class="text-xs font-bold text-gray-500 dark:text-gray-200">Address</span>
            <span class="mt-1 text-xs text-gray-900 dark:text-white">
              {{ account.name }} <br />
              {{ account.primary_address }}
            </span>
          </div>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {CheckBadgeIcon, LinkIcon, TrashIcon} from "@heroicons/vue/20/solid";
import {User} from "@/web/youpay-me-v3/types";
import {computed} from "vue";
import {FacebookIcon, InstagramIcon, OnlyfansIcon, TiktokIcon, TwitchIcon} from "vue3-simple-icons";
import TwitterXIcon from "@/web/youpay-me-v3/Icons/TwitterXIcon.vue";
import {UserCircleIcon} from "@heroicons/vue/24/outline";
import {Head} from "@inertiajs/vue3";
const props = defineProps<{
  account: User,
  type: string
}>();


const socials: Array<{
  title: string;
  icon: any;
  slug: string;
}> = [
  {
    title: 'Facebook',
    icon: FacebookIcon,
    slug: 'facebook_url',
  },
  {
    title: 'Instagram',
    icon: InstagramIcon,
    slug: 'instagram_url',
  },
  {
    title: 'X / Twitter',
    icon: TwitterXIcon,
    slug: 'twitter_url',
  },
  {
    title: 'Twitch',
    icon: TwitchIcon,
    slug: 'twitch_url',
  },
  {
    title: 'Tiktok',
    icon: TiktokIcon,
    slug: 'tiktok_url',
  },
  {
    title: 'OnlyFans',
    icon: OnlyfansIcon,
    slug: 'only_fans',
  },
  // 'custom_urls' => 'nullable|array',
  // 'custom_urls.*.url' => 'nullable|string|max:255|regex:/^https?:\/\/.*/',
  // 'custom_urls.*.title' => 'required_if:custom_urls.*.url,!=,|string|max:255',
];

const getSocials = computed(() => {
  if (!props.account.socials) {
    return [];
  }
  const socialsArray = [];
  for (const social of socials) {
    if (props.account.socials[social.slug]) {
      socialsArray.push({
        title: social.title,
        icon: social.icon,
        url: props.account.socials[social.slug],
      });
    }
  }
  if (props.account.socials.custom_urls) {
    for (const custom of props.account.socials.custom_urls) {
      socialsArray.push({
        title: custom.title,
        icon: LinkIcon,
        url: custom.url,
      });
    }
  }
  return socialsArray;
});
</script>