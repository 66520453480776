<template>
  <div class="bg-white dark:bg-gray-700 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-youpay-navy dark:text-youpay-pink">coming soon</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-youpay-white">Premium Features</p>
        <p class="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">Check back soon for more information!</p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl hidden">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <div v-for="feature in features" :key="feature.name" class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-50">
              <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-youpay-navy dark:bg-youpay-pink">
                <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              {{ feature.name }}
            </dt>
            <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-300">{{ feature.description }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckBadgeIcon, PencilIcon, ListBulletIcon, GiftIcon, CurrencyDollarIcon } from "@heroicons/vue/24/outline";

const features = [
  {
    name: 'Get Verified',
    description: 'Premium users will be manually verified, giving you a verified badge on your profile.',
    icon: CheckBadgeIcon,
  },
  {
    name: 'Personalisation',
    description: 'Even more personalisation and themes for premium users.',
    icon: PencilIcon,
  },
  {
    name: 'Rewards',
    description: 'Access to exclusive rewards, ony available to premium users.',
    icon: GiftIcon,
  },
  {
    name: 'Discounted Fees',
    description: 'Constantly sending gifts to people? Save on any gifting fee\'s that might be involved.',
    icon: CurrencyDollarIcon,
  },
]
</script>