<template>
  <div class="grid gap-y-4 gap-x-4 grid-cols-6">
    <div class="col-span-3">
      <InputLabel for="first_name" value="First Name"/>
      <TextInput
        id="first_name"
        v-model="form.first_name"
        :error="form.errors['first_name']"
        class="mt-2 block w-full"
        required
        type="text"
        @change="form.validate('first_name')"
      />
      <InputError :message="form.errors['first_name']" class="mt-2"/>
    </div>
    <div class="col-span-3">
      <InputLabel for="last_name" value="Last Name"/>
      <TextInput
        id="last_name"
        v-model="form.last_name"
        :error="form.errors['last_name']"
        class="mt-2 block w-full"
        required
        type="text"
        @change="form.validate('last_name')"
      />
      <InputError :message="form.errors['last_name']" class="mt-2"/>
    </div>
    <div class="col-span-full">
      <InputLabel class="pb-1" for="phone" value="Phone Number"/>

      <MazPhoneNumberInput
        v-model="form.phone"
        :error="form.invalid('phone')"
        :ignored-countries="['AC']"
        :preferred-countries="['AU', 'FR', 'BE', 'DE', 'US', 'GB']"
        class="overflow-visible"
        color="info"
        label=""
        fetch-country
        show-code-on-list
        @country-code="debounceUpdateCountryCode"
        @change="form.validate('phone')"
      />

      <InputError :message="form.errors['phone']" class="mt-2"/>
    </div>
    <div class="col-span-full">
      <InputLabel for="address_1" value="Address Line 1" />
      <TextInput
        id="address_1"
        v-model="form.address_1"
        :error="form.errors.address_1"
        class="mt-2 block w-full"
        required
        type="text"
        @change="form.validate('address_1')"
      />
      <InputError :message="form.errors.address_1" class="mt-2" />
    </div>
    <div class="col-span-full">
      <InputLabel for="address_2" value="Address Line 2" />
      <TextInput
        id="address_2"
        v-model="form.address_2"
        :error="form.errors.address_2"
        class="mt-2 block w-full"
        type="text"
        @change="form.validate('address_2')"
      />
      <InputError :message="form.errors.address_2" class="mt-2" />
    </div>
    <div class="col-span-3">
      <InputLabel for="suburb" value="City / Suburb"/>
      <TextInput
        id="suburb"
        v-model="form.suburb"
        :error="form.errors.suburb"
        class="mt-2 block w-full"
        required
        type="text"
        @change="form.validate('suburb')"
      />
      <InputError :message="form.errors.suburb" class="mt-2"/>
    </div>
    <div class="col-span-3">
      <InputLabel for="postcode" value="Zip / Postcode"/>
      <TextInput
        id="postcode"
        v-model="form.postcode"
        :error="form.errors.postcode"
        class="mt-2 block w-full"
        required
        type="text"
        @change="form.validate('postcode')"
      />
      <InputError :message="form.errors.postcode" class="mt-2"/>
    </div>
    <div class="col-span-3">
      <InputLabel for="state" value="State"/>
      <TextInput
        id="state"
        v-model="form.state"
        :error="form.errors.state"
        class="mt-2 block w-full"
        required
        type="text"
        @change="form.validate('state')"
      />
      <InputError :message="form.errors.state" class="mt-2"/>
    </div>
    <div class="col-span-3">
      <InputLabel for="country" value="Country" />
      <CountryList v-model="form.country_code" :error="form.errors.country_code" />
      <InputError :message="form.errors.country_code" class="mt-2" />
    </div>
  </div>
</template>

<script setup lang="ts">
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import CountryList from "@/web/youpay-me-v3/Components/Form/CountryList.vue";
import { inject, onMounted, ref } from "vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import {debounce} from "lodash";

let autocomplete = ref(null);

const props = defineProps<{
  form: any,
  ipdetails?: { latitude: number; longitude: number }
}>();

const mapsApiKey = inject('mapsApiKey') as string;

const updateCountryCode = (code:string) => form.address.country = code;
const debounceUpdateCountryCode = debounce(updateCountryCode, 500);

const onPlaceChanged = () => {
  const place = autocomplete.value?.getPlace();
  console.log(place);
  if (!place?.address_components) {
    console.error('No address components found');
    return;
  }

  let address = {
    street_number: null,
    route: null,
    address_2: null,
    suburb: null,
    state: null,
    postcode: null,
    country_code: null,
  };

  place.address_components.forEach((component) => {
    const type = component.types[0];
    switch (type) {
      case 'street_number':
        address.street_number ??= component.long_name;
        break;
      case 'route':
        address.route ??= component.long_name;
        break;
      case 'sublocality_level_1':
      case 'subpremise':
        address.address_2 ??= component.long_name;
        break;
      case 'locality':
      case 'postal_town':
        address.suburb ??= component.long_name;
        break;
      case 'administrative_area_level_1':
        address.state ??= component.short_name;
        break;
      case 'postal_code':
        address.postcode ??= component.long_name;
        break;
      case 'country':
        address.country_code ??= component.short_name;
        break;
    }
  });

  props.form.address_1 = `${address.street_number ? address.street_number + " " : ""}${address.route}`;
  props.form.address_2 = address.address_2 ?? '';
  props.form.suburb = address.suburb ?? '';
  props.form.state = address.state ?? '';
  props.form.postcode = address.postcode ?? '';
  props.form.country_code = address.country_code ?? '';
};

const loadAutocomplete = () => {
  const field = document.getElementById('address_1');
  if (!field || autocomplete.value) return;

  let options = {
    fields: ["address_components"],
    types: ["address"],
    strictBounds: false,
    ...(props.ipdetails && {
      bounds: getBounds(props.ipdetails.latitude, props.ipdetails.longitude)
    })
  };

  autocomplete.value = new google.maps.places.Autocomplete(field, options);
  autocomplete.value.addListener('place_changed', onPlaceChanged);
};

const getBounds = (lat, lng) => ({
  north: lat + 30,
  south: lat - 30,
  east: lng + 30,
  west: lng - 30,
});

const loadMaps = () => {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places`;
  script.async = true;
  script.defer = true;
  script.onload = loadAutocomplete;
  document.head.appendChild(script);
};

onMounted(loadMaps);
</script>