<template>
  <section :class="computedClass">
    <slot></slot>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  cols?: number;
  rows?: number;
  bg?: boolean;
  class?: string;
}>(), {
  cols: 6,
  rows: 1,
  bg: true,
  class: '',
});

const computedClass = computed(() => {
  // lg:col-span-4 lg:col-span-2 lg:col-span-3 lg:col-span-1 row-span-1 row-span-2 col-span-full lg:col-span-6 row-span-1
  const baseClasses = `flex-col text-gray-900 dark:text-gray-100 col-span-full`;
  let sizeClasses = '';
  if (props.cols) {
    sizeClasses = `lg:col-span-${props.cols}`;
  }
  if (props.rows) {
    sizeClasses = `${sizeClasses} row-span-${props.rows}`;
  }
  const bgClasses = props.bg ? 'bg-white dark:bg-white/10 shadow-sm ring-1 ring-gray-900/5 p-3 sm:p-6 rounded-xl' : '';
  return `${baseClasses} ${bgClasses} ${sizeClasses} ${props.class}`;
});
</script>