<template>
  <Head title="Update Addresses" />

  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Edit your address</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Change your address details.</p>
    </div>
    <form class="md:col-span-2 dark:text-white sm:max-w-xl ">
      <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
        <div class="sm:col-span-3">
          <InputLabel for="first_name" value="First Name" />
          <TextInput
            id="first_name"
            type="text"
            class="mt-2 block w-full"
            v-model="form.first_name"
            @change="form.validate('first_name')"
            :error="form.errors.first_name"
            required
          />
          <InputError class="mt-2" :message="form.errors.first_name"/>
        </div>
        <div class="sm:col-span-3">
          <InputLabel for="last_name" value="Last Name" />
          <TextInput
            id="last_name"
            type="text"
            class="mt-2 block w-full"
            v-model="form.last_name"
            @change="form.validate('last_name')"
            :error="form.errors.last_name"
            required
          />
          <InputError class="mt-2" :message="form.errors.last_name"/>
        </div>

        <div class="col-span-full">
          <InputLabel for="email" value="Phone Number" class="pb-1" />

          <MazPhoneNumberInput
            v-model="form.phone"
            @change="form.validate('phone')"
            class="overflow-visible"
            show-code-on-list
            label=""
            color="info"
            :preferred-countries="['AU', 'FR', 'BE', 'DE', 'US', 'GB']"
            :ignored-countries="['AC']"
            @update="results = $event"
            :error="form.invalid('phone')"
          />

          <InputError class="mt-2" :message="form.errors.phone"/>
        </div>

        <div class="col-span-full">
          <InputLabel for="address_1" value="Address Line 1" />
          <TextInput
            id="address_1"
            type="text"
            class="mt-2 block w-full"
            v-model="form.address_1"
            @change="form.validate('address_1')"
            :error="form.errors.address_1"
            required
          />
          <InputError class="mt-2" :message="form.errors.address_1"/>
        </div>

        <div class="col-span-full">
          <InputLabel for="address_2" value="Address Line 2" />
          <TextInput
            id="address_2"
            type="text"
            class="mt-2 block w-full"
            v-model="form.address_2"
            @change="form.validate('address_2')"
            :error="form.errors.address_2"
          />
          <InputError class="mt-2" :message="form.errors.address_2"/>
        </div>

        <div class="sm:col-span-3">
          <InputLabel for="suburb" value="Suburb"/>
          <TextInput
            id="suburb"
            type="text"
            class="mt-2 block w-full"
            v-model="form.suburb"
            @change="form.validate('suburb')"
            :error="form.errors.suburb"
            required
          />
          <InputError class="mt-2" :message="form.errors.suburb"/>
        </div>

        <div class="sm:col-span-3">
          <InputLabel for="postcode" value="Postcode" />
          <TextInput
            id="postcode"
            type="text"
            class="mt-2 block w-full"
            v-model="form.postcode"
            @change="form.validate('postcode')"
            :error="form.errors.postcode"
            required
          />
          <InputError class="mt-2" :message="form.errors.postcode"/>
        </div>

        <div class="sm:col-span-3">
          <InputLabel for="state" value="State" />
          <TextInput
            id="state"
            type="text"
            class="mt-2 block w-full"
            v-model="form.state"
            @change="form.validate('state')"
            :error="form.errors.state"
            required
          />
          <InputError class="mt-2" :message="form.errors.state"/>
        </div>

        <div class="sm:col-span-3">
          <InputLabel for="country" value="Country" />
          <CountryList v-model="form.country" :error="form.errors.country" />
          <InputError class="mt-2" :message="form.errors.country"/>
        </div>

        <div v-if="submitted && form.hasErrors" class="col-span-full text-sm text-red-600 dark:text-red-400">
          Looks like there are some errors in your form. Please review and try again.
        </div>
      </div>

      <div class="mt-8 flex flex-col xs:flex-row-reverse gap-y-6 justify-between">
        <PrimaryButton @click.prevent="submit" :disabled="form.processing">
          Save Address
        </PrimaryButton>
        <SecondaryButton v-if="address?.id" @click.prevent="makePrimary" :disabled="form.processing || address.primary">
          {{ address.primary ? 'Already your primary address' : 'Make Primary Address' }}
        </SecondaryButton>
      </div>

      <DangerButton v-if="address?.id" @click.prevent="confirmDeletion = true" :disabled="address.primary || form.processing" class="w-full mt-8 xs:w-42">
        Delete Address
      </DangerButton>
    </form>
  </div>

  <Modal :show="confirmDeletion" @close="closeModal">
    <div class="p-6">
      <h2 class="text-lg font-medium text-gray-900 dark:text-gray-100">
        Are you sure you want to delete?
      </h2>

      <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
        This will be permanently deleted.
      </p>

      <div class="mt-6 flex justify-end">
        <SecondaryButton @click="closeModal"> Cancel </SecondaryButton>

        <DangerButton
          class="ml-3"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
          @click.prevent="deleteItem"
        >
          Yes, please delete
        </DangerButton>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import {Head, Link, usePage} from '@inertiajs/vue3';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import SecondaryButton from "@/web/youpay-me-v3/Components/Buttons/SecondaryButton.vue";
import {onMounted, ref} from "vue";
import DangerButton from "@/web/youpay-me-v3/Components/Buttons/DangerButton.vue";
import Modal from "@/web/youpay-me-v3/Components/Blocks/Modal.vue";
import CountryList from "@/web/youpay-me-v3/Components/Form/CountryList.vue";
import {loadStripe} from "@stripe/stripe-js/pure";
import {compileForAnalytics} from "@/web/youpay-me-v3/types/checkout";

interface address {
  id: number,
  name: string,
  phone: string,
  address_1: string,
  address_2: string,
  suburb: string,
  state: string,
  country: string,
  postcode: string,
  is_primary: boolean,
}

const props = defineProps<{
  address: address
}>()

const form = useForm('post', route('settings.address.store'), <address>{
  id: props.address.id,
  first_name: props.address.first_name,
  last_name: props.address.last_name,
  phone: props.address.phone,
  address_1: props.address.address_1,
  address_2: props.address.address_2,
  suburb: props.address.suburb,
  state: props.address.state,
  country: props.address.country_code,
  postcode: props.address.postcode,
  is_primary: props.address.is_primary,
});

const submitted = ref(false);

const submit = () => {
  submitted.value = true;
  form.submit({
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
    }
  });
};

const makePrimary = () => {
  form.put(route('settings.address.primary', props.address.id), {
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
    }
  });
};

const confirmDeletion = ref(false);

const deleteItem = () => {
  form.delete(route('settings.address.delete', [props.address.id]), {
    preserveScroll: true,
    onSuccess: () => closeModal(),
    onFinish: () => {
      form.reset();
    },
  });
};

const closeModal = () => {
  confirmDeletion.value = false;
};

const stripeKey = usePage().props.stripe.key;

onMounted(async () => {
  // const stripe = await loadStripe(stripeKey);
  //
  // const stripeElements = stripe.elements();
  // const addressOptions = {
  //   mode: 'billing',
  //   display: {
  //     name: 'split'
  //   },
  //   fields: {
  //     phone: 'always'
  //   },
  //   validation: {
  //     phone: {
  //       required: 'always'
  //     }
  //   },
  // };
  // addressOptions['defaultValues'] = {
  //   firstName: props.address.first_name,
  //   lastName: props.address.last_name,
  //   phone: props.address.phone,
  //   address: {
  //     line1: props.address.address_1,
  //     line2: props.address.address_2,
  //     city: props.address.suburb,
  //     state: props.address.state_code,
  //     postal_code: props.address.postcode,
  //     country: props.address.country_code,
  //   }
  // }
  // addressOptions['contacts'] = [
  //   {
  //     name: props.address.first_name + ' ' + props.address.last_name,
  //     phone: props.address.phone,
  //     address: {
  //       line1: props.address.address_1,
  //       line2: props.address.address_2,
  //       city: props.address.suburb,
  //       state: props.address.state,
  //       postal_code: props.address.postcode,
  //       country: props.address.country_code,
  //     }
  //   }
  // ];

  // console.log(addressOptions);
  // const addressElement = stripeElements.create('address', addressOptions);
  //
  // addressElement.mount('#address-element');
})

defineOptions({ layout: SettingsLayout })
</script>