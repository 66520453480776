<template>
  <Head title="Create Wishlist" />

    <div class="bg-[url('/images/splash/bg-light.png')] dark:bg-[url('/images/splash/bg-dark.png')] bg-cover w-full h-full flex-grow bg-right relative">
      <div class="hidden md:fixed z-0 inset-0 bg-gradient-to-tr from-youpay-yellow/[0.1] via-youpay-pink/[0.1] to-youpay-teal/[0.1] dark:from-youpay-navy-dark/[0.2] dark:via-pink-950/[0.2] dark:to-youpay-teal/[0.1] transition-opacity" />
      <div class="inset-0 z-10 relative overflow-y-auto md:p-6 md:p-20">
        <form class="max-w-4xl md:rounded-lg mx-auto bg-white dark:bg-gray-700 p-6">
          <div class="space-y-12">
            <div class="border-b border-white/10 pb-12">
              <h1 class="text-2xl lg:text-4xl mt-2 mb-6 leading-7 text-youpay-black dark:text-white">Create a wishlist</h1>

              <div class="lg:mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-full">
                  <InputLabel for="title" value="Wishlist Title" class="lg:text-xl xl:pb-2"/>

                  <TextInput
                    id="title"
                    type="text"
                    class="mt-1 block w-full max-w-xl"
                    v-model="form.title"
                    @change="form.validate('title')"
                    :error="form.errors.title"
                    required
                  />

                  <InputError class="mt-2" :message="form.errors.title"/>
                </div>

                <div class="col-span-full">
                  <InputLabel for="description" value="Description" class="lg:text-xl xl:pb-2"/>

                  <div class="mt-2">
                    <TextAreaInput id="description" v-model="form.description" :error="form.errors.description" @change="form.validate('description')" />
                  </div>
                  <InputError class="mt-2" :message="form.errors.description"/>

                  <p class="mt-2 pl-1 text-xs leading-6 text-gray-400">Write a small description to appear on your wishlist.</p>
                </div>

                <div class="col-span-full sm:grid-cols-2 relative">
                  <div class="leading-6 text-xl xl:text-2xl dark:text-white">
                    Personalise your wishlist
                  </div>
                  <div class="mt-4">
                    <InputLabel :disabled="!can_personalise" for="anon" value="Wishlist visibility" class="lg:text-lg"/>
                    <label class="flex mt-2 items-center gap-x-2">
                      <Checkbox name="terms" :disabled="!can_personalise" v-model:checked="form.anon" @update:checked="form.validate('anon')" />
                      <span class="ml-2 text-xs lg:text-sm text-gray-600 dark:text-gray-400 flex flex-row items-center gap-x-2">
                        <EyeIcon v-if="!form.anon" class="w-5 h-5" aria-hidden="true" />
                        <EyeSlashIcon v-if="form.anon" class="w-5 h-5" aria-hidden="true" />
                        <span v-if="!form.anon" >
                          Visible on my profile and search results
                        </span>
                        <span v-if="form.anon">
                          Hidden from my profile and search results
                        </span>
                      </span>
                    </label>

                    <InputError class="mt-2" :message="form.errors.anon"/>
                  </div>
                  <div class="mt-6 md:flex md:flex-row gap-x-6 xl:gap-x-16">
                    <div class="">
                      <InputLabel :disabled="!can_personalise" for="avatar" value="Custom Avatar" class="lg:text-lg"/>
                      <UploadFile :disabled="!can_personalise" v-model="form.avatar" class="pt-4 block" />
                      <InputError class="mt-2" :message="form.errors.avatar"/>
                    </div>

                    <div class="mt-5 md:mt-0">
                      <InputLabel :disabled="!can_personalise" for="cover-photo" value="Cover photo" class="lg:text-lg"/>
                      <UploadFile :disabled="!can_personalise"  v-model="form.banner" class="pt-4 block" />
                      <InputError class="mt-2" :message="form.errors.banner"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6 flex flex-col items-end sm:flex-row-reverse justify-between gap-x-4 gap-y-18">
            <div class="flex flex-col gap-y-6 xs:flex-row items-center justify-end gap-x-4 md:gap-x-6 w-full">
              <Link as="button" :href="route('dashboard')" class="text-sm w-full xs:w-auto dark:font-semibold px-4 py-2 bg-gray-100 dark:bg-youpay-white/[0.05] rounded-md text-youpay-black dark:text-white focus-visible:outline focus-visible:outline-youpay-teal leading-6">Cancel</Link>
              <PrimaryButton @click.prevent="submit" :disabled="form.processing" class="leading-6 w-full md:w-auto">
                Save Wishlist
              </PrimaryButton>
            </div>

            <DangerButton v-if="wishlist?.id" @click.prevent="confirmWishlistDeletion" :disabled="form.processing" class="w-full xs:w-42">
              Delete Wishlist
            </DangerButton>
          </div>
        </form>
    </div>
  </div>

  <Modal :show="confirmingWishlistDeletion" @close="closeModal">
    <div class="p-6">
      <h2 class="text-lg font-medium text-gray-900 dark:text-gray-100">
        Are you sure you want to delete your wishlist?
      </h2>

      <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
        Once your wishlist is deleted, both your wishlist all of its gifts in the wishlist will be permanently deleted.
      </p>

      <div class="mt-6 flex justify-end">
        <SecondaryButton @click="closeModal"> Cancel </SecondaryButton>

        <DangerButton
          class="ml-3"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
          @click.prevent="deleteWishlist"
        >
          Delete Wishlist
        </DangerButton>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import {Head, Link, usePage} from "@inertiajs/vue3";
import DashLayout from "@/web/youpay-me-v3/Layouts/DashLayout.vue";
import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import {EyeIcon, EyeSlashIcon} from "@heroicons/vue/24/outline";
import {ref, nextTick} from "vue";
import UploadFile from "@/web/youpay-me-v3/Components/Form/UploadFile.vue";
import TextAreaInput from "@/web/youpay-me-v3/Components/Form/TextAreaInput.vue";
import {User, FeatureFlags} from "@/web/youpay-me-v3/types";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import MainLayout from "@/web/youpay-me-v3/Layouts/MainLayout.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import DangerButton from "@/web/youpay-me-v3/Components/Buttons/DangerButton.vue";
import SecondaryButton from "@/web/youpay-me-v3/Components/Buttons/SecondaryButton.vue";
import Modal from "@/web/youpay-me-v3/Components/Blocks/Modal.vue";

const user: User | null = usePage().props.auth;
const feature_flags: FeatureFlags = usePage().props.flags;
const can_personalise = !feature_flags.premium || user?.plan === 'premium';

const props = defineProps<{
  wishlist?: {
    id: string;
    url: string;
    title: string,
    description: string,
    avatar: string,
    banner: string,
    anon: boolean,
    searchable: boolean,
  }
}>();

const form = useForm('post', route('wishlist.store'), {
  id: props.wishlist?.id ?? null,
  url: props.wishlist?.url ?? null,
  title: props.wishlist?.title ?? '',
  description: props.wishlist?.description ?? '',
  avatar: props.wishlist?.avatar ?? user?.avatar ?? '',
  banner: props.wishlist?.banner ?? '',
  searchable: props.wishlist?.searchable ?? true, // TODO: set default from primary
  anon: props.wishlist?.anon ?? false,
});

const pfp = ref('pfp');
const confirmingWishlistDeletion = ref(false);

const submit = (e: Event) => {
  e.preventDefault();
  form.submit({
    preserveScroll: true,
    onSuccess: () => form.reset(),
  });
}

const confirmWishlistDeletion = () => {
  confirmingWishlistDeletion.value = true;
};

const deleteWishlist = () => {
  form.delete(route('wishlist.delete', [user?.username, props.wishlist.url]), {
    preserveScroll: true,
    onSuccess: () => closeModal(),
    onFinish: () => {
      form.reset();
    },
  });
};

const closeModal = () => {
  confirmingWishlistDeletion.value = false;
};
</script>
