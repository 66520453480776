<template>
  <Head title="Change Password" />


  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 pt-6 pb-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div class="col-span-full max-w-5xl">
      <div @click="router.visit(route('settings.password.send.reset'))" class="col-span-full cursor-pointer gap-x-1 rounded-md bg-indigo-100 px-3 py-1 text-sm text-red-900 ring-1 ring-inset ring-red-700/10 flex items-center">
        <InformationCircleIcon class="h-6 w-6 text-red-900" aria-hidden="true" />
        <div class="p-2">
          You don't need your password to change it! <span class="underline">Click here</span> to update your password via email.
        </div>
      </div>
    </div>
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Change password</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Update your password associated with your account.</p>

      <h2 class="hidden md:block mt-8 text-base font-semibold leading-7 dark:text-white text-gray-700">Having issues?</h2>
      <p class="hidden md:block mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Can't remember your password? Reset it via your email address by <Link method="post" as="button" :href="route('settings.password.send.reset')" class="cursor-pointer underline text-youpay-navy dark:text-youpay-pink">clicking here</Link>.</p>
    </div>

    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full">
          <InputLabel for="current-password" value="Current password" />
          <TextInput
            id="current-password"
            type="password"
            class="mt-2 block w-full"
            v-model="form.current_password"
            :error="form.errors.current_password"
            required
          />
          <InputError class="mt-2" :message="form.errors.current_password"/>
        </div>

        <div class="col-span-full">
          <InputLabel for="new-password" value="New password" />
          <TextInput
            id="new-password"
            type="password"
            class="mt-2 block w-full"
            v-model="form.password"
            :error="form.errors.password"
            required
          />
          <InputError class="mt-2" :message="form.errors.password"/>
        </div>

        <div class="col-span-full">
          <InputLabel for="confirm-password" value="Confirm password" />
          <TextInput
            id="confirm-password"
            type="password"
            class="mt-2 block w-full"
            v-model="form.password_confirmation"
            :error="form.errors.password_confirmation"
            required
          />
          <InputError class="mt-2" :message="form.errors.password_confirmation"/>
        </div>
      </div>

      <div class="mt-5 flex">
        <PrimaryButton @click.prevent="submit">
          Update Password
        </PrimaryButton>
      </div>
    </form>
    <div class="md:hidden block">
      <h2 class="mt-2 text-base font-semibold leading-7 dark:text-white text-gray-700">Having issues?</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Can't remember your password? Reset it via your email address by <Link method="post" as="button" :href="route('settings.password.send.reset')" class="cursor-pointer underline text-youpay-navy dark:text-youpay-pink">clicking here</Link>.</p>
    </div>
  </div>

  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div>
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">Log out other sessions</h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">Please enter your password to confirm you would like to log out of your other sessions across all of your devices.</p>
    </div>

    <form class="md:col-span-2">
      <div class="grid grid-cols-1 gap-y-1 sm:max-w-xl">
          <InputLabel for="current-password2" value="Current password" />
          <TextInput
            id="current-password2"
            type="password"
            class="mt-2 block w-full"
            v-model="invalidateForm.current_password"
            :error="invalidateForm.errors.current_password"
            required
          />
          <InputError class="mt-2" :message="invalidateForm.errors.current_password"/>

          <div class="mt-5">
            <PrimaryButton @click.prevent="submitInvalidate">
              Invalidate Sessions
            </PrimaryButton>
          </div>
      </div>
    </form>
  </div>

</template>

<script setup lang="ts">
import {Head, Link, router} from '@inertiajs/vue3';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import {InformationCircleIcon} from "@heroicons/vue/24/outline";

const form = useForm('post', route('settings.password.update'), {
  current_password: '',
  password: '',
  password_confirmation: '',
});

const invalidateForm = useForm('post', route('settings.password.invalidate'), {
  current_password: '',
});

const submitInvalidate = () => {
  invalidateForm.submit({
    preserveScroll: true,
    onSuccess: () => {
      invalidateForm.reset();
    }
  });
};

const submit = () => {
  form.submit({
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
    }
  });
};

defineOptions({ layout: SettingsLayout })
</script>
