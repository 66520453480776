<template>
  <div
    class="flex flex-col flex-shrink-0 gap-y-5 overflow-y-auto border-r border-gray-200 dark:border-gray-800 bg-white dark:bg-white/5 px-6 w-72 max-w-72 pt-8">
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-for="item in navigation" :key="item.name" v-show="!item.disabled">
              <Link v-if="!item.children" :href="route(item.route)"
                    :class="[currentRoute === 'item.route' ? 'bg-gray-50 dark:bg-white/5' : 'hover:bg-gray-50 dark:hover:bg-white/5', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 dark:text-white']">
                <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true"/>
                {{ item.name }}
              </Link>
              <Disclosure as="div" v-else v-slot="{ open }" :defaultOpen="isCurrentlyChildren(item)">
                <DisclosureButton
                  :class="[item.current ? 'bg-gray-50 dark:bg-white/5' : 'hover:bg-gray-50 dark:hover:bg-white/5', 'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 dark:text-white']">
                  <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true"/>
                  {{ item.name }}
                  <ChevronRightIcon
                    :class="[open ? 'rotate-90 text-gray-500 dark:text-gray-400' : 'text-gray-400 dark:text-gray-600', 'ml-auto h-5 w-5 shrink-0']"
                    aria-hidden="true"/>
                </DisclosureButton>
                <DisclosurePanel as="ul" class="mt-1 px-2">
                  <li v-for="subItem in item.children" :key="subItem.name" v-show="!subItem.disabled">
                    <Link :href="route(subItem.route)"
                          :class="[currentRoute === subItem.route ? 'bg-gray-50 dark:bg-white/5' : 'hover:bg-gray-50 dark:hover:bg-white/5', 'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700 dark:text-gray-100']">
                      {{ subItem.name }}
                    </Link>
                  </li>
                </DisclosurePanel>
              </Disclosure>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script setup lang="ts">
// import the debounce function
import {router, usePage, Link} from '@inertiajs/vue3';
import {computed, ref, watch} from 'vue'
import {BuildingLibraryIcon, CurrencyDollarIcon, GiftIcon, UsersIcon, ChevronRightIcon} from '@heroicons/vue/24/outline'
import {User} from "@/web/youpay-me-v3/types";
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/vue";
import {GiftTopIcon, UserCircleIcon} from "@heroicons/vue/20/solid";

const currentRoute = ref(route().current());


const flags: object = usePage().props.featureFlags;
const user = <User>usePage().props.auth;

router.on('navigate', () => {
  currentRoute.value = route().current();
})

const navigation = [
  {
    name: 'Account Settings',
    icon: UsersIcon,
    children: [
      {name: 'Private Details', route: 'settings.private'},
      {name: 'Public Details', route: 'settings.public'},
      {name: 'Social Links', route: 'settings.socials'},
      {name: 'Your Addresses', route: 'settings.addresses'},
      {name: 'Change Password', route: 'settings.password'},
      {name: 'Two Factor Authentication', route: 'settings.2fa.index'},
    ],
  },
  {
    name: 'Gifting Settings',
    icon: GiftIcon,
    route: 'settings.privacy'
  },
  {
    name: 'Bulk Update Gifts',
    icon: GiftTopIcon,
    route: 'settings.bulk-update',
  },
  {
    name: 'Verify Users',
    icon: UserCircleIcon,
    route: 'settings.restricted.verify-users',
    disabled: !user.can.verifyUsers,
  },
  {
    name: 'Payment Settings',
    icon: BuildingLibraryIcon,
    route: 'settings.payment',
    children: [
      {
        name: 'Coinflow',
        route: 'settings.coinflow.index',
      },
      {
        name: 'Stripe Connect',
        route: 'connect.index',
        disabled: !(user.can.createConnect),
      },
    ],
  },
  {
    name: 'Your Orders',
    icon: CurrencyDollarIcon,
    children: [
      {name: 'Received Gift Cards', route: 'settings.gift-cards'},
      {name: 'Received Orders', route: 'settings.presents'},
      {name: 'Paid Orders', route: 'settings.purchased'},
    ],
  },
  {
    name: 'Referrals',
    icon: UsersIcon,
    disabled: !user?.can?.seeReferrals,
    route: 'settings.referrals'
  },
]


const currentNavChildren = computed(() => {
  return navigation.find(item => item.children?.some(child => child.route === currentRoute.value))
});


const computedNavName = computed(() => {
  const main = navigation.find(item => item.route === currentRoute.value)?.name;
  if (main) return main;
  return currentNavChildren.value?.children?.find(child => child.route === currentRoute.value)?.name;
});
// when computedNavName changes, update the currentNavName
watch(computedNavName, (value) => {
  currentNavName.value = value;
});
const currentNavName = defineModel();
currentNavName.value = computedNavName.value;


const isCurrentlyChildren = (item) => {
  return item.children?.some(child => child.route === currentRoute.value)
}

</script>
