<template>
  <ul v-if="funds?.length" role="list"
      class="m-2 lg:m-4 grid grid-cols-2 gap-2 lg:gap-4 border-b border-gray-200 pb-2 lg:pb-4 dark:border-gray-800/[0.5] lg:grid-cols-3 xl:pb-6 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6">
    <li v-if="funds?.length" v-for="fund in funds" :key="fund.id"
        class="relative col-span-1 flex overflow-hidden rounded-lg bg-transparent text-left shadow-md">
      <div @click="visitLink(fund.url)" :style="fund.banner ? `background-image: url(${fund.banner});` : ''"
           class="cursor-pointer absolute top-0 w-full bg-gradient-to-r via-purple-500 bg-cover bg-center h-[150px] lg:h-[180px] from-youpay-navy-light to-youpay-pink"></div>

      <div class="relative flex flex-grow flex-col bg-white dark:bg-white/[0.05]  mt-[150px] lg:mt-[180px]">
        <div class="flex flex-row justify-start gap-x-2 pt-3 pr-3 pb-0 pl-3">
          <span class="flex-grow-0 inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs leading-none text-indigo-600 font-light ring-1 ring-inset ring-indigo-400/20 dark:text-indigo-300">
            Crowdgift
          </span>
          <span v-if="fund.category !== 'other'" class="flex-grow-0 inline-flex items-center rounded-md px-2 py-1 text-xs leading-none font-light ring-1 ring-inset text-youpay-pink-dark dark:text-youpay-pink bg-youpay-pink/20 dark:bg-youpay-pink/20 ring-youpay-pink/30">
            {{ fund.category_name }}
          </span>
        </div>
        <div class="relative z-10 flex flex-1 flex-col px-4 pt-2 pb-5">
          <dl class=" flex flex-grow flex-col">
            <dt class="sr-only">Details</dt>
            <dd class="text-lg font-medium text-gray-700 line-clamp-1 dark:text-white cursor-pointer" @click="visitLink(fund.url)">{{ fund.title }}</dd>
            <dt class="sr-only">About</dt>
            <dd class="mt-2 text-sm text-gray-400 line-clamp-2 dark:text-gray-100">
              {{ cleanDesc(fund.description) }}
            </dd>
          </dl>
          <div>
            <div class="mt-2 font-semibold text-gray-900 dark:text-white">
              {{ fund.amount_formatted }}
            </div>
            <div class="mt-2 w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div class="bg-indigo-600 h-2.5 rounded-full" :style="{ width: `${(fund.progress / fund.amount) * 100}%` }"></div>
            </div>
            <div class="mt-2 text-xs text-gray-500 dark:text-gray-400">
              {{ Math.round((fund.progress / fund.amount) * 100 * 100) / 100  }}% gifted
            </div>
          </div>
        </div>
        <div>
          <div
            class="-mt-px flex border-t divide-x divide-gray-200 dark:bg-white/[0.02] dark:divide-gray-700 dark:border-gray-700">
            <div class="-ml-px flex w-0 flex-1">
              <Link
                :href="route('fund.show', [profile.username, fund.url])"
                class="relative inline-flex w-0 flex-1 cursor-pointer items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 dark:text-white dark:hover:bg-gray-600"
              >
                <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                View Crowdgift
              </Link>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import {Link, router} from "@inertiajs/vue3";
import {EyeIcon} from "@heroicons/vue/20/solid";
import {Profile} from "@/web/youpay-me-v3/types";
import {marked} from "marked";
import DOMPurify from "dompurify";

const props = defineProps<{
  funds: any[],
  profile: Profile,
}>();

const cleanDesc = function (desc: string) {
  if (!desc || desc === '') {
    return '';
  }
  const dirty = marked.parse(desc);
  const clean = DOMPurify.sanitize(dirty, {
    USE_PROFILES: {html: false},
  });
  return clean.substring(0, 200);
}

const visitLink = (url: string) => {
  router.visit(route('fund.show', [props.profile.username, url]))
};
</script>