<template>
  <Head :title="settings.language.title" />

  <div class="mx-auto max-w-4xl rounded-md bg-white p-8 text-gray-900 gift-page dark:bg-gray-700 dark:text-white">
    <header class="flex flex-col-reverse items-center justify-between pb-4 gap-y-2 sm:flex-row relative">
      <div class="">
        <h1 class="text-2xl font-semibold">
          {{ settings.language.heading }}
        </h1>
        <p v-if="settings.language?.description" class="pt-4">
          {{ settings.language.description }}
        </p>
      </div>
      <div v-if="store"
           class="-mt-4 flex-shrink-0 ml-4 md:ml-2 rounded-md overflow-hidden flex items-center justify-center flex-col"
           :class="true ? 'dark:bg-gray-50' : ''"
      >
        <StoreLogo :store="store" class="w-[250px] sm:w-[150px] md:w-[250px] p-2" />
<!--        <a v-if="isYouPayApp" :href="store?.url" class="items-center gap-x-3 justify-center bg-gray-300 dark:bg-gray-800 w-full flex p-1 text-base">-->
<!--          Visit Store-->
<!--          <LinkIcon class="h-4 w-4 text-youpay-navy dark:text-youpay-pink" />-->
<!--        </a>-->
      </div>
    </header>
    <div>
      <div class="flex flex-col border-y border-gray-900/10 space-y-4 divide-y divide-gray-200 dark:divide-gray-600 dark:border-gray-600">
        <div v-if="!form.user.id" class="pt-4 pb-2">
          <h2 class="text-base font-semibold leading-7">
            Your Details
          </h2>
          <span class="text-xs leading-6 text-gray-600 dark:text-gray-200">
            Already have an account? <Link :href="route('login')" class="text-youpay-navy dark:text-youpay-pink">Login here</Link>
          </span>
          <div class="grid flex-grow grid-cols-1 gap-x-6 gap-y-3 pt-2 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <InputLabel for="first_name" value="First Name" />
              <TextInput
                id="first_name"
                type="text"
                class="mt-1 block w-full"
                v-model="form.user.first_name"
                @change="form.validate('user.first_name')"
                :error="form.errors['user.first_name']"
                required
              />
              <InputError class="mt-2" :message="form.errors['user.first_name']"/>
            </div>
            <div class="sm:col-span-3">
              <InputLabel for="last_name" value="Last Name" />
              <TextInput
                id="last_name"
                type="text"
                class="mt-1 block w-full"
                v-model="form.user.last_name"
                @change="form.validate('user.last_name')"
                :error="form.errors['user.last_name']"
                required
              />
              <InputError class="mt-2" :message="form.errors['user.last_name']"/>
            </div>

            <div class="sm:col-span-3">
              <InputLabel for="email" value="Email" />
              <TextInput
                id="email"
                type="text"
                class="mt-1 block w-full"
                v-model="form.user.email"
                @change="form.validate('user.email')"
                :error="form.errors['user.email']"
                required
              />
              <InputError class="mt-2" :message="form.errors['user.email']"/>
            </div>

            <div class="sm:col-span-3">
              <InputLabel for="email" value="Phone Number" />

              <MazPhoneNumberInput
                v-model="form.user.phone"
                @change="form.validate('user.user.phone')"
                class="overflow-visible"
                show-code-on-list
                fetch-country
                label=""
                color="info"
                :preferred-countries="['AU', 'FR', 'BE', 'DE', 'US', 'GB']"
                :ignored-countries="['AC']"
                @country-code="updateCountryCode"
                :error="form.invalid('user.phone')"
              />

              <InputError class="mt-2" :message="form.errors['user.phone']"/>
            </div>
          </div>
        </div>

        <div class="pt-4">
          <h2 class="text-base font-semibold leading-7">
            Gift Details
          </h2>
          <span class="text-xs leading-6 text-gray-600 dark:text-gray-200">
            This will be displayed on your wishlist.
          </span>

          <Wish
            class="relative"
            v-model="form.details"
            :errors="form.errors"
            error-key="details"
            :index="0"
            :currency="product.currency"
            :showFetch="settings.fetch && preferences.edit && !preferences.bundle"
            :denominations="product?.availableDenominations"
            :showAmount="!preferences.bundle"
          />
        </div>

<!--        <div v-if="preferences.bundle || (form.items && form.items.length > 1)" class="flex flex-col">-->
<!--          <h3 class="py-4 font-semibold xl:py-6">-->
<!--            Items in this gift request-->
<!--          </h3>-->
<!--          <Wish-->
<!--            v-for="(item, index) in form.items"-->
<!--            :key="item.id"-->
<!--            :currency="product.currency"-->
<!--            class="relative"-->
<!--            v-model="item"-->
<!--            :index="index"-->
<!--            :showFetch="settings.fetch"-->
<!--            :denominations="product?.availableDenominations ?? []"-->
<!--            :canEdit="false"-->
<!--          />-->
<!--          <div v-if="preferences.edit && preferences.bundle" class="flex justify-end pt-4">-->
<!--            <button @click="addAnotherGift" type="button" class="rounded-md px-4 py-2 text-sm font-semibold text-gray-800 bg-youpay-black text-youpay-white">-->
<!--              Add Another Gift Item-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

        <div class="pt-4" :class="user ? '' : 'hidden md:block'">
          <WishlistSelector v-model="form.wishlist" :wishlists="wishlists" :selected_wishlist="selected_wishlist" />
        </div>

        <fieldset class="pt-4 pb-4" :class="user ? '' : 'hidden md:block'">
          <div @click="user && (showAdvanced = !showAdvanced)" class="flex items-center cursor-pointer justify-between">
            <legend class="text-sm font-semibold leading-7">
              Advanced Gift Settings
              <span v-if="!user" class="pl-4 text-xs text-gray-500">Only for logged in users</span>
            </legend>
            <span v-if="user" class="cursor-pointer text-sm font-semibold leading-7 text-gray-600 dark:text-gray-200">
              <ChevronDownIcon class="h-5 w-5" aria-hidden="true"
                :class="showAdvanced ? 'transform rotate-180' : ''"
              />
            </span>
          </div>
          <div v-if="showAdvanced" class="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-200">
            Fine tune your preferences
          </div>
          <div v-if="showAdvanced" class="mt-4 space-y-6">
            <div v-for="setting in settings.visible" :key="setting" class="relative flex gap-x-4">
              <div class="flex items-center">
                <input v-model="preferences[setting]" :id="'setting-' + setting" type="checkbox" :checked="preferences[setting]" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
              </div>
              <label :for="'setting-' + setting" class="text-sm leading-6">
                <span class="block font-medium">
                  {{ lang[setting].title }}
                </span>
                <span class="text-gray-500 dark:text-gray-200">
                  <span v-if="false" class="font-semibold text-youpay-pink">Premium:</span>
                  {{ lang[setting].desc }}
                  <span v-if="lang[setting].beta" class="block text-xs text-youpay-yellow">
                    Beta feature: this may not work in all areas.
                  </span>
                </span>
              </label>
            </div>
          </div>
        </fieldset>


        <div v-show="!user || form.errors.terms" class="py-4">
          <span class="text-sm font-semibold leading-7">
            Terms of service
          </span>

          <label class="flex items-center py-2">
            <Checkbox name="terms" v-model:checked="form.terms" @update:checked="form.validate('terms')" />
            <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                  I agree to the <a :href="route('terms')" target="_blank" class="dark:text-youpay-pink text-youpay-navy underline">Terms, Conditions & Privacy Policies</a> of YouPay
                </span>
          </label>
          <InputError class="mt-2" :message="form.errors.terms"/>
        </div>
      </div>

      <div v-if="submitted && form.hasErrors" class="mt-6 text-sm text-red-600 dark:text-red-400">
        Looks like there are some errors in your form. Please review and try again.
      </div>

      <div class="mt-6 flex items-center justify-end gap-x-6">
        <Link v-if="user"
          :href="route('gift.index')"
          class="items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-center text-xs font-semibold text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 dark:bg-white/[0.03] dark:border-gray-500 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-800"
        >
          Cancel
        </Link>
        <PrimaryButton @click="submit" :disabled="form.processing">
          Request a Gift!
        </PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Head, usePage, Link} from "@inertiajs/vue3";
import WishlistSelector from "@/web/youpay-me-v3/Components/Gifting/WishlistSelector.vue";
import { ChevronDownIcon, LinkIcon } from "@heroicons/vue/24/outline";
import {computed, inject, onMounted, ref} from "vue";
import { useForm } from 'laravel-precognition-vue-inertia';
import Wish from "@/web/youpay-me-v3/Components/Gifting/Wish.vue";
import { GiftDetails, User} from "@/web/youpay-me-v3/types/index.d";
import { Settings, Store, lang } from "@/web/youpay-me-v3/types/gifting.d";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import StoreLogo from "@/web/youpay-me-v3/Components/Blocks/StoreLogo.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";

const user: User | null = usePage().props.auth;
const trackEvent: Function = inject('trackEvent');
const submitted = ref(false);
const showAdvanced = ref(false);

const props = defineProps<{
  store: Store,
  selected_wishlist: string,
  wishlists: any,
  settings: Settings,
  product: any,
  product_id: number,
  details?: GiftDetails
  items?: GiftDetails[]
}>();

const preferences = ref<Settings>(props.settings);

const form = useForm('post', route('gift.save'), {
  user: {
    id: user?.id,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    country: '',
  },
  product: props.product_id,
  details: <GiftDetails>{
    url: props.details?.url ?? '',
    currency: props.product.currency,
    title: props.details?.title ?? '',
    image: props.details?.image ?? props.product?.image ?? '',
    quantity: props.details?.quantity ?? 1,
    amount: props.details?.amount ?? 0,
    description: props.details?.description ?? '',
  },
  items: <GiftDetails[]> props.items ?? [],
  wishlist: props.selected_wishlist,
  terms: user ? true : false,
});

const addAnotherGift = () => {
  form.items.push({
    url: '',
    currency: props.product.currency,
    title: '',
    quantity: 1,
    image: '',
    amount: '',
    description: '',
  });
};

const submit = () => {
  submitted.value = true;
  form.transform(
    (data: Object) => ({
      ...data,
      settings: preferences.value
    })
  ).submit({
    preserveScroll: true,
    onSuccess: () => {
      const gaData = {
        product: props.product_id,
        type: props.product.type,
      };
      if (props.store?.title) {
        gaData.store = props.store.title;
      }
      trackEvent('gift_create_end', gaData);
      form.reset();
    }
  });
};

const isYouPayApp = computed(() => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('youpay') > -1;
});

const updateCountryCode = (code:string) => form.user.country = code;

onMounted(() => {
  const gaData = {
    product: props.product_id,
    type: props.product.type,
  };
  if (props.store?.title) {
    gaData.store = props.store.title;
  }
  trackEvent('gift_create_begin', gaData);
});
</script>

<style>
  .gift-page .m-phone-number-input {
    margin-top: 2px;
  }
  .gift-page .m-phone-number-input__select {
    height:45px!important;
  }
  .gift-page .m-phone-number-input__input {
    height:45px!important;
  }
</style>