<template>
  <MainLayout>
    <div
      class="relative flex h-full flex-grow flex-col"
    >
      <div class="h-32 lg:h-48 w-full bg-gradient-to-r via-purple-500 from-youpay-navy-light to-youpay-pink">
        <img v-if="profile.banner" class="h-32 w-full object-cover lg:h-48" :src="profile.banner" alt="" />
      </div>
      <div class="mx-auto w-full max-w-7xl px-4 lg:px-6 xl:px-8">
        <div @click="router.visit(route('profile.show', profile.username))" class="-mt-12 sm:-mt-16 flex items-end space-x-5 pt-1 cursor-pointer">
          <a class="h-28 w-28 sm:h-32 sm:w-32 relative block overflow-hidden rounded-lg bg-gray-200 dark:bg-gray-700 sm:rounded-xl md:rounded-2xl">
            <img v-if="profile.avatar" :alt="profile.display_name" fetchpriority="high" decoding="async" data-nimg="1" class="min-w-full min-h-full" style="color:transparent" :src="profile.avatar">
            <UserCircleIcon v-else class="h-full w-full p-8 text-gray-400 dark:text-gray-600" />
            <div class="absolute inset-0 rounded-lg ring-1 ring-inset ring-black/10 sm:rounded-xl md:rounded-2xl"></div>
          </a>
          <div class="mt-6 flex min-w-0 flex-1 items-center justify-end space-x-6 pb-1">
            <div class="mt-6 min-w-0 flex-1 block">
              <h1 class="truncate text-2xl font-bold text-gray-900 dark:text-white">{{ profile.display_name ?? profile.username }}</h1>
              <p class="mt-1 text-sm font-light text-slate-900 dark:text-white">
                @{{ profile.username }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <div class="mx-auto h-full w-full md:flex md:flex-row-reverse gap-x-6">
            <!-- Order summary -->
            <section v-if="bag" aria-labelledby="summary-heading" class="md:-mt-22 mb-4 md:mb-0 flex w-full flex-col bg-gray-50 dark:bg-white/5">
              <div class="flex items-center justify-between p-6 md:pb-0">
                <h2 id="summary-heading" class="dark:text-gray-50 md:sr-only">Gift Bag</h2>
                <div class="cursor-pointer flex items-center gap-x-2 font-medium text-youpay-navy dark:text-youpay-pink md:hidden"
                     @click="show_summary = !show_summary">
                  <span v-if="show_summary">Hide details</span>
                  <span v-if="!show_summary">{{ bag.total }}</span>
                  <ChevronDownIcon :class="show_summary ? 'transform rotate-180' : ''" aria-hidden="true" class="h-5 w-5"/>
                </div>
              </div>

              <CartItems :bag="bag" :profile="profile" :summary="show_summary" :class="show_summary ? 'block' : 'hidden md:block'" />
            </section>

            <!-- Checkout form -->
            <section aria-labelledby="payment-heading" class="w-full flex-auto overflow-y-auto pb-10 dark:text-gray-50 xl:pb-24" :class="bag ? 'sm:pt-6 xl:pt-0' : ''">
              <div class="pt-4 px-2">
                <slot />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>
<script setup lang="ts">
import {router, usePage} from '@inertiajs/vue3';
import MainLayout from "@/web/youpay-me-v3/Layouts/MainLayout.vue";
import {ref} from 'vue'
import {CameraIcon, ChevronDownIcon, QrCodeIcon, UserCircleIcon} from "@heroicons/vue/24/outline";
import Footer from "@/web/youpay-me-v3/Components/Blocks/Footer.vue";
import {Profile} from "@/web/youpay-me-v3/types";
import CartItems from "@/web/youpay-me-v3/Components/Checkout/CartItems.vue";
import {Bag} from "@/web/youpay-me-v3/types/checkout";

const user = usePage().props.auth;
const show_summary = ref(false);

const props = defineProps<{
  profile: Profile,
  bag: Bag,

}>();

</script>
