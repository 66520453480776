<template>
  <Head title="Update your Gift" />

  <div class="w-full bg-white dark:bg-gray-700">
    <div class="mx-auto max-w-4xl rounded-md p-8 text-gray-900 gift-page dark:text-white">
      <header class="flex flex-col-reverse items-end justify-between pb-4 md:flex-row">
        <div class="">
          <h1 class="text-2xl font-semibold">
            Update Your Gift Details
          </h1>
        </div>
        <StoreLogo v-if="store" :store="store" class=" flex-shrink-0 pl-4 w-[100px] md:w-[200px] md:pl-2" />
      </header>
      <div>
        <div class="flex flex-col border-y border-gray-900/10 space-y-4 divide-y divide-gray-200 dark:divide-gray-600 dark:border-gray-600">
          <div class="pt-4">
            <h2 class="text-base font-semibold leading-7">
              Gift Details
            </h2>
            <span class="text-xs leading-6 text-gray-600 dark:text-gray-200">
              This will be displayed on your wishlist.
            </span>

            <Wish
              class="relative"
              v-model="form.details"
              :errors="form.errors"
              error-key="details"
              :index="0"
              :currency="product.currency"
              :showFetch="false"
              :showAmount="can_update_amount"
              :denominations="product?.availableDenominations"
            />
          </div>

          <div class="pt-4" :class="user ? '' : 'hidden md:block'">
            <WishlistSelector v-model="form.wishlist" :wishlists="wishlists" :selected_wishlist="selected_wishlist" />
          </div>
        </div>

        <div v-if="submitted && form.hasErrors" class="mt-6 text-sm text-red-600 dark:text-red-400">
          Looks like there are some errors in your form. Please review and try again.
        </div>

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <Link :href="view_url"
                class="items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-center text-xs font-semibold text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 dark:bg-white/[0.03] dark:border-gray-500 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-800"
          >
            Cancel
          </Link>
          <PrimaryLink @click="submit" :disabled="form.processing">
            Update Gift
          </PrimaryLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Head, Link, usePage} from "@inertiajs/vue3";
import WishlistSelector from "@/web/youpay-me-v3/Components/Gifting/WishlistSelector.vue";
import {computed, inject, ref} from "vue";
import { useForm } from 'laravel-precognition-vue-inertia';
import Wish from "@/web/youpay-me-v3/Components/Gifting/Wish.vue";
import { GiftDetails, User} from "@/web/youpay-me-v3/types/index.d";
import { Settings, Store, lang } from "@/web/youpay-me-v3/types/gifting.d";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import StoreLogo from "@/web/youpay-me-v3/Components/Blocks/StoreLogo.vue";
import ProfileLayout from "@/web/youpay-me-v3/Layouts/ProfileLayout.vue";
import SecondaryButton from "@/web/youpay-me-v3/Components/Buttons/SecondaryButton.vue";
import PrimaryLink from "@/web/youpay-me-v3/Components/Buttons/PrimaryLink.vue";

const user: User | null = usePage().props.auth;
const trackEvent: Function = inject('trackEvent');
const submitted = ref(false);

const props = defineProps<{
  can_update_amount: boolean,
  gift_id: number,
  view_url: string,
  title?: string,
  description?: string,
  store: Store,
  selected_wishlist: string,
  wishlists: any,
  settings: Settings,
  product: any,
  product_id: number,
  details?: GiftDetails
  items?: GiftDetails[]
}>();

const form = useForm('post', route('gift.update', [props.gift_id]), {
  product: props.product_id,
  details: <GiftDetails>{
    url: props.details?.url ?? '',
    currency: props.product.currency,
    title: props.details?.title ?? '',
    image: props.details?.image ?? '',
    quantity: props.details?.quantity ?? 1,
    amount: props.details?.amount ?? 0,
    description: props.details?.description ?? '',
  },
  items: <GiftDetails[]> props.items ?? [],
  wishlist: props.selected_wishlist,
});

const submit = () => {
  submitted.value = true;
  form.submit({
    preserveScroll: true,
    onSuccess: () => {
      const gaData = {
        product: props.product_id,
        type: props.product.type,
      };
      if (props.store?.title) {
        gaData.store = props.store.title;
      }
      trackEvent('gift_updated', gaData);
      form.reset();
    }
  });
};

defineOptions({layout: ProfileLayout})
</script>