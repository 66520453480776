<template>
  <Head title="Log in"/>

  <div class="bg-white dark:bg-gray-700 md:bg-[url('/images/splash/bg-light.png')] md:dark:bg-[url('/images/splash/bg-dark.png')] bg-cover w-full h-full flex-grow bg-right relative flex md:items-center">
    <div class="inset-0 z-10 relative flex flex-col md:flex-row items-center h-full flex-grow overflow-y-auto gap-x-2 lg:gap-x-6">
      <div class="hidden md:block w-3/6 md:w-2/5 px-6 xl:w-3/5">
        <div class="gap-y-6 flex flex-col sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="mt-6 leading-[2rem] lg:leading-[3rem] text-[42px] lg:text-[62px] font-semibold text-gray-900 dark:text-white flex flex-col gap-y-6">
            <span>The wishlist</span>
            <span>for everyone</span>
          </h2>
          <p class="mt-4 text-xl text-gray-600 dark:text-gray-200 font-light">
            Share what you want, and let someone else pay, without sharing your personal details.
          </p>
        </div>
      </div>
      <div class="flex flex-1 flex-col justify-center md:px-6">
        <div class="bg-white dark:bg-gray-800 mx-auto md:rounded-lg px-6 py-12 lg:px-16">
          <div class="sm:mx-auto sm:w-full sm:max-w-lg text-center">
            <h1 class="lg:mt-0 text-3xl xl:text-4xl leading-9 font-semibold text-gray-900 dark:text-white">
              Sign in to your account
            </h1>
            <span class="py-4 dark:text-white text-gray-700 block">
              New here? <Link :href="route('register')" as="button" class="pl-1 text-youpay-navy dark:text-youpay-pink">Click here to create a new account</Link>
            </span>
          </div>

          <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" @submit.prevent="submit">
              <div>
                <InputLabel for="email" value="Email address"
                            class="block font-medium text-gray-900 dark:text-white"/>

                <div class="mt-2">
                  <TextInput
                    id="email"
                    type="email"
                    class="mt-1 block w-full"
                    v-model="form.email"
                    required
                    autofocus
                    autocomplete="username"
                  />
                </div>

                <InputError class="mt-2" :message="form.errors.email"/>
              </div>

              <div>
                <div class="flex items-center justify-between">
                  <InputLabel for="password" value="Password"
                              class="block font-medium text-gray-900 dark:text-white"/>

                  <div class="text-sm">
                    <Link
                      :href="route('password.request')"
                      class="font-semibold text-youpay-navy dark:text-youpay-pink hover:text-youpay-navy dark:hover:text-pink-400"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>

                <div class="mt-2">
                  <TextInput
                    id="password"
                    type="password"
                    class="mt-1 block w-full"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                  />
                </div>

                <InputError class="mt-2" :message="form.errors.password"/>
              </div>

              <div>
                <PrimaryButton
                  class="w-full"
                  :class="{ 'opacity-25': form.processing }" :disabled="form.processing"
                >
                  Sign in
                </PrimaryButton>
              </div>
            </form>
            <div>
              <div class="relative mt-10">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-200" />
                </div>
                <div class="relative flex justify-center text-sm font-medium leading-6">
                  <span class="bg-white dark:bg-gray-800 dark:text-white px-6 text-gray-900">Or continue with</span>
                </div>
              </div>

              <div class="mt-6 grid grid-cols-2 gap-4">
                <Link :href="route('email.login')" class="col-span-full flex w-full items-center justify-center gap-3 rounded-md bg-gray-600 dark:bg-gray-700 px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-youpay-pink">
                  <EnvelopeIcon class="h-5 w-5 text-white" />
                  <span class="text-sm font-semibold leading-6">Email me a login link</span>
                </Link>

<!--                <a @click.prevent="socialLogin('apple')" class="flex w-full items-center justify-center gap-3 rounded-md bg-black px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-youpay-pink">-->
<!--                  <svg class="h-5 w-5 text-white" fill="currentColor" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Apple</title><path d="M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.09 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.676-1.48 3.676-2.948 1.156-1.688 1.636-3.325 1.662-3.415-.039-.013-3.182-1.221-3.22-4.857-.026-3.04 2.48-4.494 2.597-4.559-1.429-2.09-3.623-2.324-4.39-2.376-2-.156-3.675 1.09-4.61 1.09zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.052-2.662.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.559-1.701"/></svg>-->
<!--                  <span class="text-sm font-semibold leading-6">Apple</span>-->
<!--                </a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import InputError from '../../Components/Form/InputError.vue';
import InputLabel from '../../Components/Form/InputLabel.vue';
import PrimaryButton from '../../Components/Buttons/PrimaryButton.vue';
import TextInput from '../../Components/Form/TextInput.vue';
import {Head, Link, useForm} from '@inertiajs/vue3';
import {inject} from "vue";
import {EnvelopeIcon} from '@heroicons/vue/24/outline';
import GuestLayout from "@/web/youpay-me-v3/Layouts/GuestLayout.vue";

defineOptions({layout: GuestLayout});

defineProps<{
  canResetPassword?: boolean;
  status?: string;
}>();

const form = useForm({
  email: '',
  password: '',
  remember: false,
});

const trackEvent = inject('trackEvent');

// platform : apple, twitch, facebook
const socialLogin = (platform) => {
  window.location.href = `/auth/${platform}`;
}

const submit = () => {
  form.post(route('login'), {
    onSuccess:() => {
      trackEvent('login', {
        method: 'Basic',
      });
    },
    onFinish: () => {
      form.reset('password');
    },
  });
};

</script>
