import {loadStripe} from "@stripe/stripe-js/pure";

/**
 * Get the theme
 *  - Dark mode is based on the system setting
 * @returns {{variables: {colorPrimary: string, colorBackground: string, colorText: string}}}
 */
export function getStripeTheme() {
    let theme = localStorage.youpayTheme ?? 'system';
    if (theme === 'system') {
        theme = (window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light'
    }
    let appearance = {
        variables: {
            fontFamily: 'Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
        },
        rules: {
            '.Input': {
                border: 'none',
            },
            '.Input:focus': {
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px #0CD9DC',
            },
            '.Label': {
                fontSize: '16px',
                fontWeight: '500',
            }
        }
    };
    if (theme === 'dark') {
        appearance.variables = {
            fontFamily: 'Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
            colorPrimary: '#ffffff',
            colorBackground: '#10141e',
            colorText: '#ffffff',
            colorDanger: '#f87171',
        };
        appearance.rules = {
            '.Input': {
                border: '1px solid #374151',
            },
            '.Input:focus': {
                outline: '1px solid #0CD9DC',
                outlineOffset: '0px',
            },
        }
    }
    return appearance;
}

let stripeElements;
let stripeContainer;

export function setupStripeContainer(stripe) {
    stripeContainer = stripe;
}

export function getStripeContainer() {
    return stripeContainer;
}

export function addAddressWatcher(callback) {
    stripeElements.getElement('address').on('change', function(event) {
        callback(event)
    });
}

export async function setupStripeElements(stripeConfig) {
    const key = stripeConfig?.key;
    setupStripeContainer(
        key ? await loadStripe(key) : await loadStripe()
    )

    stripeElements = stripeContainer.elements(stripeConfig?.secret ? {
        clientSecret: stripeConfig?.secret,
        appearance: getStripeTheme()
    } : {
        appearance: getStripeTheme()
    });
}

export function getStripeElements() {
    return stripeElements;
}

export function setupAddressElement(defaultValues, contacts) {
    const GOOGLE_MAPS_API_KEY = import.meta.env.SCRIPT_MAPS_API_KEY;

    const defaultAddressOptions = {
        mode: 'billing',
        autocomplete: {
            mode: 'google_maps_api',
            apiKey: GOOGLE_MAPS_API_KEY,
        },
        display: {
            name: 'split'
        },
        fields: {
            phone: 'always'
        },
        validation: {
            phone: {
                required: 'always'
            }
        },
    }
    let addressOptions = defaultAddressOptions;
    if (defaultValues) {
        addressOptions['defaultValues'] = defaultValues;
    }
    if (contacts) {
        addressOptions['contacts'] = contacts;
    }
    const addressElement = stripeElements.create('address', addressOptions);
    addressElement.mount('#address-element');
}

export function setupPaymentElement() {
    const paymentElement = stripeElements.create('payment', {
        fields: {
            billingDetails: 'never'
        },
    });
    paymentElement.mount('#payment-element');
}

export async function getStripeAddress() {
    const address = await getStripeElements().getElement('address').getValue();
    return address.value;
}