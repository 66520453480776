<template>
  <Head :title="profile.username + '\'s Wishlist'"/>
  <div class="h-full pb-8">
    <div v-if="!is_primary" class="flex flex-row justify-between pt-4 px-4 md:px-6">
      <Link :href="route('profile.show', profile.username)"
            class="text-sm font-semibold leading-7 text-gray-800 dark:text-white">
        <span aria-hidden="true">&larr;</span>
        Back to {{ profile.username }}'s profile
      </Link>
      <Link v-if="owner" :href="route('wishlist.edit', [profile.username, wishlist.url])"
            class="flex items-center gap-x-3 text-sm font-semibold leading-7 text-gray-800 dark:text-white">
        <PencilIcon class="h-4 w-4 text-gray-400" aria-hidden="true"/>
        Edit Wishlist
      </Link>
    </div>
    <div v-if="!uberMode" class="h-full">
      <ExpressProfileButtons v-if="user && owner" />

      <div v-if="!is_primary" class="pt-6 md:px-6 pb-6">
        <div class="">
          <div class="mx-auto px-4 sm:px-6 md:px-0">
            <h1
              class="flex flex-row items-center justify-between text-2xl leading-7 text-slate-900 dark:text-white">
              <span v-if="is_primary">
                My Wishlist
              </span>
              <span v-else>
                {{ wishlist.title }}
              </span>
              <ShareUrl type="Wishlist" :url="is_primary ? route('profile.show', profile.username) : route('wishlist.show', [profile.username, wishlist.url ])">
                  <span class="flex flex-row items-center gap-x-4 pb-2 text-sm">
                    <span class="hidden sm:inline-block">Share</span>
                    <ShareIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                  </span>
              </ShareUrl>
            </h1>
          </div>
        </div>
      </div>
      <div v-else class="pb-4"></div>

      <WishlistList :owner="owner" :is_primary="is_primary" :profile="profile" :wishlists="wishlists" />

      <FundList v-if="is_primary" :profile="profile" :funds="funds" />

      <GiftList :items="gifts" :bag="bag" :profile="profile" :owner="owner" :is_primary="is_primary" :pagination="pagination" />
    </div>
    <empty-wishlist v-else :owner="owner" :profile="profile" />
  </div>
  <GiftBagBar :bag="bag" :profile="profile"/>

</template>

<script setup lang="ts">
import {computed} from 'vue';
import {Head, usePage, Link} from "@inertiajs/vue3";
import { PencilIcon, ShareIcon } from '@heroicons/vue/24/outline'
import ProfileLayout from "@/web/youpay-me-v3/Layouts/ProfileLayout.vue";
import {Bag} from "@/web/youpay-me-v3/types/checkout";
import GiftBagBar from "@/web/youpay-me-v3/Components/Gifting/GiftBagBar.vue";
import ShareUrl from "@/web/youpay-me-v3/Components/Blocks/ShareUrl.vue";
import {Profile, WishlistInterface} from "@/web/youpay-me-v3/types/index.d";
import WishlistList from "@/web/youpay-me-v3/Components/Profile/WishlistList.vue";
import GiftList from "@/web/youpay-me-v3/Components/Profile/GiftList.vue";
import EmptyWishlist from "@/web/youpay-me-v3/Components/Profile/EmptyWishlist.vue";
import FundList from "@/web/youpay-me-v3/Components/Profile/FundList.vue";
import ExpressProfileButtons from "@/web/youpay-me-v3/Components/Buttons/ExpressProfileButtons.vue";

const user = usePage().props.auth;

const props = defineProps<{
  bag: Bag;
  profile: Profile;
  is_primary: boolean;
  wishlist: WishlistInterface;
  wishlists: WishlistInterface[];
  has_wishlists: boolean;
  gifts: any[];
  pagination?: any;
  funds: any[];
}>();

const owner = computed(() => {
  return props.profile.username === user?.username;
});

const uberMode = computed(() => {
  // if has wishlist => uberMode disabled
  // if wishlist empty and is primary => uberMode enabled
  if (props.has_wishlists) {
    return false;
  }
  return props.wishlist.empty && props.is_primary && owner.value;
});

// Set Page Layout
defineOptions({layout: ProfileLayout})
</script>
