<template>
  <Head title="Discord Settings" />

  <div class="md:py-8 text-gray-900 dark:text-white flex-shrink-0 w-full">
    <div class="max-w-3xl mx-auto p-8 bg-white dark:bg-white/5 rounded-lg ">
      <header class="pb-8 text-center flex items-center flex-col gap-y-6">
        <h1 class="text-4xl font-bold">
          Discord Server Settings
        </h1>
      </header>
      <div>
        <div class="grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6 flex-grow">
          <div class="col-span-full gap-x-1 rounded-md bg-yellow-50 px-3 py-1 text-sm text-yellow-700 ring-1 ring-inset ring-yellow-700/10 flex items-center">
            <InformationCircleIcon class="h-6 w-6 text-yellow-700" aria-hidden="true" />
            <div class="p-2">
              These settings are currently in beta and should only be edited if you understand what they do.
            </div>
          </div>

          <div class="hidden col-span-full pt-1">
            <h2 class="text-youpay-navy dark:text-youpay-pink text-lg font-semibold">Server Details</h2>
          </div>

          <div class="col-span-full pt-2">
            <InputLabel for="server_name" value="Server Name" />

            <TextInput
              id="server_name"
              type="text"
              class="mt-1 block w-full"
              v-model="form.name"
              @change="form.validate('name')"
              :error="form.errors.name"
              required
              autocomplete="name"
            />
            <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">Update your server name if it's been changed</p>

            <InputError class="mt-2" :message="form.errors.name"/>
          </div>

          <div class="col-span-full">
            <InputLabel for="channel_id" value="Channel ID for all Discord Messages" />

            <TextInput
              id="channel_id"
              type="text"
              class="mt-1 block w-full"
              v-model="form.channel_id"
              @change="form.validate('channel_id')"
              :error="form.errors.channel_id"
              required
              autocomplete="channel_id"
            />
            <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">This feature will be enabled shortly.</p>
<!--            <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">All messages by YouPay will be posted in this channel. Use the ID not the channel name here.</p>-->

            <InputError class="mt-2" :message="form.errors.channel_id"/>
          </div>

          <div class="col-span-full opacity-75">
            <InputLabel for="payer_role_id" value="Payer Role" />

            <TextInput
              id="payer_role_id"
              type="text"
              class="mt-1 block w-full"
              v-model="form.payer_role_id"
              @change="form.validate('payer_role_id')"
              :error="form.errors.payer_role_id"
              autocomplete="payer_role_id"
              placeholder="coming soon"
              :disabled="true"
            />
            <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">Give a special role to people who send gifts.</p>

            <InputError class="mt-2" :message="form.errors.payer_role_id"/>
          </div>

          <div class="col-span-full opacity-75">
            <InputLabel for="creator_role_id" value="Creator Role" />

            <TextInput
              id="creator_role_id"
              type="text"
              class="mt-1 block w-full"
              v-model="form.creator_role_id"
              @change="form.validate('creator_role_id')"
              :error="form.errors.creator_role_id"
              autocomplete="creator_role_id"
              placeholder="coming soon"
              :disabled="true"
            />
            <p class="mt-1 pl-1 text-xs leading-6 text-gray-400">Give a special role to people who are given gifts.</p>

            <InputError class="mt-2" :message="form.errors.creator_role_id"/>
          </div>

        </div>

        <fieldset class="col-span-full pt-4 mt-6 border-t border-gray-200">
          <div class="space-y-2">
            <div class="">
              <InputLabel for="leaderboard" value="Leaderboard Style" class="py-1" />

              <label class="flex items-center">
                <Checkbox name="leaderboard" v-model:checked="form.leaderboard" @update:checked="form.validate('leaderboard')" :disabled="true" />
                <span for="anon" class="ml-2 text-sm text-gray-600 dark:text-gray-400 opacity-75">
                  Let the leaderboard use all payments, not just server related ones - coming soon
                </span>
              </label>
            </div>
            <div class="">
              <InputLabel for="twitter" value="Auto Tweet" class="py-1" />

              <label class="flex items-center">
                <Checkbox name="twitter" v-model:checked="form.twitter" @update:checked="form.validate('twitter')" :disabled="true" />
                <span class="ml-2 text-sm text-gray-600 dark:text-gray-400 opacity-75">
                  Tweet about all server payments - coming soon
                </span>
              </label>
            </div>
            <div class="">
              <InputLabel for="mean" value="Mean Mode" class="py-1" />

              <label class="flex items-center">
                <Checkbox name="mean" v-model:checked="form.mean" @update:checked="form.validate('mean')" />
                <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                  Enable mean mode
                </span>
              </label>
            </div>
          </div>
        </fieldset>

        <div class="mt-6">
          <PrimaryButton @click="submit" :disabled="form.processing" class="w-full">
            Update Settings
          </PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Head} from "@inertiajs/vue3";
import { useForm } from 'laravel-precognition-vue-inertia';
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import {InformationCircleIcon} from "@heroicons/vue/24/outline";

const props = defineProps<{
  server: any;
}>();

const form = useForm('post', route('discord.server.settings', props.server.server_id), {
  name: props.server.name ?? '',
  channel_id: props.server.channel_id ?? '',
  leaderboard: false,
  twitter: false,
  mean: props.server.mean ?? false,
  payer_role_id: props.server.payer_role_id ?? '',
  creator_role_id: props.server.creator_role_id ?? '',
});

const submit = () => {
  form.submit({
    preserveScroll: false,
    onSuccess: () => {
      form.reset();
    }
  });
};
</script>