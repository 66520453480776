<template>
  <Head title="Checkout"/>

  <form class="">
    <div class="pb-4">
      <h1 class="text-3xl dark:text-white">
        Pay for {{ profile.username }}
      </h1>

      <PayerMessage
        v-if="can_message"
        class="pt-4"
        :bag_id="bag.id"
        :message="message"
        :username="profile.username"
      />
    </div>

    <CheckoutLogin :bag_id="bag.id" />

    <h2 id="contact-info-heading" class="mb-4 pt-2 text-lg font-medium text-gray-900 dark:text-gray-50">
      Billing information
    </h2>
    <div class="grid gap-y-6 gap-x-4 grid-cols-6">
      <div class="col-span-3">
        <InputLabel for="first_name" value="First Name"/>
        <TextInput
          id="first_name"
          v-model="form.details.first_name"
          :error="form.errors['details.first_name']"
          class="mt-2 block w-full"
          required
          type="text"
          @change="form.validate('details.first_name')"
        />
        <InputError :message="form.errors['details.first_name']" class="mt-2"/>
      </div>
      <div class="col-span-3">
        <InputLabel for="last_name" value="Last Name"/>
        <TextInput
          id="last_name"
          v-model="form.details.last_name"
          :error="form.errors['details.last_name']"
          class="mt-2 block w-full"
          required
          type="text"
          @change="form.validate('details.last_name')"
        />
        <InputError :message="form.errors['details.last_name']" class="mt-2"/>
      </div>
      <div class="col-span-full">
        <InputLabel for="email" value="Email Address"/>
        <TextInput
          id="email"
          v-model="form.email"
          :error="form.errors.email"
          autocomplete="email"
          class="mt-1 block w-full"
          required
          type="email"
          @change="form.validate('email')"
        />
        <InputError :message="form.errors.email" class="mt-2"/>
      </div>
      <div class="col-span-full">
        <InputLabel class="pb-1" for="phone" value="Phone Number"/>

        <MazPhoneNumberInput
          v-model="form.details.phone"
          :error="form.invalid('details.phone')"
          :ignored-countries="['AC']"
          :preferred-countries="['AU', 'FR', 'BE', 'DE', 'US', 'GB']"
          class="overflow-visible"
          color="info"
          label=""
          fetch-country
          show-code-on-list
          @country-code="debounceUpdateCountryCode"
          @change="form.validate('details.phone')"
        />

        <InputError :message="form.errors['details.phone']" class="mt-2"/>
      </div>

      <div class="col-span-full">
        <AddressForm :form="form" :ipdetails="ipdetails" />
      </div>

      <div class="col-span-full">
        <div class="-mt-2 min-h-[100px]">
          <div id="cardData" ref="cardDataDiv"></div>
          <div id="wallet-buttons"></div>
        </div>

        <div class="mt-4">
          <label class="flex items-center">
            <Checkbox v-model:checked="form.terms" name="terms" @update:checked="form.validate('terms')"/>
            <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">
                I agree to the <a :href="route('terms')" class="underline" target="_blank">Terms, Conditions & Privacy Policies</a>
              </span>
          </label>
          <InputError :message="form.errors.terms" class="mt-2"/>
          </div>
        </div>
      </div>

    <!--          <div v-if="submitted && form.hasErrors" class="mt-6 text-sm text-red-600 dark:text-red-400">-->
    <!--            Looks like there are some errors in your form. Please review and try again.-->
    <!--          </div>-->

    <InputError :message="form.errors.error" class="mt-2"/>

    <button
      class="mt-6 w-full rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-youpay-navy-light hover:bg-youpay-navy-alt focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      type="submit"
      @click.stop="submit"
    >
      <span v-if="!form.processing">
        Pay
      </span>
      <span v-else>
        Processing...
      </span>
    </button>
  </form>
</template>

<script lang="ts" setup>
import {Head, Link} from "@inertiajs/vue3";
import {onMounted, ref} from 'vue'
import {Bag, compileForAnalytics} from "@/web/youpay-me-v3/types/checkout.d";
import {Profile, User} from "@/web/youpay-me-v3/types";
import {useForm} from 'laravel-precognition-vue-inertia';
import InputError from "@/web/youpay-me-v3/Components/Form/InputError.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import TextInput from "@/web/youpay-me-v3/Components/Form/TextInput.vue";
import InputLabel from "@/web/youpay-me-v3/Components/Form/InputLabel.vue";
import {trackEvent} from "@/web/youpay-me-v3/helpers/tracking";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import {loadScript, resetPayframe, submitPayframe} from "@/web/youpay-me-v3/helpers/MerchantWarrior";
import type {warriorConfig, address, IpDetails} from "@/web/youpay-me-v3/helpers/MerchantWarrior";
import CheckoutLayout from "@/web/youpay-me-v3/Layouts/CheckoutLayout.vue";
import CheckoutLogin from "@/web/youpay-me-v3/Components/Checkout/CheckoutLogin.vue";
import PayerMessage from "@/web/youpay-me-v3/Components/Checkout/PayerMessage.vue";
import AddressForm from "@/web/youpay-me-v3/Components/Form/AddressForm.vue";
import {debounce} from "lodash";

defineOptions({layout: CheckoutLayout})

// Get Props
const props = defineProps<{
  auth?: User,
  profile: Profile,
  bag: Bag,
  address?: address
  warriorConfig: warriorConfig,
  message: string,
  can_message: boolean,
  ipdetails?: IpDetails,
}>();


const form = useForm('post', route('checkout.process.alt', {
  account: props.profile.username,
  bag: props.bag.id,
}), {
  gateway: 'warrior',
  PaymentData: {
    reference: 'BAG-' + props.bag.id,
    description: 'BAG-' + props.bag.id,
    amount: props.bag.raw_total,
    currency: props.bag.currency,
  },
  email: props.auth?.email ?? '',
  message: props.message ?? '',
  details: {
    first_name: props.address?.first_name ?? '',
    last_name: props.address?.last_name ?? '',
    phone: props.address?.phone ?? '',
  },
  address: {
    line1: props.address?.address_1 ?? '',
    line2: props.address?.address_2 ?? '',
    city: props.address?.suburb ?? '',
    state: props.address?.state ?? '',
    postal_code: props.address?.postcode ?? '',
    country: props.address?.country_code ?? '',
  },
  terms: false,
});

const submit = async function (e) {
  e.preventDefault();

  // Temp while waiting for this issue to resolve: https://github.com/laravel/precognition/issues/59
  await form.touch([
    'email',
    'details.first_name',
    'details.last_name',
    'details.phone',
    'address.line1',
    'address.city',
    'address.state',
    'address.postal_code',
    'address.country',
  ]).validate();
  if (form.email && !form.email.includes('@')) {
    form.setError('email', 'Please enter a valid email address');
  }
  if (form.details.phone && form.details.phone.length < 8) {
    form.setError('details.phone', 'Please enter a valid phone number');
  }
  if (form.details.first_name && form.details.first_name.length < 2) {
    form.setError('details.first_name', 'Please enter a valid first name');
  }
  if (form.details.last_name && form.details.last_name.length < 2) {
    form.setError('details.last_name', 'Please enter a valid last name');
  }
  if (form.address.line1 && form.address.line1.length < 2) {
    form.setError('address.line1', 'Please enter a valid address');
  }
  if (form.address.city && form.address.city.length < 2) {
    form.setError('address.city', 'Please enter a valid city');
  }
  if (form.address.state && form.address.state.length < 2) {
    form.setError('address.state', 'Please enter a valid state');
  }
  if (form.address.postal_code && form.address.postal_code.length < 2) {
    form.setError('address.postal_code', 'Please enter a valid postal code');
  }
  if (form.address.country && form.address.country.length < 2) {
    form.setError('address.country', 'Please enter a valid country');
  }

  // Validate the form
  if (!form.terms) {
    form.setError('terms', "You must agree to the terms and conditions");
    return;
  }
  if (form.hasErrors) {
    return;
  }

  form.processing = true;

  submitPayframe();
}

const updateCountryCode = (code:string) => form.address.country = code;
const debounceUpdateCountryCode = debounce(updateCountryCode, 500);

const completeCheckout = function (payframeToken: string, payframeKey: string, threeDSToken: string = '', liabilityShifted: boolean = false) {
  try {
    const gaData = compileForAnalytics(props.profile.username, props.bag);
    // gaData.transaction_id = result.paymentIntent.id;
    trackEvent('purchase', gaData);
  } catch (e) {} // Silently fail

  form.transform((data: Object) => ({
    ...data,
    details: {
      ...data.details,
      name: `${data.details.first_name} ${data.details.last_name}`,
    },
    payment_data: {
      accessToken: props.warriorConfig.token,
      payframeToken,
      payframeKey,
      threeDSToken,
      liabilityShifted,
    }
  })).submit({
    onError: (error) => {
      console.error(error);
      form.setError('error', 'There was an error with that request, please try again.');
      resetPayframe(true);
    }
  });
}

const cardDataDiv = ref('cardDataDiv');

/**
 * Load Stripe
 */
onMounted(async () => {
  trackEvent('begin_checkout', compileForAnalytics(props.profile.username, props.bag));
  loadScript(props.warriorConfig, completeCheckout, form, cardDataDiv.value);
});
</script>