<template>

</template>

<script setup lang="ts">
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-vue-v3';
import {onMounted} from "vue";
import {useForm} from "laravel-precognition-vue-inertia";

const props = defineProps<{
  bag_id?: number,
}>();

const form = useForm('put', route('checkout.finger', { bag: props.bag_id }), {
  finger: ''
});

const {data, error, isLoading, getData} = useVisitorData(
  {extendedResult: true},
  {immediate: false}
);

onMounted(async () => {
  await getData({
    ignoreCache: true
  });

  form.finger = data.value;
  form.submit();
});
</script>