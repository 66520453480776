<template>
  <Head title="Gifting Settings" />

  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div class="pt-2">
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        Have some products that have gone offline?
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Convert them all quickly to a new product.
      </p>
    </div>
    <form class="md:col-span-2 flex items-center">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="sm:col-span-3">

          <div class="flex items-center flex-col gap-y-4">
            <a target="_blank" :href="route('settings.convert-offline', 'coinflow')" class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center">
              Convert them to Coinflow
            </a>
            <!--            divider -->
            <span class="text-sm text-gray-600 dark:text-gray-400">
              or
            </span>
            <a target="_blank" :href="route('settings.convert-offline', 'stripe')" class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center">
              Convert them to Stripe
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div v-if="user.can?.createConnect" class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div class="pt-2">
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        Convert Stripe Gifts to Coinflow
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Convert your existing Stripe Connect gifts to Coinflow gifts.
      </p>
    </div>
    <form class="md:col-span-2 flex items-center">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full flex items-center justify-center w-full flex-col gap-y-4">
          <a target="_blank" :href="route('settings.coinflow.convert')"
             class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center">
            Convert Stripe Gifts to Coinflow
          </a>
        </div>
      </div>
    </form>
  </div>
  <div v-if="user?.can?.createCoinflow" class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div class="pt-2">
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        Convert Coinflow Gifts to Stripe Connect
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Convert your existing Coinflow gifts to Stripe Connect gifts.
      </p>
    </div>
    <form class="md:col-span-2 flex items-center">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="sm:col-span-3">
          <div class="flex items-center flex-col gap-y-4">
            <a target="_blank" :href="route('connect.convert')" class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center">
              Convert Coinflow Gifts to Stripe Connect
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>


  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div class="pt-2">
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        More Features Coming Soon
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        We are working on more features to make your gifting experience better.
      </p>
    </div>
    <form class="md:col-span-2 flex items-center">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="col-span-full flex items-center justify-center w-full flex-col gap-y-4">

        </div>
      </div>
    </form>
  </div>
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-6 md:gap-y-10 px-4 py-6 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
    <div class="pt-2">
      <h2 class="text-base font-semibold leading-7 dark:text-white text-gray-700">
        Need Help?
      </h2>
      <p class="mt-1 text-sm leading-6 dark:text-gray-400 text-gray-600">
        Visit the Help Center for more information
      </p>
    </div>
    <form class="md:col-span-2 flex items-center">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div class="sm:col-span-3">
          <div class="flex items-center flex-col gap-y-4">
            <a class="items-center px-4 py-2 bg-white dark:bg-white/[0.03] border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150 text-center text-xs" target="_blank" href="https://support.youpay.co">
              Visit the Help Center
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>


</template>

<script setup lang="ts">
import {Head, usePage} from '@inertiajs/vue3';
import SettingsLayout from "@/web/youpay-me-v3/Layouts/SettingsLayout.vue";
import Checkbox from "@/web/youpay-me-v3/Components/Form/Checkbox.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import {ref} from "vue";
import PrimaryButton from "@/web/youpay-me-v3/Components/Buttons/PrimaryButton.vue";
import SecondaryButton from "@/web/youpay-me-v3/Components/Buttons/SecondaryButton.vue";
import SecondaryLink from "@/web/youpay-me-v3/Components/Buttons/SecondaryLink.vue";
import {User} from "@/web/youpay-me-v3/types";

const props = defineProps<{
  twitter_auth: boolean;
  forever?: boolean;
  searchable?: boolean;
  anon?: boolean;
  require_approval?: boolean;
  can_message?: boolean;
  all_gifts?: boolean;
  allow_following_messages?: boolean;
}>();

const vvalue = ref(false)

const form = useForm('post', route('settings.privacy.update'), {
  forever: props.forever ?? true,
  searchable: props.searchable ?? true,
  anon: props.anon ?? false,
  require_approval: props.require_approval ?? false,
  allow_following_messages: props.allow_following_messages ?? true,
  can_message: props.can_message ?? true,
  all_gifts: props.all_gifts ?? true,
});

const user = <User>usePage().props.auth;

//convert-offline

const submit = () => {
  form.submit({
    preserveScroll: true
  });
};

defineOptions({ layout: SettingsLayout })
</script>