<template>
  <div class="">
    <svg id="visa_mastercard_icon" class="h-7 hidden dark:block" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.99 50.5">
      <g id="visa_mastercard_icon_1-2" data-name="Layer 1">
        <g>
          <path class="cls-2" d="M36.85,42.09l6.9-33.59h8.59l-6.9,33.59h-8.58Z"/>
          <path class="cls-2" d="M77.09,9.33c-1.7-.68-4.37-1.41-7.69-1.41-8.48,0-14.46,4.56-14.51,11.08-.05,4.82,4.27,7.52,7.53,9.13,3.34,1.64,4.46,2.69,4.45,4.17-.02,2.25-2.67,3.28-5.14,3.28-3.43,0-5.26-.51-8.09-1.76l-1.11-.54-1.2,7.52c2.01.93,5.72,1.75,9.56,1.79,9.03,0,14.89-4.51,14.95-11.48.03-3.82-2.25-6.73-7.21-9.13-3-1.56-4.84-2.59-4.82-4.17,0-1.4,1.56-2.89,4.92-2.89,2.81-.04,4.84.61,6.43,1.29l.77.39,1.17-7.28"/>
          <path class="cls-2" d="M99.11,8.53h-6.64c-2.06,0-3.59.6-4.5,2.79l-12.75,30.78h9.02s1.48-4.14,1.81-5.05c.99,0,9.74.01,11,.01.25,1.17,1.04,5.04,1.04,5.04h7.97l-6.95-33.57ZM88.52,30.19c.71-1.93,3.42-9.39,3.42-9.39-.05.09.7-1.94,1.14-3.21l.58,2.9s1.64,8.02,1.98,9.7h-7.13,0Z"/>
          <path class="cls-2" d="M31.18,8.52l-8.92,22.91-.9-4.66-3.01-15.44c-.52-2.13-2.03-2.76-3.89-2.83H0v.91c5.79,1.22,7.48,2.12,10.04,3.98.13.53,7.12,28.66,7.12,28.66h9.08s14.03-33.54,14.03-33.54h-9.1Z"/>
        </g>
        <g>
          <rect class="cls-1" x="153.08" y="5.4" width="22.1" height="39.71"/>
          <path class="cls-3" d="M154.49,25.25c0-7.75,3.55-15.07,9.64-19.85-10.96-8.62-26.84-6.72-35.46,4.25-8.62,10.96-6.72,26.84,4.25,35.46,9.16,7.2,22.05,7.2,31.21,0-6.1-4.78-9.65-12.1-9.64-19.85Z"/>
          <path class="cls-4" d="M204.99,25.25c0,13.95-11.3,25.25-25.25,25.25-5.66,0-11.16-1.9-15.61-5.4,10.96-8.62,12.86-24.5,4.24-35.46-1.24-1.58-2.66-3-4.24-4.24,10.96-8.62,26.84-6.72,35.46,4.25,3.5,4.45,5.4,9.95,5.4,15.61Z"/>
          <path class="cls-4" d="M202.58,40.9v-.81h.33v-.17h-.83v.17h.33v.81h.18ZM204.2,40.9v-.98h-.26l-.29.67-.29-.67h-.26v.98h.18v-.74l.28.64h.19l.28-.64v.74h.18Z"/>
        </g>
      </g>
    </svg>
    <svg id="Layer_2" class="h-7 dark:hidden block" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 205 50.5">
      <g id="Layer_1-2" data-name="Layer 1-2">
        <g>
          <path class="cls-4" d="M36.8,42.1l6.9-33.6h8.6l-6.9,33.6h-8.6Z"/>
          <path class="cls-4" d="M77.1,9.3c-1.7-.7-4.4-1.4-7.7-1.4-8.5,0-14.5,4.6-14.5,11.1,0,4.8,4.3,7.5,7.5,9.1,3.3,1.6,4.5,2.7,4.4,4.2,0,2.2-2.7,3.3-5.1,3.3s-5.3-.5-8.1-1.8l-1.1-.5-1.2,7.5c2,.9,5.7,1.8,9.6,1.8,9,0,14.9-4.5,15-11.5,0-3.8-2.2-6.7-7.2-9.1-3-1.6-4.8-2.6-4.8-4.2,0-1.4,1.6-2.9,4.9-2.9,2.8,0,4.8.6,6.4,1.3l.8.4,1.2-7.3"/>
          <path class="cls-4" d="M99.1,8.5h-6.6c-2.1,0-3.6.6-4.5,2.8l-12.8,30.8h9s1.5-4.1,1.8-5c1,0,9.7,0,11,0,.2,1.2,1,5,1,5h8s-6.9-33.6-7-33.6ZM88.5,30.2c.7-1.9,3.4-9.4,3.4-9.4,0,0,.7-1.9,1.1-3.2l.6,2.9s1.6,8,2,9.7h-7.1,0Z"/>
          <path class="cls-4" d="M31.2,8.5l-8.9,22.9-.9-4.7-3-15.4c-.5-2.1-2-2.8-3.9-2.8H0v.9c5.8,1.2,7.5,2.1,10,4,.1.5,7.1,28.7,7.1,28.7h9.1l14-33.5h-9.1,0Z"/>
        </g>
        <g>
          <rect class="cls-1" x="153.1" y="5.4" width="22.1" height="39.7"/>
          <path class="cls-2" d="M154.5,25.2c0-7.8,3.6-15.1,9.6-19.9-11-8.6-26.8-6.7-35.5,4.2-8.6,11-6.7,26.8,4.2,35.5,9.2,7.2,22.1,7.2,31.2,0-6.1-4.8-9.6-12.1-9.6-19.9h0Z"/>
          <path class="cls-3" d="M205,25.2c0,14-11.3,25.2-25.2,25.2s-11.2-1.9-15.6-5.4c11-8.6,12.9-24.5,4.2-35.5-1.2-1.6-2.7-3-4.2-4.2,11-8.6,26.8-6.7,35.5,4.2,3.5,4.4,5.4,9.9,5.4,15.6h0Z"/>
          <path class="cls-3" d="M202.6,40.9v-.8h.3v-.2h-.8v.2h.3v.8h.2,0ZM204.2,40.9v-1h-.3l-.3.7-.3-.7h-.3v1h.2v-.7l.3.6h.2l.3-.6v.7h.2,0Z"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<style scoped>
  .cls-1 {
    fill: #ff5f00;
  }

  .cls-1, .cls-2, .cls-3, .cls-4 {
    stroke-width: 0px;
  }

  .dark .cls-2 {
    fill: #1434cb;
  }

  .dark .cls-3 {
    fill: #eb001b;
  }

  .dark .cls-4 {
    fill: #f79e1b;
  }

  .cls-2 {
    fill: #eb001b;
  }

  .cls-3 {
    fill: #f79e1b;
  }

  .cls-4 {
    fill: #fff;
  }
</style>
