<template>
  <div>
    <div class="flex justify-between">
      <label :for="id" class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">{{label}}</label>
      <!-- if slot (name: optional) is provided, use it instead of the optional prop -->
      <span class="text-sm leading-6 text-gray-500 dark:text-gray-200">
        <slot name="optional" ></slot>
      </span>

    </div>
    <div class="relative mt-2 rounded-md shadow-sm" :class="{'pointer-events-none': icon || errorIcon}">
      <div v-if="icon" class="absolute inset-y-0 left-0 flex items-center pl-3">
        <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <div v-else-if="errorIcon" class="absolute inset-y-0 right-0 flex items-center pr-3">
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
      <input :type="type" :name="id" :id="id" :class="inputClasses" :placeholder="placeholder" :disabled="disabled" :is-error="error" :aria-describedby="id + '-description'"/>
    </div>
    <p class="mt-2 text-sm" :class="{'text-gray-500': !error, 'text-red-600': error}" :id="id + '-description'">{{helpText}}</p>
  </div>
</template>

<script>
import { ExclamationCircleIcon, EnvelopeIcon } from '@heroicons/vue/20/solid'
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    helpText: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    optional: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    },
    errorIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputClasses() {
      if (this.disabled) {
        return 'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6'
      } else if (this.error) {
        return 'block w-full rounded-md border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6'
      } else {
        return 'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
      }
    }
  }
}
</script>