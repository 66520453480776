<template>
  <Modal :show="confirmDeletion" @close="confirmDeletion = false">
    <div class="p-6">
      <h2 class="text-lg font-medium text-gray-900 dark:text-gray-100">
        Are you sure you want to delete?
      </h2>

      <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
        {{ giftToDelete?.title }} will be permanently deleted.
      </p>

      <div class="mt-6 flex justify-end">
        <SecondaryButton @click="giftToDelete = null"> Cancel</SecondaryButton>

        <DangerButton
          class="ml-3"
          @click.prevent="deleteItem"
          :disabled="deleting"
        >
          {{ deleting ? 'deleting...' : 'Yes, please delete' }}
        </DangerButton>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/web/youpay-me-v3/Components/Blocks/Modal.vue";
import SecondaryButton from "@/web/youpay-me-v3/Components/Buttons/SecondaryButton.vue";
import DangerButton from "@/web/youpay-me-v3/Components/Buttons/DangerButton.vue";
import {ref, watch} from "vue";
import {router} from "@inertiajs/vue3";
import {Gift} from "@/web/youpay-me-v3/types";

const confirmDeletion = ref(false);
const deleting = ref(false);

const giftToDelete = defineModel<Gift|null>();

watch(() => giftToDelete.value, (value) => {
  if (value) {
    confirmDeletion.value = true;
  } else {
    confirmDeletion.value = false;
  }
});

const deleteItem = async function () {
  deleting.value = true;
  router.delete(route('gift.destroy', giftToDelete.value?.id), {
    preserveScroll: true,
    preserveState: true,
    onSuccess: () => {
      const el = document.getElementById('gift-hit-' + giftToDelete.value?.id);
      el?.parentNode?.removeChild(el);
      deleting.value = false;
      confirmDeletion.value = false;
      giftToDelete.value = null;
    },
    onError: () => {
      deleting.value = false;
    }
  });
}
</script>